import { PortalAreaEnum } from "../../dtos/portal-area-enum";

export type DocumentsProps = {
  portalArea: PortalAreaEnum;
};

export type PortalAreaInformation = {
  areaId: number;
  areaTitle: string;
  areaDescription: string;
};

export const portalAreaInformation: PortalAreaInformation[] = [
  {
    areaId: PortalAreaEnum.GENERAL_DOCUMENTS,
    areaTitle: "Documents and Forms",
    areaDescription:
      "The information available here allows you to stay informed regarding products and policies.  To view PDF files, you should have the free Adobe Reader installed, or comparable browser addon.  Access the Adobe website to download the latest version of Adobe Reader.",
  },
  {
    areaId: PortalAreaEnum.LOSS_CONTROL_DOCUMENTS,
    areaTitle: "Safety and Loss Control",
    areaDescription:
      "The information available here allows you to stay informed regarding products and policies.  To view PDF files, you should have the free Adobe Reader installed, or comparable browser addon.  Access the Adobe website to download the latest version of Adobe Reader.",
  },
];
