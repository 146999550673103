import { FC, useEffect, useState } from "react";
import style from "./ModalNewPaymentSelectorStyles.module.css";
import { ModalNewPaymentSelectorProps } from "./PaymentSelectorTypes";
import { useApiGet } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { Button, Modal } from "../../../TrueUI";
import { ProcessorTypeEnum } from "../../../../dtos/processor-type-enum";
import { ProcessorFunctionEnum } from "../../../../dtos/processor-function-enum";

const ModalNewPaymentSelector: FC<ModalNewPaymentSelectorProps> = ({
  configuration,
  closeModal,
  openCreditCardModal,
  openCreditCardRedirectModal,
  openAchModal,
}) => {
  const [processorType, setProcessorType] = useState<number | null>(null);

  const { responseGet, dispatchGet } = useApiGet<number>(
    `api/Processor/GetProcessorFunctionByProgramBasedInInsuredId?processorType=${processorType}`
  );

  useEffect(() => {
    if (processorType !== null) {
      dispatchGet();
      closeModal();
    }
  }, [processorType]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      if (responseGet.axiosResponse?.data === ProcessorFunctionEnum.INTERNAL) {
        if (processorType === ProcessorTypeEnum.CREDIT_CARD) {
          openCreditCardModal?.();
          setProcessorType(null);
        }
        if (processorType === ProcessorTypeEnum.ACH) {
          openAchModal?.();
          setProcessorType(null);
        }
      }
      if (responseGet.axiosResponse?.data === ProcessorFunctionEnum.REDIRECT) {
        if (processorType === ProcessorTypeEnum.CREDIT_CARD) {
          openCreditCardRedirectModal?.();
          setProcessorType(null);
        }
      }
    }
  }, [responseGet]);

  const onOpenCreditCardModal = () => {
    setProcessorType(ProcessorTypeEnum.CREDIT_CARD);
  };

  const onOpenAchModal = () => {
    setProcessorType(ProcessorTypeEnum.ACH);
  };

  return (
    <Modal
      id={"add-payment"}
      title={"New Payment"}
      open={configuration.isOpen}
      cancelEvent={closeModal}
      showCloseButton={false}
      size="xs"
    >
      <div className={style.new_payment_selector_button_container}>
        {configuration.hasCreditCard && (
          <Button onClick={onOpenCreditCardModal}>CREDIT CARD</Button>
        )}
        {configuration.hasAch && (
          <Button onClick={onOpenAchModal}>BANK DRAFT</Button>
        )}
      </div>
      <div
        className={`${style.new_payment_selector_button_container} ${style.new_payment_selector_cancel_container}`}
      >
        <Button variantStyle="outlined" onClick={closeModal}>
          CANCEL
        </Button>
      </div>
    </Modal>
  );
};

export default ModalNewPaymentSelector;
