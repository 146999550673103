import { FC } from "react";
import PortalPageContainer from "../../TrueUI/Containers/PortalPageContainer";
import { useParams } from "react-router";
import billingStyle from "../Billing.module.css";
import { Heading6 } from "../../TrueUI";
import PayrollReportHeader from "./PayrollReportHeader";
import PayrollReportDetailsTable from "./PayrollReportDetailsTable";

const PayrollReport: FC = () => {
  const { payrollReportId } = useParams();

  return (
    <PortalPageContainer title={"Billing"} childrenContainerHeight="100%">
      <Heading6>Payroll Report</Heading6>
      <div className={billingStyle.billing_components_container}>
        <PayrollReportHeader payrollReportId={payrollReportId} />
        <PayrollReportDetailsTable payrollReportId={payrollReportId} />
      </div>
    </PortalPageContainer>
  );
};
export default PayrollReport;
