import { SxProps, Theme } from "@mui/material";
import { FontsType } from "../media/themeTypes";
import { KeysAsType } from "../types/KeysAsAType";
export const selectConfiguration = (
  id: string,
  name: string,
  label: string,
  readOnly?: boolean
) => {
  return {
    id: id,
    name: name,
    label: label,
    selectContainerWidth: "100%",
    readOnly: readOnly ?? false,
  };
};

export const inputConfiguration = (
  id: string,
  name: string,
  label: string,
  maxLength: number,
  readOnly?: boolean,
  sx?: SxProps<Theme>
) => {
  return {
    id: id,
    name: name,
    label: label,
    maxLength: maxLength,
    selectContainerWidth: "100%",
    readOnly: readOnly ?? false,
    sx: sx,
  };
};

export const formInputConfiguration = (
  id: string,
  name: string,
  label: string,
  readOnly?: boolean
) => {
  const variant: "filled" | "standard" | undefined = "filled";
  const labelFontType: KeysAsType<FontsType> | undefined = "BOLD_CAPTION";
  return {
    id: id,
    name: name,
    label: label,
    phoneLabel: label,
    readOnly: readOnly ?? false,
    labelFontType: labelFontType,
    variant: variant,
  };
};
