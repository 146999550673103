import { InsuredAccountDto } from "../../../../dtos/insured-account-dto";
import { PayAccountTypeEnum } from "../../../../dtos/pay-account-type-enum";
import { StatusEnums } from "../../../../dtos/status-enums";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";

export type BillingAccountProps = {
  isOpen: boolean;
  action: "add" | "edit" | "delete";
  rowData?: any;
  modalData: InsuredAccountDto;
};

export type BillingAccountModalProps = {
  insuredId?: number;
  setBillingAccount: (value: BillingAccountProps) => void;
  billingAccount: BillingAccountProps;
  dispatchGetConfig?: () => void;
  setRefresh?: (value: boolean) => void;
};

export type BillingAccountModalContentProps = {
  insuredId?: number;
  billingAccount?: BillingAccountProps;
  billingAccountData?: InsuredAccountDto | null;
  setBillingAccountData: (value: InsuredAccountDto) => void;
  errorMessages: any;
  setErrorMessages: (value: any) => void;
};

export const defaultBillingAccountData: InsuredAccountDto = {
  insuredId: 0,
  insuredAccountId: 0,
  accountTypeId: 1,
  accountStatusId: 1,
  nickName: "",
  routingNumber: "",
  accountNumber: "",
  useForACH: false,
  externalToken: "",
};

export const defaultBillingAccount: BillingAccountProps = {
  isOpen: false,
  action: "add",
  rowData: null,
  modalData: defaultBillingAccountData,
};

export const accountTypeOptions = [
  { value: PayAccountTypeEnum.BANK, label: "Bank Account" },
  { value: PayAccountTypeEnum.CREDIT_CARD, label: "Credit Card" },
];

export const payAccountStatus = [
  { displayName: "Active", intValue: StatusEnums.ACTIVE },
  { displayName: "Inactive", intValue: StatusEnums.INACTIVE },
  { displayName: "Deleted", intValue: StatusEnums.DELETED },
];

export const setDefaultBillingAccountData = (
  insuredId?: number,
  billingAccount?: BillingAccountProps,
  billingAccountData?: InsuredAccountDto | null,
  setBillingAccountData?: (value: InsuredAccountDto) => void
) => {
  if (!conditionHasValue(billingAccountData)) {
    if (billingAccount?.modalData.insuredAccountId === 0) {
      setBillingAccountData?.({
        insuredId: insuredId ?? 0,
        insuredAccountId: billingAccount?.modalData.insuredAccountId,
        accountTypeId: PayAccountTypeEnum.BANK,
        accountStatusId: StatusEnums.ACTIVE,
        nickName: "",
        routingNumber: "",
        accountNumber: "",
        useForACH: false,
        externalToken: "",
      });
    }
  }
};

export const switchStyles = {
  paddingTop: "10px",
  marginBottom: "-10px",
  "& span:first-of-type": {
    paddingLeft: "20px",
  },
};
