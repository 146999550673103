import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  Box,
  IconButton,
  InputAdornment,
  Autocomplete as MaAutocomplete,
  Paper,
  TextField,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import { SelectOptions } from "../../../dtos/select-options";
import { useApiGet } from "../../../hooks";
import { SearchIcon } from "../../TrueUI/Icons/CustomIcons";

type AutocompleteProps = {
  id?: string;
  name?: string;
  variant?: "filled" | "standard" | "outlined";
  getURL?: string;
  staticOptions?: SelectOptions[] | Partial<SelectOptions>[];
  defaultValueSelected?: string;
  isOnTable?: boolean;
  readOnly?: boolean;
  onChange?: (e: any) => void;
  onChangeRawValue?: (e?: any) => void;
};

const Autocomplete: FC<AutocompleteProps> = ({
  id = "",
  name = "",
  variant = "standard",
  getURL = "no_url_provided",
  staticOptions,
  defaultValueSelected,
  isOnTable = false,
  readOnly = false,
  onChange,
  onChangeRawValue,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;

  const [options, setOptions] = useState<
    SelectOptions[] | Partial<SelectOptions>[]
  >(
    staticOptions === undefined
      ? [{ displayName: "", intValue: -1 }]
      : staticOptions
  );
  const [selectedValue, setSelectedValue] = useState<
    SelectOptions | Partial<SelectOptions>
  >(
    staticOptions === undefined
      ? options[0]
      : (staticOptions.find(
          (option) => option.stringValue === defaultValueSelected
        ) as Partial<SelectOptions>)
  );
  const [inputValue, setInputValue] = useState<string>("");
  const { responseGet, dispatchGet } = useApiGet<any>(
    getURL.replace("{0}", inputValue)
  );

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (inputValue.length > 2 && !isOnTable) {
      dispatchGet();
    } else if (!isOnTable) {
      setOptions([]);
      setOpen(false);
    }
  }, [inputValue]);

  useEffect(() => {
    if (responseGet !== null && !isOnTable) {
      if (
        responseGet?.responseData !== null &&
        responseGet?.responseData?.length > 0 &&
        inputValue.trim().length > 2 &&
        selectedValue?.displayName !== inputValue.trim()
      ) {
        setOpen(true);
        setOptions(
          responseGet.responseData?.map((o) => {
            return { displayName: o.displayName, intValue: o.intValue };
          })
        );
      } else {
        setOptions([]);
        setOpen(false);
      }
    }
  }, [responseGet]);

  const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const onOptionSelected = (e, option) => {
    onChange?.(e);
    setInputValue(option.displayName);
    setSelectedValue(option);
    onChangeRawValue?.(option);
    setOpen(false);
  };

  return (
    <MaAutocomplete
      id={id}
      freeSolo
      fullWidth
      disablePortal
      disableClearable
      disabled={readOnly}
      options={options}
      value={selectedValue}
      open={open}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.displayName ?? ""
      }
      PaperComponent={CustomPaper}
      onClickCapture={() => setOpen(true)}
      onBlur={() => setOpen(false)}
      inputValue={inputValue}
      onInputChange={(_e, newvalue, _reason) => {
        setInputValue(newvalue);
        const optionsFound = options.filter(
          (option) => option.displayName === newvalue
        );
        if (optionsFound.length === 0 && isOnTable)
          onChangeRawValue?.({ displayName: "DEFAULT", stringValue: newvalue });
        if (optionsFound.length > 0 && isOnTable)
          onChangeRawValue?.(optionsFound[0]);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          inputProps={{
            ...params.inputProps,
            "true-element": `true-input-autocomplete-${name}`,
          }}
          InputProps={{
            ...params.InputProps,
            disabled: readOnly,
            endAdornment: (
              <InputAdornment position="end">
                {!isOnTable ? (
                  <SearchIcon htmlColor={theme?.primary} fontSize="inherit" />
                ) : open ? (
                  <IconButton disableRipple onClick={() => setOpen(false)}>
                    <ArrowDropUpIcon
                      htmlColor={
                        readOnly ? theme?.secondaryBackground : "inherit"
                      }
                    />
                  </IconButton>
                ) : (
                  <IconButton disableRipple onClick={() => setOpen(true)}>
                    <ArrowDropDownIcon
                      htmlColor={
                        readOnly ? theme?.secondaryBackground : "inherit"
                      }
                    />
                  </IconButton>
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          key={option.intValue ?? option.stringValue}
          fontSize={!isOnTable ? "14px" : "inherit"}
          textAlign={"left"}
          onClick={(e) => onOptionSelected(e, option)}
        >
          {option.displayName}
        </Box>
      )}
    />
  );
};

export default Autocomplete;
