import { SelectOptions } from "../dtos/select-options";
import { formatNumberFloat } from "./dataConversionFunctions";

export function uniqBySetWithArrayFrom<T>(array: T[]): T[] {
  return Array.from(new Set(array));
}

export function idArrayToFlatString(array: Partial<SelectOptions>[]) {
  const flatString = array.map(
    (value) => `{"Id":${value.stringValue ?? value.intValue}}`
  );
  return `[${flatString.toString()}]`;
}

export function getArrayByObjectOrArray<T>(argAsObjOrArray: T | T[]): T[] {
  return (
    Array.isArray(argAsObjOrArray as T)
      ? argAsObjOrArray ?? []
      : [argAsObjOrArray as T] ?? []
  ) as T[];
}

export function sumAllValuesOfAnArray(array: number[]) {
  return array.reduce((accumulator, value) => {
    return accumulator + value;
  }, 0);
}

export function dynamicSort(a, b, prop) {
  if (a[prop] < b[prop]) {
    return -1;
  }
  if (a[prop] > b[prop]) {
    return 1;
  }
  return 0;
}

export function groupArrayByKey(array: any[], key: string) {
  return array.reduce((hash, obj) => {
    if (obj[key] === undefined) return hash;
    return Object.assign(hash, {
      [obj[key]]: (hash[obj[key]] || []).concat(obj),
    });
  }, {});
}

export function removeValue(array: any[], value: any) {
  return array.filter((item: any) => item !== value);
}

export const sumInt = (values: any[]) => {
  return values.reduce((x, y) => {
    return x + parseInt(y);
  }, 0);
};

export const sumFloat = (values: any[]) => {
  return values.reduce((x, y) => {
    return x + parseFloat(formatNumberFloat(y));
  }, 0);
};
