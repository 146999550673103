import { FC } from "react";
import boundStyles from "./Policy.module.css";
import { FormattingDate } from "../../utilities/dateFunctions";
import { PortalPolicyBoundDto } from "../../dtos/portal-policy-bound-dto";
import { Title } from "../TrueUI";
import InlineLink from "../TrueUI/Links/InlineLink";

type CollapseTitleProps = {
  policy: PortalPolicyBoundDto;
};

const CollapseTitle: FC<CollapseTitleProps> = ({ policy }) => {
  return (
    <div className={boundStyles.bound_policy_title}>
      <Title>Policy Number: </Title>
      <InlineLink permission fontType="BOLD_TITLE">
        {policy.policyNumber}
      </InlineLink>
      <Title className={boundStyles.second_column}>Status </Title>
      <Title opacity={50}>{policy.policyStatus}</Title>
      <Title opacity={50} truncate>
        {`${FormattingDate(policy.effectiveDate)} - ${FormattingDate(
          policy.expirationDate
        )}`}
      </Title>
    </div>
  );
};
export default CollapseTitle;
