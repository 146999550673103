import { getColorWithOpacityHexaDecimal } from "../../../media/themeConstants";
import { Theme } from "../../../media/themeTypes";

export const progressStepperStyles = (theme: Theme) => ({
  alignItems: "flex-start",
  m: 1.5,
  ".MuiTimelineConnector-root": {
    backgroundColor: getColorWithOpacityHexaDecimal(theme?.primary, 36),
  },
  ".MuiTimelineContent-root": {
    color: theme?.textColor,
  },
});

export const getAvatarStyle = (
  isActiveDot: boolean,
  theme: Theme,
  isPrevious: boolean
) => {
  const isActive = isActiveDot || isPrevious;
  return {
    width: 30,
    height: 30,
    fontSize: "14px",
    backgroundColor: isActive ? theme?.primary : theme?.primaryBackground,
    outlineOffset: isActive ? "-3px" : "unset",
    outlineWidth: isActive ? "5px" : "unset",
    outlineColor: isActive ? theme?.primary : "unset",
    outlineStyle: isActive ? "solid" : "unset",
    color: isActive ? theme?.primaryBackground : theme?.textColor,
  };
};

export const getDotStyle = (isActive: boolean, theme: Theme) => ({
  m: 0,
  borderWidth: "2px",
  borderColor: isActive ? theme?.primary : theme?.borderColor,
});

export const getPrevDotStyle = (setActive: any, theme: Theme) => ({
  m: 0,
  color: theme?.textColor,
  borderWidth: 2,
  borderColor: theme?.primary,
  cursor: setActive ? "pointer" : "unset",
});
