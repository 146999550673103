import { FC } from "react";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import BaseTable2 from "../BaseTable2/BaseTable2";
import {
  BaseTable2Properties,
  ColumnOptionsProperties,
  OptionsContextActionProperties,
} from "../BaseTable2/TableProperties";
import { updateURL } from "../tableFunctions";
import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";

type BaseTableGridFormProperties = {
  obj: any;
  urlPattern?: string;
  columnsAndData?: { columns: BaseTableColumn[]; data: string[][] };
  columnOptions?: ColumnOptionsProperties<any>[];
  parameters?: { paramaterKey: string; parameterPropertyValue: string }[];
  contextMenu?: OptionsContextActionProperties[];
  isEditMode?: boolean;
  isOptionsColumnHidden?: boolean;
  childEditComponent?: (e: any) => JSX.Element | null;
  onDataChange?: (data: string[][]) => void;
  customFooterComponent?: (rows: any) => JSX.Element;
};

const BaseTableGridForm: FC<BaseTableGridFormProperties> = ({
  obj,
  urlPattern,
  columnsAndData,
  parameters,
  columnOptions,
  contextMenu,
  childEditComponent,
  isEditMode,
  isOptionsColumnHidden = false,
  onDataChange,
  customFooterComponent,
}) => {
  const tableName = obj?.GroupUiidKey ?? crypto.randomUUID();

  const getURL = () => updateURL(urlPattern ?? "", parameters ?? [], obj);

  const getURLOrColumnsAndData = () => {
    if (conditionHasValue(columnsAndData)) return { columnsAndData };
    return { getURL: getURL() };
  };

  const getOptionColumnType = () => {
    if (childEditComponent) return "component";
    else if (contextMenu) return "context";
    return "none";
  };

  const config: BaseTable2Properties = {
    name: tableName,
    ...getURLOrColumnsAndData(),
    tableType: "standard",
    toolbarOptions: {
      hideToolbar: true,
    },
    columnOptions: columnOptions,
    advancedOptions: {
      isEditMode: isEditMode ?? false,
      optionsColumnConfiguration: {
        isHidden: isOptionsColumnHidden,
        optionType: getOptionColumnType(),
      },
      optionsContextActions: {
        contextMenu: contextMenu,
        contextComponent: childEditComponent,
      },
      hideHeader: true,
      paginate: false,
    },
    events: {
      onChangeDataListener: (data) => onDataChange?.(data),
    },
    footerOptions: {
      CustomFooterComponent: customFooterComponent,
    },
  };

  useBaseTable(config);

  return <BaseTable2 name={tableName} />;
};

export default BaseTableGridForm;
