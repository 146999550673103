import { UploadFileInfo } from "@progress/kendo-react-upload";
import { AssignmentTypesEnum } from "../../dtos/assignment-types-enum";
import { RadioButtonOptionsType } from "../TrueUI/Checkbox/RadioGroup";
import { ProgramDto } from "../../dtos/program-dto";
import { conditionHasValue } from "../../utilities/conditionalSupportFunctions";

export type FileAttachment = { [key: string]: number };
export const options: RadioButtonOptionsType[] = [
  {
    label: "Information",
    value: AssignmentTypesEnum.INFORMATION,
  },
  {
    label: "Policy",
    value: AssignmentTypesEnum.POLICY,
  },
  {
    label: "Claims",
    value: AssignmentTypesEnum.CLAIM,
  },
  {
    label: "Training",
    value: AssignmentTypesEnum.TRAINING,
  },
  {
    label: "Loss Control",
    value: AssignmentTypesEnum.LOSS_CONTROL,
  },

  {
    label: "Audit",
    value: AssignmentTypesEnum.AUDIT,
  },
  {
    label: "Billing",
    value: AssignmentTypesEnum.BILLING,
  },
];

export const getFilteredOptions = (programInfo?: ProgramDto) => {
  return options.filter((option) => {
    return (
      (option.value === AssignmentTypesEnum.INFORMATION &&
        conditionHasValue(programInfo?.informationEmail)) ||
      (option.value === AssignmentTypesEnum.CLAIM &&
        conditionHasValue(programInfo?.claimEmail)) ||
      (option.value === AssignmentTypesEnum.POLICY &&
        conditionHasValue(programInfo?.policyEmail)) ||
      (option.value === AssignmentTypesEnum.TRAINING &&
        conditionHasValue(programInfo?.trainingEmail)) ||
      (option.value === AssignmentTypesEnum.LOSS_CONTROL &&
        conditionHasValue(programInfo?.lossControlEmail)) ||
      (option.value === AssignmentTypesEnum.AUDIT &&
        conditionHasValue(programInfo?.auditEmail)) ||
      (option.value === AssignmentTypesEnum.BILLING &&
        conditionHasValue(programInfo?.billingEmail))
    );
  });
};

export const filterEmptyFiles = (
  addedFiles: UploadFileInfo[]
): UploadFileInfo[] => {
  return addedFiles.filter((file) => {
    const fileSize = file.size ?? 0;
    if (fileSize <= 0) {
      return false;
    }
    if (fileSize < 10) {
      file.size = 10;
    }
    return true;
  });
};
