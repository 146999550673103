import { FC, useState } from "react";
import { BaseTable2 } from "../TrueUI";
import { useBaseTable } from "../../hooks/useBaseTable";
import Paper from "../TrueUI/Containers/Paper";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../TrueUI/Tables/BaseTable2/TableProperties";
import { SelectOptions } from "../../dtos/select-options";
import { ClaimStatusEnums } from "../../dtos/claim-status-enums";
import BaseTableDropdownFilter from "../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { Box, IconButton } from "@mui/material";
import { Launch } from "@mui/icons-material";
import { useNavigate } from "react-router";
import ExternalBaseTablePolicyDropdownFilter from "./ExternalBaseTablePolicyDropdownFilter";

type ClaimInfo = {
  statusSelected: number;
  policySelected: number;
};

const tableName = "claims-table";

const claimstatusFilterOptions: Partial<SelectOptions>[] = [
  { displayName: "All", intValue: -1 },
  { displayName: "Open Only", intValue: 1 },
];

const ClaimsTable: FC = () => {
  const navigate = useNavigate();

  const [claimInfo, setClaimInfo] = useState<ClaimInfo>({
    statusSelected: ClaimStatusEnums.OPEN,
    policySelected: -1,
  });

  const ClaimOpenDetail = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box>
        <IconButton
          aria-label="save"
          size="small"
          onClick={() => navigate(`/claims/${actionOptions.row?.ClaimId}`)}
        >
          <Launch fontSize="inherit" />
        </IconButton>
      </Box>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/Claims/GetClaimsTable?status=${claimInfo.statusSelected}&policyId=${claimInfo.policySelected}`,
    advancedOptions: {
      paginate: false,
      tableStyle: {
        headerStyle: "normal",
        height: "165px",
      },
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) => ClaimOpenDetail(actionOptions),
      },
    },
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "incident-status-filter",
          filterOptions: actionOptions,
          optionsOverride: claimstatusFilterOptions,
          defaultValue: 1,
          usingExternalFiltering: true,
          isStaticFilter: true,
          onChange: (value) =>
            setClaimInfo((prev) => ({ ...prev, statusSelected: value })),
        }),
      (actionOptions) =>
        ExternalBaseTablePolicyDropdownFilter({
          id: "incident-policy-filter",
          filterOptions: actionOptions,
          onChange: (value) =>
            setClaimInfo((prev) => ({ ...prev, policySelected: value })),
        }),
    ],
  };

  useBaseTable(tableConfiguration);
  return (
    <Paper title={"Claims"} margin="12px 0">
      <BaseTable2 name={tableName} />
    </Paper>
  );
};

export default ClaimsTable;
