import moment from "moment";
import { conditionHasValue } from "./conditionalSupportFunctions";

export const TRUE_ID_STORAGE = "true_id";
export const TRUE_PORTAL_ID_STORAGE = "true_portal_id";
export const LAST_UPDATED_CACHE = "lastTruePortalRefreshTimestamp";

export type ClaimTabsStorage = {
  insuredId: number;
  claimNumber: string;
  claimClaimant: string;
};
export const setOpenClaimTabs = (
  insuredId: number,
  claimNumber: string,
  claimClaimant: string
) => {
  if (conditionHasValue(insuredId) && claimNumber != "") {
    const claimTabs = getStorageValue("openClaimTabs");
    const clearClaimTabs = claimTabs?.filter(
      (tab) => tab?.insuredId != insuredId
    );
    const newClaimsTabs = clearClaimTabs?.concat([
      {
        insuredId: insuredId,
        claimNumber: claimNumber,
        claimClaimant: claimClaimant,
      },
    ]);

    localStorage.setItem("openClaimTabs", JSON.stringify(newClaimsTabs));
  }
};

export const getStorageValue = (nameOfStorage: string): ClaimTabsStorage[] => {
  const localTabs = localStorage.getItem(nameOfStorage);
  return localTabs ? JSON.parse(localTabs) : [];
};

export const removeClaimTab = (insuredId: number) => {
  const claimTabs = getStorageValue("openClaimTabs");
  const clearClaimTabs = claimTabs?.filter(
    (tab) => tab?.insuredId != insuredId
  );
  localStorage.setItem("openClaimTabs", JSON.stringify(clearClaimTabs));
};

export const removeSystemLocalStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const hardRefresh = () => {
  window.location.reload();
};

export const refreshCachePeriodically = () => {
  const lastRefreshString = localStorage.getItem(LAST_UPDATED_CACHE);
  const lastRefreshMoment = lastRefreshString
    ? moment(lastRefreshString)
    : null;

  if (!lastRefreshMoment || moment().diff(lastRefreshMoment, "hours") > 6) {
    hardRefresh();
    localStorage.setItem(LAST_UPDATED_CACHE, moment().toISOString());
  }
};
