export const DEFAULT_ROW_INDEX = "_default_row_index";
export const ROW_KEY_COLUMN_FIELD_NAME = "_row_key";
export const INDICATION_COLUMN_FIELD_NAME = "_indication_column";
export const OPTION_COLUMN_FIELD_NAME = "_option_column";
export const DELETED_COLUMN_FIELD_NAME = "_is_row_deleted_column";
export const IS_NEW_ROW_COLUMN_FIELD_NAME = "_is_row_new_column";
export const MULTI_SELECTION_COLUMN_FIELD_NAME = "_multi_selection_column";

export const INTERNAL_TABLE_COLUMNS = [
  ROW_KEY_COLUMN_FIELD_NAME,
  INDICATION_COLUMN_FIELD_NAME,
  OPTION_COLUMN_FIELD_NAME,
  DELETED_COLUMN_FIELD_NAME,
  MULTI_SELECTION_COLUMN_FIELD_NAME,
  IS_NEW_ROW_COLUMN_FIELD_NAME,
];

export const INDICATION_DEFAULT_VALUE = "none";
export const OPTION_DEFAULT_VALUE = "none";
export const DELETED_DEFAULT_VALUE = "false";
export const IS_NEW_ROW_DEFAULT_VALUE = "false";
export const DELETED_ALTERNATIVE_VALUE = "true";
export const FILTER_ALL_RESULTS = "all";

export const NO_CELL_KEY_ERROR = "NO_CELL_KEY_FOUND";
export const NO_API_DATA_FOUND_ERROR = "NO_API_DATA_FOUND";
export const NO_ROW_KEY_ERROR = "NO_ROW_KEY_FOUND";
export const NO_URL_PROVIDED_ERROR = "NO_URL_PROVIDED";
export const NO_COLUMN_FIELD_NAME_FOUND_ERROR = "NO_COLUMN_FIELD_NAME_FOUND";

// messages
export const WARN_TRIGGER_COMPUTE_OVERLOAD = (fieldName) =>
  `Both triggerComputes and apiOptions.triggerComputes have fields names applied. This will cause unexpected compute results. See the compute(s) attached to ${fieldName}`;

export const WARN_MISSING_CELLKEYS = (removedCount) =>
  `Some cells we're missing cellKeys and have been removed. Number of cellObjects removed: ${Math.abs(
    removedCount
  )}`;

export const NO_FILTER_ID_FOUND = "NO_FILTER_ID_FOUND";
export const NO_FILTER_NAME_FOUND = "NO_FITLER_NAME_FOUND";
export const SEARCH_FILTER = "search_filter";
export const SORT_FILTER = "sort_filter";
export const DROPDOWN_FILTER = "dropdown_filter";
export const MULTI_DROPDOWN_FILTER = "multiselect_dropdown_filter";
export const DROPDOWN_FILTER_AND = "dropdown_filter_and";
export const SPECIAL_SORT_ORDER = "special_sort_order";
export const INPUT_DATE_FILTER = "input_date_filter";
