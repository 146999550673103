export type DateSectionProps = {
  start: number;
  end: number;
  targetSection: string;
};

export type DateSectionsProps = {
  sectionM: DateSectionProps;
  sectionD: DateSectionProps;
  sectionY: DateSectionProps;
};

export const regexMonth = new RegExp("^(0[1-9]|1[0-2])$");
export const regexTwoDigitMonth = new RegExp("^1[0-2]$");
export const regexDay = new RegExp("^(0[1-9]|[1-2][0-9]|3[0-1])$");
export const regexTwoDigitDay = new RegExp("^([1-2][0-9]|3[0-1])$");
export const regexYear = new RegExp("^[1-9][0-9]{3}$");
export const regexKeyTrigger = new RegExp("^[0-9]|Tab$");

export const getDateSections = (
  format: string,
  indexes: number[],
  inputValue: string
) => {
  const monthIndex = format.indexOf("M");
  const dayIndex = format.indexOf("D");
  const yearIndex = format.indexOf("Y");

  if (monthIndex < dayIndex && monthIndex < yearIndex && dayIndex < yearIndex)
    return {
      sectionM: {
        start: 0,
        end: indexes[0],
        targetSection: "D",
      },
      sectionD: {
        start: indexes[0] + 1,
        end: indexes[1],
        targetSection: "Y",
      },
      sectionY: {
        start: indexes[1] + 1,
        end: inputValue.length,
        targetSection: "M",
      },
    } as DateSectionsProps;
  else if (monthIndex < dayIndex && monthIndex > yearIndex)
    return {
      sectionY: {
        start: 0,
        end: indexes[0],
        targetSection: "M",
      },
      sectionM: {
        start: indexes[0] + 1,
        end: indexes[1],
        targetSection: "D",
      },
      sectionD: {
        start: indexes[1] + 1,
        end: inputValue.length,
        targetSection: "Y",
      },
    } as DateSectionsProps;
  else
    return {
      sectionD: {
        start: 0,
        end: indexes[0],
        targetSection: "M",
      },
      sectionM: {
        start: indexes[0] + 1,
        end: indexes[1],
        targetSection: "Y",
      },
      sectionY: {
        start: indexes[1] + 1,
        end: inputValue.length,
        targetSection: "D",
      },
    } as DateSectionsProps;
};

export const getTargetSection = (
  targetSection: string,
  sections: DateSectionsProps
) => {
  switch (targetSection) {
    case "D":
      return sections.sectionD;
    case "Y":
      return sections.sectionY;
    default:
      return sections.sectionM;
  }
};
