import { FC, useEffect, useState } from "react";
import style from "./Document.module.css";
import Document from "./Document";
import { areObjectsEqual } from "../../../utilities/objectFunctions";
import { PortalDocumentDto } from "../../../dtos/portal-document-dto";
import Font from "../Typography/Font";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { isAPITotallyCompleteNoContentResponse } from "../../../utilities/apiFunctions";
import DownloadAsZipButton from "../File/DownloadAsZipButton";
import { PortalDocumentSectionEnum } from "../../../dtos/portal-document-section-enum";
import { useApiPost } from "../../../hooks";
import DownloadButton from "../File/DownloadButton";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";

type ListDocumentProps = {
  documents: PortalDocumentDto[];
  section?: number | null;
  isFromSearch?: boolean;
  onSaveFavorite?: () => void;
};

const ListDocument: FC<ListDocumentProps> = ({
  documents,
  section,
  isFromSearch = false,
  onSaveFavorite,
}) => {
  const [currentFavorite, setCurrentFavorite] =
    useState<PortalDocumentDto | null>(null);

  const [localDocuments, setLocalDocuments] = useState<
    PortalDocumentDto[] | null
  >(documents);

  const localOptions = useRecoilValue(globalOptions);

  const { responsePost: responseFavorite, dispatchPost: dispatchPostFavorite } =
    useApiPost<PortalDocumentDto[]>(
      `api/Documents/SaveDocumentAsFavorite?documentId=${currentFavorite?.portalDocumentId}`
    );

  const onFavoriteClick = (document: PortalDocumentDto) => {
    setCurrentFavorite(document);
  };

  const onCheckDocument = (isChecked: boolean, document: PortalDocumentDto) => {
    const updatedLocalDocuments =
      localDocuments?.map((_document) => {
        if (areObjectsEqual(_document, document)) {
          return { ..._document, isChecked: isChecked };
        }
        return _document;
      }) ?? null;

    setLocalDocuments(updatedLocalDocuments);
  };

  const getSelectedDocuments = () =>
    localDocuments
      ?.filter((document) => document.isChecked === true)
      .map((document) => document?.blobName) ?? [];

  const getZipName = () => {
    if (isFromSearch) {
      return `${localOptions?.initOptions?.insuredName
        ?.toLocaleLowerCase()
        .replaceAll(" ", "-")}-documents`;
    }
    switch (section) {
      case PortalDocumentSectionEnum?.RECENTLY_ADDED:
        return "recently-added-documents";
      case PortalDocumentSectionEnum?.FAVORITE:
        return "favorite-documents";
      default:
        return `${localDocuments?.[0]?.categoryName
          ?.toLocaleLowerCase()
          .replaceAll(" ", "-")}-documents`;
    }
  };

  const unCheckDocuments = () => {
    const unChecked =
      localDocuments?.map((document) => ({
        ...document,
        isChecked: false,
      })) ?? [];
    setLocalDocuments(unChecked);
  };

  const updateVisualChanges = () => {
    const updatedLocalDocuments =
      localDocuments?.map((_document) => {
        if (areObjectsEqual(_document, currentFavorite)) {
          return { ..._document, isFavorite: !currentFavorite?.isFavorite };
        }
        return _document;
      }) ?? null;

    setLocalDocuments(updatedLocalDocuments);
  };

  useEffect(() => {
    setLocalDocuments(documents);
  }, [documents]);

  useEffect(() => {
    if (conditionHasValue(currentFavorite)) {
      dispatchPostFavorite();
    }
  }, [currentFavorite]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseFavorite)) {
      onSaveFavorite?.();
      setCurrentFavorite(null);
      isFromSearch && updateVisualChanges();
    }
  }, [responseFavorite]);

  return (
    <div className={style.list_document_container}>
      {(localDocuments?.length ?? 0) === 0 && (
        <Font>There are no documents in this category</Font>
      )}
      {localDocuments?.map((document) => (
        <Document
          document={document}
          onCheckDocument={onCheckDocument}
          onFavoriteClick={onFavoriteClick}
          key={crypto?.randomUUID()}
        />
      ))}
      {getSelectedDocuments().length == 1 && (
        <DownloadButton
          fileName={getSelectedDocuments()[0]}
          variantStyle={"outlined"}
          onClick={unCheckDocuments}
        >
          Open Selected
        </DownloadButton>
      )}
      {getSelectedDocuments().length > 1 && (
        <DownloadAsZipButton
          fileNames={getSelectedDocuments()}
          zipName={`${getZipName()}.zip`}
          variantStyle={"outlined"}
          onClick={unCheckDocuments}
        >
          Open Selected
        </DownloadAsZipButton>
      )}
    </div>
  );
};
export default ListDocument;
