import { FC, useEffect, useState } from "react";
import style from "./CategoryButtonsCollapse.module.css";
import FontBold from "../Typography/FontBold";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { PortalDocCategoryDto } from "../../../dtos/portal-doc-category-dto";
import ListDocumentContainer from "../../Documents/ListDocumentContainer";

type DoubleButtonCollapseProps = {
  id?: string;
  name?: string;
  expanded?: boolean;
  categoryToShow?: 1 | 2;
  informationCategory1?: PortalDocCategoryDto | null;
  informationCategory2?: PortalDocCategoryDto | null;
  setExpanded?: (expanded: boolean) => void;
  onButton1Change?: (e: any) => void;
  onButton2Change?: (e: any) => void;
};

const DoubleCategoryButtonCollapse: FC<DoubleButtonCollapseProps> = ({
  id,
  name,
  expanded = false,
  setExpanded,
  onButton1Change,
  onButton2Change,
  categoryToShow = 1,
  informationCategory1,
  informationCategory2,
}) => {
  const [expandedCollapse, setExpandedCollapse] = useState<boolean>(expanded);
  const [_contentToShow, setContentToShow] = useState<1 | 2 | null>();

  const handleChange = (buttonClicked: 1 | 2) => {
    if (buttonClicked === _contentToShow && expandedCollapse) {
      setExpandedCollapse(false);
      setContentToShow(null);
    } else {
      setExpandedCollapse(true);
      setContentToShow(buttonClicked);
    }
    buttonClicked === 1 && onButton1Change?.(!expandedCollapse);
    buttonClicked === 2 && onButton2Change?.(!expandedCollapse);
  };

  useEffect(() => {
    setExpanded?.(!expandedCollapse);
  }, [expandedCollapse]);

  return (
    <div
      className={style.double_button_collapse_container}
      true-element={`true-element-double-button-collapse-${name}`}
      id={id}
    >
      <div className={style.double_button_collapse_buttons_container}>
        <div
          onClick={() => handleChange(1)}
          className={`${style.double_button_collapse} ${
            !conditionHasValue(informationCategory2) && style.first_button_gap
          } ${_contentToShow === 1 && style.double_button_selected_collapse}`}
        >
          <FontBold textAlign="center">
            {informationCategory1?.categoryName}
          </FontBold>
        </div>
        {conditionHasValue(informationCategory2) && (
          <div
            onClick={() => handleChange(2)}
            className={`${
              _contentToShow === 2 && style.double_button_selected_collapse
            } ${style.double_button_collapse}`}
          >
            <FontBold textAlign="center">
              {informationCategory2?.categoryName}
            </FontBold>
          </div>
        )}
      </div>
      {expandedCollapse && (
        <div className={style.double_button_collapse_content_container}>
          <ListDocumentContainer
            category={
              (_contentToShow ?? categoryToShow) === 1
                ? informationCategory1
                : informationCategory2
            }
          />
        </div>
      )}
    </div>
  );
};
export default DoubleCategoryButtonCollapse;
