import WelcomeText from "./WelcomeText";
import CurrentBilling from "./CurrentBilling";
import ActivePolicies from "./ActivePolicies";
import RecentClaims from "./RecentClaims";

const Dashboard = () => {
  return (
    <>
      <WelcomeText />
      <CurrentBilling />
      <ActivePolicies />
      <RecentClaims />
    </>
  );
};

export default Dashboard;
