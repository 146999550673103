import { getColorWithOpacityHexaDecimal } from "./themeConstants";
import { Theme } from "./themeTypes";

// Added this in support of when we add this to the Portal project.
const defaultPrefixIdentifier = `--t`;

const opacityColors = [10, 20, 30, 40, 50]; // Do not update this

const getColor = (color, opacity) =>
  getColorWithOpacityHexaDecimal(color, opacity as any) ?? "red";

const getAllOpacityColors = (theme: Theme) => {
  return opacityColors
    .map((opacity) => {
      return [
        {
          propertyName: `${defaultPrefixIdentifier}-secondary-background-${opacity}`,
          value: getColor(theme?.secondaryBackground, opacity),
        },
        {
          propertyName: `${defaultPrefixIdentifier}-primary-${opacity}`,
          value: getColor(theme?.primary, opacity),
        },
        {
          propertyName: `${defaultPrefixIdentifier}-warning-${opacity}`,
          value: getColor(theme?.warning, opacity),
        },
        {
          propertyName: `${defaultPrefixIdentifier}-danger-${opacity}`,
          value: getColor(theme?.danger, opacity),
        },
        {
          propertyName: `${defaultPrefixIdentifier}-success-${opacity}`,
          value: getColor(theme?.success, opacity),
        },
        {
          propertyName: `${defaultPrefixIdentifier}-contrast-background-${opacity}`,
          value: getColor(theme?.contrastBackground, opacity),
        },
        {
          propertyName: `${defaultPrefixIdentifier}-text-color-${opacity}`,
          value: getColor(theme?.textColor, opacity),
        },
        {
          propertyName: `${defaultPrefixIdentifier}-border-color-${opacity}`,
          value: theme.borderColor ?? "white",
        },
      ];
    })
    .flat();
};

// Add any global/valuable variables to this list.
export const globalCSSVariableOptions = (
  theme: Theme
): { propertyName: string; value: string }[] => {
  return [
    {
      propertyName: `${defaultPrefixIdentifier}-primary`,
      value: theme.primary ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-secondary`,
      value: theme.secondary ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-success`,
      value: theme.success ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-primary-background`,
      value: theme.primaryBackground ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-secondary-background`,
      value: theme.secondaryBackground ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-contrast-background`,
      value: theme.contrastBackground ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-selected-row-background`,
      value: theme.selectedRowBackground ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-text-color`,
      value: theme.textColor ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-secondary-text-color`,
      value: theme.secondaryTextColor ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-contrast-text-color`,
      value: theme.contrastTextColor ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-danger`,
      value: theme?.danger ?? "red",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-warning`,
      value: theme?.warning ?? "orange",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-input-text-color`,
      value: theme.inputTextColor ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-border-color`,
      value: theme.borderColor ?? "white",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-border-modal-color`,
      value: theme.borderModalColor ?? "white",
    },
    /* Font */
    {
      propertyName: `${defaultPrefixIdentifier}-heading-5-size`,
      value: theme.heading5.size ?? "14px",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-heading-6-size`,
      value: theme.heading6.size ?? "20px",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-small-title-size`,
      value: theme.smallTitle.size ?? "12px",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-body-size`,
      value: theme.body.size ?? "14px",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-caption-size`,
      value: theme.caption.size ?? "10px",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-title-size`,
      value: theme.title.size ?? "16px",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-title-weight`,
      value: "400",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-title-bold-weight`,
      value: "700",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-body-bold-size`,
      value: theme.boldBody.size ?? "16px",
    },
    {
      propertyName: `${defaultPrefixIdentifier}-body-bold-weight`,
      value: "700",
    },
    ...getAllOpacityColors(theme),
  ];
};
