import { FC, useEffect, useState } from "react";
import { Collapse } from "../TrueUI";
import { PortalPolicyBoundDto } from "../../dtos/portal-policy-bound-dto";
import { useApiGet } from "../../hooks";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";
import { conditionHasValue } from "../../utilities/conditionalSupportFunctions";
import CollapseTitle from "./CollapseTitle";
import boundStyles from "./Policy.module.css";
import { getQueryValue } from "../../hooks/useURL";
import { POLICY_ID } from "../../utilities/queryStringsHash";
import CollapseContent from "./CollapseContent";

const BoundPolicies: FC = () => {
  const [boundPolicies, setBoundPolicies] = useState<PortalPolicyBoundDto[]>();

  const { responseGet, dispatchGet } = useApiGet<PortalPolicyBoundDto[]>(
    `api/Policy/GetBoundedPolicies`
  );

  const policyIdQuery =
    getQueryValue(POLICY_ID) != "" ? parseInt(getQueryValue(POLICY_ID)) : null;

  const isExpandedPolicy = (policy: PortalPolicyBoundDto) => {
    if (policyIdQuery != null) {
      return policy.policyId === policyIdQuery;
    }
    return policy.isCurrentPolicy ?? false;
  };

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setBoundPolicies(responseGet?.responseData ?? []);
    }
  }, [responseGet]);

  useEffect(() => {
    dispatchGet();
  }, []);

  return (
    <div className={boundStyles.bound_policies_container}>
      {conditionHasValue(boundPolicies) &&
        boundPolicies?.map((policy) => (
          <Collapse
            id={"policy-bound-collapse"}
            title={<CollapseTitle policy={policy} />}
            expanded={isExpandedPolicy(policy)}
            hasBottomMargin
            titleHeightAuto
            content={<CollapseContent policy={policy} />}
          />
        ))}
    </div>
  );
};
export default BoundPolicies;
