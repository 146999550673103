import { ErrorResponse } from "../../../../dtos/error-response";
import { SelectOptions } from "../../../../dtos/select-options";
import { TransactionResponseDto } from "../../../../dtos/transaction-response-dto";
import { InstanceRequestConfigurationOptions } from "../../../../hooks/useApi";
import {
  conditionHasValue,
  isEmptyValue,
  isValidEmail,
} from "../../../../utilities/conditionalSupportFunctions";
import {
  AbbrMonthsProps,
  getCardProviderMaxLength,
} from "../../../../utilities/staticDataFunctions";

export type CreditCardInfo = {
  cardNumber: number;
  cardNumberMaxLength: number;
  cardCvvMaxLength: number;
  cardMonth: number;
  cardYear: number;
  cardBillingZipCode: number;
  cardCvv: number;
  emailReceipt: string;
  emailFormatError: string | null;
};

export const evaluateMonthToSetDefaultYear = (
  setCreditCardInfo: (credirCardInfo: Partial<CreditCardInfo> | null) => void,
  creditCardInfo: Partial<CreditCardInfo> | null
) => {
  const currentYear = new Date().getUTCFullYear();
  const nextYear =
    creditCardInfo?.cardMonth !== 1 ? currentYear + 1 : currentYear;

  setCreditCardInfo({
    ...creditCardInfo,
    cardYear: nextYear,
  });
};

const getUpdatedCvv = (cvvMaxLength: number, currentCvv?: number) =>
  (currentCvv?.toString()?.length ?? 0) > cvvMaxLength
    ? parseInt(currentCvv?.toString()?.substring(0, cvvMaxLength) ?? "")
    : currentCvv;

export const evaluateCardNumber = (
  cardNumber: number,
  setCreditCardInfo: (credirCardInfo: Partial<CreditCardInfo> | null) => void,
  creditCardInfo: Partial<CreditCardInfo> | null
) => {
  const cardNumberMaxLength = getCardProviderMaxLength(cardNumber)?.pop();
  const cvvMaxLength = cardNumberMaxLength === 15 ? 4 : 3;
  const currentCvv = creditCardInfo?.cardCvv;
  const updatedCurrentCvv = getUpdatedCvv(cvvMaxLength, currentCvv);
  setCreditCardInfo({
    ...creditCardInfo,
    cardNumber: cardNumber,
    cardNumberMaxLength: conditionHasValue(cardNumber)
      ? cardNumberMaxLength
      : 16,
    cardCvvMaxLength: cvvMaxLength,
    cardCvv: updatedCurrentCvv,
  });
};

export const getAbbrMonthsSelectOptions = (monthsList: AbbrMonthsProps[]) => {
  return monthsList?.map((month) => ({
    displayName: month.abbreviation,
    intValue: month.value,
  }));
};

function loopWithoutLet(counter, numberOfFutureYears, futureYears) {
  if (counter < numberOfFutureYears) {
    futureYears.push({
      displayName: `${new Date().getFullYear() + counter}`,
      intValue: new Date().getFullYear() + counter,
    });
    loopWithoutLet(counter + 1, numberOfFutureYears, futureYears);
  }
  return futureYears;
}

export const getCardYearsSelectOptions = (numberOfFutureYears: number) => {
  const futureYears: Partial<SelectOptions>[] = [];
  return loopWithoutLet(0, numberOfFutureYears, futureYears);
};

export const getDefaultYear = () => {
  const currentDate = new Date();
  if (currentDate.getMonth() === 1) return currentDate.getFullYear();
  return currentDate.getFullYear() + 1;
};

export const evaluateEmailReceipt = (
  email: string,
  setCreditCardInfo: (creditCardInfo: Partial<CreditCardInfo> | null) => void,
  creditCardInfo: Partial<CreditCardInfo> | null
) => {
  const errorMessage: { value: string | null } = { value: null };
  if (isValidEmail(email) === false) {
    errorMessage.value = "Email with wrong format.";
  }
  setCreditCardInfo({
    ...creditCardInfo,
    emailReceipt: email ?? "",
    emailFormatError: errorMessage.value,
  });
};

const hasTableError = (errorDetails?: { [key: string]: Object }) => {
  const keys = Object.keys(errorDetails ?? []);
  const hasDataError = keys.find((key) => key.includes("paymentData"));
  return hasDataError ? true : false;
};

export const evaluateErrorResponse = (
  setErrorDetails: (value: any) => void,
  responsePost: InstanceRequestConfigurationOptions<TransactionResponseDto>,
  validatorErrorResponse?: ErrorResponse | null
) => {
  if (
    conditionHasValue(validatorErrorResponse) &&
    !isEmptyValue(validatorErrorResponse?.errorDetails)
  ) {
    const tableError = hasTableError(validatorErrorResponse?.errorDetails)
      ? {
          code: validatorErrorResponse?.status ?? 500,
          msg: "Review the table data, there are missing required fields",
        }
      : {};
    setErrorDetails({
      ...validatorErrorResponse?.errorDetails,
      ...tableError,
    });
  }
  if (
    conditionHasValue(validatorErrorResponse) &&
    isEmptyValue(validatorErrorResponse?.errorDetails)
  ) {
    setErrorDetails({
      code: validatorErrorResponse?.status ?? 500,
      msg:
        validatorErrorResponse?.message ?? validatorErrorResponse?.toString(),
    });
  }
  if (!isEmptyValue(responsePost.axiosResponse?.data.errorCode)) {
    setErrorDetails({
      code: responsePost.axiosResponse?.data.errorCode,
      msg: responsePost.axiosResponse?.data.errorText,
    });
  }
};
