import { FC } from "react";
import PortalPageContainer from "../TrueUI/Containers/PortalPageContainer";
import { TabPanel, Tabs } from "../TrueUI";
import BoundPolicies from "./BoundPolicies";
import CertificatesGrid from "./Certificates/CertificatesGrid";
const Policy: FC = () => {
  return (
    <PortalPageContainer title={"Policy"} paperPadding="5px">
      <Tabs name={`portals-policy-tabs`}>
        <TabPanel title={"Information"} key={"information-tab"}>
          <BoundPolicies />
        </TabPanel>
        <TabPanel title={"Certificates"} key={"certificates-tab"}>
          <CertificatesGrid />
        </TabPanel>
      </Tabs>
    </PortalPageContainer>
  );
};
export default Policy;
