import { Box } from "@mui/material";
import { FC } from "react";
import { useFileRequestInstance } from "../../../hooks/useFileStorage";
import Font from "../Typography/Font";
import { KeysAsType } from "../../../types/KeysAsAType";
import { FontsType } from "../../../media/themeTypes";

type DownloadLinkButtonProps = {
  fileName: string;
  fileTitle?: string;
  fontType?: KeysAsType<FontsType>;
  truncate?: boolean;
  display?: "inline" | "block" | "inline-block" | "flex" | "none";
  textAlign?: "start" | "center" | "end";
};

const DownloadLinkButton: FC<DownloadLinkButtonProps> = ({
  fileName,
  fileTitle,
  fontType,
  truncate,
  display,
  textAlign,
}) => {
  const { downloadFile } = useFileRequestInstance();
  return (
    <Box
      className={`true_input_general_container`}
      true-element={`true-element true-download-link-${fileName}`}
      onClick={() => downloadFile(fileName)}
      sx={{ cursor: "pointer" }}
    >
      <Font
        name={fileTitle ?? fileName}
        fontType={fontType}
        truncate={truncate}
        display={display}
        textAlign={textAlign}
        primaryColor
      >
        {fileTitle ?? fileName}
      </Font>
    </Box>
  );
};

export default DownloadLinkButton;
