import { FC } from "react";
import Paper from "../TrueUI/Containers/Paper";

const WaitingInfo: FC = () => {
  return (
    <Paper title="Waiting for Information" margin={"10px 0px"} collapse>
      Waiting for Information!
    </Paper>
  );
};

export default WaitingInfo;
