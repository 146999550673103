import { FC } from "react";
import { Button } from "@mui/material";
import "./ScoreChip.css";
import color from "../../../media/colors";

interface ScoreChipProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  value: number;
  color?: "red" | "orange" | "yellow" | "green" | "darkGreen";
  size?: "small" | "medium" | "big";
  clickable?: boolean;
}

const ScoreChip: FC<ScoreChipProps> = ({ value, ...props }) => {
  const defineDefaultColor = (value, hover = false) => {
    switch (+value) {
      case 1:
        return hover === false
          ? color.ScoreBackground1
          : color.ScoreBackgroundHover1;
      case 2:
        return hover === false
          ? color.ScoreBackground2
          : color.ScoreBackgroundHover2;
      case 3:
        return hover === false
          ? color.ScoreBackground3
          : color.ScoreBackgroundHover3;
      case 4:
        return hover === false
          ? color.ScoreBackground4
          : color.ScoreBackgroundHover4;
      case 5:
        return hover === false
          ? color.ScoreBackground5
          : color.ScoreBackgroundHover5;
      default:
        return hover === false
          ? color.ScoreBackground0
          : color.ScoreBackgroundHover0;
    }
  };

  return (
    <Button
      onClick={props.onClick}
      sx={{
        backgroundColor: defineDefaultColor(value),
        "&: hover": {
          background: defineDefaultColor(value, true),
        },
      }}
      className={`${props.size ?? "small"} scoreChip ${
        props.clickable ? "clickable" : ""
      }`}
    >
      {value}
    </Button>
  );
};

export default ScoreChip;
