import { IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  claimIncidentStateProps,
  getSectionIndexByName,
  GetSplitButtonLabel,
  getValue,
  showSaveItems,
} from "./AddNewIncidentUtils";
import CloseIcon from "@mui/icons-material/Close";
import IncidentStart from "./IncidentSections/IncidentStart";
import IncidentFinish from "./IncidentSections/IncidentFinish";
import DynamicFields from "./DynamicIncidentFields";
import { IncidentStatusEnums } from "../../dtos/incident-status-enums";
import { useApiPost } from "../../hooks";
import { addQueryStrings } from "../../hooks/useURL";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../utilities/apiFunctions";
import { conditionHasValue } from "../../utilities/conditionalSupportFunctions";
import { INCIDENT_ID } from "../../utilities/queryStringsHash";
import { Row, Col, Font, Button, SplitButton } from "../TrueUI";
import DialogConfirmation from "../TrueUI/Dialogs/DialogConfirmation";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../GlobalAtoms";
import styles from "./IncidentStyles.module.css";

type IncidentFieldsProps = {
  insuredId?: number;
  claimIncidentUI?: claimIncidentStateProps;
  readOnlyMode: boolean;
  setClaimIncidentUI: (claimIncidentUI: claimIncidentStateProps) => void;
};

const IncidentFields: FC<IncidentFieldsProps> = ({
  claimIncidentUI,
  readOnlyMode,
  setClaimIncidentUI,
}) => {
  const navigate = useNavigate();
  const textFieldVariant = readOnlyMode ? "standard" : "filled";
  const incidentFieldLabelType = "BOLD_CAPTION";
  const [isLastStep, setIsLastStep] = useState<boolean>(false);
  const [isSignatureCheckedDialogOpen, setIsSignatureCheckedDialogOpen] =
    useState<boolean>(false);
  const [cancelConfirmationOpen, setCancelConfirmationOpen] =
    useState<boolean>(false);

  const localOptions = useRecoilValue(globalOptions);

  const {
    responsePost,
    dispatchPost: saveIncident,
    validatorErrorResponse,
  } = useApiPost<number>(
    "api/PortalIncident/SaveClaimIncident",
    claimIncidentUI?.claimIncident
  );
  const { responsePost: responseEmail, dispatchPost: sendFROIEmail } =
    useApiPost(
      `api/PortalIncident/SendEmail?incidentId=${claimIncidentUI?.claimIncident?.incident?.incidentID}&stateCode=${claimIncidentUI?.stateReport?.stateReport.state}&userEmail=${localOptions?.initOptions?.email}`
    );

  const redirectBack = () => {
    navigate(`/claims`);
  };

  const checkIfSplitButtonIsDisable = () => {
    if (
      !conditionHasValue(
        claimIncidentUI?.claimIncident?.incident?.stateReportID
      )
    ) {
      return true;
    }
    if (showSaveItems(claimIncidentUI)) {
      return !claimIncidentUI?.insuredInfo?.insuredId;
    }
    return false;
  };

  const actionOnNext = () => {
    if (!readOnlyMode) {
      saveIncidentAndStatus(IncidentStatusEnums.IN_PROGRESS);
    } else {
      setClaimIncidentUI({
        ...claimIncidentUI,
        activeSection: (claimIncidentUI?.activeSection ?? 0) + 1,
        lastSavedSection:
          (claimIncidentUI?.activeSection ?? 0) <=
          (claimIncidentUI?.lastSavedSection ?? -1)
            ? claimIncidentUI?.lastSavedSection
            : claimIncidentUI?.activeSection,
      });
    }
  };

  const saveItems = () => {
    if (readOnlyMode) {
      return [
        {
          option: "Next",
          dropdownText: "Next",
          action: () => actionOnNext(),
        },
      ];
    } else {
      return [
        {
          option: "Save and Next",
          dropdownText: "Save and Next",
          action: () => actionOnNext(),
        },
        {
          option: "Save Only",
          dropdownText: "Save Only",
          action: () => {
            saveIncidentAndStatus(IncidentStatusEnums.IN_PROGRESS, true);
          },
        },
      ];
    }
  };

  const actionFinish = () => {
    if (readOnlyMode) {
      redirectBack();
    }
    if (!readOnlyMode && !claimIncidentUI?.hasPolicy) {
      setIsLastStep(true);
      if (claimIncidentUI?.hasSignature) {
        saveIncidentAndStatus(IncidentStatusEnums.SUBMITTED);
      } else {
        setIsSignatureCheckedDialogOpen(true);
      }
    }
    if (!readOnlyMode && claimIncidentUI?.hasPolicy) {
      setIsLastStep(true);
      saveIncidentAndStatus(IncidentStatusEnums.SUBMITTED);
    }
  };

  const saveIncidentAndStatus = (status: number, saveOnly: boolean = false) => {
    const activeSection = claimIncidentUI?.activeSection ?? 0;
    setClaimIncidentUI({
      ...claimIncidentUI,
      activeSection: saveOnly || isLastStep ? activeSection : activeSection + 1,
      lastSavedSection:
        activeSection <= (claimIncidentUI?.lastSavedSection ?? -1)
          ? claimIncidentUI?.lastSavedSection
          : activeSection,
      claimIncident: {
        ...claimIncidentUI?.claimIncident,
        incident: {
          ...claimIncidentUI?.claimIncident?.incident,
          incidentStatus: status,
        },
      },
      errorDetails: null,
    });
    saveIncident();
  };

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      setClaimIncidentUI({
        ...claimIncidentUI,
        claimIncident: {
          ...claimIncidentUI?.claimIncident,
          incident: {
            ...claimIncidentUI?.claimIncident?.incident,
            incidentID: responsePost.responseData ?? 0,
          },
        },
        isAfterSave: true,
      });
      if (responsePost.responseData) {
        addQueryStrings([
          {
            nameOfHash: INCIDENT_ID,
            valueOfHash: responsePost?.responseData?.toString(),
          },
        ]);
      }

      isLastStep && sendFROIEmail();
    } else {
      if (validatorErrorResponse?.errorDetails || responsePost?.errorResponse) {
        setClaimIncidentUI({
          ...claimIncidentUI,
          errorDetails: validatorErrorResponse?.errorDetails,
          activeSection: getValue(claimIncidentUI?.activeSection) - 1,
        });
      }
    }
  }, [responsePost]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responseEmail)) redirectBack();
  }, [responseEmail]);

  return (
    <>
      <div className={styles.incident_actions_and_fields}>
        <div className={styles.incident_field_container}>
          <div className={styles.incident_fields_content}>
            {/* // IncidentStart */}
            {claimIncidentUI?.activeSection === 0 && (
              <IncidentStart
                textFieldVariant={textFieldVariant}
                readOnly={readOnlyMode}
                claimIncidentUI={claimIncidentUI}
                setClaimIncidentUI={setClaimIncidentUI}
                errorDetails={claimIncidentUI?.errorDetails}
                labelsType={incidentFieldLabelType}
              />
            )}

            <DynamicFields
              textFieldVariant={textFieldVariant}
              readOnlyMode={readOnlyMode}
              claimIncidentUI={claimIncidentUI}
              setClaimIncidentUI={setClaimIncidentUI}
              labelsType={incidentFieldLabelType}
            />
            {/* Finish Section */}
            {claimIncidentUI?.activeSection ===
              getSectionIndexByName(claimIncidentUI?.sectionDots, "Finish") && (
              <IncidentFinish
                textFieldVariant={textFieldVariant}
                readOnly={readOnlyMode}
                hasSignature={claimIncidentUI?.hasSignature ?? false}
                setSignature={(value: boolean) =>
                  setClaimIncidentUI({
                    ...claimIncidentUI,
                    hasSignature: value,
                  })
                }
                stateReport={getValue(claimIncidentUI?.stateReport)}
                claimIncident={getValue(claimIncidentUI?.claimIncident)}
                setClaimIncident={setClaimIncidentUI}
                errorDetails={claimIncidentUI?.errorDetails}
                labelsType={incidentFieldLabelType}
              />
            )}
          </div>
          <div className={"incident-close-content"}>
            {getValue(claimIncidentUI?.activeSection) > 0 && (
              <IconButton
                onClick={() => {
                  setCancelConfirmationOpen(true);
                }}
              >
                <CloseIcon />
              </IconButton>
            )}
          </div>
        </div>
        {!responseEmail.isLoading && (
          <div className={"incident-action-buttons"}>
            <Row horizontalMargin="0px" verticalMargin="0px">
              <Col
                horizontalAlign="flex-start"
                equalWidth={false}
                breakpoints={{ md: 3, lg: 3, xl: 3 }}
              >
                <Font textAlign="start">
                  {claimIncidentUI?.sectionDots?.length
                    ? `Step ${
                        getValue(claimIncidentUI?.activeSection) + 1
                      } of ${claimIncidentUI?.sectionDots?.length}`
                    : `Step ${getValue(claimIncidentUI?.activeSection) + 1}`}
                </Font>
              </Col>
              <Col
                horizontalAlign="flex-end"
                equalWidth={false}
                breakpoints={{ md: 9, lg: 9, xl: 9 }}
              >
                <Button
                  variantStyle={"outlined"}
                  size="medium"
                  sx={{ mr: 2 }}
                  onClick={() => {
                    setCancelConfirmationOpen(true);
                  }}
                >
                  Cancel
                </Button>
                {showSaveItems(claimIncidentUI) ? (
                  <SplitButton
                    overrideLabel={GetSplitButtonLabel(
                      claimIncidentUI,
                      readOnlyMode
                    )}
                    disabled={checkIfSplitButtonIsDisable()}
                    items={saveItems()}
                    triggerEventOnSelect
                  />
                ) : (
                  <Button onClick={actionFinish}>
                    {readOnlyMode ? "Close" : "Save as Submission"}
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        )}
      </div>
      <DialogConfirmation
        id="signatureDialogConfirmation"
        open={isSignatureCheckedDialogOpen}
        dialogDescriptionText="Please check the sign field first!"
        onCloseEvent={(close) => {
          setIsSignatureCheckedDialogOpen(close);
        }}
      />
      <DialogConfirmation
        id="cancelConfirmation"
        open={cancelConfirmationOpen}
        dialogDescriptionText="Are you sure you want to cancel?"
        onOptionYesEvent={redirectBack}
        onCloseEvent={setCancelConfirmationOpen}
        onOptionNoEvent={() => setCancelConfirmationOpen(false)}
      />
    </>
  );
};

export default IncidentFields;
