import { FC, useState } from "react";
import { AchFormProps } from "./ModalAchPaymentUtil";
import { BillingAccountProps } from "../../Account/AccountModal/BillingAccountsUtil";
import Row, { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import { Button, Col, InputDate, Select } from "../../../TrueUI";
import { selectConfiguration } from "../../../../utilities/inputPropsConfigurationFunctions";
import BillingAccountModal from "../../Account/AccountModal/BillingAccountModal";
import { InsuredAccountDto } from "../../../../dtos/insured-account-dto";
const defaultBillingAccountData: InsuredAccountDto = {
  insuredId: 0,
  insuredAccountId: 0,
  accountTypeId: 1,
  accountStatusId: 1,
  nickName: "",
  routingNumber: "",
  accountNumber: "",
  useForACH: false,
  externalToken: "",
};
const defaultBillingAccount: BillingAccountProps = {
  isOpen: false,
  action: "add",
  rowData: null,
  modalData: defaultBillingAccountData,
};

const ModalAchPaymentContent: FC<AchFormProps> = ({
  insuredId,
  configuration,
  achFormData,
  setAchFormData,
  errorMessages,
  dispatchGetConfig,
}) => {
  const [billingAccount, setBillingAccount] = useState<BillingAccountProps>(
    defaultBillingAccount
  );

  const displayAddAccountForm = () => {
    setBillingAccount({
      isOpen: true,
      action: "add",
      rowData: null,
      modalData: {
        insuredId: insuredId,
        insuredAccountId: 0,
      },
    });
  };
  const setRefresh = (refresh: boolean) => {
    if (refresh === true) {
      dispatchGetConfig?.();
    }
  };

  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col>
          <Select
            {...selectConfiguration(
              "id-insured-account",
              "insured-account",
              "Select Account to Draw From",
              false
            )}
            labelFontType="BOLD_CAPTION"
            options={configuration?.insuredAccountList ?? []}
            value={achFormData?.insuredAccountId}
            onChange={(value) => {
              setAchFormData({ ...achFormData, insuredAccountId: value });
            }}
            errorMessage={errorMessages?.insuredAccountId}
            optionsMaxHeight={"150px"}
          />
        </Col>
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }} horizontalAlign="flex-end">
          <Button
            variantStyle={"outlined"}
            onClick={displayAddAccountForm}
            sx={{ marginTop: "12px" }}
          >
            {"Add Account"}
          </Button>
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            id="id-payment-date"
            name="payment-date"
            label="Payment Date"
            labelFontType="BOLD_CAPTION"
            value={achFormData?.paymentDate}
            onChangeRawValue={(dateValue) =>
              setAchFormData?.({
                ...achFormData,
                paymentDate: dateValue,
              })
            }
            errorMessage={errorMessages?.paymentDate}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            id="id-date-received"
            name="name-date-received"
            label="Date Received"
            labelFontType="BOLD_CAPTION"
            value={achFormData?.dateReceived}
            onChangeRawValue={(dateValue) =>
              setAchFormData?.({
                ...achFormData,
                dateReceived: dateValue,
              })
            }
            readOnly
            errorMessage={errorMessages?.dateReceived}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}></Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter} verticalGutter="25px">
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}></Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}></Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}></Col>
      </Row>
      <BillingAccountModal
        billingAccount={billingAccount}
        setBillingAccount={setBillingAccount}
        insuredId={insuredId}
        dispatchGetConfig={dispatchGetConfig}
        setRefresh={setRefresh}
      />
    </>
  );
};

export default ModalAchPaymentContent;
