import { FC, useEffect, useRef } from "react";
import {
  DateTimePicker,
  DatePicker,
  ToggleButton,
  ToggleButtonProps,
} from "@progress/kendo-react-dateinputs";
import moment from "moment";
import { Popup, PopupProps } from "@progress/kendo-react-popup";
import {
  getDateSections,
  getTargetSection,
  regexDay,
  regexKeyTrigger,
  regexMonth,
  regexTwoDigitDay,
  regexTwoDigitMonth,
  regexYear,
} from "./InputDateUtils";

type inputProps = {
  type?: "internalDateTime" | "internalDate";
  formatDate?: string;
  disableFuture?: boolean;
  disablePast?: boolean;
  onChange?: (e?: any) => void;
  value?: any | null;
  disabled?: boolean;
  trueElement?: string;
  maxValue?: number;
  minValue?: number;
  tabIndex?: number;
};

export type DateSectionProps = {
  start: number;
  end: number;
  targetSection: string;
};

export type DateSectionsProps = {
  sectionM: DateSectionProps;
  sectionD: DateSectionProps;
  sectionY: DateSectionProps;
};

const CustomPopup = (props: PopupProps) => {
  return <Popup {...props} style={{ ...props.style, zIndex: 10000 }} />;
};

const InternalDatePicker: FC<inputProps> = ({
  type,
  formatDate,
  disableFuture,
  disablePast,
  onChange,
  value,
  disabled,
  trueElement,
  maxValue,
  minValue,
  tabIndex = 0,
}) => {
  const inputRef = useRef<DatePicker>(null);
  const newDate = value != null ? moment(value).toDate() : null;

  const datePickerIcon = (props: ToggleButtonProps) => (
    <ToggleButton {...props} hidden={disabled} />
  );

  const commonTags = {
    format: formatDate,
    placeholder: "M/D/YYYY",
    onChange: onChange,
    max: disableFuture ? new Date() : maxValue ? new Date(maxValue) : undefined,
    min: disablePast ? new Date() : minValue ? new Date(minValue) : undefined,
    value: newDate,
    disabled: disabled,
    ref: inputRef,
  };

  const setAttribute = (
    componentRef: React.RefObject<any>,
    attr: string,
    value: string
  ) => {
    if (componentRef && componentRef.current && componentRef.current.element) {
      componentRef.current.element.setAttribute(`${attr}`, `${value}`);
    }
  };

  const evaluateMonthSection = (
    value: string,
    isTwoDigitMonth: boolean,
    keyPressed: string,
    sections: DateSectionsProps
  ) => {
    const inputElement = inputRef.current?.dateInput?.element;

    if (
      (isTwoDigitMonth
        ? regexTwoDigitMonth.test(value)
        : regexMonth.test(value)) ||
      keyPressed === "Tab"
    ) {
      const section = getTargetSection(
        sections.sectionM.targetSection,
        sections
      );
      inputElement?.setSelectionRange(section.start, section.end);
    }
  };

  const evaluateDaySection = (
    value: string,
    isTwoDigitDay: boolean,
    keyPressed: string,
    sections: DateSectionsProps
  ) => {
    const inputElement = inputRef.current?.dateInput?.element;

    if (
      (isTwoDigitDay ? regexTwoDigitDay.test(value) : regexDay.test(value)) ||
      keyPressed === "Tab"
    ) {
      const section = getTargetSection(
        sections.sectionD.targetSection,
        sections
      );
      inputElement?.setSelectionRange(section.start, section.end);
    }
  };

  const evaluateYearSection = (
    value: string,
    keyPressed: string,
    sections: DateSectionsProps
  ) => {
    const inputElement = inputRef.current?.dateInput?.element;

    if (regexYear.test(value) || keyPressed === "Tab") {
      const section = getTargetSection(
        sections.sectionY.targetSection,
        sections
      );
      inputElement?.setSelectionRange(section.start, section.end);
    }
  };

  const evaluateSection = (
    caretPosition: number,
    inputValue: string,
    isTwoDigitMonth: boolean,
    isTwoDigitDay: boolean,
    keyPressed: string,
    sections: DateSectionsProps
  ) => {
    const { sectionM, sectionD, sectionY } = sections;

    if (caretPosition >= sectionM.start && caretPosition <= sectionM.end) {
      const value = inputValue.substring(sectionM.start, sectionM.end);
      evaluateMonthSection(value, isTwoDigitMonth, keyPressed, sections);
    }
    if (caretPosition >= sectionD.start && caretPosition <= sectionD.end) {
      const value = inputValue.substring(sectionD.start, sectionD.end);
      evaluateDaySection(value, isTwoDigitDay, keyPressed, sections);
    }
    if (caretPosition >= sectionY.start && caretPosition <= sectionY.end) {
      const value = inputValue.substring(sectionY.start, sectionY.end);
      evaluateYearSection(value, keyPressed, sections);
    }
  };

  const updateCaretPositionByDynamicType = (event: KeyboardEvent) => {
    if (regexKeyTrigger.test(event.key)) {
      const datePicker = inputRef.current;
      const inputElement = datePicker?.dateInput?.element;
      const inputValue = inputElement?.value?.trim() ?? "";

      if (inputValue !== "" && datePicker) {
        const caretPosition = inputElement?.selectionStart ?? 0;
        const valueAsArray = [...inputValue];
        const indexes = valueAsArray
          .map((c, index) => {
            if (c === "/") {
              return index;
            } else return -1;
          })
          .filter((index) => index !== -1);

        const format = commonTags.format?.toLocaleUpperCase() ?? "M/D/YYYY";

        const isTwoDigitMonth = format.indexOf("MM") !== -1;
        const isTwoDigitDay = format.indexOf("DD") !== -1;

        const sections = getDateSections(format, indexes, inputValue);

        evaluateSection(
          caretPosition,
          inputValue,
          isTwoDigitMonth,
          isTwoDigitDay,
          event.key,
          sections
        );
      }
    }
  };

  const disableTabBehavior = (event: KeyboardEvent) => {
    if (event.key === "Tab") {
      event.preventDefault();
    }
  };

  useEffect(() => {
    setAttribute(inputRef, "true-element", trueElement ?? "");
    const inputElement = inputRef.current?.dateInput?.element;

    inputElement?.addEventListener("keyup", updateCaretPositionByDynamicType);
    inputElement?.addEventListener("keydown", disableTabBehavior);

    return () => {
      inputElement?.removeEventListener(
        "keyup",
        updateCaretPositionByDynamicType
      );
      inputElement?.removeEventListener("keydown", disableTabBehavior);
    };
  }, []);

  return type === "internalDate" ? (
    <DatePicker
      {...commonTags}
      toggleButton={datePickerIcon}
      popup={CustomPopup}
      tabIndex={tabIndex}
    />
  ) : (
    <DateTimePicker {...commonTags} tabIndex={tabIndex} />
  );
};

export default InternalDatePicker;
