import { FC } from "react";
import { useNavigate } from "react-router";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../GlobalAtoms";
import { Font } from "../TrueUI";
import { NavItemType } from "./NavBarElements";

type NavItemProps = {
  item: NavItemType;
  index: number;
  current: number;
  setCurrent: (current: number) => void;
};

const NavItem: FC<NavItemProps> = ({ item, index, current, setCurrent }) => {
  const navigate = useNavigate();

  // This probably will be updated to the new way
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;

  return (
    <div
      onClick={() => {
        navigate(item?.url);
        setCurrent(index);
      }}
      className="nav_item"
      style={{
        cursor: "pointer",
        borderBottom: `4px solid ${
          index == current ? theme?.primary : "transparent"
        }`,
        padding: "10px",
      }}
    >
      <Font
        className="portals-nav-item"
        display="block"
        fontType="BOLD_TITLE"
        secondaryFont
      >
        {item?.displayName}
      </Font>
    </div>
  );
};

export default NavItem;
