import { FC, useEffect, useState } from "react";
import MultiHeader from "../../TrueUI/Tables/MultiTable/MultiHeader";
import {
  BaseTable2Properties,
  ColumnOptionsProperties,
  ComputeForCellParameters,
  ConditionForCellResponse,
} from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../../hooks/useBaseTable";
import { BaseTable2 } from "../../TrueUI";
import { formatToCurrency } from "../../../utilities/stringFunctions";
import PayrollReportButtons from "./PayrollReportButtons";
import payrollStyles from "./PayrollReport.module.css";

import PayrollReportChildTable from "./PayrollReportChildTable";

type PayrollReportDetailProps = {
  payrollReportId?: string;
};

export type PayrollDetailsUpdatedProps = {
  payrollReportDetailsUpdated?: any;
  isEERequired?: boolean;
  payrollReportId?: string;
  payrollReportStatus?: number;
};

const PayrollReportDetailsTable: FC<PayrollReportDetailProps> = ({
  payrollReportId,
}) => {
  const defaultPayrollDetails: PayrollDetailsUpdatedProps = {
    payrollReportDetailsUpdated: [],
    payrollReportId: payrollReportId,
    isEERequired: false,
  };
  // Need to have two hooks because the table configurations has problems
  // and it does not have the updated data.
  const [payrollDetailsData, setPayrollDetailsData] =
    useState<PayrollDetailsUpdatedProps | null>(defaultPayrollDetails);
  const [newUpdatedData, setNewUpdatedData] =
    useState<PayrollDetailsUpdatedProps | null>();
  const [uuid, setUUID] = useState(crypto.randomUUID());

  const onPayrollChange = () => {
    return {
      triggerComputeFieldNames: ["Amount"],
    } as ConditionForCellResponse;
  };

  const updateAmountByPayroll = (options: ComputeForCellParameters<any>) => {
    const payroll = parseFloat(options?.row?.NetPayroll ?? "0");
    const modifiedRate = parseFloat(options?.row?.ModifiedRate ?? "0");
    if (isNaN(payroll) || isNaN(modifiedRate)) {
      return {
        value: "-",
      };
    } else {
      return { value: formatToCurrency((payroll / 100) * modifiedRate) };
    }
  };

  const columnOptionsProps: ColumnOptionsProperties<any>[] = [
    { fieldName: "PayrollReportId", width: 0 },
    { fieldName: "PayrollReportDetailId", width: 0 },
    { fieldName: "NameId", width: 0 },
    { fieldName: "State", width: 7 },
    { fieldName: "ClassCode", width: 10 },
    { fieldName: "Description", width: 40 },
    { fieldName: "NumEE", width: 14, align: "right" },
    {
      fieldName: "NetPayroll",
      width: 10,
      align: "right",
      decimalScale: 2,
      computeOnChange: {
        conditionForCell: [() => onPayrollChange()],
      },
    },
    { fieldName: "ModifiedRate", width: 10, align: "right", decimalScale: 2 },
    {
      fieldName: "Amount",
      width: 10,
      align: "right",
      decimalScale: 2,
      computeOnChange: {
        conditionForCell: [(options) => updateAmountByPayroll(options)],
      },
    },
  ];

  const tableName = "portal-payroll-report-details-table";

  const payrollReportDetailConfig: BaseTable2Properties = {
    name: tableName,
    getURL: `api/PayrollReport/GetPayrollReportDetailMultiTable?payrollReportId=${payrollReportId}&uuid=${uuid}`,
    tableType: "multi",
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSaveButton: false,
      showSortFilter: false,
      showImportButton: false,
      showExcelButton: false,
      showPDFButton: false,
    },
    columnOptions: columnOptionsProps,
    advancedOptions: {
      paginate: false,
      multiTableProperties: {
        allRowsExpanded: true,
        MultiTablePatternComponent: (tableData) => (
          <PayrollReportChildTable
            tableData={tableData}
            columnOptionsProps={columnOptionsProps}
            setNewUpdatedData={setNewUpdatedData}
            payrollReportId={payrollReportId}
            payrollDetailsData={payrollDetailsData?.payrollReportDetailsUpdated}
          />
        ),
        MultiTableHeaderPatternComponent: (params) => {
          const titleParams = params ?? [];
          return <MultiHeader headerParams={titleParams} />;
        },
      },
    },
  };

  useBaseTable(payrollReportDetailConfig);

  useEffect(() => {
    if (newUpdatedData != null) {
      const allItemsList =
        payrollDetailsData?.payrollReportDetailsUpdated ?? [];
      const newItemList = newUpdatedData?.payrollReportDetailsUpdated ?? [];

      // Is necessary to storage all the rows, so they are being concatenated
      // and filtering to remove the repeated rows.
      const newList = [
        ...newItemList,
        ...allItemsList?.filter(
          (previousItem) =>
            !newItemList.some(
              (detail) =>
                detail?.PayrollReportDetailId ===
                previousItem?.PayrollReportDetailId
            )
        ),
      ];

      setPayrollDetailsData({
        payrollReportDetailsUpdated: newList,
        isEERequired: newUpdatedData?.isEERequired ?? false,
        payrollReportId: payrollReportId,
      });
    }
  }, [newUpdatedData]);

  return (
    <div className={payrollStyles.payroll_report_body_container}>
      <BaseTable2 name={tableName} />
      <PayrollReportButtons
        payrollDetailsData={payrollDetailsData}
        setUUID={setUUID}
        setPayrollDetailsData={setPayrollDetailsData}
        defaultPayrollDetails={defaultPayrollDetails}
      />
    </div>
  );
};
export default PayrollReportDetailsTable;
