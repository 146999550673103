import { FC } from "react";
import { useBaseTable } from "../../hooks/useBaseTable";
import { BaseTable2Properties } from "../TrueUI/Tables/BaseTable2/TableProperties";
import { BaseTable2 } from "../TrueUI";
import { PortalPolicyBoundDto } from "../../dtos/portal-policy-bound-dto";

type PolicyDocumentsTableProps = {
  policy: PortalPolicyBoundDto;
};

const PolicyDocumentsTable: FC<PolicyDocumentsTableProps> = ({ policy }) => {
  const tableName = `portal-policy-documents-${policy.policyId}`;
  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/Policy/GetPolicyDocumentsTable?policyId=${policy?.policyId}`,
    columnOptions: [
      { fieldName: "FileName", width: 85 },
      { fieldName: "Date", width: 15 },
    ],

    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
      showExcelButton: false,
      showPDFButton: false,
    },
    advancedOptions: {
      paginate: false,
      disableOrderBy: true,
    },
  };

  useBaseTable(tableConfiguration);
  return (
    <div
      style={{
        height: "150px",
        marginTop: "-15px",
        width: "90%",
      }}
    >
      <BaseTable2 name={tableName} />
    </div>
  );
};
export default PolicyDocumentsTable;
