import { getColorWithOpacityHexaDecimal } from "../../../media/themeConstants";
import { Theme } from "../../../media/themeTypes";

export const buttonStyles = (theme: Theme) => ({
  fontSize: theme?.body.size,
  padding: "4px 10px",
  textTransform: "uppercase",
  "&.contained": {
    background: theme?.primary,
    boxShadow: 2,
    color: theme?.primaryBackground,
    borderColor: `${theme?.borderColor} !important`,
    "&:hover": {
      background: getColorWithOpacityHexaDecimal(theme?.primary, 60),
    },
  },
  "&.outlined": {
    borderColor: `${theme?.primary} !important`,
    background: "transparent",
    color: theme?.primary,
    "&:hover": {
      background: getColorWithOpacityHexaDecimal(theme?.primary, 10),
      //color: theme?.TEXT_COLOR,
    },
  },
  "&.text": {
    background: "transparent",
    color: theme?.primary,
    fontSize: theme?.boldBody.size,
    fontWeight: theme?.boldBody.weight,
    "&:hover": {
      background: getColorWithOpacityHexaDecimal(theme?.primary, 10),
    },
  },
});
