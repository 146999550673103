import { atom } from "recoil";

const dispatchPrintJSONAtom = atom<boolean>({
  key: "dispatchPrintJSON",
  default: false,
});

const refreshPayrollReportDetailsHeaderAtom = atom<boolean>({
  key: "refreshHeader",
  default: false,
});
export { dispatchPrintJSONAtom, refreshPayrollReportDetailsHeaderAtom };
