import { useEffect } from "react";
import { BaseTableFilterOptions } from "../BaseTable2/TableProperties";
import { DROPDOWN_FILTER } from "../TableConstants";
import PolicyDropdown from "../../Select/PolicyDropdown";

type BaseTableDropdownFilterProps = {
  id?: string;
  filterOptions: BaseTableFilterOptions;
  columnsNames?: string[];
  defaultValue?: string | number;
  labelPosition?: "start" | "end" | "top" | "bottom";
  labelText?: string;
  overrideAllOptionLabel?: string;
  overrideAllOptionValue?: string;
  selectAllOptionAsDefault?: boolean;
  isExternalAction?: boolean;
  selectWidth?: string;
  addAllOption?: boolean;
  onChangeAfterAPI?: boolean;
  onChange?: (e: any) => void;
};

const BaseTablePolicyDropdownAsyncFilter: (
  properties: BaseTableDropdownFilterProps
) => any = ({
  id,
  filterOptions,
  columnsNames,
  defaultValue,
  labelPosition,
  labelText,
  overrideAllOptionLabel,
  overrideAllOptionValue,
  selectAllOptionAsDefault,
  isExternalAction = false,
  selectWidth,
  addAllOption,
  onChangeAfterAPI,
  onChange,
}) => {
  if (isExternalAction) {
    filterOptions.isStaticFilter?.(true);
  }

  const changeFilter = (val: string) => {
    filterOptions?.setFilterInclude?.([
      {
        filterName: DROPDOWN_FILTER,
        columnNames: columnsNames,
        filterValue: val,
      },
    ]);
  };

  useEffect(() => {
    if (!isExternalAction) changeFilter(defaultValue?.toString() ?? "all");
  }, []);

  return (
    <PolicyDropdown
      id={`filter-policy-dropdown-${id}`}
      name={`filter-policy-dropdown-${id}`}
      variant={"filled"}
      labelPosition={labelPosition ?? "start"}
      label={labelText}
      type={"tableFilter"}
      inputWidth={selectWidth ? selectWidth : "fit-content"}
      selectAllOptionAsDefault={selectAllOptionAsDefault}
      overrideAllOptionValue={overrideAllOptionValue}
      overrideAllOptionLabel={overrideAllOptionLabel}
      isCustomArrow
      labelFontType="TITLE"
      policySelectedOnChange={(value: any) => {
        if (!isExternalAction) changeFilter(value);
        onChange?.(value);
      }}
      addAllOption={addAllOption}
      defaultValue={defaultValue}
      onChangeAfterAPI={onChangeAfterAPI}
    />
  );
};

export default BaseTablePolicyDropdownAsyncFilter;
