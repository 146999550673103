import { IconButton } from "@mui/material";
import { FC, useState } from "react";
import DialogConfirmation from "../Dialogs/DialogConfirmation";
import Button from "./Button";
import CloseIcon from "@mui/icons-material/Close";

type CancelButtonProps = {
  typeOfCancelButton?: "button" | "icon";
  textOnButton?: string;
  textOnConfirmation?: string;
  withConfirmation?: boolean;
  variantStyle?: "contained" | "outlined" | "text";
  name?: string;
  onClick?: () => void; // For yes too.
  onNoClick?: () => void;
};

const CancelButton: FC<CancelButtonProps> = ({
  typeOfCancelButton = "button",
  textOnButton = "Cancel",
  textOnConfirmation = "Are you sure you want to cancel?",
  withConfirmation = false,
  variantStyle = "outlined",
  name = "",
  onClick,
  onNoClick,
}) => {
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);

  const _onClick = () => {
    if (withConfirmation) {
      setIsDialogConfirmationOpen(true);
    } else {
      onClick?.();
    }
  };
  return (
    <>
      {typeOfCancelButton != "button" ? (
        <IconButton aria-label="close" onClick={_onClick}>
          <CloseIcon />
        </IconButton>
      ) : (
        <Button variantStyle={variantStyle} onClick={_onClick} name={name}>
          {textOnButton}
        </Button>
      )}

      <DialogConfirmation
        id="cancel-button-confirmation"
        open={isDialogConfirmationOpen}
        dialogDescriptionText={textOnConfirmation}
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          onNoClick?.();
          setIsDialogConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          onClick?.();
          setIsDialogConfirmationOpen(close);
        }}
      />
    </>
  );
};

export default CancelButton;
