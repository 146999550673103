import { Button, Font, InputMemo } from "../TrueUI";
import Paper from "../TrueUI/Containers/Paper";
import { useEffect, useState } from "react";
import MultiUpload from "../TrueUI/Upload/MultiUpload";
import Circle from "../TrueUI/Shapes/Cicle";
import { PortalUploadFilesDto } from "../../dtos/portal-upload-files-dto";
import { useApiPost } from "../../hooks";
import { FileAttachment, getFilteredOptions } from "./UploadFilesUtil";
import { useNavigate } from "react-router";
import TrueRadioGroup from "../TrueUI/Checkbox/RadioGroup";
import { isEmptyValue } from "../../utilities/conditionalSupportFunctions";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";
import DialogConfirmation from "../TrueUI/Dialogs/DialogConfirmation";
import { PortalUploadFilesSectionsEnum } from "../../dtos/portal-upload-files-sections-enum";
import CancelButton from "../TrueUI/Buttons/CancelButton";
import WelcomeText from "../Dashboard/WelcomeText";
import style from "./UploadFilesForm.module.css";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../GlobalAtoms";

const UploadFilesForm = () => {
  const navigate = useNavigate();
  const localOptions = useRecoilValue(globalOptions);
  const [sendEmailRequest, setSendEmailRequest] =
    useState<PortalUploadFilesDto>({
      currentStep: 1,
      filesToAttach: {},
      regarding: 0,
      comments: "",
      blobNames: [],
    });
  const [clear, setClear] = useState<boolean>(false);
  const { responsePost, dispatchPost, validatorErrorResponse } = useApiPost(
    "api/PortalUploadFiles/SendEmail",
    { ...sendEmailRequest }
  );
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const programInfo = localOptions.initOptions?.programInfo;
  const filteredOptions = getFilteredOptions(programInfo);

  const getNextStep = () => {
    if (isEmptyValue(sendEmailRequest.blobNames)) return 1;
    if (sendEmailRequest.regarding === 0) return 2;
    return 3;
  };

  const closeConfirmationBox = (close: boolean) => {
    setIsDialogOpen(close);
    navigate("/");
  };

  useEffect(() => {
    setSendEmailRequest({
      ...sendEmailRequest,
      currentStep: getNextStep(),
    });
  }, [
    sendEmailRequest.regarding,
    sendEmailRequest.comments,
    sendEmailRequest.blobNames,
  ]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      const isSendEmailSuccess = responsePost.responseData as boolean;
      setIsDialogOpen(isSendEmailSuccess);
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    } else {
      setErrorDetails(null);
    }
  }, [validatorErrorResponse]);

  const sendEmail = () => {
    dispatchPost();
  };

  return (
    <>
      <WelcomeText />
      <Paper title="Send us Something">
        {sendEmailRequest.currentStep >=
          PortalUploadFilesSectionsEnum.ATTACHMENT_FILES && (
          <div id="first-step">
            <Circle>1</Circle>
            <Font whiteSpace="normal" fontType="TITLE">
              What would you like to send us? Drag one or more files here or
              click to upload your document(s).
            </Font>
            <div
              className={`${style.section_container} ${style.file_upload_container}`}
            >
              <MultiUpload
                name="upload-files"
                files={sendEmailRequest?.filesToAttach as FileAttachment}
                saveUrl={"api/PortalFileArchive/UploadMultiple"}
                removeUrl={"api/PortalFileArchive/Remove"}
                clearFiles={clear}
                resetClearFiles={setClear}
                onUploadFileList={(newfiles, response) => {
                  const fileNames = response?.map(
                    (item) => item?.fileName ?? null
                  );

                  setSendEmailRequest({
                    ...sendEmailRequest,
                    filesToAttach: { ...(newfiles ?? {}) },
                    blobNames: [
                      ...sendEmailRequest.blobNames,
                      ...(fileNames ?? []),
                    ],
                  });
                }}
                onRemovedFile={(newFiles, response) => {
                  const newBlobNames = sendEmailRequest.blobNames.filter(
                    (blobName) => blobName !== response?.fileName
                  );
                  setSendEmailRequest({
                    ...sendEmailRequest,
                    filesToAttach: { ...(newFiles ?? {}) },
                    blobNames: [...newBlobNames],
                  });
                }}
                errorMessages={errorDetails?.blobNames}
              />
            </div>
          </div>
        )}
        {sendEmailRequest.currentStep >=
          PortalUploadFilesSectionsEnum.REGARDING && (
          <div id="second-step" style={{ marginTop: "20px" }}>
            <Circle>2</Circle>
            <Font whiteSpace="normal" textAlign="start" fontType="TITLE">
              What is this about?
            </Font>
            <div className={`${style.section_container}`}>
              <TrueRadioGroup
                options={filteredOptions}
                value={sendEmailRequest?.regarding}
                onChangeRaw={(value) =>
                  setSendEmailRequest({
                    ...sendEmailRequest,
                    regarding: value,
                  })
                }
                errorMessages={errorDetails?.regarding}
              />
            </div>
          </div>
        )}
        {sendEmailRequest.currentStep >=
          PortalUploadFilesSectionsEnum.ADDITIONAL_COMMENTS && (
          <div id="third-step" style={{ marginTop: "20px" }}>
            <Circle>3</Circle>
            <Font whiteSpace="normal" textAlign="start" fontType="TITLE">
              Want to add any additional information or notes?
            </Font>
            <div className={`${style.section_container}`}>
              <InputMemo
                variant="outlined"
                id={"additional-information"}
                name={"additional-information"}
                value={sendEmailRequest?.comments}
                onChangeRawValue={(value) =>
                  setSendEmailRequest({
                    ...sendEmailRequest,
                    comments: value,
                  })
                }
                maxLength={500}
                errorMessage={errorDetails?.comments}
              />
            </div>
          </div>
        )}
        <div className={style.buttons_container}>
          <CancelButton
            withConfirmation
            onClick={() => navigate("/")}
            textOnButton="GO BACK"
          />
          {sendEmailRequest.currentStep >= 3 && (
            <Button variantStyle="contained" onClick={sendEmail}>
              SEND
            </Button>
          )}
        </div>
      </Paper>
      <DialogConfirmation
        id="sendSuccessDialogConfirmation"
        open={isDialogOpen}
        dialogDescriptionText="Thank you. Your files have been sent. We will get back to you shortly."
        optionYesOverrideLabel="Ok"
        onOptionYesEvent={(close) => {
          closeConfirmationBox(close);
        }}
        onCloseEvent={(close) => {
          closeConfirmationBox(close);
        }}
      />
    </>
  );
};

export default UploadFilesForm;
