import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { BillingTransactionDataDto } from "../../../../dtos/billing-transaction-data-dto";
import { TranzpayRequestDto } from "../../../../dtos/tranzpay-request-dto";
import { useApiPost } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import {
  isEmptyValue,
  conditionHasValue,
} from "../../../../utilities/conditionalSupportFunctions";

const PaymentRedirectPostBack = () => {
  const navigate = useNavigate();
  const url = decodeURIComponent(window.location.search);
  const urlParams = new URLSearchParams(url);

  const insuredId = urlParams.get("insuredId") ?? "";
  const thirdPartyCallId = urlParams.get("thirdPartyCallID");
  const payments = urlParams.get("payments")?.split(",") ?? [];
  const policies = urlParams.get("policies")?.split(",") ?? [];
  const tranAccounts = urlParams.get("tranAccounts")?.split(",") ?? [];

  const [transactionRequest, setTransactionRequest] = useState<
    TranzpayRequestDto | null | undefined
  >();

  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<TranzpayRequestDto>(
      `api/Tranzpay/ChargeCreditCard`,
      transactionRequest
    );

  useEffect(() => {
    if (!isEmptyValue(insuredId) && !isEmptyValue(thirdPartyCallId)) {
      setTransactionRequest({
        insuredID: parseInt(insuredId),
        thirdPartyCallID: thirdPartyCallId,
        paymentData: payments.map(
          (payment, index) =>
            ({
              policyId: Number(policies[index]),
              payment: Number(payment),
              tranAccountId: Number(tranAccounts[index]),
            } as BillingTransactionDataDto)
        ),
      });
    }
  }, []);

  useEffect(() => {
    if (conditionHasValue(transactionRequest)) {
      dispatchPost();
    }
  }, [transactionRequest]);

  useEffect(() => {
    if (isAPITotallyComplete(responsePost)) {
      navigate(`/billing`);
    }
  }, [responsePost, validatorErrorResponse]);

  return <></>;
};

export default PaymentRedirectPostBack;
