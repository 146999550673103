import { Box } from "@mui/material";
import { TabStrip } from "@progress/kendo-react-layout";
import { FC, ReactNode, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import {
  addQueryStrings,
  getQueryValue,
  removeQueryStrings,
} from "../../../hooks/useURL";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { tabsSx } from "./TabsStyles";

type TabsProperties = {
  id?: string;
  className?: string;
  name: string;
  themeOverride?: "material" | "square";
  selected?: number;
  position?: "top" | "bottom" | "left" | "right";
  children?: any;
  actionBar?: ReactNode | JSX.Element;
  stateControlled?: boolean; // To let know that their state is controlled from outside, this was created when the state is storage on recoil and queryString (both).
  disableQueryString?: boolean; // When you do not need to add queryString to the URL
  onSelect?: (e: any) => void;
  backgroundTransparent?: boolean;
};

const getInitialSelect = (name, stateControlled, selected) => {
  const queryState = getQueryValue(name) != "" ? getQueryValue(name) : "0";
  return stateControlled ? selected : parseInt(queryState);
};

const Tabs: FC<TabsProperties> = ({
  id,
  className,
  name,
  stateControlled = false,
  selected,
  disableQueryString = false,
  onSelect,
  position,
  children,
  themeOverride,
  actionBar,
  backgroundTransparent = true,
}) => {
  const valueSelected = getInitialSelect(name, stateControlled, selected);
  const [selectedTab, setSelectedTab] = useState<number>(valueSelected);
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;

  const handleSelect = (e) => {
    onSelect?.(e);
    setSelectedTab(e.selected);
  };

  useEffect(() => {
    if (!disableQueryString) {
      addQueryStrings([{ nameOfHash: name, valueOfHash: selectedTab }]);
    }
  }, [selectedTab]);

  useEffect(() => {
    return () => {
      removeQueryStrings([name]);
    };
  }, []);

  useEffect(() => {
    if (conditionHasValue(selected)) {
      setSelectedTab(selected ?? 0);
    }
  }, [selected]);

  return (
    <Box
      id={id}
      className={`tabs_container ${className ?? ""}`}
      sx={tabsSx(theme)}
    >
      <TabStrip
        true-element={`true-element-tab-container-${name}`}
        animation={false}
        selected={selectedTab}
        tabPosition={position ?? "top"}
        onSelect={handleSelect as any}
        className={`tab-strip-wrapper ${themeOverride ?? "material"} ${
          backgroundTransparent ? "set-background-transparent" : ""
        }`}
      >
        {children}
      </TabStrip>
      {actionBar && (
        <Box
          left={-65}
          bottom={10}
          zIndex={999}
          position={"relative"}
          height={"fit-content"}
        >
          {actionBar}
        </Box>
      )}
    </Box>
  );
};

export default Tabs;
