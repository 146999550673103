import { Theme } from "../../../media/themeTypes";

export const tabsSx = (theme: Theme) => {
  return {
    display: "flex",
    width: "100%",
    height: "100%",
    // marginBottom: "10px",
    "& .k-tabstrip": {
      flex: "auto",
      width: "100%",
    },
    "& .square": {
      "& > .k-tabstrip-items-wrapper": {
        width: "100%",
        marginLeft: "5px",
        background: theme?.primaryBackground,
        border: "none",
        "& .k-tabstrip-items": {
          "& .k-item": {
            background: theme?.primaryBackground,
            textTransform: "capitalize",
            padding: "5px 10px",
            color: theme?.textColor,
            fontSize: theme?.title?.size,
            fontWeight: theme?.title?.weight,
            borderBottom: `1px solid ${theme?.borderColor}`,
            marginBottom: 0,
            "& .k-link": {
              padding: 0,
              "& .MuiButtonBase-root": {
                padding: 0,
                color: theme?.textColor,
                fontSize: theme?.title?.size,
              },
            },
          },
          "& .k-active": {
            fontSize: theme?.boldTitle?.size,
            fontWeight: theme?.boldTitle?.weight,
            border: `1px solid ${theme?.borderColor}`,
            borderRadius: "5px 5px 0 0",
            borderBottom: "none",
            display: "flex",
            flexDirection: "column",
          },
          "&::after": {
            content: '""',
            display: "block",
            width: "100%",
            borderBottom: `1px solid ${theme?.borderColor}`,
          },
        },
      },
      "& .k-content": {
        background: theme?.primaryBackground,
        padding: 0,
        border: "none",
        // display: "block",
        display: "flex !important",
        flexDirection: "column",
        overflow: "hidden",
      },
    },
    "& .set-background-transparent > .k-tabstrip-items-wrapper": {
      backgroundColor: "transparent!important",
    },
    "& .set-background-transparent > .k-tabstrip-items-wrapper  .k-item": {
      backgroundColor: "transparent!important",
    },
    "& .set-background-transparent .k-content": {
      backgroundColor: "transparent!important",
    },
    "& .material": {
      "& > .k-tabstrip-items-wrapper": {
        background: theme?.primaryBackground,
        border: "none",
        "& .k-tabstrip-items": {
          "& .k-item": {
            background: theme?.primaryBackground,
            textTransform: "capitalize",
            padding: "0px 25px",
            color: theme?.textColor,
            fontSize: theme?.title?.size,
            fontWeight: theme?.title?.weight,
            border: "none",
            borderBottom: `1px solid ${theme?.borderColor}`,
            marginBottom: 0,
            "& .k-link": {
              padding: "0px",
              "& div": {
                padding: "0px",
              },
            },
          },
          "& .k-active": {
            fontSize: theme?.boldTitle?.size,
            fontWeight: theme?.boldTitle?.weight,
            borderBottom: `3px solid ${theme?.primary}`,
          },
        },
      },
      "& .k-content": {
        background: theme?.primaryBackground,
        padding: 0,
        border: "none",
        display: "flex",
      },
      ".k-content": {
        overflow: "hidden",
      },
    },
    ".tabs_container": {
      flexGrow: 1,
      height: "100%",
      overflow: "hidden",
      display: "flex",
    },
    ".k-content .k-active": {
      display: "flex !important",
    },
    ".k-active > div[role='tabpanel']": {
      flexGrow: 1,
      display: "flex",
      overflow: "hidden",
      flexDirection: "column",
    },
  };
};
