import { FC, useEffect, useState } from "react";
import { useApiGet } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { Select } from "../../TrueUI";
import { SelectOptions } from "../../../dtos/select-options";
import { KeysAsType } from "../../../types/KeysAsAType";
import { FontsType } from "../../../media/themeTypes";
import { addCustomOption } from "../../../utilities/selectFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

type PolicyDropdownProps = {
  id: string;
  name: string;
  className?: string;
  errorMessage?: string[] | null;
  readOnly?: boolean;
  inputWidth?: string;
  variant?: "filled" | "standard";
  labelPosition?: "start" | "end" | "top" | "bottom";
  type?: "standard" | "tableFilter" | "tableCell";
  label?: string;
  labelFontType?: KeysAsType<FontsType>;
  isCustomArrow?: boolean;
  overrideAllOptionLabel?: string;
  overrideAllOptionValue?: string;
  selectAllOptionAsDefault?: boolean;
  currentPolicyAsDefault?: boolean;
  defaultValue?: string | number;
  addAllOption?: boolean;
  onChangeAfterAPI?: boolean;
  policySelectedOnChange?: (value: any) => void;
};

const PolicyDropdown: FC<PolicyDropdownProps> = ({
  id,
  name,
  className,
  readOnly,
  errorMessage,
  inputWidth,
  variant,
  labelPosition = "top",
  label = "Policy: ",
  type = "standard",
  labelFontType = "BOLD_CAPTION",
  isCustomArrow = false,
  overrideAllOptionLabel,
  overrideAllOptionValue,
  selectAllOptionAsDefault,
  currentPolicyAsDefault,
  defaultValue,
  policySelectedOnChange,
  addAllOption = true,
  onChangeAfterAPI = true,
}) => {
  const [policies, setPolicies] = useState<
    SelectOptions[] | Partial<SelectOptions>[]
  >([]);
  const [selectedPolicy, setSelectedPolicy] = useState<number | string | null>(
    null
  );

  const { responseGet, dispatchGet } = useApiGet<SelectOptions[]>(
    `api/Policy/GetPoliciesByCurrentInsured`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      if (type === "tableFilter" && addAllOption) {
        setPolicies(
          addCustomOption(responseGet.axiosResponse?.data ?? [], {
            displayName: overrideAllOptionLabel
              ? overrideAllOptionLabel
              : "All",
            stringValue: overrideAllOptionValue
              ? overrideAllOptionValue
              : "Active",
          })
        );
      } else {
        setPolicies(responseGet.axiosResponse?.data ?? []);
      }
      if (conditionHasValue(defaultValue)) {
        setSelectedPolicy(parseInt(defaultValue?.toString() ?? ""));
      } else {
        if (currentPolicyAsDefault) {
          const currentPolicy =
            responseGet.axiosResponse?.data?.find((x) => x.isDefault)
              ?.intValue ?? -1;
          setSelectedPolicy(currentPolicy);
          policySelectedOnChange?.(currentPolicy);
        }
        if (!currentPolicyAsDefault) {
          onChangeAfterAPI &&
            setSelectedPolicy(
              selectAllOptionAsDefault
                ? overrideAllOptionValue ?? "All"
                : responseGet.axiosResponse?.data?.[0]?.intValue ?? -1
            );
        }
      }
    }
  }, [responseGet]);

  return (
    <Select
      id={id}
      name={name}
      label={label}
      className={className}
      variant={variant}
      labelFontType={labelFontType}
      labelPosition={labelPosition}
      type={type}
      options={policies}
      firstOptionAsDefault
      value={selectedPolicy}
      errorMessage={errorMessage}
      readOnly={readOnly}
      isCustomArrow={isCustomArrow}
      inputWidth={inputWidth}
      onChange={(value) => {
        policySelectedOnChange?.(value);
      }}
      optionsContainerPosition="bottom"
      optionsMaxHeight="150px"
    />
  );
};

export default PolicyDropdown;
