import { FC, useState } from "react";
import { Collapse } from "../TrueUI";
import { useBaseTable } from "../../hooks/useBaseTable";
import { BaseTable2Properties } from "../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTablePolicyDropdownAsyncFilter from "../TrueUI/Tables/BaseTableCommonFilters/BaseTablePolicyDropdownAsyncFilter";
import BaseTable2 from "../TrueUI/Tables/BaseTable2/BaseTable2";

const Payments: FC = () => {
  const TABLE_NAME = "payments-table-by-policyId";
  const [policyId, setPolicyId] = useState<number>(-1);

  const tableConfiguration: BaseTable2Properties = {
    name: TABLE_NAME,
    getURL: `api/Billing/GetBillingPaymentsByPolicyId?policyId=${policyId}`,
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showExcelButton: true,
      showPDFButton: true,
      showImportButton: false,
      showSaveButton: false,
      showSortFilter: false,
    },
    advancedOptions: {
      paginate: false,
    },
    columnOptions: [
      { fieldName: "PaymentDate", width: 15 },
      { fieldName: "PaymentType", width: 15 },
      { fieldName: "ReferenceNumber", width: 50 },
      { fieldName: "Amount", align: "right", width: 20 },
    ],
    filterOptions: [
      (actionOptions) =>
        BaseTablePolicyDropdownAsyncFilter({
          selectWidth: "230px",
          columnsNames: ["PolicyId"],
          filterOptions: actionOptions,
          isExternalAction: true,
          labelText: "Policy:",
          addAllOption: false,
          onChangeAfterAPI: false,
          onChange: (value) => {
            setPolicyId(value);
          },
        }),
    ],
  };

  useBaseTable(tableConfiguration);

  return (
    <Collapse
      id={"billing-payments-collapse"}
      title="Payments"
      expanded={false}
      titleHeightAuto
      content={
        <div style={{ height: "215px", marginTop: "-15px" }}>
          <BaseTable2 name={TABLE_NAME} />
        </div>
      }
    />
  );
};
export default Payments;
