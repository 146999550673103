import { Grid, GridSize } from "@mui/material";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import { columnStyles } from "./ColStyles";

export type ColProps = {
  colHeight?: string;
  borderLeftWidth?: string;
  borderRightWidth?: string;
  borderStyle?: string;
  verticalAlign?: "flex-start" | "center" | "flex-end";
  horizontalAlign?: "flex-start" | "center" | "flex-end" | "space-between";
  verticalAlignSelf?:
    | "flex-start"
    | "center"
    | "flex-end"
    | "stretch"
    | "unset";
  horizontalAlignSelf?: "flex-start" | "center" | "flex-end" | "space-between";
  verticalGutter?: "0px" | "5px" | "10px" | "15px" | "20px" | "25px";
  horizontalGutter?: "0px" | "5px" | "10px" | "15px" | "20px" | "25px";
  verticalMargin?: "-5px" | "0px" | "10px" | "20px" | "30px" | "40px" | "50px";
  horizontalMargin?: "0px" | "10px" | "20px" | "30px" | "40px" | "50px";
  equalWidth?: boolean;
  displayFlex?: boolean;
  breakpoints?: {
    xs?: boolean | GridSize | undefined;
    sm?: boolean | GridSize | undefined;
    md?: boolean | GridSize | undefined;
    lg?: boolean | GridSize | undefined;
    xl?: boolean | GridSize | undefined;
  };
  children?: any;
};

export const colWithNoMarginNorGutter: ColProps = {
  horizontalMargin: "0px",
  verticalMargin: "0px",
  horizontalGutter: "0px",
  verticalGutter: "0px",
};

const Col: FC<ColProps> = ({
  colHeight,
  borderLeftWidth,
  borderRightWidth,
  borderStyle,
  verticalAlign = "flex-start",
  horizontalAlign = "center",
  verticalAlignSelf = "flex-start",
  horizontalAlignSelf = "flex-start",
  verticalGutter = "5px",
  horizontalGutter = "5px",
  verticalMargin = "0px",
  horizontalMargin = "0px",
  equalWidth = true,
  displayFlex = true,
  breakpoints,
  children,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;
  return (
    <Grid
      className="col-grid-wrapper"
      item
      xs={breakpoints?.xs ?? 12}
      sm={breakpoints?.sm ?? 12}
      md={breakpoints?.md ?? true}
      lg={breakpoints?.lg ?? true}
      xl={breakpoints?.xl ?? true}
      sx={{
        width: equalWidth ? "100%" : "auto",
        display: displayFlex ? "flex" : "block",
        margin: `${verticalMargin} ${horizontalMargin}`,
        padding: `${verticalGutter} ${horizontalGutter}`,
        alignItems: verticalAlign,
        justifyContent: horizontalAlign,
        alignSelf: verticalAlignSelf,
        justifySelf: horizontalAlignSelf,
        flexGrow: equalWidth === true ? 1 : "unset!important",
        textAlign: "left",
        ...columnStyles(
          theme,
          colHeight,
          borderLeftWidth,
          borderRightWidth,
          borderStyle
        ),
      }}
      flexGrow={equalWidth === true ? 1 : "unset!important"}
    >
      {children}
    </Grid>
  );
};

export default Col;
