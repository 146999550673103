import {
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from "@mui/material";

const NavDrawerContent = ({ navItems, onDrawerOpen }) => {
  return (
    <Box onClick={onDrawerOpen}>
      <List>
        {navItems.map((item) => (
          <ListItem key={item?.displayName} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item?.displayName} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default NavDrawerContent;
