import { Box } from "@mui/material";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../GlobalAtoms";
import { Button } from "../TrueUI";
import style from "./NavBar.module.css";

const AgencyNavBarElements: FC = () => {
  const localOptions = useRecoilValue(globalOptions);
  return (
    <Box
      className={"top_nav_items_container"}
      sx={{
        display: { xs: "none", sm: "flex" },
        width: "100%",
        alignItems: "center",
      }}
    >
      <div className={style.with_letters}>With</div>
      <div className={style.agency_name}>
        {localOptions?.initOptions?.agencyName}
      </div>
      <Button id={"nav_button"} variantStyle="outlined">
        SUBMIT NEW BUSINESS
      </Button>
      <Button id={"nav_button"} variantStyle="outlined">
        GET AN INDICATION
      </Button>
    </Box>
  );
};
export default AgencyNavBarElements;
