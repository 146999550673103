import { FC, useEffect } from "react";
import { useApiGet } from "../../../../hooks";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { Col, Input, Row, Select } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import {
  CreditCardInfo,
  evaluateCardNumber,
  evaluateEmailReceipt,
  getAbbrMonthsSelectOptions,
  getCardYearsSelectOptions,
  getDefaultYear,
} from "./CreditCardPaymentUtils";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { abbreviatedMonths } from "../../../../utilities/staticDataFunctions";
import CvvInstructions from "./CvvInstructions";

type CreditCardPaymentFormProps = {
  insuredId?: number;
  errorDetails?: any;
  creditCardInfo: Partial<CreditCardInfo> | null;
  setCreditCardInfo: (creditCardInfo: Partial<CreditCardInfo> | null) => void;
};

const CreditCardPaymentForm: FC<CreditCardPaymentFormProps> = ({
  insuredId,
  errorDetails,
  creditCardInfo,
  setCreditCardInfo,
}) => {
  const { responseGet, dispatchGet } = useApiGet<string>(
    `api/Processor/GetBillingContactEmailByInsuredId?insuredId=${insuredId}`
  );

  const getEmailError = () =>
    conditionHasValue(errorDetails?.emailReceiptTo)
      ? errorDetails?.emailReceiptTo
      : creditCardInfo?.emailFormatError !== null
      ? [creditCardInfo?.emailFormatError ?? ""]
      : null;

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setCreditCardInfo({
        ...creditCardInfo,
        emailReceipt: responseGet.axiosResponse?.data ?? "",
        cardYear: creditCardInfo?.cardYear ?? getDefaultYear(),
      });
    }
  }, [responseGet]);

  useEffect(() => {
    dispatchGet();
  }, []);

  return (
    <Row {...rowWithNoMarginNorGutter} numberOfColumns={24} allowWrap={true}>
      <Col breakpoints={{ md: 24, lg: 10, xl: 12 }}>
        <Input
          id={"paymentCreditCardNumber"}
          name={"paymentCreditCardNumber"}
          label={"Enter Card Number"}
          type={"number"}
          maxLength={creditCardInfo?.cardNumberMaxLength}
          thousandSeparator={false}
          value={creditCardInfo?.cardNumber}
          onChangeRawValue={(cardNumber) =>
            evaluateCardNumber(cardNumber, setCreditCardInfo, creditCardInfo)
          }
          errorMessage={errorDetails?.cardNumber}
        />
      </Col>
      <Col breakpoints={{ md: 12, lg: 6, xl: 5 }}>
        <Select
          variant="filled"
          id={"paymentCreditCardMonth"}
          name={"paymentCreditCardMonth"}
          label={"Expiration Month"}
          firstOptionAsDefault={false}
          options={getAbbrMonthsSelectOptions(abbreviatedMonths)}
          value={creditCardInfo?.cardMonth ?? 1}
          onChange={(month) =>
            setCreditCardInfo({ ...creditCardInfo, cardMonth: month })
          }
          labelFontType={"BOLD_CAPTION"}
        />
      </Col>
      <Col breakpoints={{ md: 12, lg: 6, xl: 5 }}>
        <Select
          variant="filled"
          id={"paymentCreditCardYear"}
          name={"paymentCreditCardYear"}
          label={"Year"}
          firstOptionAsDefault={false}
          options={getCardYearsSelectOptions(7)}
          value={creditCardInfo?.cardYear}
          onChange={(year) =>
            setCreditCardInfo({ ...creditCardInfo, cardYear: year })
          }
          labelFontType={"BOLD_CAPTION"}
        />
      </Col>
      <Col breakpoints={{ md: 24, lg: 2, xl: 2 }}></Col>
      <Col breakpoints={{ md: 24, lg: 5, xl: 5 }}>
        <Input
          id={"paymentCreditCardBillingZipCode"}
          name={"paymentCreditCardBillingZipCode"}
          label={"Billing Zip Code"}
          maxLength={5}
          thousandSeparator={false}
          type={"number"}
          value={creditCardInfo?.cardBillingZipCode}
          onChangeRawValue={(cardBillingZipCode) =>
            setCreditCardInfo({
              ...creditCardInfo,
              cardBillingZipCode: cardBillingZipCode,
            })
          }
          errorMessage={errorDetails?.postcode}
        />
      </Col>
      <Col breakpoints={{ md: 24, lg: 5, xl: 5 }}>
        <Input
          id={"paymentCreditCardCvv"}
          name={"paymentCreditCardCvv"}
          label={"Security/CVV Code"}
          type={"number"}
          maxLength={creditCardInfo?.cardCvvMaxLength}
          thousandSeparator={false}
          value={creditCardInfo?.cardCvv}
          onChangeRawValue={(cardCvv) =>
            setCreditCardInfo({
              ...creditCardInfo,
              cardCvv: cardCvv,
            })
          }
          errorMessage={errorDetails?.cardCode}
        />
      </Col>
      <Col breakpoints={{ md: 24, lg: 14, xl: 14 }}>
        <CvvInstructions />
      </Col>
      <Col breakpoints={{ md: 24 }}>
        <Input
          id={"paymentEmailReceiptTo"}
          name={"paymentEmailReceiptTo"}
          label={"Send Email Receipt To"}
          thousandSeparator={false}
          type="email"
          value={creditCardInfo?.emailReceipt}
          onChangeRawValue={(emailReceipt) =>
            evaluateEmailReceipt(
              emailReceipt,
              setCreditCardInfo,
              creditCardInfo
            )
          }
          errorMessage={getEmailError()}
        />
      </Col>
    </Row>
  );
};
export default CreditCardPaymentForm;
