import { FC, useEffect, useState } from "react";
import { useApiGet } from "../../../hooks";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import {
  getObjectWithPropertiesFiltered,
  castObjectValuesToString,
} from "../../../utilities/objectFunctions";
import BaseTableGridForm from "../../TrueUI/Tables/ComponentPatterns/BaseTableGridForm";
import { getColumnIndexByColumnName } from "../../TrueUI/Tables/tableFunctions";
import PayrollReportDetailsFooter from "./PayrollReportDetailsFooter";
import { PayrollReportStatusEnum } from "../../../dtos/payroll-report-status-enum";
import { ColumnOptionsProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";

type PayrollReportChildTableProps = {
  tableData: any;
  payrollReportId?: string;
  payrollDetailsData?: any[];
  columnOptionsProps: ColumnOptionsProperties<any>[];
  setNewUpdatedData: (data: any) => void;
};

const PayrollReportChildTable: FC<PayrollReportChildTableProps> = ({
  tableData,
  payrollReportId,
  payrollDetailsData,
  columnOptionsProps,
  setNewUpdatedData,
}) => {
  const [rowData, setRowData] = useState<string[][]>();

  const { responseGet, dispatchGet } = useApiGet<any>(
    `api/PayrollReport/GetPayrollReportDetailChildData?payrollReportId=${payrollReportId}&nameId=${tableData.NameId}`
  );

  const isReportSubmitted = (e) =>
    parseInt(e?.PayrollStatus ?? 0) === PayrollReportStatusEnum.COMPLETE ||
    parseInt(e?.PayrollStatus ?? 0) === PayrollReportStatusEnum.COMPLETE_ZERO;

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      if (conditionHasValue(payrollDetailsData)) {
        const columnIndex = getColumnIndexByColumnName(
          "PayrollReportDetailId",
          responseGet?.responseData?.tableData?.columns ?? []
        );
        const rowData = responseGet?.responseData?.tableData?.data?.map(
          (row) => {
            const updatedData = payrollDetailsData?.find(
              (data) =>
                data.PayrollReportDetailId === parseInt(row[columnIndex])
            );
            if (conditionHasValue(updatedData)) {
              const withOutKey = getObjectWithPropertiesFiltered(
                updatedData,
                "baseTableRowKey",
                true
              );
              const withOutRowDeleted = getObjectWithPropertiesFiltered(
                withOutKey,
                "isBaseTableRowDeleted",
                true
              );

              const objectString = castObjectValuesToString(withOutRowDeleted);

              return Object.values(objectString);
            }
            return row;
          }
        );
        setRowData(rowData);
      } else {
        setRowData(responseGet?.responseData?.tableData?.data);
      }
    }
  }, [responseGet]);

  return (
    <>
      {conditionHasValue(rowData) && (
        <BaseTableGridForm
          columnOptions={[...columnOptionsProps]}
          obj={tableData}
          columnsAndData={{
            columns: responseGet?.responseData?.tableData?.columns ?? [],
            data: rowData ?? [],
          }}
          isEditMode={!isReportSubmitted(tableData)}
          isOptionsColumnHidden={true}
          parameters={[
            {
              paramaterKey: "payrollReportId",
              parameterPropertyValue: "PayrollReportId",
            },
            {
              paramaterKey: "nameId",
              parameterPropertyValue: "NameId",
            },
          ]}
          onDataChange={(data) => {
            setNewUpdatedData({
              payrollReportDetailsUpdated: data,
              isEERequired: tableData?.EERequired === "1" ? true : false,
            });
          }}
          customFooterComponent={(rows) => {
            return (
              <PayrollReportDetailsFooter
                rows={rows}
                columnOptionsProps={columnOptionsProps}
              />
            );
          }}
        />
      )}
    </>
  );
};
export default PayrollReportChildTable;
