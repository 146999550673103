import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Grow,
  List,
  ListItemButton,
  ListItemText,
  Menu,
  Popover,
  Typography,
} from "@mui/material";
import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { useApiGet } from "../../../hooks";

import { globalOptions } from "../../../GlobalAtoms";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../utilities/conditionalSupportFunctions";
import { TABBABLE_CLASS_NAME } from "../../../utilities/tabFunctions";
import Font from "../Typography/Font";
import {
  SearchIconWrapper,
  SearchStyle,
  StyledInputBaseFullWidth,
  menuStyles,
  searchStyleInputForm,
} from "./SearchStyles";

type SearchProperties = {
  saveSelectedResult: (result: any) => void;
  searchTextKey: string;
  searchCodeKey?: string;
  searchStatusKey?: string;
  labelText?: string;
  placeholderText?: string;
  url: string;
  errorMessage?: string[] | null;
  labelFontType?: KeysAsType<FontsType>;
  initialValue?: string;
  focus?: boolean;
  tabIndex?: number;
  name?: string;
  additionalParams?: string;
  menuPadding?: string;
  floatList?: boolean;
  CustomComponent?: (listItems: any[]) => JSX.Element;
  setValidSelection?: (isValid: boolean) => void;
};

const SearchTextNoPredictive: FC<SearchProperties> = ({
  saveSelectedResult,
  searchTextKey,
  searchCodeKey,
  searchStatusKey,
  labelText,
  placeholderText,
  url,
  errorMessage,
  labelFontType = "BOLD_BODY",
  initialValue,
  focus = true,
  tabIndex = 0,
  name,
  additionalParams = "",
  menuPadding = "0",
  CustomComponent,
  setValidSelection,
  floatList,
}) => {
  const [searchTextState, setSearchTextState] = useState<any>({
    searchText: initialValue ?? "",
    resultSelected: false,
    isSearching: false,
  });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [dispatchSearch, setDispatchSearch] = useState<boolean>(false);
  const [listItems, setListItems] = useState<any>();
  const [noSearchResults, setNoSearchResults] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;

  const handleSearchTextChange = (e) => {
    setValidSelection?.(false);
    setSearchTextState({
      ...searchTextState,
      searchText: e.target.value,
      resultSelected: false,
      isSearching: true,
    });
    setAnchorEl(e.currentTarget);
    if (e.target.value.trim() === "") {
      saveSelectedResult(null);
      setValidSelection?.(true);
    }
  };

  useEffect(() => {
    if (initialValue !== undefined && initialValue !== "") {
      setSearchTextState({
        ...searchTextState,
        searchText: initialValue,
        resultSelected: false,
        isSearching: false,
      });
    }
  }, [initialValue]);

  const { responseGet, dispatchGet } = useApiGet<any>(
    `${url}${searchTextState.searchText}${additionalParams}`,
    {
      disablePropertyUpdaters: true,
    }
  );

  useEffect(() => {
    if (dispatchSearch) {
      setDispatchSearch(false);
      if (
        searchTextState.searchText.trim() !== "" &&
        searchTextState.resultSelected === false &&
        searchTextState.isSearching === true
      ) {
        dispatchGet();
      }
    }
  }, [dispatchSearch]);

  useEffect(() => {
    if (responseGet !== null && responseGet?.responseData !== null) {
      setListItems(responseGet?.responseData);
      if (responseGet?.responseData?.length > 0) {
        setIsOpen(true);
        setNoSearchResults(false);
      } else {
        setIsOpen(false);
        setNoSearchResults(true);
      }
    }
  }, [responseGet]);

  const resultSelected = (result: any) => {
    setIsOpen(false);
    const searchText = getFormattedValue(result);
    setSearchTextState({
      ...searchTextState,
      searchText,
      resultSelected: true,
      isSearching: false,
    });
    saveSelectedResult(result ?? null);
    setValidSelection?.(true);
  };

  const onKeyDown = (e) => {
    if (e.key === "Enter") {
      setDispatchSearch(true);
    }
  };

  const inputBaseProps = {
    placeholder: placeholderText ?? "Search…",
    inputProps: {
      "aria-label": "search",
      "true-element": `true-search-input-${name}`,
      tabIndex,
      className: TABBABLE_CLASS_NAME,
    },
    onChange: handleSearchTextChange,
    autoFocus: focus,
    value: searchTextState?.searchText ?? "",
    sx: { fontSize: 16, lineHeight: 1.5, height: 27 },
    onKeyDown: onKeyDown,
  };

  const getFormattedValue = (result: any) => {
    const statusValue = !isEmptyValue(searchStatusKey)
      ? `- ${result?.[searchStatusKey ?? ""]}`
      : "";
    if (!isEmptyValue(searchCodeKey)) {
      return `${result?.[searchCodeKey!]} - ${
        result?.[searchTextKey]
      } ${statusValue}`;
    }
    return result?.[searchTextKey];
  };

  const InternalComponent = () => (
    <Box className={"true_container_list"}>
      {listItems?.length >= 50 && (
        <Typography>Please refine your search criteria</Typography>
      )}
      {listItems?.length > 0 &&
        (conditionHasValue(CustomComponent) ? (
          CustomComponent?.(listItems)
        ) : (
          <List dense>
            {listItems.map((result, i) => {
              return (
                <ListItemButton
                  key={i}
                  onClick={() => {
                    resultSelected(result);
                  }}
                >
                  <ListItemText
                    className={"true_first_row_typography"}
                    primary={getFormattedValue(result)}
                  />
                </ListItemButton>
              );
            })}
          </List>
        ))}
    </Box>
  );
  return (
    <>
      {labelText && <Font fontType={labelFontType}>{labelText}</Font>}
      <SearchStyle
        sx={{
          ...searchStyleInputForm(theme, "calc(100% - 70px)"),
          margin: "0 !important",
          height: 27,
          "& span.true_input_error_txt": {
            color: theme?.danger,
            fontSize: "0.75rem",
            letterSpacing: "0.03333em",
            textAlign: "left",
            marginTop: "4px",
            marginRight: 0,
            marginBottom: 0,
            marginLeft: 0,
            display: "block",
          },
          "&.with-errors": {
            borderColor: theme?.danger,
          },
          "& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall": { opacity: 0.4 },
        }}
        className={`true_search_text_input ${
          errorMessage != null ? "with-errors" : ""
        }`}
      >
        <StyledInputBaseFullWidth {...inputBaseProps} />
        <SearchIconWrapper
          onClick={() => {
            setDispatchSearch(true);
            console.log("Closeed");
          }}
          sx={{ cursor: "pointer", pointerEvents: "auto" }}
        >
          <SearchIcon htmlColor={theme?.primary} fontSize="small" />
        </SearchIconWrapper>
        {errorMessage != null && (
          <span className="true_input_error_txt">
            {errorMessage?.join(" ")}
          </span>
        )}
      </SearchStyle>
      {listItems &&
        (floatList ? (
          <Menu
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            id={"primary-notifications-menu"}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            elevation={3}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            sx={menuStyles(theme, menuPadding)}
            disableAutoFocus
            TransitionComponent={Grow as any}
          >
            {InternalComponent()}
          </Menu>
        ) : (
          <Box>{InternalComponent()}</Box>
        ))}

      {listItems?.length === 0 &&
        (floatList ? (
          <Popover
            id={"true_not_found_results_message"}
            anchorEl={anchorEl}
            open={noSearchResults}
            onClose={() => setNoSearchResults(false)}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
            sx={{
              mt: 6,
            }}
            elevation={3}
            keepMounted
            disableAutoFocus
            TransitionComponent={Grow as any}
          >
            <Typography sx={{ p: 2 }}>
              Sorry... We couldn't find any matches for{" "}
              <b>{searchTextState?.searchText ?? ""}</b>
            </Typography>
          </Popover>
        ) : (
          <Box>
            <Typography sx={{ p: 2 }}>
              Sorry... We couldn't find any matches for{" "}
              <b>{searchTextState?.searchText ?? ""}</b>
            </Typography>
          </Box>
        ))}
    </>
  );
};

export default SearchTextNoPredictive;
