import { FC, useState } from "react";
import DialogConfirmation from "../Dialogs/DialogConfirmation";
import Button from "./Button";

type DeleteButtonProps = {
  textOnButton?: string;
  textOnConfirmation?: string;
  withConfirmation?: boolean;
  variantStyle?: "contained" | "outlined" | "text";
  name?: string;
  onClick?: () => void; // For yes too.
  onNoClick?: () => void;
};

const DeleteButton: FC<DeleteButtonProps> = ({
  textOnButton = "Delete",
  textOnConfirmation = "Are you sure you want to delete this item?",
  withConfirmation = false,
  variantStyle = "outlined",
  name = "",
  onClick,
  onNoClick,
}) => {
  const [isDialogConfirmationOpen, setIsDialogConfirmationOpen] =
    useState<boolean>(false);

  const _onClick = () => {
    if (withConfirmation) {
      setIsDialogConfirmationOpen(true);
    } else {
      onClick?.();
    }
  };
  return (
    <>
      <Button variantStyle={variantStyle} onClick={_onClick} name={name}>
        {textOnButton}
      </Button>

      <DialogConfirmation
        id="cancel-button-confirmation"
        open={isDialogConfirmationOpen}
        dialogDescriptionText={textOnConfirmation}
        onCloseEvent={(close) => {
          setIsDialogConfirmationOpen(close);
        }}
        onOptionNoEvent={(close) => {
          onNoClick?.();
          setIsDialogConfirmationOpen(close);
        }}
        onOptionYesEvent={(close) => {
          onClick?.();
          setIsDialogConfirmationOpen(close);
        }}
      />
    </>
  );
};

export default DeleteButton;
