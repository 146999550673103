import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { FC, ReactNode } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import Button from "../Buttons/Button";
import { dialogStyle, paperStyles } from "./DialogConfirmationStyles";

export type DialogConfirmationProps = {
  id?: string;
  open?: boolean;
  dialogTitle?: string;
  name?: string;
  dialogDescriptionText?: string;
  optionYesOverrideLabel?: string;
  optionNoOverrideLabel?: string;
  InteractiveComponent?: () => any;
  onCloseEvent?: (close: boolean) => any;
  onOptionNoEvent?: (close: boolean) => any;
  onOptionYesEvent?: (close: boolean) => any;
  buttonRibbonNode?: ReactNode;
};

const DialogConfirmation: FC<DialogConfirmationProps> = ({
  id = "",
  name = "",
  open = false,
  dialogTitle,
  dialogDescriptionText = "",
  InteractiveComponent,
  onCloseEvent,
  onOptionNoEvent,
  onOptionYesEvent,
  buttonRibbonNode,
  optionYesOverrideLabel = "YES",
  optionNoOverrideLabel = "NO",
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;

  return (
    <Dialog
      id={id}
      open={open}
      fullWidth={true}
      sx={dialogStyle()}
      onClose={() => {
        onCloseEvent?.(false);
      }}
      PaperProps={{
        sx: { ...paperStyles(theme) },
      }}
      aria-labelledby="dialogConfirmationTitle"
      aria-describedby="dialogConfirmationDescription"
    >
      {dialogTitle !== undefined && (
        <DialogTitle id="dialogConfirmationTitle">{dialogTitle}</DialogTitle>
      )}
      <DialogContent id="dialogConfirmationDescription">
        <DialogContentText>{dialogDescriptionText}</DialogContentText>
        {InteractiveComponent && (
          <Box className={"dialog-content"}>{InteractiveComponent()}</Box>
        )}
      </DialogContent>
      <DialogActions>
        {onOptionYesEvent !== undefined && (
          <Button
            name={`${name}-yes`}
            onClick={() => {
              onOptionYesEvent?.(false);
            }}
            autoFocus
          >
            {optionYesOverrideLabel}
          </Button>
        )}
        {onOptionNoEvent !== undefined && (
          <Button
            name={`${name}-no`}
            variantStyle={"outlined"}
            onClick={() => {
              onOptionNoEvent?.(false);
            }}
          >
            {optionNoOverrideLabel}
          </Button>
        )}

        {buttonRibbonNode !== undefined && (
          <Box className={"ribbon-node-container"}>
            <Box className={"ribbon-node"}>{buttonRibbonNode}</Box>
          </Box>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirmation;
