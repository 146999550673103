import { forwardRef, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";

interface CustomProps {
  onChange?: (e?: any) => void;
  onBlur?: (e?: any) => void;
  type?: string;
  prefix?: string;
  decimals?: number;
  negatives?: boolean;
  maxValue?: number;
  minValue?: number;
  value?: string;
  thousandSeparator?: boolean;
  tabIndex?: number;
  isAutoComplete?: boolean;
  displayNumericArrows?: boolean;
}

const isInvalidNumberMinValue = (floatValue?: number, minValue?: number) =>
  minValue !== undefined && floatValue !== undefined && floatValue < minValue;

const isInvalidNumberMaxValue = (floatValue?: number, maxValue?: number) =>
  maxValue !== undefined && floatValue !== undefined && floatValue > maxValue;

const NumberFormatCustom = forwardRef<any, CustomProps>(
  function NumberFormatCustom(props, ref) {
    const {
      onChange,
      prefix,
      decimals,
      type,
      negatives,
      maxValue,
      minValue,
      value: propValue,
      thousandSeparator,
      tabIndex = 0,
      isAutoComplete = true,
      displayNumericArrows = false,
      ...other
    } = props;

    const [value, setValue] = useState(propValue?.toString());
    const [startsWithDot, setStartsWithDot] = useState<boolean>(false);

    const internalOnChange = ({ value, floatValue }) => {
      onChange?.({
        target: {
          value: floatValue,
        },
      });
      setValue(value);

      if ((value as string).at(0) === ".") setStartsWithDot(true);
      else setStartsWithDot(false);
    };

    const internalOnBlur = () => {
      if (propValue !== undefined) {
        if (isInvalidNumberMaxValue(parseFloat(propValue), maxValue)) {
          onChange?.({
            target: {
              value: maxValue,
            },
          });
        }
        if (isInvalidNumberMinValue(parseFloat(propValue), minValue)) {
          onChange?.({
            target: {
              value: minValue,
            },
          });
        }
      }
      props.onBlur?.();
    };

    useEffect(() => {
      if (parseFloat(value ?? "0") !== parseFloat(propValue ?? "0"))
        setValue(propValue?.toString());
    }, [propValue]);

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        value={value}
        thousandSeparator={!startsWithDot && (thousandSeparator ?? true)}
        valueIsNumericString={!startsWithDot}
        decimalScale={decimals ?? 2}
        prefix={prefix ?? "$"}
        allowNegative={negatives ?? false}
        fixedDecimalScale={type === "fixedCurrency"}
        tabIndex={tabIndex}
        onValueChange={internalOnChange}
        onBlur={internalOnBlur}
        autoComplete={isAutoComplete ? "on" : "off"}
        type={displayNumericArrows ? (type as any) : null}
      />
    );
  }
);
export default NumberFormatCustom;
