import { Box } from "@mui/material";
import { TableData, TableRow } from "../../TrueUI/Tables/BaseTableStructure";
import { Input } from "../../TrueUI";
import { ClaimFinancialTotalsDto } from "../../../dtos/claim-financial-totals-dto";

export const ClaimFinancialTotalsCustomRow = (
  claimFinancialTotals?: ClaimFinancialTotalsDto
) => {
  return (
    <>
      <TableRow
        style={{
          width: "100%",
          display: "flex",
          fontWeight: "700",
          height: "32px",
        }}
      >
        <TableData key={"total-label"}>
          <Box sx={{ width: "100%" }}>
            <Input
              type={"text"}
              id={"total"}
              name={"total"}
              value={"Total"}
              inputFontType={"BOLD_BODY"}
              align="left"
              readOnly
            />
          </Box>
        </TableData>
        <TableData key={"total-reserves"}>
          <Box sx={{ width: "100%" }}>
            <Input
              id={"reserves"}
              name={"reserves"}
              value={claimFinancialTotals?.reserves}
              inputFontType={"BOLD_BODY"}
              align="right"
              prefix=""
              readOnly
            />
          </Box>
        </TableData>
        <TableData key={"total-paid"}>
          <Box sx={{ width: "100%" }}>
            <Input
              id={"paid"}
              name={"paid"}
              value={claimFinancialTotals?.paid}
              inputFontType={"BOLD_BODY"}
              align="right"
              prefix=""
              readOnly
            />
          </Box>
        </TableData>
        <TableData key={"total-incurred"}>
          <Box sx={{ width: "100%" }}>
            <Input
              id={"incurred"}
              name={"incurred"}
              value={claimFinancialTotals?.netIncurred}
              inputFontType={"BOLD_BODY"}
              align="right"
              prefix=""
              readOnly
            />
          </Box>
        </TableData>
      </TableRow>
    </>
  );
};
