import { Typography } from "@mui/material";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import { PercentageType } from "../../../types/RangeNumberType";
import { FontSx } from "./FontStyles";
import { fromUppercaseSnakeCaseToCamelCase } from "../../../utilities/stringFunctions";

export type FontProperties = {
  children: string | React.ReactNode;
  display?: "inline" | "block" | "inline-block" | "flex" | "none";
  whiteSpace?:
    | "normal"
    | "pre"
    | "nowrap"
    | "pre-wrap"
    | "pre-line"
    | "break-spaces";
  textAlign?: "start" | "center" | "end";
  maxWidth?: string;
  truncate?: boolean;
  name?: string;
  className?: string;
  fontType?: KeysAsType<FontsType>;
  primaryColor?: boolean;
  opacity?: PercentageType;
  secondaryFont?: boolean;
  isErrorFont?: boolean;
  trueElement?: string;
};

const Font: FC<FontProperties> = ({
  fontType = "BODY",
  display = "inline",
  whiteSpace = "nowrap",
  textAlign = "center",
  name = "",
  className = "",
  maxWidth,
  truncate,
  children,
  primaryColor = false,
  opacity = 100,
  secondaryFont = false,
  trueElement = "",
  isErrorFont = false,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;

  const getTrueElement = () => {
    return trueElement !== ""
      ? trueElement
      : name
      ? "true-element-label-" + name
      : "";
  };

  const newType = fromUppercaseSnakeCaseToCamelCase(fontType);
  return (
    <Typography
      className={className}
      component={"span"}
      true-element={getTrueElement()}
      sx={FontSx(
        theme,
        newType,
        primaryColor,
        opacity,
        whiteSpace,
        display,
        textAlign,
        maxWidth,
        truncate,
        secondaryFont,
        isErrorFont
      )}
    >
      {children}
    </Typography>
  );
};

export default Font;
