import { useRecoilValue } from "recoil";
import { FontBold } from "./components/TrueUI";
import { globalOptions } from "./GlobalAtoms";
import { getCurrentYear } from "./utilities/dateFunctions";

const Footer = () => {
  const localOptions = useRecoilValue(globalOptions);
  const clientName = localOptions?.initOptions?.programInfo?.programName;
  const year = getCurrentYear();
  return (
    <div style={{ marginTop: "10px" }}>
      <FontBold display="block" whiteSpace="normal">
        Copyright {year} © - {clientName} - All Rights Reserved
      </FontBold>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img
          style={{ marginRight: "5px" }}
          src={"../media/true/true-brandmark.svg"}
        ></img>
        <FontBold display="block" whiteSpace="normal">
          Powered by True Insurtech Solutions, LLC
        </FontBold>
      </div>
    </div>
  );
};

export default Footer;
