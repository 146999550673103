import { FC, useEffect, useState } from "react";
import {
  ButtonGroup,
  CircularProgress,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  SxProps,
  Theme,
} from "@mui/material";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { usePermissions } from "../../../hooks";
import { useRecoilValue } from "recoil";
import {
  globalIsDisabledElementAtom,
  globalIsSaving,
  globalOptions,
} from "../../../GlobalAtoms";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Button from "./Button";
import { castStringToTags } from "../../../utilities/stringFunctions";
import { TABBABLE_CLASS_NAME } from "../../../utilities/tabFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { PermissionsEnums } from "../../../dtos/permissions-enums";

export type ItemProperties = {
  option: string;
  dropdownText?: string;
  action: () => void;
};

type ButtonProperties = {
  items: ItemProperties[];
  disabled?: boolean;
  disabledItems?: number[];
  placement?: "top" | "bottom";
  sx?: SxProps<Theme>;
  triggerEventOnSelect?: boolean;
  overrideLabel?: string;
  showLoading?: boolean;
  tabIndex?: number;
  withDropdownTextLabel?: boolean;
  currentSelectionIndex?: number;
  optionsMinWidth?: string;
  permissions?: PermissionsEnums[];
};

const getFirstNoDisabledItem = (
  items: { option: string; dropdownText?: string }[],
  disabledItems: number[],
  selectedIndex?: number
) => {
  if (selectedIndex !== undefined) return selectedIndex;
  const enabledItems = items.filter(
    (_: any, idx: number) => !disabledItems.includes(idx)
  );
  const firstEnabledIndex = items.findIndex(
    (item: { option: string }) => item.option === enabledItems[0].option
  );
  return firstEnabledIndex;
};

const SplitButton: FC<ButtonProperties> = ({
  items,
  disabled = false,
  disabledItems = [],
  placement = "bottom",
  sx = {},
  triggerEventOnSelect = true,
  overrideLabel,
  showLoading,
  tabIndex = 0,
  withDropdownTextLabel = false,
  currentSelectionIndex,
  optionsMinWidth,
  permissions,
}) => {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(
    getFirstNoDisabledItem(items, disabledItems, currentSelectionIndex)
  );
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;
  const hasPermission =
    usePermissions(permissions ?? []).hasPermission ?? false;
  const isElementDisabled = useRecoilValue(globalIsDisabledElementAtom);

  const isSaving = useRecoilValue(globalIsSaving);

  const getDisabledState = () => {
    if (hasPermission === false) return hasPermission;
    if (isElementDisabled !== false) return !isElementDisabled;
    return disabled ?? false;
  };

  useEffect(() => {
    if (conditionHasValue(currentSelectionIndex)) {
      setSelectedIndex(currentSelectionIndex ?? 0);
    }
  }, [currentSelectionIndex]);

  const handleMenuItemClick = (index: number) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleClick = (index: number) => items[index].action();

  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const colorIcon = `${theme.primaryBackground} !important`;

  const labelKey = withDropdownTextLabel ? "dropdownText" : "option";
  const label = items?.[selectedIndex]?.[labelKey] ?? "";

  const ArrowDropIcon = () =>
    open ? (
      placement === "bottom" ? (
        <ArrowDropUpIcon sx={{ color: colorIcon }} />
      ) : (
        <ArrowDropDownIcon sx={{ color: colorIcon }} />
      )
    ) : placement === "bottom" ? (
      <ArrowDropDownIcon sx={{ color: colorIcon }} />
    ) : (
      <ArrowDropUpIcon sx={{ color: colorIcon }} />
    );

  const popperConfiguration: any = {
    transition: true,
    open: open,
    sx: { zIndex: 10000, minWidth: optionsMinWidth },
    role: undefined,
    placement: placement,
  };

  return (
    <PopupState variant="popper">
      {(popupState) => (
        <>
          <ButtonGroup
            {...bindToggle(popupState)}
            disabled={getDisabledState()}
            variant="contained"
            sx={sx}
          >
            <Button
              tabIndex={tabIndex}
              className={TABBABLE_CLASS_NAME}
              onClick={() => {
                handleClick(selectedIndex);
              }}
              name={`execute-with-action`}
            >
              {showLoading ? (
                isSaving ? (
                  <CircularProgress size={24} color="secondary" />
                ) : (
                  overrideLabel ?? label
                )
              ) : (
                overrideLabel ?? label
              )}
            </Button>
            <Button
              className={TABBABLE_CLASS_NAME}
              onClick={handleOpen}
              tabIndex={tabIndex}
              name={"open-other-actions"}
            >
              <ArrowDropIcon />
            </Button>
          </ButtonGroup>
          <Popper {...bindPopper(popupState)} {...popperConfiguration}>
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleClose}>
                    <MenuList>
                      {items.map((item, index) => (
                        <MenuItem
                          key={item.option}
                          disabled={disabledItems.includes(index)}
                          selected={index === selectedIndex}
                          onClick={() => {
                            handleMenuItemClick(index);
                            if (triggerEventOnSelect) handleClick(index);
                          }}
                          true-element={`element-${castStringToTags(
                            item.dropdownText ?? item.option
                          )}`}
                        >
                          {item.dropdownText ?? item.option}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </>
      )}
    </PopupState>
  );
};

export default SplitButton;
