import { Theme } from "../../../media/themeTypes";
import { DividerProps } from "./Divider";

export const dividerStyles = (theme: Theme, dividerProps: DividerProps) => ({
  height: dividerProps.height,
  width: dividerProps.width,
  border: dividerProps.showBorder
    ? `${dividerProps.borderSize} solid ${theme.borderColor}`
    : "none",
});
