import { IconButton } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import { FC } from "react";
import { BaseTableMetaData } from "../../../../dtos/base-table-meta-data";

type MultiTitleHeaderActions = {
  addRow?: ((e?: any) => void) | null;
  addRowOverride?: (e?: any) => void;
  editRow?: ((e?: any) => void) | null;
  editRowOverride?: (e?: any) => void;
};

type MultiTitleHeaderProperties = {
  headerParams?: BaseTableMetaData[];
  actions?: MultiTitleHeaderActions;
  // needs the ability to filter out title icon/actions
};

const MultiHeader: FC<MultiTitleHeaderProperties> = ({
  headerParams,
  actions,
}) => {
  const params = headerParams ?? [];

  return (
    <>
      <div
        style={{
          display: "flex",
          flexGrow: 1,
          alignItems: "center",
          fontWeight: "bold",
        }}
      >
        {params.length > 0 && (
          <>
            <div style={{ marginRight: "50px" }}>{params[0]?.value ?? ""}</div>
            <div style={{ marginRight: "50px" }}>{params[1]?.value ?? ""}</div>
            <div style={{ marginRight: "50px" }}>{params[2]?.value ?? ""}</div>
            <div style={{ marginRight: "50px" }}>{params[3]?.value ?? ""}</div>
            <div style={{ marginRight: "50px" }}>{params[4]?.value ?? ""}</div>
          </>
        )}
      </div>
      <div
        style={{ display: "flex", marginRight: "15px" }}
        className={"multi_row_action_container"}
      >
        <div>
          {actions?.addRow && (
            <IconButton
              className="plus-minus-icon-container"
              onClick={(e) => {
                e.stopPropagation();
                actions?.addRowOverride === undefined
                  ? actions?.addRow?.()
                  : actions?.addRowOverride();
              }}
            >
              <AddOutlined className="plus-action-button" />
            </IconButton>
          )}
        </div>
        <div>
          {actions?.editRow && (
            <IconButton
              aria-label="edit"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                actions?.editRowOverride === undefined
                  ? actions?.editRow?.()
                  : actions?.editRowOverride();
              }}
            >
              <EditIcon fontSize="small" />
            </IconButton>
          )}
        </div>
      </div>
    </>
  );
};
export default MultiHeader;
