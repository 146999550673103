import { FC, useState } from "react";
import { InputDateTime } from "../TrueUI";

const Emma: FC = () => {
  const [value, setValue] = useState();
  return (
    <>
      <InputDateTime
        id={`id-`}
        name={`Name}`}
        key={`input-1222`}
        label={"label"}
        variant={"filled"}
        value={value}
        onChangeRawValue={(value) => {
          console.log({ value });
          setValue(value);
        }}
      />
    </>
  );
};
export default Emma;
