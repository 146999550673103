import { useEffect, useState } from "react";
import { Select } from "../..";
import { SelectOptions } from "../../../../dtos/select-options";
import { StatusEnums } from "../../../../dtos/status-enums";
import { FromEnumToArrayWithExceptions } from "../../../../utilities/enumFunctions";
import { BaseTableFilterOptions } from "../BaseTable2/TableProperties";
import { DROPDOWN_FILTER } from "../TableConstants";

type ToggleColumnsByOptionsProps = {
  options: string[];
  columnsToToggle: string[];
};

type BaseTableDropdownFilterProps = {
  id: string;
  filterOptions: BaseTableFilterOptions;
  columnsNames?: string[];
  optionsOverride?: Partial<SelectOptions>[] | SelectOptions[];
  defaultValue?: string | number;
  labelText?: string;
  labelPosition?: "start" | "end" | "top" | "bottom";
  toggleColumnsByOptions?: ToggleColumnsByOptionsProps[];
  usingExternalFiltering?: boolean;
  isStaticFilter?: boolean;
  validatingAgainstDisplayValue?: boolean;
  selectWidth?: string;
  onChange?: (e: any) => void;
};

const BaseTableDropdownFilter: (
  properties: BaseTableDropdownFilterProps
) => any = ({
  id,
  filterOptions,
  columnsNames,
  optionsOverride,
  defaultValue,
  labelText,
  labelPosition,
  toggleColumnsByOptions,
  usingExternalFiltering,
  isStaticFilter = false,
  validatingAgainstDisplayValue,
  selectWidth,
  onChange,
}) => {
  const [hiddenColumns, setHiddenColumns] = useState<string[]>([]);

  if (isStaticFilter) {
    filterOptions.isStaticFilter?.(true);
  }

  const statusTypes = [{ displayName: "All", stringValue: "all" }].concat(
    FromEnumToArrayWithExceptions(StatusEnums, [StatusEnums.DELETED]).map(
      (enumType) => ({
        displayName: enumType?.key ?? "",
        stringValue: validatingAgainstDisplayValue
          ? enumType?.key ?? ""
          : enumType?.value.toString(),
      })
    )
  );

  const setStatus = (val: string) => {
    if (toggleColumnsByOptions) {
      const columnsToToggleFound = toggleColumnsByOptions?.find(
        (toggleOptionsAndColumns) => {
          return toggleOptionsAndColumns.options.includes(val)
            ? toggleOptionsAndColumns
            : null;
        }
      )?.columnsToToggle;

      const columnsNotHiddenToToggle = columnsToToggleFound?.filter(
        (column) => !hiddenColumns.includes(column)
      );

      const columnsAlreadyHiddenToToggle = hiddenColumns?.filter(
        (column) => !columnsToToggleFound?.includes(column)
      );

      const columnsToToggleResult = columnsNotHiddenToToggle?.concat(
        columnsAlreadyHiddenToToggle
      );

      const newHiddenColumns = hiddenColumns
        ?.filter((column) => columnsToToggleFound?.includes(column))
        .concat(columnsNotHiddenToToggle ?? []);

      if (
        columnsNotHiddenToToggle !== undefined &&
        columnsAlreadyHiddenToToggle !== undefined &&
        columnsToToggleResult !== undefined &&
        columnsToToggleResult.length > 0
      ) {
        filterOptions?.toggleColumnFilters?.(columnsToToggleResult);
        setHiddenColumns(newHiddenColumns);
      }

      if (val === "all" && hiddenColumns.length > 0) {
        filterOptions?.toggleColumnFilters?.(hiddenColumns);
        setHiddenColumns([]);
      }
    }

    filterOptions?.setFilterInclude?.([
      {
        filterName: DROPDOWN_FILTER,
        columnNames: columnsNames,
        filterValue: val,
      }, // TODO - fix this, it's bad and broken
    ]);
  };

  useEffect(() => {
    if (!usingExternalFiltering) setStatus(defaultValue?.toString() ?? "all");
  }, []);

  return (
    <Select
      id={`true-filter-for-table-${id}`}
      name={`filter-dropdown-${id}`}
      variant={"filled"}
      // This to show maximum 15 options without scroll
      optionsMaxHeight={"440px"}
      label={labelText ?? "Show:"}
      labelPosition={labelPosition ?? "start"}
      options={optionsOverride ?? statusTypes}
      value={defaultValue ?? "all"}
      type={"tableFilter"}
      inputWidth={selectWidth ? selectWidth : "fit-content"}
      isCustomArrow
      labelFontType="TITLE"
      onChange={(value) => {
        if (!usingExternalFiltering) setStatus(value);
        onChange?.(value);
      }}
    />
  );
};

export default BaseTableDropdownFilter;
