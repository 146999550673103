import { FC, FormEvent, useEffect } from "react";
import {
  Col,
  Input,
  InputDate,
  InputMemo,
  InputPhone,
  Row,
  Select,
  Switch,
} from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../utilities/inputPropsConfigurationFunctions";
import { CertificateDto } from "../../../../dtos/certificate-dto";
import { CertificateStatusEnums } from "../../../../dtos/certificate-status-enums";
import { useApiGet } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import {
  certStateOptions,
  certStatusOptions,
  getInsuredCopyOnlyData,
} from "./IssueCertificateUtils";
import { globalOptions } from "../../../../GlobalAtoms";
import { useRecoilValue } from "recoil";
import { IssueCertificateModalContentProps } from "./CertificateTypes";

const IssueCertificateModalContent: FC<IssueCertificateModalContentProps> = ({
  configuration,
  errorDetails,
  formData,
  setFormData,
  editCertificateId,
  modalMode,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const portalAutoApproveCert =
    localOptions?.initOptions?.programInfo?.portalAutoApproveCert ?? false;
  const certificateId =
    formData?.certificateId && formData?.certificateId > 0
      ? formData?.certificateId
      : "";

  const readOnly =
    modalMode !== "ADD" &&
    formData?.certificateStatus === CertificateStatusEnums.ISSUED;

  const { responseGet, dispatchGet } = useApiGet<CertificateDto>(
    `api/Certificate/GetCertificateById?certificateId=${editCertificateId}`
  );

  const updateForm = (e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget) {
      setFormData({
        ...formData,
        [e.currentTarget.name]: e.currentTarget.value,
      });
    }
  };

  const updateInsuredCopyOnly = (insuredCopyCheckValue: boolean) => {
    setFormData({
      ...formData,
      ...getInsuredCopyOnlyData(
        formData,
        modalMode,
        insuredCopyCheckValue,
        portalAutoApproveCert,
        configuration.certificateHolder
      ),
    });
  };

  useEffect(() => {
    if (
      editCertificateId &&
      editCertificateId > 0 &&
      modalMode === "READONLY"
    ) {
      dispatchGet();
    }
  }, [editCertificateId, modalMode]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet) && responseGet?.responseData) {
      setFormData(responseGet?.responseData);
    }
  }, [responseGet]);


  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col
          breakpoints={{ md: 8, lg: 8, xl: 8 }}
          horizontalAlign={"flex-start"}
        >
          <Switch
            tabIndex={1}
            control={"checkbox"}
            isChecked={formData?.insuredCopyOnly}
            label={"Issue for Insured Copy only?"}
            labelPlacement={"end"}
            onChangeRawValue={updateInsuredCopyOnly}
            labelFontType={"BODY"}
            name={"insuredCopyOnly"}
            readOnly={readOnly}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            tabIndex={2}
            id="id-issue-date"
            name={"issueDate"}
            label="Issue Date"
            value={formData?.issueDate}
            onChangeRawValue={(value) => {
              setFormData({ ...formData, issueDate: value });
            }}
            errorMessage={errorDetails?.issueDate}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Select
            {...selectConfiguration(
              "id-issue-name",
              "issueName",
              "Issued Name (insured or location)"
            )}
            tabIndex={3}
            labelFontType="BOLD_CAPTION"
            firstOptionAsDefault
            options={configuration.issueNameOptions ?? []}
            optionsMaxHeight={"400px"}
            value={formData?.issueName ?? -1}
            onChange={(value) => {
              setFormData({ ...formData, issueName: value });
            }}
            errorMessage={errorDetails?.issueName}
            readOnly={readOnly}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Select
            {...selectConfiguration("id-policy", "policyId", "Policy")}
            tabIndex={4}
            labelFontType="BOLD_CAPTION"
            options={configuration.policyOptions ?? []}
            firstOptionAsDefault={false}
            optionsMaxHeight={"400px"}
            value={formData?.policyId ?? ""}
            onChange={(value) => {
              setFormData({ ...formData, policyId: value });
            }}
            errorMessage={errorDetails?.policyId}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Input
            {...inputConfiguration(
              "id-certificate-holder",
              "certHolder",
              "Certificate Holder",
              300
            )}
            tabIndex={5}
            readOnly={formData?.insuredCopyOnly || readOnly}
            value={formData?.certHolder ?? ""}
            onChange={updateForm}
            errorMessage={errorDetails?.certHolder}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Select
            {...selectConfiguration(
              "id-certificate-status",
              "certificateStatus",
              "Status"
            )}
            labelFontType="BOLD_CAPTION"
            firstOptionAsDefault={false}
            options={certStatusOptions}
            optionsMaxHeight={"400px"}
            value={formData?.certificateStatus ?? -1}
            errorValues={[CertificateStatusEnums.PENDING]}
            readOnly
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Input
            {...inputConfiguration("id-dba", "certDba", "DBA", 200)}
            tabIndex={6}
            readOnly={formData?.insuredCopyOnly || readOnly}
            value={formData?.certDba ?? ""}
            onChange={updateForm}
            errorMessage={errorDetails?.certDba}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            {...inputConfiguration(
              "id-source",
              "auditSource",
              "Source",
              20,
              true
            )}
            value={formData?.auditSource ?? ""}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Input
            {...inputConfiguration(
              "id-address",
              "certAddress1",
              "Address",
              100
            )}
            tabIndex={7}
            readOnly={formData?.insuredCopyOnly || readOnly}
            value={formData?.certAddress1 ?? ""}
            onChange={updateForm}
            errorMessage={errorDetails?.certAddress1}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            {...inputConfiguration(
              "id-issued-by",
              "issuedBy",
              "Issued By",
              300,
              true
            )}
            value={formData?.issuedBy ?? ""}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Input
            {...inputConfiguration(
              "id-address-2",
              "certAddress2",
              "Address 2",
              100
            )}
            tabIndex={8}
            readOnly={formData?.insuredCopyOnly || readOnly}
            value={formData?.certAddress2 ?? ""}
            onChange={updateForm}
            errorMessage={errorDetails?.certAddress2}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            {...inputConfiguration(
              "id-certificate-number",
              "certificateId",
              "Certificate Number",
              300,
              true
            )}
            value={certificateId}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            {...inputConfiguration("id-city", "certCity", "City", 50)}
            tabIndex={9}
            readOnly={formData?.insuredCopyOnly || readOnly}
            value={formData?.certCity ?? ""}
            onChange={updateForm}
            errorMessage={errorDetails?.certCity}
          />
        </Col>
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
          <Select
            {...selectConfiguration("id-state", "certState", "State")}
            tabIndex={10}
            labelFontType="BOLD_CAPTION"
            firstOptionAsDefault={false}
            readOnly={formData?.insuredCopyOnly || readOnly}
            options={certStateOptions}
            optionsMaxHeight={"400px"}
            onChange={(value) => setFormData({ ...formData, certState: value })}
            value={formData?.certState ?? ""}
            errorMessage={errorDetails?.certState}
          />
        </Col>
        <Col breakpoints={{ md: 2, lg: 2, xl: 2 }}>
          <Input
            {...inputConfiguration(
              "id-postal-code",
              "certPostalCode",
              "Postal Code",
              10
            )}
            tabIndex={11}
            readOnly={formData?.insuredCopyOnly || readOnly}
            value={formData?.certPostalCode ?? ""}
            onChange={updateForm}
            errorMessage={errorDetails?.certPostalCode}
          />
        </Col>
        <Col
          breakpoints={{ md: 4, lg: 4, xl: 4 }}
          horizontalAlign={"flex-start"}
          verticalAlign={"flex-end"}
        >
          <Switch
            tabIndex={12}
            control={"checkbox"}
            isChecked={formData?.issueAtRenewal}
            label={"Auto Issue at Policy Renewal?"}
            labelPlacement={"end"}
            onChangeRawValue={(value) => {
              setFormData({ ...formData, issueAtRenewal: value });
            }}
            labelFontType={"BODY"}
            inputWidth={"auto"}
            name={"issueAtRenewal"}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Input
            {...inputConfiguration(
              "id-attend-to",
              "certAttention",
              "Attend To",
              100
            )}
            readOnly={formData?.insuredCopyOnly || readOnly}
            tabIndex={13}
            value={formData?.certAttention ?? ""}
            onChange={updateForm}
            errorMessage={errorDetails?.certAttention}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            tabIndex={14}
            id="id-expiration-date"
            name={"expirationDate"}
            label="Continue Until (leave blank if no end date)"
            value={formData?.expirationDate}
            onChangeRawValue={(value) => {
              setFormData({ ...formData, expirationDate: value });
            }}
            errorMessage={errorDetails?.expirationDate}
            readOnly={readOnly}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputPhone
            {...inputConfiguration("id-phone", "certPhone", "Phone", 20)}
            phoneLabel={"Phone"}
            tabIndex={15}
            readOnly={formData?.insuredCopyOnly || readOnly}
            phoneValue={formData?.certPhone ?? ""}
            onChange={(e) =>
              setFormData({ ...formData, certPhone: e.target.value })
            }
            errorMessagePhone={errorDetails?.certPhone}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputPhone
            {...inputConfiguration("id-fax", "certFax", "Fax", 20)}
            phoneLabel={"Fax"}
            readOnly={formData?.insuredCopyOnly || readOnly}
            tabIndex={16}
            phoneValue={formData?.certFax ?? ""}
            onChange={(e) =>
              setFormData({ ...formData, certFax: e.target.value })
            }
            errorMessagePhone={errorDetails?.certFax}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}></Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Input
            {...inputConfiguration(
              "id-email-address",
              "certEmail",
              "Email Address",
              100
            )}
            tabIndex={17}
            readOnly={formData?.insuredCopyOnly || readOnly}
            value={formData?.certEmail ?? ""}
            onChange={updateForm}
            errorMessage={errorDetails?.certEmail}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}></Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Input
            {...inputConfiguration("id-job-name", "jobName", "Job Name", 200)}
            tabIndex={18}
            readOnly={formData?.insuredCopyOnly || readOnly}
            value={formData?.jobName ?? ""}
            onChange={updateForm}
            errorMessage={errorDetails?.jobName}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}></Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 12, lg: 12, xl: 12 }}>
          <InputMemo
            id={"id-comments"}
            name={"comments"}
            label={"Comments"}
            tabIndex={19}
            rows={3}
            value={formData?.comments ?? ""}
            onChange={updateForm}
            errorMessage={errorDetails?.comments}
            readOnly={readOnly}
          />
        </Col>
      </Row>
    </>
  );
};

export default IssueCertificateModalContent;
