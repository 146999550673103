import { useEffect } from "react";
import { RecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { SPECIAL_SORT_ORDER } from "../TableConstants";
import {
  filterBy,
  getIndexOfColumn,
  getPaginatedData,
  groupBy,
  // orderRowsByGroup,
  sortOrderBy,
} from "../tableFunctions";
import { TableInstanceType2 } from "./TableProperties";

const SortAndFilterData = (props) => {
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(props.uiid) as RecoilState<TableInstanceType2>
  );

  const setTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        set(tableInstanceAtomFamily2(props.uiid), {
          ...tableInstance,
          ...newValueTableInstance,
          uiid: props.uiid,
        });
      },
    []
  );

  const orderParentWithChildrenRows = (data) => {
    const rows: string[][] = Array.from(data.values());
    const parentValue =
      tableInstance?.advancedOptions?.collapsedTableProperties
        ?.parentColumnValue;
    const parentName =
      tableInstance?.advancedOptions?.collapsedTableProperties
        ?.parentColumnName;
    const columnIndex = getIndexOfColumn(
      tableInstance?.columns ?? [],
      parentName ?? ""
    );

    return rows.map((row) => {
      const parent = row.filter((item) => item[columnIndex] === parentValue);
      const children = row.filter((item) => item[columnIndex] !== parentValue);
      const newRows: any[] = [parent, children];
      return newRows;
    });
  };

  const processDataToCollapseTable = (data: string[][]) => {
    if (
      tableInstance?.advancedOptions?.collapsedTableProperties
        ?.structureDataByParent
    ) {
      const columnIndex = getIndexOfColumn(
        tableInstance?.columns,
        tableInstance?.advancedOptions?.collapsedTableProperties
          ?.collapseFilterField ?? ""
      );
      const groupedData = groupBy(data, columnIndex);
      const collapsedRows = orderParentWithChildrenRows(groupedData);
      return collapsedRows;
    }
    return getPaginatedData(
      data,
      tableInstance?.rowsPerPage ?? 0,
      tableInstance?.selectedPage ?? 0
    );
  };

  const selectorDataProcess = (data: string[][]) => {
    switch (tableInstance?.tableType) {
      // case "multi":
      //   return processDataToMultiTable(data);
      case "collapse":
        return processDataToCollapseTable(data);
      default:
        return getPaginatedData(
          data,
          tableInstance?.rowsPerPage ?? 0,
          tableInstance?.selectedPage ?? 0
        );
    }
  };

  const sortAndFilterBy = (
    data: string[][],
    order: any,
    orderBy: any,
    filterValues: {
      columnIndexes: number[];
      filterValue: string;
      filterName: string;
    }[],
    isColumnOrderChange?: boolean
  ) => {
    const orderFilter = filterValues?.find(
      (filter) => filter?.filterName === SPECIAL_SORT_ORDER
    );

    const orderType = isColumnOrderChange
      ? order
      : orderFilter?.filterValue ?? order;
    const orderByIndex = isColumnOrderChange
      ? orderBy
      : orderFilter?.columnIndexes?.[0] ?? orderBy;

    const orderResult = sortOrderBy(
      data,
      orderType,
      orderByIndex,
      tableInstance?.columns
    );

    const searchResult = filterBy(
      tableInstance?.searchValue ?? [],
      orderResult,
      filterValues
    );

    return {
      paginated: tableInstance?.paginate
        ? selectorDataProcess(searchResult)
        : searchResult,
      filtered: searchResult,
    };
  };

  useEffect(() => {
    // TODO - investigate why searchValue gets computed twice -

    /*
      UPDATE [Antonio - 3/28/2023]: Discovered why. Initially when the toolbar component loads, it loads a defult length of N 
      (relative to the number of filters attached to the filterOptions property when the table is executed). These have automatic 
      default values when generated. Unfortunately, once additional processes are completed, a second update is done to 
      searchValue which pulls the additional information for those filters and updates the searchValue object which causes that 
      second effect to trigger. This issue isn't easy to solve as their are many depencecies required for the toolbars, filters, 
      searchValue, and sortedAndFilterData object to all work in cohesion. Additional "proper" time is required to truly fix 
      the problem, which should start with a slight refactor of how we get the initial stat for the filters on the toolbar component. 
      Once that is complete, we can likely remove suppressant logic in this component since the real issue would have been resolved.
      */

    const hasValidFilters = () => {
      if (tableInstance.searchValue.length === 0) return true;

      const isValid = !tableInstance.searchValue
        .map((f) => {
          if (f.isStatic) {
            return false; // We need to return false to counter the inverse evaluation above but really we mean "true".
          }
          return (f?.columnNames?.length ?? 0) === 0;
        })
        .every((s) => s);

      if (!isValid) {
        console.error(
          "One or more of the applied table filters does not contain a list of columnNames values or it is not flagged as a isStatic."
        );
      }
      return isValid;
    };

    if (
      hasValidFilters() &&
      (tableInstance?.data?.length ?? 0) > 0 &&
      tableInstance?.isPreProcessingComplete &&
      tableInstance?.searchValue?.length > 0 &&
      tableInstance?._inProcessComputesQueue.length === 0
    ) {
      const result = sortAndFilterBy(
        tableInstance?.data,
        tableInstance?.order,
        tableInstance?.orderBy,
        tableInstance?.searchValue,
        false
        // tableInstance?.columns
      );

      setTableInstance({
        ...tableInstance,
        sortedAndFilteredData: result.paginated,
        sortedAndFilteredDataWithOutPaginate: result.filtered,
      });

      // TODO - this is what currently sorts the data
    }
  }, [
    tableInstance?.isPreProcessingComplete,
    tableInstance?.searchValue,
    tableInstance?.data,
    tableInstance?.rowsPerPage,
    tableInstance?.selectedPage,
    tableInstance?._inProcessComputesQueue,
  ]);

  useEffect(() => {
    // TODO - investigate why searchValue gets computed twice

    if (
      (tableInstance?.data?.length ?? 0) > 0 &&
      tableInstance?.isPreProcessingComplete &&
      tableInstance?.searchValue?.length === 0
    ) {
      const result = sortAndFilterBy(
        tableInstance?.data,
        tableInstance?.order,
        tableInstance?.orderBy,
        tableInstance?.searchValue,
        false
        // tableInstance?.columns
      );

      setTableInstance({
        ...tableInstance,
        sortedAndFilteredData: result.paginated,
        sortedAndFilteredDataWithOutPaginate: result.filtered,
      });
    }
  }, [
    tableInstance?.isPreProcessingComplete,
    tableInstance?.searchValue,
    tableInstance?.data,
    tableInstance.rowsPerPage,
    tableInstance.selectedPage,
  ]);

  // TODO - find a way to consolidate this with the above useEffect
  useEffect(() => {
    if (tableInstance.isEdit === false && tableInstance.data.length > 0) {
      const result = sortAndFilterBy(
        tableInstance?.data,
        tableInstance?.order,
        tableInstance?.orderBy,
        tableInstance?.searchValue,
        true
      );

      setTableInstance({
        ...tableInstance,
        sortedAndFilteredData: result.paginated,
        sortedAndFilteredDataWithOutPaginate: result.filtered,
      });
    }
  }, [tableInstance?.order, tableInstance?.orderBy]);

  return null;
};

export default SortAndFilterData;
