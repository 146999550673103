import { FC, useEffect } from "react";
import Paper from "../TrueUI/Containers/Paper";
import { Divider, Font, Input } from "../TrueUI";
import { BillingHeaderDto } from "../../dtos/billing-header-dto";
import { useApiGet } from "../../hooks";
import InlineLink from "../TrueUI/Links/InlineLink";
import style from "../Billing/Billing.module.css";

const CurrentBilling: FC = () => {
  const { responseGet, dispatchGet } = useApiGet<BillingHeaderDto>(
    `api/Billing/GetBillingHeaderInformation`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  const currentBilling = responseGet?.axiosResponse?.data;
  return (
    <Paper title="Current Billing" margin="25px 0" collapse>
      <div>
        <div className={style.container_billing_header_information_landing}>
          <Input
            inputFontType="TITLE"
            labelFontType="TITLE"
            labelPosition="start"
            label={"Current Amount Due:"}
            value={currentBilling?.currentAmountDue}
            type={"fixedCurrency"}
            readOnly
            id={"current-amount-due"}
            name={"current-amount-due"}
            inputWidth="300px"
          />
          <Input
            inputFontType="TITLE"
            labelFontType="TITLE"
            labelPosition="start"
            label={"Past Due Amount:"}
            value={currentBilling?.pastDueAmount}
            type={"fixedCurrency"}
            readOnly
            id={"past-due-amount"}
            name={"past-due-amount"}
            inputWidth="300px"
          />
          <Input
            inputFontType="TITLE"
            labelFontType="TITLE"
            labelPosition="start"
            label={"Paid to Date for Current Policy:"}
            value={currentBilling?.paidToDateDueAmount}
            type={"fixedCurrency"}
            readOnly
            id={"paid-to-date-current-policy"}
            name={"paid-to-date-current-policy"}
            inputWidth="360px"
          />
          <InlineLink href={"/billing"} permission>
            See Details
          </InlineLink>
        </div>
        <Divider height={"5px"} />
        {currentBilling?.isPendingReport && (
          <div>
            <Font isErrorFont textAlign="start">
              Your {currentBilling?.payrollReportMonth} payroll report is{" "}
              {currentBilling?.payrollReportMessage}.
            </Font>
            <Font whiteSpace="normal">
              Click <InlineLink permission>here</InlineLink> to complete it.
            </Font>
            <Divider height={"10px"} />
            <InlineLink href="/billing" permission>
              View billing
            </InlineLink>
          </div>
        )}
      </div>
    </Paper>
  );
};

export default CurrentBilling;
