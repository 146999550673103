import { useEffect, useState } from "react";
import { BaseTableFilterOptions } from "../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTableDropdownFilter from "../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { SelectOptions } from "../../dtos/select-options";
import { useApiGet } from "../../hooks";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";

type PolicyInfo = {
  policies: Partial<SelectOptions>[];
  defaultValue: number;
};
type BaseTableDropdownFilterProps = {
  id: string;
  filterOptions: BaseTableFilterOptions;
  labelPosition?: "start" | "end" | "top" | "bottom";
  labelText?: string;
  isExternalAction?: boolean;
  selectWidth?: string;
  onChange?: (e: any) => void;
};

const ExternalBaseTablePolicyDropdownFilter: (
  properties: BaseTableDropdownFilterProps
) => any = ({
  id,
  filterOptions,
  labelPosition,
  labelText,
  selectWidth,
  onChange,
}) => {
  const [policyInfo, setPolicyInfo] = useState<PolicyInfo>({
    policies: [],
    defaultValue: -1,
  });
  const { responseGet, dispatchGet } = useApiGet<SelectOptions[]>(
    `api/Policy/GetPoliciesByCurrentInsured`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setPolicyInfo({
        defaultValue: responseGet.responseData?.[0]?.intValue ?? -1,
        policies: [allOption, ...(responseGet.responseData ?? [])],
      });
      onChange?.(responseGet.responseData?.[0]?.intValue ?? -1);
    }
  }, [responseGet]);

  const allOption: Partial<SelectOptions> = {
    intValue: -1,
    displayName: "All",
  };

  const PolicyDropdown = BaseTableDropdownFilter({
    id: id,
    filterOptions: filterOptions,
    optionsOverride: policyInfo.policies,
    defaultValue: policyInfo.defaultValue,
    usingExternalFiltering: true,
    isStaticFilter: true,
    onChange: (value) => onChange?.(value),
    labelPosition: labelPosition,
    labelText: labelText,
    selectWidth: selectWidth,
  });

  return PolicyDropdown;
};

export default ExternalBaseTablePolicyDropdownFilter;
