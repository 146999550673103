import { FC } from "react";
import { ClaimDiaryNoteDto } from "../../../dtos/claim-diary-note-dto";
import Note from "./Note";
import { Heading6, Title } from "../../TrueUI";
import style from "./claim_notes.module.css";

type ClaimNotesProps = {
  diaryNoteList: ClaimDiaryNoteDto[];
};

const ClaimNotes: FC<ClaimNotesProps> = ({ diaryNoteList }) => {
  return (
    <div>
      <Heading6>Notes</Heading6>
      <div id={"claim-notes"} className={style.true_conversation_wrapper}>
        {diaryNoteList?.length > 0 ? (
          diaryNoteList?.map((note: ClaimDiaryNoteDto) => (
            <div key={note.diaryNoteId}>
              <Note information={note} />
            </div>
          ))
        ) : (
          <div className={style.true_empty_message_container}>
            <Title>There are no notes yet!</Title>
          </div>
        )}
      </div>
    </div>
  );
};

export default ClaimNotes;
