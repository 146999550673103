import { FC, useEffect, useState } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import {
  ModalNewPaymentContainerProps,
  PaymentModalConfigurationProps,
  PaymentSelectorConfig,
} from "./PaymentSelectorTypes";
import ModalNewPaymentSelector from "./ModalNewPaymentSelector";
import ModalAchPayment from "../AchPayment/ModalAchPayment";
import ModalCreditCardPayment from "../CreditCardPayment/ModalCreditCardPayment";
import ModalCreditCardPaymentRedirect from "../CreditCardPaymentRedirect/ModalCreditCardPaymentRedirect";

const ModalNewPaymentContainer: FC<ModalNewPaymentContainerProps> = ({
  isOpen,
  setIsModalPaymentsOpen,
  setRefreshPaymentsTableData,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const [paymentSelectorModalConfig, setPaymentSelectorModalConfig] =
    useState<PaymentSelectorConfig>({
      isOpen: isOpen ?? false,
      hasAch: localOptions?.initOptions?.programInfo?.hasAch ?? false,
      hasCreditCard:
        localOptions?.initOptions?.programInfo?.hasCreditCard ?? false,
    });

  const [paymentModalConfig, setPaymentModalConfig] =
    useState<PaymentModalConfigurationProps>({
      isOpenModalCreditCardPayment: false,
      isOpenAchPaymentModal: false,
      isOpenModalCreditCardPaymentRedirect: false,
    });

  const closePaymentSelectorModal = () => {
    setPaymentSelectorModalConfig({
      ...paymentSelectorModalConfig,
      isOpen: false,
    });
    setIsModalPaymentsOpen?.(false);
  };

  useEffect(() => {
    setPaymentSelectorModalConfig({
      ...paymentSelectorModalConfig,
      isOpen: isOpen ?? false,
      hasAch: localOptions?.initOptions?.programInfo?.hasAch ?? false,
      hasCreditCard:
        localOptions?.initOptions?.programInfo?.hasCreditCard ?? false,
    });
  }, [isOpen, localOptions]);

  return (
    <>
      <ModalNewPaymentSelector
        configuration={paymentSelectorModalConfig}
        closeModal={closePaymentSelectorModal}
        openCreditCardModal={() =>
          setPaymentModalConfig({
            ...paymentModalConfig,
            isOpenModalCreditCardPayment: true,
          })
        }
        openAchModal={() =>
          setPaymentModalConfig({
            ...paymentModalConfig,
            isOpenAchPaymentModal: true,
          })
        }
        openCreditCardRedirectModal={() =>
          setPaymentModalConfig({
            ...paymentModalConfig,
            isOpenModalCreditCardPaymentRedirect: true,
          })
        }
      />
      <ModalCreditCardPayment
        paymentModalConfiguration={paymentModalConfig}
        setPaymentModalConfiguration={setPaymentModalConfig}
        setUiid={setRefreshPaymentsTableData}
      />
      <ModalAchPayment
        paymentModalConfiguration={paymentModalConfig}
        setPaymentModalConfiguration={setPaymentModalConfig}
        setUiid={setRefreshPaymentsTableData}
      />
      <ModalCreditCardPaymentRedirect
        paymentModalConfiguration={paymentModalConfig}
        setPaymentModalConfiguration={setPaymentModalConfig}
      />
    </>
  );
};
export default ModalNewPaymentContainer;
