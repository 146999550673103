import { FC, useEffect } from "react";
import IncidentSection from "./IncidentSections/IncidentSection";
import {
  claimIncidentStateProps,
  getActiveSectionById,
  getSectionDots,
  getSectionsWithFields,
  getValue,
} from "./AddNewIncidentUtils";
import { KeysAsType } from "../../types/KeysAsAType";
import { StateReportPage } from "../../dtos/state-report-page";
import { useApiGet } from "../../hooks";
import { FontsType } from "../../media/themeTypes";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";

type DynamicIncidentFieldsProps = {
  claimIncidentUI?: claimIncidentStateProps;
  readOnlyMode: boolean;
  textFieldVariant?: "filled" | "standard";
  labelsType?: KeysAsType<FontsType>;
  setClaimIncidentUI: (claimIncidentUI: claimIncidentStateProps) => void;
};

const DynamicIncidentFields: FC<DynamicIncidentFieldsProps> = ({
  claimIncidentUI,
  readOnlyMode,
  textFieldVariant,
  setClaimIncidentUI,
  labelsType,
}) => {
  const {
    responseGet: getStateReportPage,
    dispatchGet: dispatchGetStateReportPage,
  } = useApiGet<StateReportPage>(
    `api/PortalIncident/GetStateReportPage?state=${claimIncidentUI?.jurisdiction}`
  );

  useEffect(() => {
    if (
      !claimIncidentUI?.filledFromAtom &&
      claimIncidentUI?.jurisdiction &&
      !readOnlyMode
    )
      dispatchGetStateReportPage();
  }, [claimIncidentUI?.jurisdiction]);

  useEffect(() => {
    if (isAPITotallyComplete(getStateReportPage)) {
      setClaimIncidentUI({
        ...claimIncidentUI,
        stateReport: getStateReportPage?.responseData,
        sectionDots: getSectionDots(
          getStateReportPage?.responseData?.stateReportFields
        ),
        claimIncident: {
          ...claimIncidentUI?.claimIncident,
          incident: {
            ...claimIncidentUI?.claimIncident?.incident,
            stateReportID:
              getStateReportPage?.responseData?.stateReport?.stateReportId,
          },
        },
      });
    }
  }, [getStateReportPage]);

  return (
    <>
      {/* Dynamic Fields */}
      {getSectionsWithFields(
        claimIncidentUI?.stateReport?.stateReportFields
      ).map(
        (section) =>
          section.sectionId ===
            getActiveSectionById(
              claimIncidentUI?.sectionDots,
              claimIncidentUI?.activeSection ?? -1
            )?.sectionId && ( //Filter to show only the active section
            <IncidentSection
              key={`incidentSection${section.sectionName}`}
              textFieldVariant={textFieldVariant}
              labelsType={labelsType}
              readOnly={readOnlyMode}
              sectionId={section.sectionId}
              stateReport={getValue(claimIncidentUI?.stateReport)}
              claimIncident={claimIncidentUI}
              setClaimIncident={setClaimIncidentUI}
              errorDetails={claimIncidentUI?.errorDetails}
            />
          )
      )}
    </>
  );
};

export default DynamicIncidentFields;
