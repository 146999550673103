import { FC } from "react";
import { useState } from "react";
import Link from "../TrueUI/Typography/Link";
import Menu from "../NavBar/Menu";
import { usePermissions } from "../../hooks";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../GlobalAtoms";
import { Container } from "@mui/material";
import { truncateString } from "../../utilities/stringFunctions";

type TopLinksProps = {
  isAgencyPortal?: boolean;
};

const TopLinks: FC<TopLinksProps> = ({ isAgencyPortal = false }) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const hasPermission = usePermissions([30]);
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;

  const TopLinkItem = ({
    displayValue,
    icon,
    href = "#",
    onClick = () => {},
  }) => (
    <div className={"top-link"}>
      <Link
        primaryFontColor
        displayValue={displayValue}
        icon={icon}
        linkFontType="TITLE"
        href={href}
        onClick={onClick}
      />
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        backgroundColor: theme?.primary,
        alignItems: "center",
      }}
    >
      <Container sx={{}} maxWidth={"lg"}>
        <div className="icons-top-bar" /*onClick={() => LogoutHandler()}*/>
          <div className="icons-top-bar-container">
            {localOptions?.initOptions?.programInfo?.portalHasDocuments && (
              <TopLinkItem
                icon={"file-bac"}
                displayValue={"DOCUMENTS AND FORMS"}
                href={"/documents-and-forms"}
              />
            )}
            {hasPermission &&
              localOptions?.initOptions?.programInfo?.portalHasLossControl && (
                <TopLinkItem
                  icon={"silverlight"}
                  displayValue={
                    localOptions?.initOptions?.programInfo
                      ?.portalLossControlLabel ?? "SAFETY AND LOSS CONTROL"
                  }
                  href={"/safety-and-loss-control"}
                />
              )}
            <TopLinkItem icon={"question"} displayValue={"GET HELP"} />
            <TopLinkItem
              icon={"user"}
              displayValue={
                isAgencyPortal
                  ? truncateString(
                      localOptions?.initOptions?.longName,
                      20,
                      true
                    )
                  : "MY ACCOUNT"
              }
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            />
            <Menu
              open={isMenuOpen}
              onTopNavClose={() => setIsMenuOpen(!isMenuOpen)}
            />
          </div>
        </div>
      </Container>
    </div>
  );
};
export default TopLinks;
