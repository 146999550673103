import { BillingScheduledTransactionDto } from "../../../../dtos/billing-scheduled-transaction-dto";
import { ScheduledTransactionConfigurationDto } from "../../../../dtos/scheduled-transaction-configuration-dto";
import { FC, useEffect } from "react";
import { useApiGet } from "../../../../hooks";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";
import { defaultData } from "./ScheduledTransactionModal";
import { Button, Col, Input, InputDate, Row, Select } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import {
  inputConfiguration,
  selectConfiguration,
} from "../../../../utilities/inputPropsConfigurationFunctions";
import PolicyDropdown from "../../../TrueUI/Select/PolicyDropdown";

type ScheduledTransactionModalContentProps = {
  configuration: ScheduledTransactionConfigurationDto;
  formData: BillingScheduledTransactionDto;
  setFormData: (newData: BillingScheduledTransactionDto) => void;
  transactionId: number;
  errorDetails?: any;
  openAddAccountModal?: () => void;
};

const ScheduledTransactionModalContent: FC<
  ScheduledTransactionModalContentProps
> = ({
  configuration,
  transactionId,
  formData,
  setFormData,
  errorDetails,
  openAddAccountModal,
}) => {
  const { responseGet, dispatchGet } =
    useApiGet<BillingScheduledTransactionDto>(
      `api/BillingTransaction/GetScheduledTransactionById?scheduledTransactionId=${transactionId}`
    );

  useEffect(() => {
    dispatchGet();
  }, [transactionId]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setFormData(responseGet.responseData ?? defaultData);
    }
  }, [responseGet]);

  return (
    <>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Input
            {...inputConfiguration(
              "id-insured-name",
              "insured-name",
              "Insured Name",
              300,
              true
            )}
            value={formData.insuredName}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Select
            {...selectConfiguration(
              "id-pay-account-type",
              "pay-account-type",
              "Pay Account Type",
              true
            )}
            labelFontType="BOLD_CAPTION"
            options={configuration?.accountTypes ?? []}
            value={formData.insuredAccountId}
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Select
            {...selectConfiguration(
              "id-pay-from-account",
              "pay-from-account",
              "Pay From Account",
              false
            )}
            labelFontType="BOLD_CAPTION"
            firstOptionAsDefault={false}
            options={configuration.accountOptions ?? []}
            optionsMaxHeight={"400px"}
            value={formData.insuredAccountId ?? -1}
            onChange={(value) =>
              setFormData({ ...formData, insuredAccountId: value })
            }
          />
        </Col>
        <Col verticalAlignSelf={"flex-end"} breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Button
            variantStyle="outlined"
            onClick={() => openAddAccountModal?.()}
          >
            ADD ACCOUNT
          </Button>
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            {...inputConfiguration(
              "id-created-by",
              "created-by",
              "Created by/on",
              50
            )}
            type={"text"}
            value={formData.createBy}
            readOnly
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <PolicyDropdown
            name={"policyId"}
            id={"id-policy"}
            label={"Policy"}
            labelFontType={"BOLD_CAPTION"}
            defaultValue={formData.policyId ?? -1}
            policySelectedOnChange={(value) =>
              setFormData({ ...formData, policyId: value })
            }
            readOnly
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 8, lg: 8, xl: 8 }}>
          <Select
            {...selectConfiguration(
              "id-deposit-account-number",
              "deposit-account-number",
              "Deposit Account Number",
              true
            )}
            firstOptionAsDefault={false}
            labelFontType="BOLD_CAPTION"
            optionsMaxHeight={"400px"}
            options={configuration.depositAccountOptions ?? []}
            value={formData.tranAccountId}
            onChange={(value) =>
              setFormData({ ...formData, tranAccountId: value })
            }
          />
        </Col>
      </Row>
      <Row {...rowWithNoMarginNorGutter}>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <Input
            {...inputConfiguration("id-amount", "amount", "Amount", 22, false)}
            type={"fixedCurrency"}
            value={formData.transactionAmount}
            onChangeRawValue={(value) =>
              setFormData({ ...formData, transactionAmount: value })
            }
            maxNumericValue={9999999999999.99}
            errorMessage={errorDetails?.transactionAmount}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }}>
          <InputDate
            tabIndex={8}
            id="id-scheduled-date"
            name={"scheduled-date"}
            label="Scheduled Date"
            value={formData.scheduledDate ?? new Date()}
            onChangeRawValue={(value) =>
              setFormData({ ...formData, scheduledDate: value })
            }
            disablePast
            errorMessage={errorDetails?.scheduledDate}
          />
        </Col>
        <Col breakpoints={{ md: 4, lg: 4, xl: 4 }} />
      </Row>
    </>
  );
};

export default ScheduledTransactionModalContent;
