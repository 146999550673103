import { FC } from "react";
import { Box, Paper } from "@mui/material";
import { FormattingDate } from "../../../utilities/dateFunctions";
import { Caption } from "../../TrueUI";
import { ClaimDiaryNoteDto } from "../../../dtos/claim-diary-note-dto";
import style from "./claim_notes.module.css";

type NoteProps = {
  id?: string;
  information: ClaimDiaryNoteDto;
};

const Note: FC<NoteProps> = ({ id, information }) => {
  return (
    <Box className={style.true_note}>
      <>
        <Box className={style.message_description}>
          <Caption>{information.category ?? ""}</Caption>
        </Box>
        <Box
          display={"flex"}
          alignItems={"center"}
          flexDirection={"row"}
          id={id}
        >
          <Paper
            elevation={3}
            className={style.true_message}
          >
            <Box
              display={"flex"}
              alignItems={"flex-start"}
              justifyContent={"space-between"}
            >
              <div
                className={style.true_message_content}
                dangerouslySetInnerHTML={{ __html: information.content ?? "" }}
              />
            </Box>
          </Paper>
          <Box className={style.true_note_information}>
            <Caption>
              {FormattingDate(information.updateOn, "M/D/YYYY h:mm a")}
            </Caption>
            <Caption>{information.shortName}</Caption>
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default Note;
