import { Route, Routes } from "react-router-dom";

import RouteError from "./RouteError";
import Dashboard from "./components/Dashboard/Dashboard";
import Incident from "./components/Incident/Incident";
import MainClaims from "./components/Claims/MainClaims";
import Emma from "./components/SandBox/Emma";
import Documents from "./components/Documents/Documents";
import { PortalAreaEnum } from "./dtos/portal-area-enum";
import ClaimDetail from "./components/Claims/ClaimDetail";
import UploadFilesForm from "./components/UploadFilesForm/UploadFilesForm";
import Billing from "./components/Billing/Billing";
import Mafer from "./components/SandBox/Mafer";
import PayrollReport from "./components/Billing/PayrollReport/PayrollReport";
import Policy from "./components/Policy/Policy";
import PaymentRedirectPostBack from "./components/Billing/PayrollReport/CreditCardPaymentRedirect/PaymentRedirectPostBack";

const AppRoutes = (
  <Routes>
    <Route path="/home" element={<Dashboard />} />
    <Route path="/claims" element={<MainClaims />} />
    <Route path="/claims/:claimId" element={<ClaimDetail />} />
    <Route path="/" element={<Dashboard />} />
    <Route path="*" element={<RouteError />} />
    <Route path="/incident" element={<Incident />} />
    <Route
      path="/documents-and-forms"
      element={<Documents portalArea={PortalAreaEnum?.GENERAL_DOCUMENTS} />}
    />
    <Route
      path="/safety-and-loss-control"
      element={
        <Documents portalArea={PortalAreaEnum?.LOSS_CONTROL_DOCUMENTS} />
      }
    />
    <Route path="/upload-files" element={<UploadFilesForm />} />
    <Route path="/billing" element={<Billing />} />
    <Route
      path="/payroll-report/:payrollReportId"
      element={<PayrollReport />}
    />
    <Route path="/payment-redirect" element={<PaymentRedirectPostBack />} />
    <Route path="/policy" element={<Policy />} />
    <Route path="/emma" element={<Emma />} />
    <Route path="/mafer" element={<Mafer />} />
  </Routes>

  // <Redirect from="*" to="/" />
);

export default AppRoutes;
