import { FC, useState } from "react";
import { BaseTable2, Collapse } from "../TrueUI";
import { BaseTable2Properties } from "../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../hooks/useBaseTable";
import BaseTablePolicyDropdownAsyncFilter from "../TrueUI/Tables/BaseTableCommonFilters/BaseTablePolicyDropdownAsyncFilter";

const Activity: FC = () => {
  const [policyId, setPolicyId] = useState<number>(-1);
  const tableName = "billing-activity-table";

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/Billing/GetBillingActivityByPolicyId?policyId=${policyId}`,
    filterOptions: [
      (actionOptions) =>
        BaseTablePolicyDropdownAsyncFilter({
          selectWidth: "230px",
          columnsNames: ["PolicyId"],
          filterOptions: actionOptions,
          isExternalAction: true,
          labelText: "Policy:",
          addAllOption: false,
          onChangeAfterAPI: false,
          onChange: (value) => {
            setPolicyId(value);
          },
        }),
    ],
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
    },
    columnOptions: [
      { fieldName: "Balance", align: "right" },
      { fieldName: "Amount", align: "right" },
    ],
    advancedOptions: {
      paginate: false,
    },
  };

  useBaseTable(tableConfiguration);
  return (
    <Collapse
      id={"billing-activity-collapse"}
      title="Activity"
      titleHeightAuto
      content={
        <div style={{ height: "215px", marginTop: "-15px" }}>
          <BaseTable2 name={tableName} />
        </div>
      }
    />
  );
};
export default Activity;
