export const TABBABLE_CLASS_NAME = "tabbable_element";

export const focusNextElement = (id: string) => {
  const tabbableInputs = [
    ...(document.getElementsByClassName(TABBABLE_CLASS_NAME) ?? []),
  ] as HTMLElement[];

  const elementIndex = tabbableInputs.findIndex((i) => i.id === id);

  if (elementIndex > -1 && elementIndex !== tabbableInputs?.length - 1) {
    const firstMatch = tabbableInputs?.at(elementIndex + 1);
    firstMatch?.focus();
  }
};
