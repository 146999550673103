import { useEffect, useState } from "react";
import { BaseTableFilterOptions } from "../TrueUI/Tables/BaseTable2/TableProperties";
import { DROPDOWN_FILTER } from "../TrueUI/Tables/TableConstants";
import PolicyDropdown from "../TrueUI/Select/PolicyDropdown";

type BaseTableDropdownFilterProps = {
  id?: string;
  filterOptions: BaseTableFilterOptions;
  columnsNames?: string[];
  defaultValue?: string | number;
  currentPolicyAsDefault?: boolean;
  labelPosition?: "start" | "end" | "top" | "bottom";
  labelText?: string;
  overrideAllOptionLabel?: string;
  overrideAllOptionValue?: string;
  selectAllOptionAsDefault?: boolean;
  isExternalAction?: boolean;
  selectWidth?: string;
  onChange?: (e: any) => void;
};

const BaseTablePolicyDropdownFilter: (
  properties: BaseTableDropdownFilterProps
) => any = ({
  id,
  filterOptions,
  columnsNames,
  defaultValue,
  currentPolicyAsDefault = false,
  labelPosition,
  labelText,
  overrideAllOptionLabel,
  overrideAllOptionValue,
  selectAllOptionAsDefault,
  isExternalAction,
  selectWidth,
  onChange,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(defaultValue ?? "all");

  useEffect(() => {
    filterOptions?.setFilterInclude?.([
      {
        filterName: DROPDOWN_FILTER,
        columnNames: columnsNames,
        filterValue: selectedOptions?.toString(),
      }, // TODO - fix this, it's bad and broken
    ]);
  }, [selectedOptions]);

  return (
    <PolicyDropdown
      id={`filter-policy-dropdown-${id}`}
      name={`filter-policy-dropdown-${id}`}
      variant={"filled"}
      labelPosition={labelPosition ?? "start"}
      label={labelText}
      type={"tableFilter"}
      inputWidth={selectWidth ? selectWidth : "fit-content"}
      selectAllOptionAsDefault={selectAllOptionAsDefault}
      overrideAllOptionValue={overrideAllOptionValue}
      overrideAllOptionLabel={overrideAllOptionLabel}
      isCustomArrow
      labelFontType="TITLE"
      policySelectedOnChange={(value: any) => {
        !isExternalAction && setSelectedOptions(value);
        onChange?.(value);
      }}
      defaultValue={defaultValue}
      currentPolicyAsDefault={currentPolicyAsDefault}
    />
  );
};

export default BaseTablePolicyDropdownFilter;
