import { FC, useEffect, useState } from "react";
import InputPhone from "../../Inputs/InputPhone";
import { BaseTableCellSelectProperties } from "../BaseTable2/TableProperties";

interface BaseTablePhoneCellProperties2 extends BaseTableCellSelectProperties {
  phoneExt?: boolean;
}

const getPhone = (phone: string) => {
  const phoneResult = phone.length > 9 ? phone.substring(0, 10) : phone;
  return phoneResult;
};
const getExt = (phone: string) => {
  const extResult = phone.length > 9 ? phone.substring(10, phone.length) : "";
  return extResult;
};

const PhoneCell2: FC<BaseTablePhoneCellProperties2> = ({
  cellKey,
  rowKey,
  value,
  isEdit,
  variant,
  errorMessage,
  align,
  column,
  // rowIndex,
  onChange,
  phoneExt,
}) => {
  const [updatedValues, setUpdatedValues] = useState<{
    phoneCell: string;
    extensionCell?: string;
  }>({
    phoneCell: getPhone(value),
    extensionCell: phoneExt ? getExt(value) : "",
  });

  useEffect(() => {
    onChange?.({
      cellKey: cellKey,
      rowKey: rowKey,
      column: column,
      value: `${updatedValues.phoneCell ?? ""}${
        updatedValues.extensionCell ?? ""
      }`,
    });
  }, [updatedValues]);
  useEffect(() => {
    setUpdatedValues({
      phoneCell: getPhone(value),
      extensionCell: phoneExt ? getExt(value) : "",
    });
  }, [value]);

  return (
    <div className="container-phone-table-cell">
      <InputPhone
        id={`id-${column.fieldName}-${cellKey}`}
        name={`name-${column.fieldName}-${cellKey}`}
        key={cellKey}
        hideLabelExt={false}
        hasExtension={
          phoneExt && (updatedValues.extensionCell !== "" || isEdit)
        }
        extValue={updatedValues.extensionCell}
        onChangeExtensionValue={(ext) => {
          setUpdatedValues({
            phoneCell: updatedValues.phoneCell,
            extensionCell: ext,
          });
        }}
        readOnly={!isEdit}
        onChangePhoneValue={(phone) => {
          setUpdatedValues({
            phoneCell: phone,
            extensionCell: updatedValues.extensionCell,
          });
        }}
        phoneValue={updatedValues?.phoneCell}
        variant={variant}
        errorMessagePhone={errorMessage}
        sx={{ width: "120px" }}
        isLabelInline={true}
        className={"phone-table-cell"}
        align={align}
      />
    </div>
  );
};

export default PhoneCell2;
