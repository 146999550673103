import { Box } from "@mui/material";
import { FC } from "react";
import { Button, Font } from "../TrueUI";
import { useApiPost } from "../../hooks";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../GlobalAtoms";

const Mafer: FC = () => {
  const localOptions = useRecoilValue(globalOptions);

  const { dispatchPost } = useApiPost(
    `api/PortalIncident/SendEmail?incidentId=${1634}&stateCode=${"MI"}&userEmail=${
      localOptions?.initOptions?.email
    }`
  );

  return (
    <Box
      sx={{
        width: "100%",
        padding: "20px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <Font fontType="TITLE">Try out FROI email</Font>
      <Button sx={{ width: "150px" }} onClick={dispatchPost}>
        Send email
      </Button>
    </Box>
  );
};

export default Mafer;
