import { getColorWithOpacityHexaDecimal } from "../../../media/themeConstants";
import { Theme } from "../../../media/themeTypes";
import { PercentageType } from "../../../types/RangeNumberType";

export const FontSx = (
  theme: Theme,
  type,
  primaryColor: boolean,
  opacity: PercentageType,
  whiteSpace,
  display,
  textAlign,
  maxWidth,
  truncate,
  secondaryFont,
  isErrorFont
) => ({
  color: getColorWithOpacityHexaDecimal(
    isErrorFont
      ? theme?.danger
      : primaryColor
      ? theme?.primary
      : theme?.textColor,
    opacity
  ),
  fontSize: theme?.[type]?.size,
  fontWeight: theme?.[type]?.weight,
  whiteSpace: whiteSpace,
  display: display,
  textAlign: textAlign,
  maxWidth: maxWidth ?? "none",
  overflow: truncate ? "hidden" : "visible",
  textOverflow: "ellipsis",
  "&.align-label-left": {
    display: "block",
    textAlign: "left",
  },
  "&.align-label-center": { display: "block", textAlign: "center" },
  "&.align-label-right": { display: "block", textAlign: "right" },
  fontFamily: secondaryFont
    ? theme?.secondaryFontFamily
    : theme?.primaryFontFamily,
});
