import moment from "moment";
import { customRound } from "./stringFunctions";

export function FormattingDate(
  dateTime: Date | undefined | null,
  dateFormat?: string
): string {
  const DATE_FORMAT = dateFormat ? dateFormat : "M/D/YYYY";
  return dateTime !== null && dateTime !== undefined
    ? moment(dateTime).format(DATE_FORMAT)
    : "";
}

export function FormattingDateAndTime(
  dateTime: Date | undefined | null
): string {
  const dateFormatted = FormattingDate(dateTime);
  const timeFormatted = moment(dateTime).format("h:mm a");
  return `${dateFormatted} ${timeFormatted}`;
}

export function GettingDateWithoutTime(
  dateTime: Date | undefined | null
): Date {
  return moment(dateTime).startOf("day").toDate();
}

export function StringToDate(dateTime: string | undefined | null): Date | null {
  if (dateTime) {
    const newDate = moment(dateTime).toDate();
    return isValidDateMoment(newDate) ? newDate : null;
  }
  return null;
}

export const newDate = () => GettingDateWithoutTime(new Date());

export const createDateFromParts = (
  year: number | string,
  month: number | string,
  day: number | string
) => {
  const dateObj = moment(month + "/" + day + "/" + year, "M/D/YYYY").toDate();
  return dateObj;
};

export const parseDay = (day: string | number) => {
  const dayValue = typeof day === "string" ? parseInt(day) ?? -1 : day;
  return dayValue;
};

export const parseMonth = (month: string | number) => {
  const monthValue = typeof month === "string" ? parseInt(month) - 1 : month;
  return monthValue;
};

export const parseYear = (year: string | number) => {
  const yearValue = typeof year === "string" ? parseInt(year) : year;
  return yearValue;
};

export const isValidDate = (
  year: string | number,
  month: string | number,
  day: string | number
) => {
  const d = createDateFromParts(year, month, day);
  if (
    d.getFullYear() === parseYear(year) &&
    d.getMonth() === parseMonth(month) &&
    d.getDate() === parseDay(day)
  ) {
    return true;
  }
  return false;
};

export const isValidDateMoment = (dateTime: Date | null | undefined) => {
  return moment(dateTime).isValid();
};
export const momentString = (dateTime: Date | null | undefined) => {
  return moment(dateTime).inspect().split("*")[1];
};

type PeriodType = "years" | "months" | "days";

export const getTimeUntilNow = (
  dateTime: Date | null | undefined,
  period: PeriodType
) => {
  const years = moment().diff(dateTime, period);
  return years;
};

export const getTimeBetweenDates = (
  startDate: Date | null | undefined,
  endDate: Date | null | undefined,
  period: PeriodType = "years"
) => {
  return moment(endDate).diff(startDate, period);
};

export const isDatesRangesOverlap = (
  rangeStart1: Date,
  rangeFinish1: Date,
  rangeStart2: Date,
  rangeFinish2: Date,
  isExclusive?: Boolean
) => {
  return isExclusive
    ? rangeStart1 < rangeFinish2 && rangeStart2 < rangeFinish1
    : rangeStart1 <= rangeFinish2 && rangeStart2 <= rangeFinish1;
};

export const isDateAfterDate = (
  rangeStart: Date | string,
  rangeFinish: Date | string
) => moment(rangeStart).isAfter(rangeFinish);

export const isDateAfterOrEqualDate = (
  rangeStart: Date | string,
  rangeFinish: Date | string
) => moment(rangeStart).isSameOrAfter(rangeFinish);

export const isEndDateSameOrAfterStartDate = (
  startDate: Date | string,
  endDate: Date | string
) => moment(endDate).isSameOrAfter(startDate);

export const isExpiredDate = (date: Date | string) =>
  moment().isAfter(moment(date));

export const isDateBetweenDates = (
  date: Date,
  rangeStart: Date,
  rangeFinish: Date
) => {
  return moment(date).isBetween(rangeStart, rangeFinish);
};

export const isDateBetweenDatesIncludingRanges = (
  date: Date | null,
  rangeStart: Date,
  rangeFinish: Date | null
) => {
  if (date !== null) {
    if (rangeFinish !== null)
      return moment(date).isBetween(rangeStart, rangeFinish, null, "[]");
    else return moment(date).isSameOrAfter(rangeStart);
  } else {
    return true;
  }
};

export const getDaysBetweenDates = (startDate: Date, endDate: Date) => {
  startDate.setHours(0);
  startDate.setMinutes(0);
  startDate.setSeconds(0);
  endDate.setHours(0);
  endDate.setMinutes(0);
  endDate.setSeconds(0);
  const daysBetweenDates = customRound(
    ((startDate.getTime() - endDate.getTime()) / (1000 * 3600 * 24)).toString(),
    0
  );
  return daysBetweenDates < 0 ? daysBetweenDates * -1 : daysBetweenDates;
};

export const sortByDate = (a, b) => {
  var dateA = new Date(a.updateOn).getTime();
  var dateB = new Date(b.updateOn).getTime();
  return dateA < dateB ? 1 : -1;
};

export const getDateDiff = (
  startDate: Date,
  endDate: Date,
  unitOfTime: moment.unitOfTime.Diff,
  withDecimal: boolean
) => moment(endDate).diff(startDate, unitOfTime, withDecimal);

export const getDateObject = (date?: Date | string | null): Date =>
  moment(date).toDate();

export const addDays = (date: Date, days: number): Date =>
  moment(date).add(days, "days").toDate();

export const subtractDays = (date: Date, days: number): Date =>
  moment(date).subtract(days, "days").toDate();

export const addYears = (date: Date, years: number): Date =>
  moment(date).add(years, "years").toDate();

export const getNumberOfDaysInAYear = (year: number) => {
  return (year % 4 === 0 && year % 100 > 0) || year % 400 === 0 ? 366 : 365;
};

export const getUniversalTime = () => {
  var date = new Date();
  var now_utc = date.toUTCString().replace("GMT", "");
  return moment(now_utc).toDate();
};

export const getValidatedDateToPost = (date: Date) => {
  try {
    const castedDate = date.toDateString();
    return castedDate;
  } catch {
    return date;
  }
};

export const getCurrentYear = (): number => moment().toDate().getFullYear();
export const getCurrentOrNextBusinessDate = (hourLimit: number) => {
  /// if the time is before hourLimit (local time), default to the current date, otherwise default to the next business day.
  const currentDate = moment();
  if (currentDate.hour() < hourLimit) {
    return currentDate.startOf("day").toDate();
  }
  const day = currentDate.day();
  const FRIDAY = 5;
  const SATURDAY = 6;
  if (day === FRIDAY) {
    return currentDate.add(3, "days");
  }
  if (day === SATURDAY) {
    return currentDate.add(2, "days");
  }
  return currentDate.add(1, "day");
};

export const getYearAsLiteralInt = (date: Date | string) => {
  // Note: new Date('1/1/0001').getFullYear() - returns 2001 if date is '1/1/0001'.
  // Note: new Date('1/1/0001', 'MM/DD/YYYY').getFullYear(); - returns NaN if date is '1/1/0001'.
  return moment(date ?? "", "MM/DD/YYYY hh:mm:ss").year(); // returns 1 if date is '1/1/0001'.
};

export const getSafeDateObject = (date?: Date | string | null): Date | null => {
  const parsedDate = moment(date);
  if (!parsedDate.isValid()) {
    return null;
  }
  return parsedDate.toDate();
};
