import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  PaperProps,
} from "@mui/material";
import { FC, ReactNode, useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import CancelButton from "../Buttons/CancelButton";
import SaveButton from "../Buttons/SaveButton";
import Font from "../Typography/Font";
import { modalPaperStyles, modalStyle } from "./ModalStyles";
import DeleteButton from "../Buttons/DeleteButton";
import "./modal.module.css";
import ButtonWithConfirmation, { ButtonWithConfirmationProps } from "../Buttons/ButtonWithConfirmation";

type ModalProperties = {
  id: string;
  title: string;
  open?: boolean;
  showCloseButton?: boolean;
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  height?: number | string;
  minHeight?: number | string;
  maxHeight?: number | string;
  width?: number | string;
  minWidth?: number | string;
  maxWidth?: number | string;
  sxInternalComponentsStyles?: any;
  internalComponentsClassName?: string;
  deleteDisabled?: boolean;
  saveDisabled?: boolean;
  saveOverrideLabel?: string;
  deleteOverrideLabel?: string;
  buttonRibbonNode?: ReactNode;
  showCancelTextButton?: boolean;
  children?: any;
  saveButtonWithConfirmation?: boolean;
  saveButtonConfirmationText?: string;
  cancelButtonWithConfirmation?: boolean;
  cancelButtonConfirmationText?: string;
  deleteButtonWithConfirmation?: boolean;
  deleteButtonConfirmationText?: string;
  permissionsToSaveButton?: number[];
  cancelButtonOnNoClick?: () => void;
  deleteButtonOnNoClick?: () => void;
  deleteEvent?: () => void;
  saveEvent?: { (): void } | null;
  cancelEvent?: () => void;
  closeEvent?: (isOpen: boolean) => void;
  extraButtons?: ButtonWithConfirmationProps[];
  showExtraButtons?: boolean;
};

const PaperComponent = (props: PaperProps) => {
  const ref = useRef<HTMLDivElement>(null);
  const [isNotDragging, setIsNotDragging] = useState<boolean>(true);
  const [modalPosition, setModalPosition] = useState<{
    x: number;
    y: number;
  }>();

  return (
    <Draggable
      bounds="parent"
      handle="#draggable-dialog-title"
      cancel={'[class*="MuiDialogContent-root"]'}
      onStop={() => setIsNotDragging(true)}
      onStart={() => setIsNotDragging(false)}
      onDrag={() => {
        const position = ref?.current?.getBoundingClientRect();
        setModalPosition({
          x: position?.x ?? 0,
          y: position?.y ?? 0,
        });
      }}
    >
      <Paper
        {...props}
        ref={ref}
        sx={
          isNotDragging
            ? {
                position: modalPosition ? "fixed !important" : "relative",
                top: `${modalPosition?.y}px`,
                left: `${modalPosition?.x}px`,
                transform: `none !important`,
              }
            : {}
        }
      />
    </Draggable>
  );
};
const Modal: FC<ModalProperties> = ({
  id,
  title,
  open,
  showCloseButton,
  size,
  height,
  minHeight,
  maxHeight,
  width,
  minWidth,
  maxWidth,
  sxInternalComponentsStyles,
  internalComponentsClassName,
  deleteDisabled,
  saveDisabled,
  saveOverrideLabel,
  deleteOverrideLabel,
  buttonRibbonNode,
  showCancelTextButton,
  children,
  saveButtonWithConfirmation,
  saveButtonConfirmationText,
  cancelButtonWithConfirmation,
  cancelButtonConfirmationText,
  deleteButtonWithConfirmation,
  deleteButtonConfirmationText,
  permissionsToSaveButton,
  cancelButtonOnNoClick,
  deleteButtonOnNoClick,
  deleteEvent,
  cancelEvent,
  closeEvent,
  saveEvent,
  extraButtons,
  showExtraButtons = false,
}) => {
  const [_open, _setOpen] = useState<boolean>(open ?? false);
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;

  useEffect(() => {
    _setOpen(open ?? false);
  }, [open]);

  useEffect(() => {
    if (_open === false) {
      closeEvent?.(_open);
    }
  }, [_open]);

  return (
    <div>
      <Dialog
        id={id}
        open={_open}
        // onClose={() => _setOpen(false)} // this always us to close the modal by clicking out. we might not want this right now.
        maxWidth={size ?? "md"}
        fullWidth={true}
        aria-labelledby="draggable-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperComponent={PaperComponent}
        sx={modalStyle(height, width, minHeight, minWidth, maxHeight, maxWidth)}
        PaperProps={{
          sx: {
            ...modalPaperStyles(theme, buttonRibbonNode),
            ...sxInternalComponentsStyles,
          },
        }}
        className={`true_modal_dialog_container ${internalComponentsClassName}`}
      >
        <DialogTitle
          id="draggable-dialog-title"
          sx={{
            position: "relative",
            padding: "10px 20px !important",
            height: "auto",
          }}
        >
          <Font fontType={"BODY_BIG"}>{title}</Font>
          <CancelButton
            typeOfCancelButton="icon"
            onClick={() => {
              cancelEvent ? cancelEvent?.() : _setOpen(false);
            }}
            onNoClick={() => cancelButtonOnNoClick?.()}
            textOnButton={showCancelTextButton ? "CANCEL" : "CLOSE"}
            withConfirmation={cancelButtonWithConfirmation}
            textOnConfirmation={cancelButtonConfirmationText}
          />
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
          {children}
          {/* </DialogContentText> */}
        </DialogContent>
        {(deleteEvent !== undefined ||
          saveEvent !== undefined ||
          showCloseButton) && (
          <DialogActions>
            {deleteEvent !== undefined &&
              (deleteDisabled ?? false) === false && (
                <Box
                  className={`true_modal_button_delete_container ${
                    buttonRibbonNode ? "button_ribbon_node" : ""
                  }`}
                >
                  <DeleteButton
                    onClick={() => {
                      deleteEvent ? deleteEvent?.() : _setOpen(false);
                    }}
                    onNoClick={() => deleteButtonOnNoClick?.()}
                    textOnButton={deleteOverrideLabel ?? "DELETE"}
                    withConfirmation={deleteButtonWithConfirmation}
                    textOnConfirmation={deleteButtonConfirmationText}
                    name={"delete-on-modal"}
                  />
                </Box>
              )}
            {extraButtons && showExtraButtons && (
              <Box
                className={`true_modal_button_extra_container ${
                  buttonRibbonNode ? "button_ribbon_node" : ""
                }`}
              >
                {extraButtons.map((buttonConfig, index) => (
                  <ButtonWithConfirmation key={index} {...buttonConfig} />
                ))}
              </Box>
            )}
            <Box className={"button-button-ribbon-node-container"}>
              <Box className={"node"}>{buttonRibbonNode}</Box>
            </Box>
            {conditionHasValue(saveEvent) && (
              <SaveButton
                sx={{
                  ml: 1,
                  mr: 1,
                  boxShadow: 2,
                  minWidth:
                    (saveOverrideLabel?.length ?? 0) > 5 ? "auto" : "64px",
                }}
                disabled={saveDisabled ?? false}
                onClick={() => saveEvent?.()}
                text={saveOverrideLabel ? saveOverrideLabel : "SAVE"}
                permissions={permissionsToSaveButton}
                name={"save-on-modal"}
                withConfirmation={saveButtonWithConfirmation}
                textOnConfirmation={saveButtonConfirmationText}
              />
            )}
            {showCloseButton && (
              <CancelButton
                onClick={() => {
                  cancelEvent ? cancelEvent?.() : _setOpen(false);
                }}
                onNoClick={() => cancelButtonOnNoClick?.()}
                textOnButton={showCancelTextButton ? "CANCEL" : "CLOSE"}
                withConfirmation={cancelButtonWithConfirmation}
                textOnConfirmation={cancelButtonConfirmationText}
                name={"cancel-on-modal"}
              />
            )}
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};

export default Modal;
