import { useRecoilValue } from "recoil";
import { usePermissions } from "../../hooks";
import { Col, Font, Heading3, Row } from "../TrueUI";
import InlineLink from "../TrueUI/Links/InlineLink";
import { globalOptions } from "../../GlobalAtoms";

const WelcomeText = () => {
  const localOptions = useRecoilValue(globalOptions);
  const userName = localOptions?.initOptions?.shortName;
  const hasClaimPermission = usePermissions([22]);

  return (
    <Row horizontalMargin="0px" horizontalGutter="0px">
      <Col breakpoints={{ sm: 12, md: 6, lg: 6, xl: 6 }} displayFlex={false}>
        <Heading3 textAlign="start" display="block" whiteSpace="normal">
          Welcome, {userName}!
        </Heading3>
        <Font
          secondaryFont
          textAlign={"start"}
          whiteSpace="normal"
          fontType="TITLE"
          display="block"
        >
          {"The policyholder portal is an easy-to-use tool to manage your "}
          <InlineLink permission={true}>coverage</InlineLink>
          {", submit and review "}
          <InlineLink permission={hasClaimPermission} href={"/claims"}>
            claims
          </InlineLink>
          {" and pay your "}
          <InlineLink permission={true} href={"/billing"}>
            bill
          </InlineLink>
          {" online. Need to send us something? Click "}
          <InlineLink permission={true} href={"/upload-files"}>
            here
          </InlineLink>
          {" to upload any documents."}
        </Font>
      </Col>
    </Row>
  );
};

export default WelcomeText;
