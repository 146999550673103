import { FC } from "react";
import { ClaimInformationDto } from "../../../dtos/claim-information-dto";
import { Col, Heading6, Input, InputDate, Row } from "../../TrueUI";
import { colWithNoMarginNorGutter } from "../../TrueUI/Grids/Col";
import Link from "../../TrueUI/Typography/Link";
import { isEmptyValue } from "../../../utilities/conditionalSupportFunctions";
import { rowWithNoMarginNorGutter } from "../../TrueUI/Grids/Row";

type ClaimInformationProps = {
  claimInformation?: ClaimInformationDto;
};

const ClaimInformation: FC<ClaimInformationProps> = ({ claimInformation }) => {
  return (
    <div>
      <Heading6>Claim Information</Heading6>
      <div style={{ marginLeft: "20px" }}>
        <Row {...rowWithNoMarginNorGutter}>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
          >
            <Input
              id="claim-number"
              name="claim-number"
              label="Claim Number"
              labelFontType="BOLD_CAPTION"
              value={claimInformation?.claimNumber}
              readOnly
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
          >
            <Input
              id="claim-status"
              name="claim-status"
              label="Claim Status"
              labelFontType="BOLD_CAPTION"
              value={claimInformation?.claimStatus}
              readOnly
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
          >
            <Input
              id="claim-type"
              name="claim-type"
              label="Claim Type"
              labelFontType="BOLD_CAPTION"
              value={claimInformation?.claimType}
              readOnly
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
          >
            <Input
              id="claim-litigated"
              name="claim-litigated"
              label="Litigated?"
              labelFontType="BOLD_CAPTION"
              value={claimInformation?.litigated ? "Yes" : "No"}
              readOnly
            />
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter}>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
          >
            <Input
              id="claim-claimant"
              name="claim-claimant"
              label="Claimant"
              labelFontType="BOLD_CAPTION"
              value={claimInformation?.claimantName}
              readOnly
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
          >
            <Input
              id="claim-gender"
              name="claim-gender"
              label="Gender"
              labelFontType="BOLD_CAPTION"
              value={claimInformation?.claimantGender}
              readOnly
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 6, lg: 6, xl: 6 }}
          >
            <Input
              id="claim-location"
              name="claim-location"
              label="Location"
              labelFontType="BOLD_CAPTION"
              value={claimInformation?.location}
              readOnly
            />
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter}>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
          >
            <InputDate
              id="claim-date-of-incident"
              name="claim-date-of-incident"
              label="Date of Incident"
              labelFontType="BOLD_CAPTION"
              value={claimInformation?.dateOfIncident}
              readOnly
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
          >
            <InputDate
              id="claim-date-opened"
              name="claim-date-opened"
              label="Date Opened"
              labelFontType="BOLD_CAPTION"
              value={claimInformation?.dateOpened}
              readOnly
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
          >
            <InputDate
              id="claim-date-closed"
              name="claim-date-closed"
              label="Date Closed"
              labelFontType="BOLD_CAPTION"
              value={claimInformation?.dateClosed}
              readOnly
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
          >
            {!isEmptyValue(claimInformation?.assigneeEmail) ? (
              <Link
                id="claim-claimant-email"
                name="claim-claimant-email"
                label="Examiner"
                labelFontType="BOLD_CAPTION"
                linkFontType="BODY"
                labelPosition="top"
                underline="always"
                href={`mailto:${claimInformation?.assigneeEmail}?Subject=RE: ${claimInformation?.claimNumber}`}
                displayValue={claimInformation?.assigneeName}
              />
            ) : (
              <Input
                id="claim-claimant-email"
                name="claim-claimant-email"
                label="Examiner"
                labelFontType="BOLD_CAPTION"
                value={claimInformation?.assigneeName}
                readOnly
              />
            )}
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter}>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
          >
            <Input
              id="claim-body-part"
              name="claim-body-part"
              label="Body Part"
              labelFontType="BOLD_CAPTION"
              value={claimInformation?.bodyPart}
              readOnly
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 3, lg: 3, xl: 3 }}
          >
            <Input
              id="claim-nature"
              name="claim-nature"
              label="Nature of Injury"
              labelFontType="BOLD_CAPTION"
              value={claimInformation?.natureOfInjury}
              readOnly
            />
          </Col>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 6, lg: 6, xl: 6 }}
          >
            <Input
              id="claim-cause"
              name="claim-cause"
              label="Cause of Injury"
              labelFontType="BOLD_CAPTION"
              value={claimInformation?.causeOfInjury}
              readOnly
            />
          </Col>
        </Row>
        <Row {...rowWithNoMarginNorGutter}>
          <Col
            {...colWithNoMarginNorGutter}
            breakpoints={{ md: 12, lg: 12, xl: 12 }}
          >
            <Input
              id="claim-description"
              name="claim-description"
              label="Incident Description"
              labelFontType="BOLD_CAPTION"
              value={claimInformation?.description}
              readOnly
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ClaimInformation;
