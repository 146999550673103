import { FC, useEffect, useState } from "react";

import ModalAchPaymentContent from "./ModalAchPaymentContent";
import { AchModalProps, defaultAchPaymentData } from "./ModalAchPaymentUtil";
import { AchPaymentConfigurationDto } from "../../../../dtos/ach-payment-configuration-dto";
import { AchPaymentDto } from "../../../../dtos/ach-payment-dto";
import { BillingTransactionDataDto } from "../../../../dtos/billing-transaction-data-dto";
import { useApiGet, useApiPost } from "../../../../hooks";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../utilities/apiFunctions";
import { Modal } from "../../../TrueUI";
import AccountAndBalanceSection from "../AccountAndBalance/AccountAndBalanceSection";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import { ProcessorTypeEnum } from "../../../../dtos/processor-type-enum";

const ModalAchPayment: FC<AchModalProps> = ({
  paymentModalConfiguration,
  setPaymentModalConfiguration,
  setUiid,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const insuredId = localOptions?.initOptions?.insuredId;
  const [achPaymentConfiguration, setAchPaymentConfiguration] =
    useState<AchPaymentConfigurationDto | null>(null);
  const [achFormData, setAchFormData] = useState<AchPaymentDto | null>(
    defaultAchPaymentData(insuredId ?? 0)
  );
  const [errorDetails, setErrorDetails] = useState<any>();
  const [refreshConfiguration, setRefreshConfiguration] = useState<string>("");
  const [billingTransactionData, setBillingTransactionData] = useState<
    null | BillingTransactionDataDto[]
  >();

  const { responseGet: responseGetConfig, dispatchGet: dispatchGetConfig } =
    useApiGet<AchPaymentConfigurationDto>(
      `api/BillingPayments/GetACHPaymentConfiguration?insuredId=${insuredId}`
    );

  useEffect(() => {
    if (paymentModalConfiguration.isOpenAchPaymentModal) {
      dispatchGetConfig();
    }
  }, [paymentModalConfiguration.isOpenAchPaymentModal]);

  useEffect(() => {
    setAchFormData({
      ...achFormData,
      billingTransactionDataList: billingTransactionData,
    });
  }, [billingTransactionData]);

  const {
    responsePost: responseSaveData,
    dispatchPost: dispatchSaveData,
    validatorErrorResponse,
  } = useApiPost<any>(`api/BillingPayments/SaveAchPayment`, achFormData);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse.errorDetails);
    }
    if (isAPITotallyCompleteNoContentResponse(responseSaveData)) {
      setPaymentModalConfiguration?.({
        ...paymentModalConfiguration,
        isOpenAchPaymentModal: false,
      });
      const data = defaultAchPaymentData(
        insuredId ?? 0,
        responseGetConfig?.responseData?.processorBankAccountId,
        responseGetConfig?.responseData?.insuredAccountList?.[0]?.intValue
      );
      setAchFormData(data);
      setUiid?.(crypto.randomUUID());
    }
  }, [responseSaveData]);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetConfig)) {
      setAchPaymentConfiguration(responseGetConfig?.responseData ?? null);
      setAchFormData(
        defaultAchPaymentData(
          insuredId ?? 0,
          responseGetConfig?.responseData?.processorBankAccountId,
          responseGetConfig?.responseData?.insuredAccountList?.[0]?.intValue
        )
      );
    }
  }, [responseGetConfig]);

  useEffect(() => {
    if (refreshConfiguration !== "") {
      dispatchGetConfig();
    }
  }, [refreshConfiguration]);

  const onSaveEvent = () => {
    setErrorDetails(null);
    dispatchSaveData();
  };

  const onCancelEvent = () => {
    setPaymentModalConfiguration?.({
      ...paymentModalConfiguration,
      isOpenAchPaymentModal: false,
    });
  };

  const onCloseEvent = () => {
    setErrorDetails(null);
    setAchFormData(
      defaultAchPaymentData(
        insuredId ?? 0,
        responseGetConfig?.responseData?.processorBankAccountId,
        responseGetConfig?.responseData?.insuredAccountList?.[0]?.intValue
      )
    );
  };

  return (
    <Modal
      id={"ach-payment"}
      title={"Schedule Bank Draft (ACH)"}
      open={paymentModalConfiguration.isOpenAchPaymentModal}
      showCloseButton={true}
      deleteEvent={undefined}
      showCancelTextButton={true}
      saveEvent={onSaveEvent}
      cancelEvent={onCancelEvent}
      closeEvent={onCloseEvent}
      saveOverrideLabel={"OK"}
      cancelButtonWithConfirmation
    >
      <ModalAchPaymentContent
        achFormData={achFormData}
        setAchFormData={setAchFormData}
        configuration={achPaymentConfiguration}
        errorMessages={errorDetails}
        insuredId={insuredId}
        setRefreshConfiguration={setRefreshConfiguration}
        dispatchGetConfig={dispatchGetConfig}
      />
      <AccountAndBalanceSection
        insuredId={insuredId}
        setBillingTransactionData={setBillingTransactionData}
        processorType={ProcessorTypeEnum.ACH}
        tranAccountOptions={
          responseGetConfig?.responseData?.tranAccountOptions ?? []
        }
      />
    </Modal>
  );
};

export default ModalAchPayment;
