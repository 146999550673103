import { AchPaymentConfigurationDto } from "../../../../dtos/ach-payment-configuration-dto";
import { AchPaymentDto } from "../../../../dtos/ach-payment-dto";
import {
  GettingDateWithoutTime,
  getCurrentOrNextBusinessDate,
} from "../../../../utilities/dateFunctions";
import { PaymentModalConfigurationProps } from "../PaymentSelector/PaymentSelectorTypes";

export type AchModalProps = {
  paymentModalConfiguration: PaymentModalConfigurationProps;
  setPaymentModalConfiguration?: (
    value: PaymentModalConfigurationProps
  ) => void;
  setUiid?: (value: string) => void;
};

export type AchFormProps = {
  setRefreshConfiguration: (value: string) => void;
  setAchFormData: (AchFormData: AchPaymentDto | null) => void;
  achFormData: AchPaymentDto | null;
  insuredId?: number;
  configuration?: AchPaymentConfigurationDto | null;
  errorMessages?: any;
  dispatchGetConfig?: () => void;
};

export const defaultAchPaymentData = (
  insuredId: number,
  defaultBankAccountId?: number | null,
  defaultInsuredAccountId?: number | null
) => {
  return {
    insuredId: insuredId,
    paymentDate: getCurrentOrNextBusinessDate(12),
    dateReceived: GettingDateWithoutTime(new Date()),
    billingTransactionDataList: [],
    bankAccountId: defaultBankAccountId,
    insuredAccountId: defaultInsuredAccountId,
  } as AchPaymentDto;
};
