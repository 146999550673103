import {
  getTagsAsCommaSeparatedString,
  hydrateData2,
} from "../components/TrueUI/Tables/tableFunctions";
import { BaseTableColumn } from "../dtos/base-table-column";
import { BaseTableInputType } from "../dtos/base-table-input-type";
import { DataTypeEnums } from "../dtos/data-type-enums";
import { FormattingDate } from "../utilities/dateFunctions";
import {
  isJsonString,
  splitPhoneNumberAndExtension,
} from "../utilities/stringFunctions";

export function useHydratedDataWithAlternateDisplayValueOrOptionsValue(
  tableInstance
) {
  const hydratedData = hydrateData2(
    tableInstance?.columns ?? [],
    tableInstance?.sortedAndFilteredDataWithOutPaginate ?? []
  );
  hydratedData.map((rowData) => {
    const rowColumnKeys = Object.keys(rowData);
    rowColumnKeys.forEach((rowColumnKey) => {
      const column = tableInstance?.columns.find(
        (column) => column.fieldName === rowColumnKey
      );
      switch (column?.type) {
        case BaseTableInputType.LINK:
          return getHydratedDataWithAlternateDisplayValue(
            column,
            rowData,
            rowColumnKey
          );
        case BaseTableInputType.SELECT:
        case BaseTableInputType.TAGS:
          return getHydratedDataWithOptionsValue(column, rowData, rowColumnKey);
        case BaseTableInputType.PHONE_AND_EXTENSION:
          return getHydratedDataWithFormattedPhoneNumber(rowData, rowColumnKey);
        case BaseTableInputType.DATE_PICKER:
          return getFormattedDate(column, rowData, rowColumnKey);
        default:
          return rowData;
      }
    });
    return rowData;
  });
  return hydratedData;
}

const getFormattedDate = (column, rowData, rowColumnKey) => {
  if (column?.defaultValueType === DataTypeEnums.DATE) {
    rowData[rowColumnKey] = `${FormattingDate(rowData[rowColumnKey])}`;
  }
  return rowData;
};

const getHydratedDataWithFormattedPhoneNumber = (rowData, rowColumnKey) => {
  const match = splitPhoneNumberAndExtension(rowData[rowColumnKey]);
  const phoneNumber = match?.[1] ?? "";
  const extension = match?.[2] ? `-${match?.[2]}` : "";
  rowData[rowColumnKey] = `${phoneNumber}${extension}`;
  return rowData;
};

const getHydratedDataWithAlternateDisplayValue = (
  column: BaseTableColumn,
  rowData,
  rowColumnKey
) => {
  if (
    column?.alternateDisplayValues !== null &&
    column?.alternateDisplayValues !== undefined &&
    column?.alternateDisplayValues?.length > 0
  ) {
    const columnAlternateValues = column?.alternateDisplayValues?.find(
      (x) => x.guid === rowData[rowColumnKey]
    );
    rowData[rowColumnKey] = columnAlternateValues?.displayValuesOrFieldNames[0];
  }
  return rowData;
};

const getHydratedDataWithOptionsValue = (
  column: BaseTableColumn,
  rowData,
  rowColumnKey
) => {
  if (
    column?.displayOptions !== null &&
    column?.displayOptions !== undefined &&
    (column?.displayOptions?.length ?? 0) > 0
  ) {
    if (isJsonString(rowData[rowColumnKey])) {
      rowData[rowColumnKey] = getTagsAsCommaSeparatedString(
        rowData[rowColumnKey],
        column?.displayOptions
      );
    } else {
      const columnOptionValues = column?.displayOptions?.find(
        (x) => x.value === rowData[rowColumnKey]
      );

      rowData[rowColumnKey] = columnOptionValues?.text ?? "";
    }
  }
  return rowData;
};
