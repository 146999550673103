import { useEffect, useState } from "react";
import {
  RecoilState,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import { useFileRequestInstance } from "../../../../hooks";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { cellManagerCells, tableInstanceAtomFamily2 } from "../TableAtoms";
import {
  DELETED_COLUMN_FIELD_NAME,
  ROW_KEY_COLUMN_FIELD_NAME,
} from "../TableConstants";
import {
  add,
  addMultiple,
  addRowKeysWithOutRepeat,
  addRowKeyWithOutRepeat,
  castToDataType,
  castToRequestedInternalColumnsDataType,
  findRowByKey,
  flagRowAsDeleted,
  flagRowsAsDeleted,
  getColumnByColumnIndex,
  getDataOfRowKeys,
  getDataWithoutDeletedRows,
  getSafeRowKey,
  hydrateData2,
  hydrateDataSingle,
  hydrateDataWithRequestedInternalColumns,
  OverridableInternalColumnValueProps,
  stringifyAndDehydrateDataObject,
  stringifyHydratedDataObject,
  updateDataByRowKey,
  updateInternalColumnValuesByConfiguration,
  upsertDataRows,
  upsertRowData,
} from "../tableFunctions";
import {
  HydratedData,
  TableDataItem,
  TableInstanceType2,
} from "./TableProperties";

const BaseTable2EventListenerManager = (props) => {
  // TODO - move listener events here
  const [newRowData, setNewRowData] = useState<any>(null);
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(props.uiid) as RecoilState<TableInstanceType2>
  );

  const { downloadMultipleFiles } = useFileRequestInstance(true);

  const setTableInstance = useRecoilCallback(
    ({ snapshot, set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        const instance =
          snapshot.getLoadable(tableInstanceAtomFamily2(props.uiid)).contents ??
          null;
        set(tableInstanceAtomFamily2(props.uiid), {
          ...instance,
          ...newValueTableInstance,
          uiid: props.uiid,
        });
      },
    []
  );

  useEffect(() => {
    if ((tableInstance?.changedData.length ?? 0) > 0) {
      const castedData = castToRequestedInternalColumnsDataType(
        tableInstance?.columns ?? [],
        [DELETED_COLUMN_FIELD_NAME, ROW_KEY_COLUMN_FIELD_NAME],
        tableInstance?.changedData ?? []
      );

      const hydratedChangedData = hydrateDataWithRequestedInternalColumns(
        tableInstance?.columns ?? [],
        [DELETED_COLUMN_FIELD_NAME, ROW_KEY_COLUMN_FIELD_NAME],
        castedData ?? []
      );

      // the onChangeDataListener is also configured for multi table data since that data is different in structure. see MultiCollapse for implementation details.
      if (tableInstance.tableType !== "multi") {
        tableInstance?.events?.onChangeDataListener?.(hydratedChangedData);
      }
    }
  }, [tableInstance?.changedData]);

  const [currentUpdateRow, setCurrentUpdateRow] = useState<any>(null);

  const [cells, setCells] = useRecoilState(
    cellManagerCells(currentUpdateRow?.rowKey ?? "")
  );

  useEffect(() => {
    if (currentUpdateRow !== null && cells !== null) {
      const updatedCells = cells.map((c) => {
        return {
          ...c,
          currentValue:
            currentUpdateRow.hydratedData[c.column.fieldName ?? ""] ?? "",
        };
      });

      setCells(updatedCells);
    }
  }, [currentUpdateRow]);

  useEffect(() => {
    if (tableInstance?._accessors?._addRow) {
      const hydratedAddedRowData =
        tableInstance?._accessors?._addRow.hydratedData;

      const dehydratedNewRow = conditionHasValue(hydratedAddedRowData)
        ? stringifyAndDehydrateDataObject(hydratedAddedRowData)
        : [];

      const newRow = add(
        tableInstance?.columns ?? [],
        tableInstance?.data ?? [],
        tableInstance?.changedData ?? [],
        dehydratedNewRow
      );

      const overridableConfiguration = {
        indicationType:
          tableInstance.advancedOptions?.indicationColumnConfiguration
            ?.indicationType,
        optionType:
          tableInstance.advancedOptions?.optionsColumnConfiguration?.optionType,
      } as OverridableInternalColumnValueProps;

      const updateInternalOverridableColumnValues =
        updateInternalColumnValuesByConfiguration(
          tableInstance.columns,
          newRow.updatedTableData,
          overridableConfiguration
        );

      const allUpdatedRowKeys =
        newRow.updatedTableData.map((d) => getSafeRowKey(d)) ?? [];

      setNewRowData({
        data: updateInternalOverridableColumnValues ?? [],
        changedData: newRow.updatedTableChangedData ?? [],
        allRowKeys: allUpdatedRowKeys,
        selectedEditRows:
          tableInstance?._accessors?._addRow?.isEditableAfterAdd === true
            ? addRowKeyWithOutRepeat(
                tableInstance?.selectedEditRows ?? [],
                newRow.newRowKey
              )
            : null,
        toggleEditModeState: true,
        _newestRowKey: newRow.newRowKey,
        _requestingCellManagerRowKeys: addRowKeyWithOutRepeat(
          tableInstance?._requestingCellManagerRowKeys ?? [],
          newRow.newRowKey
        ),
        _recentlyAddedRowKey: addRowKeyWithOutRepeat(
          tableInstance?._recentlyAddedRowKey ?? [],
          newRow.newRowKey
        ),
        _accessors: { ...tableInstance._accessors, _addRow: null },
      } as any);
    }
  }, [tableInstance?._accessors?._addRow]);

  useEffect(() => {
    if (newRowData !== null) {
      setTableInstance(newRowData);
      setNewRowData(null);
    }
  }, [newRowData]);

  // useEffect(() => {}, [tableInstance.data]);

  useEffect(() => {
    if (
      tableInstance?._accessors?._updateRow !== undefined &&
      tableInstance?._accessors?._updateRow !== null
    ) {
      // TODO - create a pipeline that executes onComputeInit values againest updated values

      const safeString = stringifyHydratedDataObject(
        tableInstance?._accessors?._updateRow.hydratedData
      );

      const updatedData = updateDataByRowKey(
        tableInstance?._accessors?._updateRow.rowKey,
        tableInstance.columns,
        tableInstance.data,
        safeString
      );

      const updatedRow = findRowByKey(
        tableInstance?._accessors?._updateRow?.rowKey ?? "",
        updatedData
      );

      const updatedChangedData = upsertRowData(
        updatedRow,
        tableInstance.changedData
      );

      const sortedAndFitleredChangedData = upsertRowData(
        updatedRow,
        tableInstance.sortedAndFilteredData
      );

      // TODO - add support for 'allRowKeys' prop
      setTableInstance({
        ...tableInstance,
        data: updatedData,
        changedData: updatedChangedData,
        sortedAndFilteredData: sortedAndFitleredChangedData,
        _recentlyUpdatedData: [updatedRow], // Currently we only allow one update at a time but I made this an array in the event we update this to support multiple updates (hope not).
      });
      setCurrentUpdateRow(tableInstance?._accessors?._updateRow);
    }
  }, [tableInstance?._accessors?._updateRow]);

  const mergeDeletedHydratedDataWithFlatData = (
    row: TableDataItem,
    hydratedData: HydratedData
  ): string[] =>
    row.map((v, i) => {
      const foundColumn = getColumnByColumnIndex(i, tableInstance.columns);
      const valueFromHydratedData = hydratedData[foundColumn?.fieldName ?? ""];

      if (
        valueFromHydratedData === undefined ||
        valueFromHydratedData === null
      ) {
        return v;
      }

      return valueFromHydratedData;
    });

  useEffect(() => {
    if (conditionHasValue(tableInstance?._accessors?._deleteRow)) {
      const foundRow = findRowByKey(
        tableInstance?._accessors?._deleteRow?.rowKey ?? "",
        tableInstance.data
      );

      const stringSafeHydratedData = stringifyHydratedDataObject(
        tableInstance?._accessors?._deleteRow?.hydratedData
      );

      const mergedResults = mergeDeletedHydratedDataWithFlatData(
        foundRow,
        stringSafeHydratedData
      );

      const updatedDataWithFlag = flagRowAsDeleted(
        tableInstance.columns,
        mergedResults
      );

      const updatedData = upsertDataRows(
        [updatedDataWithFlag],
        tableInstance?.data ?? []
      );

      const updatedChangedData = upsertDataRows(
        [updatedDataWithFlag],
        tableInstance?.changedData ?? []
      );

      const updatedDeletedData = upsertDataRows(
        [updatedDataWithFlag],
        tableInstance?.deletedData ?? []
      );

      setTableInstance({
        ...tableInstance,
        changedData: updatedChangedData,
        data: updatedData,
        deletedData: updatedDeletedData,
      });

      tableInstance.events?.onDeleteRow?.({
        rowKey: tableInstance._accessors?._deleteRow?.rowKey ?? "",
        hydratedRow: hydrateDataSingle(
          tableInstance?.columns ?? [],
          updatedDataWithFlag ?? []
        ),
      });
    }
  }, [tableInstance?._accessors?._deleteRow]);

  useEffect(() => {
    if (
      tableInstance._initalInProcessComputesQueueStarted === true &&
      tableInstance._inProcessComputesQueue.length === 0
    ) {
      const updatedData = getDataWithoutDeletedRows(
        tableInstance?.data,
        tableInstance.columns
      );

      tableInstance?.events?.onComputeFinish?.(
        updatedData ?? [],
        tableInstance?.columns ?? []
      );

      setTableInstance({
        ...tableInstance,
        _lastOriginComputeChainCellKey: null,
        _initalInProcessComputesQueueStarted: false,
      });
    }
  }, [tableInstance._inProcessComputesQueue]);

  useEffect(() => {
    if (tableInstance?.advancedOptions?.tableErrors) {
      setTableInstance({
        ...(tableInstance as TableInstanceType2),
        validationErrors: tableInstance?.advancedOptions?.tableErrors,
        isSave: false,
      });
      tableInstance?.events?.onError?.(
        tableInstance?.advancedOptions?.tableErrors
      );
    }
  }, [tableInstance?.advancedOptions?.tableErrors]);

  useEffect(() => {
    if (conditionHasValue(tableInstance?._accessors?._addMultipleRows)) {
      const newRows = addMultiple(
        tableInstance?.columns ?? [],
        tableInstance?.data ?? [],
        tableInstance?.changedData ?? [],
        tableInstance?._accessors?._addMultipleRows?.rows ?? []
      );

      const overridableConfiguration = {
        indicationType:
          tableInstance.advancedOptions?.indicationColumnConfiguration
            ?.indicationType,
        optionType:
          tableInstance.advancedOptions?.optionsColumnConfiguration?.optionType,
      } as OverridableInternalColumnValueProps;

      const updateInternalOverridableColumnValues =
        updateInternalColumnValuesByConfiguration(
          tableInstance.columns,
          newRows.updatedTableData,
          overridableConfiguration
        );

      const allUpdatedRowKeys =
        newRows.updatedTableData.map((d) => getSafeRowKey(d)) ?? [];

      setNewRowData({
        ...tableInstance,
        data: updateInternalOverridableColumnValues ?? [],
        changedData: newRows.updatedTableChangedData ?? [],
        allRowKeys: allUpdatedRowKeys,
        selectedEditRows:
          tableInstance?._accessors?._addRow?.isEditableAfterAdd === true
            ? addRowKeysWithOutRepeat(
                tableInstance?.selectedEditRows ?? [],
                newRows.rowKeys
              )
            : null,
        toggleEditModeState: true,
        selectedRows: [],
        allSelectedRows: false,
        _requestingCellManagerRowKeys: addRowKeysWithOutRepeat(
          tableInstance?._requestingCellManagerRowKeys ?? [],
          newRows.rowKeys
        ),
        _accessors: { ...tableInstance._accessors, _addMultipleRows: null },
      });
    }
  }, [tableInstance?._accessors?._addMultipleRows]);

  useEffect(() => {
    if (conditionHasValue(tableInstance?._accessors?._updateMultipleRows)) {
      const rowKeys =
        tableInstance?._accessors?._updateMultipleRows?.rowsKeys ?? [];
      setNewRowData({
        ...(tableInstance as TableInstanceType2),
        selectedEditRows: addRowKeysWithOutRepeat(
          tableInstance?.selectedEditRows ?? [],
          rowKeys
        ),
        toggleEditModeState: true,
        allSelectedRows: false,
        selectedRows: [],
        _requestingCellManagerRowKeys: addRowKeysWithOutRepeat(
          tableInstance?._requestingCellManagerRowKeys ?? [],
          rowKeys
        ),
      });
    }
  }, [tableInstance?._accessors?._updateMultipleRows]);

  useEffect(() => {
    if (conditionHasValue(tableInstance?._accessors?._deleteMultipleRows)) {
      const fullDataRows = getDataOfRowKeys(
        tableInstance.data,
        tableInstance?._accessors?._deleteMultipleRows?.rowsKeys ?? []
      );

      const updatedDataWithFlag = flagRowsAsDeleted(
        tableInstance.columns,
        fullDataRows
      );

      const updatedData = upsertDataRows(
        [...updatedDataWithFlag],
        tableInstance?.data ?? []
      );

      const updatedChangedData = upsertDataRows(
        [...updatedDataWithFlag],
        tableInstance?.changedData ?? []
      );

      const updatedDeletedData = upsertDataRows(
        [...updatedDataWithFlag],
        tableInstance?.deletedData ?? []
      );

      setTableInstance({
        ...tableInstance,
        changedData: updatedChangedData,
        data: updatedData,
        deletedData: updatedDeletedData,
        selectedRows: [],
        allSelectedRows: false,
      });
    }
  }, [tableInstance?._accessors?._deleteMultipleRows]);

  useEffect(() => {
    if (conditionHasValue(tableInstance?._accessors?._downloadMultipleRows)) {
      const downloadColumn = tableInstance?.columns?.find(
        (column) => column?.type === BaseTableInputType?.DOWNLOAD_LINK
      );

      if (conditionHasValue(downloadColumn)) {
        const fullDataRows = getDataOfRowKeys(
          tableInstance.data,
          tableInstance?._accessors?._downloadMultipleRows?.rowsKeys ?? []
        );

        const fileNames = fullDataRows?.map((row) => {
          const guid = row?.[downloadColumn?._columnIndex ?? 0];
          const alternatives = downloadColumn?.alternateDisplayValues?.find(
            (x) => x.guid === guid
          );
          const fileName =
            alternatives?.displayValuesOrFieldNames[1] ??
            alternatives?.displayValuesOrFieldNames[0] ??
            "";
          return fileName;
        });
        downloadMultipleFiles(fileNames);
      }

      setTableInstance({
        ...tableInstance,
        selectedRows: [],
        allSelectedRows: false,
      });
    }
  }, [tableInstance?._accessors?._downloadMultipleRows]);

  useEffect(() => {
    if ((tableInstance?.selectedRows?.length ?? 0) > 0) {
      const fullDataRows = getDataOfRowKeys(
        tableInstance.data,
        tableInstance?.selectedRows ?? []
      );
      const castedUpdatedData = castToDataType(
        tableInstance?.columns ?? [],
        fullDataRows ?? []
      );

      const data = hydrateData2(
        tableInstance?.columns ?? [],
        castedUpdatedData
      ).map((row) => {
        return { ...row };
      });
      tableInstance?.events?.onCheckClick?.(data);
    } else {
      tableInstance?.events?.onCheckClick?.([]);
    }
  }, [tableInstance?.selectedRows]);

  return null;
};

export default BaseTable2EventListenerManager;
