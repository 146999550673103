import { FC, useEffect, useState } from "react";
import { IncidentInformationPage } from "../../dtos/incident-information-page";
import { IncidentStatusEnums } from "../../dtos/incident-status-enums";
import { useApiGet } from "../../hooks";
import { addQueryStrings, getQueryValue } from "../../hooks/useURL";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";
import { conditionHasValue } from "../../utilities/conditionalSupportFunctions";
import {
  INCIDENT_ID,
  INCIDENT_STEPPER_SECTION,
} from "../../utilities/queryStringsHash";
import { Heading6 } from "../TrueUI";
import PortalPageContainer from "../TrueUI/Containers/PortalPageContainer";
import { ProgressStepper } from "../TrueUI/Steppers";
import styles from "./IncidentStyles.module.css";
import {
  AddNewIncidentProps,
  claimIncidentStateProps,
  getSectionDotNames,
  getSectionDots,
  getValue,
  hasActivePolicy,
} from "./AddNewIncidentUtils";
import IncidentFields from "./IncidentFields";

const Incident: FC<AddNewIncidentProps> = ({ incidentId }) => {
  const insuredId = 4392; //  TODO: Re-review to update the correct insuredId
  const [claimIncidentUI, setClaimIncidentUI] =
    useState<claimIncidentStateProps>();
  const [readOnlyMode, setReadOnlyMode] = useState<boolean>(false);
  const incidentQV = getQueryValue(INCIDENT_ID);
  const _incidentId = incidentQV != "" ? incidentQV : incidentId ?? -1;
  const stepperSectionTmp = getQueryValue(INCIDENT_STEPPER_SECTION);
  const stepperSection =
    stepperSectionTmp !== "" ? parseInt(stepperSectionTmp) : 0;

  const { responseGet: getIncidentResponse, dispatchGet: dispatchGetIncident } =
    useApiGet<IncidentInformationPage>(
      `api/PortalIncident/GetIncidentInformationByIncidentId?incidentId=${_incidentId}`
    );

  const onClickDot = (clickedDot: number) => {
    setClaimIncidentUI({
      ...claimIncidentUI,
      activeSection: clickedDot,
    });
  };

  const setStateExistingIncident = () => {
    const responseData = getIncidentResponse?.axiosResponse?.data;
    setClaimIncidentUI({
      ...claimIncidentUI,
      insuredInfo: responseData?.insured,
      locations: responseData?.insuredAddresses,
      policies: responseData?.policies ?? [],
      hasPolicy: hasActivePolicy(
        getValue(claimIncidentUI?.claimIncident?.incident?.injuryDateTime),
        getValue(responseData?.policies)
      ),
      claimIncident: responseData?.claimIncident,
      stateReport: responseData?.stateReportPage,
      sectionDots: getSectionDots(
        responseData?.stateReportPage?.stateReportFields
      ),
      jurisdiction: null,
      activeSection: stepperSection,
      lastSavedSection: stepperSection,
    });
    setReadOnlyMode(
      responseData?.claimIncident?.incident?.incidentStatus !==
        IncidentStatusEnums.IN_PROGRESS
    );
  };

  const setStateNewIncident = () => {
    const responseData = getIncidentResponse?.axiosResponse?.data;
    setClaimIncidentUI({
      ...claimIncidentUI,
      claimIncident: {
        ...claimIncidentUI?.claimIncident,
        incident: {
          ...claimIncidentUI?.claimIncident?.incident,
          insuredID: Number(responseData?.insured.insuredId),
          injuryAddressID: Number(responseData?.insured.primaryAddressId),
          stateReportID:
            claimIncidentUI?.stateReport?.stateReport?.stateReportId,
        },
      },
      insuredInfo: responseData?.insured,
      jurisdiction: responseData?.insured?.addressInformation?.state,
      locations: responseData?.insuredAddresses,
      policies: responseData?.policies ?? [],
      hasPolicy: hasActivePolicy(
        getValue(claimIncidentUI?.claimIncident?.incident?.injuryDateTime),
        getValue(responseData?.policies)
      ),
      activeSection: stepperSection,
      lastSavedSection: stepperSection,
    });
  };

  useEffect(() => {
    dispatchGetIncident();
  }, []);

  useEffect(() => {
    if (conditionHasValue(claimIncidentUI?.activeSection)) {
      addQueryStrings([
        {
          nameOfHash: INCIDENT_STEPPER_SECTION,
          valueOfHash: claimIncidentUI?.activeSection ?? 0,
        },
      ]);
    }
  }, [claimIncidentUI?.activeSection]);

  useEffect(() => {
    if (isAPITotallyComplete(getIncidentResponse)) {
      if (_incidentId != -1) {
        setStateExistingIncident();
      } else {
        setStateNewIncident();
      }
    }
  }, [getIncidentResponse]);

  return (
    <PortalPageContainer title={"Claims"} childrenContainerHeight="100%">
      <div className={styles.add_new_incident_container}>
        <div className={styles.header_wrapper}>
          <Heading6>First Report of Injury</Heading6>
        </div>
        <div className={styles.add_incident_fields_container}>
          <div className={styles.left_incident_wrapper}>
            <ProgressStepper
              dots={getSectionDotNames(
                getSectionDots(claimIncidentUI?.stateReport?.stateReportFields)
              )}
              active={claimIncidentUI?.activeSection ?? 0}
              lastSavedSection={claimIncidentUI?.lastSavedSection ?? -1}
              allDotsAvailable={incidentId ? true : false}
              onClickDot={onClickDot}
            />
          </div>
          <div className={styles.right_incident_wrapper}>
            <IncidentFields
              readOnlyMode={readOnlyMode}
              insuredId={insuredId}
              claimIncidentUI={claimIncidentUI}
              setClaimIncidentUI={setClaimIncidentUI}
            />
          </div>
        </div>
      </div>
    </PortalPageContainer>
  );
};

export default Incident;
