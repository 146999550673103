import { VerticalAlignCenter } from "@mui/icons-material";
import { Box, IconButton, TableSortLabel } from "@mui/material";
import { MouseEvent, useEffect, useState } from "react";
import { useRecoilCallback, useRecoilValue, RecoilState } from "recoil";
// import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import { ExpandIcon } from "../../Icons/CustomIcons";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import { BaseTableOrder, TableInstanceType2 } from "./TableProperties";
import { visuallyHidden } from "@mui/utils";
import MoreVertIcon from "@mui/icons-material/MoreVert";

import { dynamicSort } from "../../../../utilities/arrayFunctions";
import { OPTION_COLUMN_FIELD_NAME } from "../TableConstants";
import {
  getCurrentlySelectedRowKeys,
  getWidthOfCells,
} from "../tableFunctions";
import { TableHead, TableHeader, TableRow } from "../BaseTableStructure";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import Switch from "../../Checkbox/Switch";

const BaseTable2Header = ({ uiid }) => {
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(uiid) as RecoilState<TableInstanceType2>
  );

  const setTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        set(tableInstanceAtomFamily2(uiid), {
          ...tableInstance,
          ...newValueTableInstance,
          uiid: uiid,
        });
      },
    []
  );

  const getHeaderType = () => {
    switch (
      tableInstance?.advancedOptions?.headerType ??
      tableInstance?.tableType
    ) {
      case "compact":
        return "compact";
      default:
        return "standard";
    }
  };

  const changeOrder = (order: BaseTableOrder) =>
    order === "desc" ? "asc" : "desc";

  const onRequestSort = (
    _event: MouseEvent<unknown, globalThis.MouseEvent>,
    property: any
  ) => {
    const isSameColumn = (tableInstance?.orderBy ?? property) === property;
    setTableInstance({
      ...(tableInstance as TableInstanceType2),
      order: isSameColumn
        ? changeOrder(tableInstance?.order ?? "desc")
        : "desc",
      orderBy: property,
    });
  };

  const createSortHandler =
    (property: any) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  const columnGap = (gapType: "initial" | "final") => {
    if (
      tableInstance?.tableType === "multi" ||
      tableInstance?.tableType === "collapse"
    ) {
      return (
        <TableHeader className={`cell_header_${gapType}_gap`}>
          &nbsp;
        </TableHeader>
      );
    }
    return null;
  };

  const collapseIcon = () => {
    if (
      tableInstance?.tableType === "multi" ||
      tableInstance?.tableType === "collapse"
    ) {
      return (
        <TableHeader className="multi_cell_collapse" width={"35px"}>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() =>
              setTableInstance({
                ...tableInstance,
                toggleMultiTableRowCollapse:
                  !tableInstance?.toggleMultiTableRowCollapse,
              })
            }
          >
            {tableInstance?.toggleMultiTableRowCollapse ? (
              <VerticalAlignCenter />
            ) : (
              <ExpandIcon className={"multi_expand_icon"} />
            )}
          </IconButton>
        </TableHeader>
      );
    }
    return null;
  };

  const [columnHeaders, setColumnHeaders] = useState<any[]>([]);

  useEffect(() => {
    if (tableInstance.columns.length > 0) {
      const clonedColumns = [...tableInstance?.columns];
      const sortedColumns = clonedColumns.sort((a, b) =>
        dynamicSort(a, b, "_headerSortOrder")
      );
      setColumnHeaders(sortedColumns);
    }
  }, [tableInstance.columns]);

  const displayColumnByType = (column: any) => {
    if (column.type === BaseTableInputType.OPTIONS) {
      return null;
    }
    if (column.type === BaseTableInputType.MULTI_SELECTION) {
      return (
        <Switch
          control="checkbox"
          isChecked={tableInstance?.allSelectedRows}
          readOnly={tableInstance?.toggleEditModeState}
          onChange={(value) => {
            const isChecked = value?.target?.checked ?? false;

            setTableInstance({
              ...tableInstance,
              allSelectedRows: isChecked,
              selectedRows: isChecked
                ? getCurrentlySelectedRowKeys(
                    tableInstance?.sortedAndFilteredData
                  )
                : [],
            });
          }}
        />
      );
    }

    return column.displayName ?? "NO_COLUMN_DISPLAY_NAME_FOUND";
  };

  return (
    <>
      {tableInstance.advancedOptions?.hideHeader !== true ? (
        <TableHead>
          <TableRow>
            <>
              {columnGap("initial")}
              {columnHeaders.map((column, alternativeIndex) => {
                const columnType = displayColumnByType(column);
                const isOptions = column.type === BaseTableInputType.OPTIONS;
                const isMultiSelection =
                  column.type === BaseTableInputType.MULTI_SELECTION;
                const isIndication =
                  column.type === BaseTableInputType.INDICATION;

                return (
                  (column?.isHidden === null || column?.isHidden === false) && (
                    <TableHeader
                      key={crypto.randomUUID()}
                      width={getWidthOfCells(
                        tableInstance?.columnOptions ?? [],
                        tableInstance?.columns,
                        column.fieldName,
                        isOptions,
                        isMultiSelection,
                        isIndication
                      )}
                      className={getHeaderType()}
                    >
                      {tableInstance?.advancedOptions?.disableOrderBy ||
                      !column.isSortable ? (
                        columnType
                      ) : (
                        <>
                          <TableSortLabel
                            active={
                              tableInstance?.orderBy === column._columnIndex
                            }
                            direction={
                              tableInstance?.orderBy === column._columnIndex
                                ? tableInstance?.order
                                : "asc"
                            }
                            onClick={createSortHandler(
                              column._columnIndex ?? alternativeIndex
                            )}
                            className={
                              column.fieldName === OPTION_COLUMN_FIELD_NAME
                                ? "options-column"
                                : ""
                            }
                          >
                            <span className="column-name">
                              {column?.displayName ?? "NO_COLUMN_NAME_FOUND"}
                            </span>
                            {tableInstance?.orderBy === column._columnIndex ? (
                              <Box component="span" sx={visuallyHidden}>
                                {tableInstance?.order === "desc"
                                  ? "sorted descending"
                                  : "sorted ascending"}
                              </Box>
                            ) : null}
                          </TableSortLabel>
                        </>
                      )}
                      {getHeaderType() === "compact" && (
                        <MoreVertIcon
                          fontSize="small"
                          onClick={() => alert("Filters in a next PR")}
                          sx={{ cursor: "pointer" }}
                        />
                      )}
                    </TableHeader>
                  )
                );
              })}
              {tableInstance.advancedOptions?.tableStyle?.gapColumnAtTheEnd ===
                true && columnGap("final")}
              {collapseIcon()}
            </>
          </TableRow>
        </TableHead>
      ) : null}
    </>
  );
};
export default BaseTable2Header;
