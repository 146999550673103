import { FC, useEffect } from "react";
import { BillingHeaderDto } from "../../dtos/billing-header-dto";
import { useApiGet } from "../../hooks";
import { Button, Divider, Font, Input, Title } from "../TrueUI";
import style from "./Billing.module.css";
import InlineLink from "../TrueUI/Links/InlineLink";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../GlobalAtoms";

type CurrentBillingHeaderProps = {
  setIsModalPaymentsOpen?: (isOpen: boolean) => void;
};

const CurrentBillingHeader: FC<CurrentBillingHeaderProps> = ({
  setIsModalPaymentsOpen,
}) => {
  const { responseGet, dispatchGet } = useApiGet<BillingHeaderDto>(
    `api/Billing/GetBillingHeaderInformation`
  );

  useEffect(() => {
    dispatchGet();
  }, []);

  const currentBilling = responseGet?.axiosResponse?.data;

  const localOptions = useRecoilValue(globalOptions);
  const showPayOnline =
    (localOptions?.initOptions?.programInfo.hasAch ?? false) ||
    (localOptions?.initOptions?.programInfo.hasCreditCard ?? false);

  return (
    <div>
      <div className={style.container_billing_header_information}>
        <Input
          inputFontType="TITLE"
          labelFontType="TITLE"
          labelPosition="start"
          label={"Current Amount Due:"}
          value={currentBilling?.currentAmountDue}
          type={"fixedCurrency"}
          readOnly
          id={"current-amount-due"}
          name={"current-amount-due"}
          inputWidth="300px"
        />
        <Input
          inputFontType="TITLE"
          labelFontType="TITLE"
          labelPosition="start"
          label={"Past Due Amount:"}
          value={currentBilling?.pastDueAmount}
          type={"fixedCurrency"}
          readOnly
          id={"past-due-amount"}
          name={"past-due-amount"}
          inputWidth="300px"
        />
        <Input
          inputFontType="TITLE"
          labelFontType="TITLE"
          labelPosition="start"
          label={"Paid to Date for Current Policy:"}
          value={currentBilling?.paidToDateDueAmount}
          type={"fixedCurrency"}
          readOnly
          id={"paid-to-date-current-policy"}
          name={"paid-to-date-current-policy"}
          inputWidth="360px"
        />
        {showPayOnline && (
          <Button
            onClick={() => {
              setIsModalPaymentsOpen?.(true);
            }}
          >
            Make a payment
          </Button>
        )}
      </div>
      <Divider height={"5px"} />
      {currentBilling?.isPendingReport && (
        <div>
          <Title isErrorFont textAlign="start">
            Your {currentBilling?.payrollReportMonth} payroll report is{" "}
            {currentBilling?.payrollReportMessage}.{" "}
          </Title>
          <Font whiteSpace="normal">
            Click <InlineLink permission>here</InlineLink> to complete it.
          </Font>
        </div>
      )}
    </div>
  );
};

export default CurrentBillingHeader;
