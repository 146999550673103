import Font from "../Typography/Font";

const InlineLink = ({
  href = "#",
  permission,
  children,
  fontType = "TITLE",
}) => {
  return permission ? (
    <a href={href} style={{ textDecoration: "none" }}>
      <Font
        primaryColor
        secondaryFont
        textAlign={"start"}
        whiteSpace="normal"
        fontType={fontType as any}
        display="inline"
      >
        {children}
      </Font>
    </a>
  ) : (
    <Font
      secondaryFont
      textAlign={"start"}
      whiteSpace="normal"
      fontType={fontType as any}
      display="inline"
    >
      {children}
    </Font>
  );
};

export default InlineLink;
