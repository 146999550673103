import { FC, useEffect } from "react";
import "./App.css";
import Layout from "./Layout";
import { PortalInitOptionsPage } from "./dtos/portal-init-options-page";
import { useApiGet } from "./hooks";
import { isAPITotallyComplete } from "./utilities/apiFunctions";
import { useRecoilState } from "recoil";
import { globalOptions } from "./GlobalAtoms";
import { useCSSVariableManager } from "./media/useCSSVariableManager";
import { conditionHasValue } from "./utilities/conditionalSupportFunctions";
import { Loading } from "./components/TrueUI";
import { refreshCachePeriodically } from "./utilities/localStorageFunctions";
import { UserTypeEnum } from "./dtos/user-type-enum";

const App: FC<any> = () => {
  const { responseGet, dispatchGet } = useApiGet<PortalInitOptionsPage>(
    "api/init/GetPortalInitOptions"
  );

  const [localOptions, setLocalOptions] = useRecoilState(globalOptions);

  const { initializeDefaultVariables } = useCSSVariableManager("App");

  useEffect(() => {
    initializeDefaultVariables();
  }, [localOptions.theme]);

  useEffect(() => {
    refreshCachePeriodically();
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setLocalOptions({ ...localOptions, ...responseGet?.responseData });
    }
  }, [responseGet]);

  const renderLayout = () => {
    const userType = responseGet.axiosResponse?.data?.initOptions.userType;
    switch (userType) {
      case UserTypeEnum.AGENCY_PORTAL:
        return <Layout isAgencyPortal />;
      case UserTypeEnum.PORTAL:
      default:
        return <Layout />;
    }
  };

  return (
    <div className="App">
      {conditionHasValue(responseGet.axiosResponse?.data) ? (
        renderLayout()
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default App;
