import { ThemeProvider } from "@emotion/react";
import { createTheme, CssBaseline } from "@mui/material";
import AppAuthenticator from "./AppAuthenticator";

const Initiator = () => {
  const lightTheme = createTheme({
    palette: {
      mode: "light",
    },
  });

  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <AppAuthenticator />
    </ThemeProvider>
  );
};

export default Initiator;
