import { useEffect } from "react";
import { useRecoilState } from "recoil";
import App from "./App";
import { hasAppAccess } from "./GlobalAtoms";
import {
  AuthenticatedTemplate,
  MsalAuthenticationTemplate,
} from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { isStillValid } from "./utilities/jwtFunctions";
import InsuredSelector from "./components/Auth/InsuredSelector";

const AppAuthenticator = () => {
  const [_hasAppAccess, setHasAppAccess] = useRecoilState(hasAppAccess);

  const listenForTokenRemoval = (storageEvent: StorageEvent) => {
    if (
      storageEvent.key === "true_portal_id" &&
      storageEvent.newValue === null
    ) {
      setHasAppAccess(false);
    } else {
      const isValid = isStillValid(storageEvent.newValue ?? "");

      if (isValid) {
        setHasAppAccess(true);
      } else {
        setHasAppAccess(false);
      }
    }
  };

  useEffect(function hasTrueIdToken() {
    const tokenValue = localStorage.getItem("true_portal_id");
    if (tokenValue !== null) setHasAppAccess(true);
    window.addEventListener("storage", (e) => listenForTokenRemoval(e));
  }, []);

  return (
    <>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
      ></MsalAuthenticationTemplate>
      <AuthenticatedTemplate>
        {_hasAppAccess ? <App /> : <InsuredSelector />}
      </AuthenticatedTemplate>
    </>
  );
};

export default AppAuthenticator;
