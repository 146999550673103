import { useRecoilValue } from "recoil";
import { globalOptions } from "../GlobalAtoms";
import { PermissionsEnums } from "../dtos/permissions-enums";

export function usePermissions(
  requiredPermissions: number[],
  debug_TestWithoutTrueAdmin?: boolean
): { hasPermission: boolean; isAuthenticated: boolean } {
  let hasPermission = false;
  const { initOptions } = useRecoilValue(globalOptions);
  const userPermissions = initOptions?.permissions ?? [];

  if (requiredPermissions.length > 0) {
    const availablePermissions = requiredPermissions.filter((permission) => {
      // We can optionally test a set of permissions without including a check for TRUE_ADMIN, just to confirm our passed in permissions works correctly.
      if (!debug_TestWithoutTrueAdmin) {
        const isAdmin = userPermissions.includes(PermissionsEnums.TRUE_ADMIN);

        if (isAdmin) {
          return true;
        }
      }

      return userPermissions.includes(permission);
    });
    hasPermission = availablePermissions.length > 0;
  } else {
    hasPermission = true; // If there are no requiredPermissions set, then anyone has access
  }

  return {
    hasPermission,
    isAuthenticated: true, //Set to true as not isAuthenticated is in initOptios
  };
}
