import { Theme } from "../../../media/themeTypes";

export const modalStyle = (
  height,
  width,
  minHeight,
  minWidth,
  maxHeight,
  maxWidth
) => ({
  height: height,
  width: width,
  minHeight: minHeight,
  minWidth: minWidth,
  maxHeight: maxHeight,
  maxWidth: maxWidth,
  zIndex: 100,
  "#draggable-dialog-title": {
    cursor: "move",
  },
});

export const modalPaperStyles = (theme: Theme, buttonRibbonNode) => ({
  border: 1,
  borderRadius: 0,
  borderColor: theme?.borderModalColor,
  background: theme?.primaryBackground,
  margin: 0,
  "#draggable-dialog-title": {
    background: theme?.contrastBackground,
    px: 2,
    py: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    ".MuiTypography-root, svg": {
      color: "#FFF",
    },
    button: {
      position: "relative",
      display: "block",
      right: "0",
      top: "0",
      fontSize: theme?.body?.size,
    },
  },
  ".MuiDialogContent-root": {
    p: "24px 24px 0 24px !important",
    background: theme?.primaryBackground,
  },
  ".MuiDialogActions-root": {
    background: theme?.primaryBackground,
    padding: 3,
    ".button-delete-container": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      width: buttonRibbonNode ? "auto" : "100%",
    },
    ".button-button-ribbon-node-container": {
      display: "flex",
      justifyContent: "flex-start",
      alignItems: "flex-end",
      width: buttonRibbonNode ? "100%" : "auto",
      ".node": {
        flexGrow: 1,
      },
    },
  },
});
