import WaitingInfo from "./WaitingInfo";

const AgencyDashboard = () => {
  return (
    <div style={{ marginTop: "5px" }}>
      <WaitingInfo />
    </div>
  );
};

export default AgencyDashboard;
