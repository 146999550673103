import { FC, useState } from "react";
import { BaseTable2, Collapse } from "../TrueUI";
import {
  BaseTable2Properties,
  DeleteRowParameters,
  OptionsContextActionParameterProperties,
} from "../TrueUI/Tables/BaseTable2/TableProperties";
import { useBaseTable } from "../../hooks/useBaseTable";
import { Launch } from "@mui/icons-material";
import { Box, IconButton } from "@mui/material";
import ScheduledTransactionModal, {
  ScheduledTransactionModalConfig,
} from "./Scheduled/TransactionModal/ScheduledTransactionModal";
import {
  BillingAccountProps,
  defaultBillingAccount,
} from "./Account/AccountModal/BillingAccountsUtil";
import BillingAccountModal from "./Account/AccountModal/BillingAccountModal";
import { FormattingDate } from "../../utilities/dateFunctions";
import { currencyFormat } from "../../utilities/currencyFunctions";
import { BillingScheduledTransactionDto } from "../../dtos/billing-scheduled-transaction-dto";

type ScheduledPaymentsProps = {
  insuredId: number;
  refreshTable: string;
};

const ScheduledPayments: FC<ScheduledPaymentsProps> = ({
  insuredId,
  refreshTable,
}) => {
  const [modalConfiguration, setModalConfiguration] =
    useState<ScheduledTransactionModalConfig>({
      isOpen: false,
    });

  const [billingAccount, setBillingAccount] = useState<BillingAccountProps>(
    defaultBillingAccount
  );
  const [refreshConfig, setRefreshConfig] = useState(false);

  const tableName = "billing-scheduled-payments-table";

  const onEditScheduledTransaction = (row: any) => {
    setModalConfiguration({
      isOpen: true,
      transactionId: row.TransactionId,
      insuredId: insuredId,
      rowData: row,
      rowKey: row._row_key,
    });
  };

  const onSaveSuccessful = (data: BillingScheduledTransactionDto) => {
    const updatedRow = {
      rowKey: modalConfiguration.rowKey ?? "",
      hydratedData: {
        ...modalConfiguration.rowData,
        ScheduledDate: FormattingDate(data.scheduledDate, "M/D/YYYY"),
        TransactionAmount: currencyFormat(data.transactionAmount, false, 2),
        Account: data.insuredAccountName,
        AccountType: data.accountType,
      },
    };
    hooks?.methods.updateRow(updatedRow);
  };

  const onDeleteSuccessful = () => {
    if (modalConfiguration.transactionId) {
      const deletedRow: DeleteRowParameters = {
        rowKey: modalConfiguration.rowKey ?? "",
        hydratedData: { ...modalConfiguration.rowData },
        deleteType: "hidden",
      };
      hooks?.methods?.deleteRow(deletedRow);
    }
  };

  const EditScheduledPayment = (
    _actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box>
        <IconButton
          aria-label="save"
          size="small"
          onClick={() => onEditScheduledTransaction(_actionOptions.row)}
        >
          <Launch fontSize="inherit" />
        </IconButton>
      </Box>
    );
  };

  const onOpenAddAccountModal = () => {
    setBillingAccount({
      ...billingAccount,
      isOpen: true,
      action: "add",
    });
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/Billing/GetBillingScheduledTransactions?refresh=${refreshTable}`,
    toolbarOptions: {
      hideToolbar: true,
    },
    columnOptions: [{ fieldName: "TransactionAmount", align: "right" }],
    advancedOptions: {
      paginate: false,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          EditScheduledPayment(actionOptions),
      },
    },
  };

  useBaseTable(tableConfiguration);

  const { tableMethods } = useBaseTable(tableConfiguration);
  const hooks = tableMethods[tableName];

  return (
    <>
      <Collapse
        id={"billing-scheduled-payments-collapse"}
        title="Scheduled Payments"
        expanded={false}
        titleHeightAuto
        content={
          <div style={{ height: "215px", marginTop: "-15px" }}>
            <BaseTable2 name={tableName} />
          </div>
        }
      />
      <ScheduledTransactionModal
        configuration={modalConfiguration}
        closeModal={(close) =>
          setModalConfiguration({
            ...modalConfiguration,
            isOpen: close,
            transactionId: null,
            insuredId: null,
          })
        }
        onSaveSuccessful={onSaveSuccessful}
        onDeleteSuccessful={onDeleteSuccessful}
        openAddAccountModal={onOpenAddAccountModal}
        refreshConfig={refreshConfig}
        setRefreshConfig={setRefreshConfig}
      />
      <BillingAccountModal
        setBillingAccount={setBillingAccount}
        billingAccount={billingAccount}
        insuredId={insuredId}
        setRefresh={(refresh: boolean) => setRefreshConfig(refresh)}
      />
    </>
  );
};
export default ScheduledPayments;
