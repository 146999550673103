import { useState } from "react";
import axios from "axios";
import printJS from "print-js";
import { FormTypeEnum } from "../dtos/form-type-enum";
import { FormRequestDto } from "../dtos/form-request-dto";
import { SaveGeneratedFileDto } from "../dtos/save-generated-file-dto";
import { ImageBlob } from "../dtos/image-blob";
import { TRUE_PORTAL_ID_STORAGE } from "../utilities/localStorageFunctions";
import { convertFromBase64ToUINT8Array } from "../utilities/blobFunctions";
import JSZip from "jszip";

export type MergedFileExtensionType = "pdf" | "docx";

type MergeFormRequest = {
  jsonObjectWithMergeFields: any;
  templateNames: string[];
  customFileName?: string;
  printOrDownload?: "print" | "download" | "mail";
  formType: FormTypeEnum;
  configurationToSaveFile?: SaveGeneratedFileDto;
  mergedFileExtension?: MergedFileExtensionType;
  attachedImages?: ImageBlob[];
};

function useFormRequest() {
  const token =
    `Bearer ${localStorage.getItem(TRUE_PORTAL_ID_STORAGE)}` ?? null;

  const headers: any = {
    authorization: token,
  };

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMergeRequestSuccessful, setIsSuccess] = useState<boolean>(true);

  async function sendMergeFormRequest({
    jsonObjectWithMergeFields,
    templateNames,
    printOrDownload = "download",
    formType,
    customFileName,
    configurationToSaveFile,
    mergedFileExtension = "pdf",
    attachedImages,
  }: MergeFormRequest) {
    setIsLoading(true);

    const getPostBodyDataByFormType = (
      formType: FormTypeEnum
    ): Partial<FormRequestDto> => {
      const r = {
        formType,
        templateNameWithExtensionList: templateNames,
        customFileName,
        configurationToSaveFile,
        mergedFileExtension,
        attachedImages,
      };
      switch (formType) {
        case FormTypeEnum.POLICY_FORM:
          return {
            ...r,
            policyMergeFields: jsonObjectWithMergeFields,
          };
        case FormTypeEnum.INCIDENT_FORM:
          return { ...r, incidentMergeFields: jsonObjectWithMergeFields };
        case FormTypeEnum.CLAIM_FORM:
          return { ...r, claimMergeFields: jsonObjectWithMergeFields };
        case FormTypeEnum.TEST_MERGE:
          return { ...r, testMergeFields: jsonObjectWithMergeFields };
        case FormTypeEnum.BATCH_FORM:
          return { ...r, batchMergeFields: jsonObjectWithMergeFields };
        case FormTypeEnum.PAYROLL_REPORT_FORM:
          return {
            ...r,
            allPayrollReportMergeFields: jsonObjectWithMergeFields,
          };
        case FormTypeEnum.CANCELLATION_FORM:
          return { ...r, cancellationMergeFields: jsonObjectWithMergeFields };
        case FormTypeEnum.CERTIFICATE_FORM:
          return { ...r, certificateMergeFields: jsonObjectWithMergeFields };
        default:
          return {};
      }
    };

    const axiosConfig: any = {
      url: process.env.REACT_APP_API_URL + "api/PortalFormEngine/GenerateForm",
      method: "POST",
      headers: headers,
      data: getPostBodyDataByFormType(formType),
    };

    try {
      const resp = await axios(axiosConfig);

      if (resp.status === 200) {
        if (printOrDownload === "print") {
          printJS({
            printable: resp.data.fileContents,
            type: "pdf",
            base64: true,
          });
        } else if (printOrDownload === "download") {
          const documentLink = document.createElement("a");
          const constructedHref =
            `data:${resp.data.contentType};base64, ` + resp.data.fileContents;
          documentLink.href = constructedHref;
          documentLink.download = resp.data.fileDownloadName;
          document.body.appendChild(documentLink);
          documentLink.click();
          document.body.removeChild(documentLink);
        } else {
          const documentLink = document.createElement("a");
          const constructedDocumentHref =
            `data:${resp.data.contentType};base64, ` + resp.data.fileContents;
          documentLink.href = constructedDocumentHref;
          documentLink.download = resp.data.fileDownloadName;
          document.body.appendChild(documentLink);
          documentLink.click();
          document.body.removeChild(documentLink);

          const mailLink = document.createElement("a");
          const constructedHref = "mailto:";
          mailLink.href = constructedHref;
          document.body.appendChild(mailLink);
          mailLink.click();
          document.body.removeChild(mailLink);
        }
        setIsSuccess(true);
      } else {
        setIsSuccess(false);
      }
    } catch (err: any) {
      setIsSuccess(false);
      console.warn("Merging failed.");
    } finally {
      setIsLoading(false);
    }
  }

  const PdfToDocxAsImagePages = async (pdfBlobName: string) => {
    const axiosConfig: any = {
      url:
        process.env.REACT_APP_API_URL +
        "api/FormEngine/ConvertPdfToDocxAsImagePages?pdfBlobName=" +
        pdfBlobName,
      headers: headers,
      "Content-Type": "application/json",
    };

    try {
      const resp = await axios(axiosConfig);
      if (resp.status === 200) {
        const link = document.createElement("a");
        const constructedHref =
          `data:${resp.data.contentType};base64, ` + resp.data.fileContents;
        link.href = constructedHref;
        link.download = resp.data.fileDownloadName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err: any) {
      console.warn("Converting PDF file failed.");
    }
  };

  return {
    sendMergeFormRequest,
    PdfToDocxAsImagePages,
    isLoading,
    isMergeRequestSuccessful,
  };
}

function useFileRequestInstance(isAsyncDownload: boolean = false) {
  const downloadFile = async (fileName: string) => {
    const token =
      `Bearer ${localStorage.getItem(TRUE_PORTAL_ID_STORAGE)}` ?? null;
    const url = isAsyncDownload ? "downloadAsync" : "download";

    const headers: any = {
      Authorization: token,
    };

    const axiosConfig: any = {
      url:
        process.env.REACT_APP_API_URL + `api/File/${url}?fileName=${fileName}`,
      headers: headers,
      "Content-Type": "application/json",
    };

    try {
      const resp = await axios(axiosConfig);
      if (resp.status === 200) {
        const link = document.createElement("a");
        const constructedHref =
          `data:${resp.data.contentType};base64, ` + resp.data.fileContents;
        link.href = constructedHref;
        link.download = resp.data.fileDownloadName;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err: any) {
      console.warn("Downloading file failed.");
    }
  };

  const downloadMultipleFiles = (fileNames: string[]) => {
    fileNames.forEach((fileName) => downloadFile(fileName));
  };

  const downloadAsZipFile = async (fileNames: string[], zipName: string) => {
    const token =
      `Bearer ${localStorage.getItem(TRUE_PORTAL_ID_STORAGE)}` ?? null;
    const url = isAsyncDownload ? "downloadAsync" : "download";
    const zip = new JSZip();

    const headers: any = {
      Authorization: token,
    };

    const fetchAll = fileNames.map(async (fileName) => {
      const axiosConfig: any = {
        url:
          process.env.REACT_APP_API_URL +
          `api/File/${url}?fileName=${fileName}`,
        headers: headers,
        "Content-Type": "application/json",
      };

      try {
        const resp = await axios(axiosConfig);
        if (resp.status === 200) {
          const contentType = resp.data.contentType;
          const uint8Array = convertFromBase64ToUINT8Array(
            resp.data.fileContents
          );

          const blob = new Blob([uint8Array], {
            type: contentType,
          });

          const newName = addUniqueFile(resp.data.fileDownloadName, zip, 1);
          zip.file(newName, blob, { binary: true });
        }
      } catch (err: any) {
        console.warn("Getting file failed.");
      }
    });

    Promise.all(fetchAll).then(() => {
      zip
        .generateAsync({ type: "blob" })
        .then((content) => {
          // Create a Blob URL for the zip content
          const zipBlobUrl = URL.createObjectURL(content);
          // Create a download link for the zip file
          const downloadLink = document.createElement("a");
          downloadLink.href = zipBlobUrl;
          // File name you want to save as
          downloadLink.download = zipName;
          // Append the download link to the DOM
          document.body.appendChild(downloadLink);
          // Trigger a click event to download the zip file
          downloadLink.click();
        })
        .catch((error) => {
          console.error("Error creating zip file: ", error);
        });
    });
  };

  return { downloadFile, downloadMultipleFiles, downloadAsZipFile };
}

const addUniqueFile = (baseFileName, zip, fileNumber) => {
  const fileExtension = baseFileName.split(".").pop();
  const indexExtension = baseFileName.lastIndexOf(".");
  const fileNameWithoutExtension = baseFileName.substring(0, indexExtension);
  const fileName =
    fileNumber === 1
      ? baseFileName
      : `${fileNameWithoutExtension}-${fileNumber}.${fileExtension}`;

  if (zip.file(fileName)) {
    return addUniqueFile(baseFileName, zip, fileNumber + 1);
  }
  return fileName;
};

export { useFileRequestInstance, useFormRequest };
