import { Box, IconButton } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { usePermissions } from "../../../hooks";
import {
  einFormat,
  einMask,
  ssnFormat,
  ssnMask,
} from "../../../utilities/stringFunctions";
// import "./Input.css";
import EyeBallOffIcon from "@mui/icons-material/VisibilityOffOutlined";
import EyeBallIcon from "@mui/icons-material/VisibilityOutlined";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import { isEmptyValue } from "../../../utilities/conditionalSupportFunctions";
import Input from "./Input";
import { inputTaxStyles } from "./InputStyles";

type inputTaxProps = {
  id: string;
  name: string;
  label?: string;
  value?: string;
  initialType?: "" | "SSN" | "FEIN";
  className?: string;
  readOnly?: boolean;
  labelPosition?: "start" | "end" | "top" | "bottom";
  variant?: "filled" | "standard" | "outlined";
  align?: "left" | "center" | "right";
  permissions?: number[];
  errorMessage?: string[] | null;
  inputWidth?: string;
  helperText?: string;
  inputFontType?: KeysAsType<FontsType>;
  labelFontType?: KeysAsType<FontsType>;
  onChange?: (e) => void;
  onBlur?: (e?: any) => void;
  onChangeRaw?: (e?: any) => void;
  tabIndex?: number;
};

const InputTax: FC<inputTaxProps> = ({
  id = "",
  name = "",
  label = "",
  value = "",
  initialType = "SSN",
  readOnly = false,
  labelPosition,
  variant,
  permissions,
  onChange,
  onChangeRaw,
  onBlur,
  errorMessage = null,
  inputWidth = "100%",
  helperText = "",
  align,
  inputFontType,
  labelFontType,
  tabIndex = 0,
}) => {
  const [state, setState] = useState<{
    noFormattedTax: string;
    formattedTax: string;
    maskedTax: string;
    initialType: string;
  }>({
    noFormattedTax: "",
    formattedTax: "",
    maskedTax: "",
    initialType: "",
  });
  const { hasPermission } = usePermissions(permissions ?? []);
  const [showTaxId, setShowTaxId] = useState(readOnly ? false : true);
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;

  const checkIfEIN = (text) => {
    //EIN (##-#######)
    const patternEIN = /^\d{2}\-\d{7}$/;
    return patternEIN.test(text);
  };

  const checkIfSSN = (text) => {
    //Social Security Number (###-##-####)
    const ssnRegex = /^(?!666|9\d{2})\d{3}-\d{2}-\d{4}$/;
    return ssnRegex.test(text);
  };

  const getInputType = (inputText) => {
    return checkIfEIN(inputText) === true
      ? "FEIN"
      : checkIfSSN(inputText) === true
      ? "SSN"
      : "";
  };

  useEffect(() => {
    if (!isEmptyValue(value) && getInputType(value) !== "") {
      setState({
        noFormattedTax: value,
        formattedTax:
          initialType === "SSN"
            ? ssnFormat(value)
            : initialType === "FEIN"
            ? einFormat(value)
            : value,
        maskedTax:
          initialType === "SSN"
            ? ssnMask(value)
            : initialType === "FEIN"
            ? einMask(value)
            : value,
        initialType: getInputType(value),
      });
    }
  }, [value]);

  const handleTaxChange = (event: any) => {
    const eventValue = event.target.value.replace(/[^\d-X]/g, "");
    const eventValueWithNoDashes = eventValue.replace(/\-/g, "");
    const isCompletedAndValid = getInputType(eventValue) === "" ? false : true;
    if (isCompletedAndValid === false) {
      setState({
        noFormattedTax: eventValueWithNoDashes,
        formattedTax: eventValue,
        maskedTax: "",
        initialType: getInputType(eventValue),
      });
    } else {
      const inputType = getInputType(eventValue);
      setState({
        noFormattedTax: eventValueWithNoDashes,
        formattedTax:
          inputType === "SSN"
            ? ssnFormat(eventValueWithNoDashes)
            : inputType === "FEIN"
            ? einFormat(eventValueWithNoDashes)
            : eventValue,
        maskedTax:
          inputType === "SSN"
            ? ssnMask(eventValueWithNoDashes)
            : inputType === "FEIN"
            ? einMask(eventValueWithNoDashes)
            : eventValue,
        initialType: inputType,
      });
    }
    onChange?.(event);
  };

  useEffect(() => {
    setShowTaxId(readOnly ? false : true);
    onChangeRaw?.({ value: state.noFormattedTax, type: state.initialType });
  }, [state]);

  useEffect(() => {
    setShowTaxId(readOnly ? false : true);
  }, [readOnly]);

  useEffect(() => {
    setState({
      noFormattedTax: value,
      formattedTax:
        initialType === "SSN"
          ? ssnFormat(value)
          : initialType === "FEIN"
          ? einFormat(value)
          : value,
      maskedTax:
        initialType === "SSN"
          ? ssnMask(value)
          : initialType === "FEIN"
          ? einMask(value)
          : value,
      initialType: initialType,
    });
  }, []);

  return (
    <Box className={`input-tax`} sx={inputTaxStyles(theme, readOnly)}>
      <Input
        tabIndex={tabIndex}
        className="base-input-tax-id"
        id={id}
        name={name}
        sx={{ width: readOnly === true ? "calc(100% - 38px)" : "100%" }}
        value={showTaxId ? state.formattedTax : state.maskedTax}
        label={label}
        labelPosition={labelPosition}
        variant={variant}
        inputWidth={inputWidth}
        errorMessage={errorMessage}
        helperText={helperText}
        readOnly={hasPermission ? readOnly : true}
        onChange={handleTaxChange}
        onBlur={onBlur}
        maxLength={initialType === "SSN" || initialType === "" ? 11 : 10}
        align={align}
        inputFontType={inputFontType}
        labelFontType={labelFontType}
      />
      {hasPermission && value !== "" && readOnly === true && (
        <Box className={"icons-container"}>
          <IconButton onClick={() => setShowTaxId(!showTaxId)}>
            {showTaxId ? <EyeBallOffIcon /> : <EyeBallIcon />}
          </IconButton>
        </Box>
      )}
    </Box>
  );
};

export default InputTax;
