import { FC, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import NavItem from "./NavItem";
import { Button } from "../TrueUI";
import { Box } from "@mui/material";
import { NavBarEnum } from "./NavBar";
import { useSetRecoilState } from "recoil";
import { navBarActiveSection } from "../../GlobalAtoms";

export type NavItemType = {
  displayName: string;
  url: string;
  hasPermission: boolean;
};

type NavBarElementsProps = {
  navItems: NavItemType[];
  activeSection: number;
  setActiveSection: (section: number) => void;
};
const NavBarElements: FC<NavBarElementsProps> = ({
  navItems,
  activeSection,
  setActiveSection,
}) => {
  const navigate = useNavigate();
  const getLocation = useLocation();
  const setNavBarActiveSection = useSetRecoilState(navBarActiveSection);

  const setLocationNav = () => {
    const location = getLocation.pathname.split("/")?.[1] ?? "";
    //Update this method as we add more routes
    switch (location) {
      case "claims":
      case "incident":
        setNavBarActiveSection(NavBarEnum.CLAIMS);
        break;
      case "billing":
      case "payroll-report":
        setNavBarActiveSection(NavBarEnum.BILLING);
        break;
      case "my-policies":
      case "policy":
        setNavBarActiveSection(NavBarEnum.MY_POLICIES);
        break;
      default:
        setNavBarActiveSection(NavBarEnum.HOME);
        break;
    }
  };

  useEffect(() => {
    setLocationNav();
  }, [getLocation]);

  return (
    <Box
      className={"top_nav_items_container"}
      sx={{
        display: { xs: "none", sm: "flex" },
        gap: { xs: "none", sm: "flex", md: "15px 30px" },
        alignItems: "flex-end",
      }}
    >
      {navItems.map(
        (item, key) =>
          item.hasPermission && (
            <NavItem
              item={item}
              index={key}
              current={activeSection}
              setCurrent={setActiveSection}
              key={`${item}-${key}`}
            />
          )
      )}
      <Button
        id={"nav_button"}
        variantStyle="outlined"
        onClick={() => {
          setActiveSection(NavBarEnum?.CLAIMS);
          navigate("/incident");
        }}
      >
        FILE A CLAIM
      </Button>
    </Box>
  );
};
export default NavBarElements;
