import { FC } from "react";
import boundStyles from "./Policy.module.css";
import { PortalPolicyBoundDto } from "../../dtos/portal-policy-bound-dto";
import { Title } from "../TrueUI";
import { currencyFormat } from "../../utilities/currencyFunctions";
import PolicyDocumentsTable from "./PolicyDocumentsTable";

type CollapseContentProps = {
  policy: PortalPolicyBoundDto;
};

const CollapseContent: FC<CollapseContentProps> = ({ policy }) => {
  return (
    <div className={boundStyles.bound_policies_content_container}>
      <div className={boundStyles.bound_policy_content}>
        <Title>Type: </Title>
        <Title opacity={50} truncate>
          {policy.policyType}
        </Title>
        <Title className={boundStyles.second_column}>Name: </Title>
        <Title opacity={50} truncate>
          {policy.policyName}
        </Title>
        <Title opacity={50} truncate>
          {""}
        </Title>
      </div>
      <div className={boundStyles.bound_policy_content}>
        <Title>Limits: </Title>
        <Title opacity={50} truncate>
          {policy.policyLimit}
        </Title>
        <Title className={boundStyles.second_column}>Term: </Title>
        <Title opacity={50} truncate>
          {policy.policyTerm}
        </Title>
        <Title opacity={50} truncate>
          {""}
        </Title>
      </div>
      <div className={boundStyles.bound_policy_content}>
        <Title>Premium: </Title>
        <Title opacity={50} truncate>
          {currencyFormat(policy.policyPremium, false)}
        </Title>
        <Title className={boundStyles.second_column}>Paid to Date: </Title>
        <Title opacity={50} truncate>
          {currencyFormat(policy.policyPaidToDate, true)}
        </Title>
        <Title opacity={50} truncate>
          {""}
        </Title>
      </div>
      <div>
        <PolicyDocumentsTable policy={policy} />
      </div>
    </div>
  );
};
export default CollapseContent;
