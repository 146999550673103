import { Container } from "@mui/material";
import { FC } from "react";

type NavContainerProps = {
  children: any;
  isAgencyPortal: boolean;
};
const NavContainer: FC<NavContainerProps> = ({ children, isAgencyPortal }) => {
  const DivContainer = () => (
    <div
      style={{
        display: "flex",
        justifyContent: isAgencyPortal ? "flex-start" : "space-between",
        alignItems: isAgencyPortal ? "center" : "flex-end",
        gap: "10px",
        height: isAgencyPortal ? "83px" : "104px",
        margin: isAgencyPortal ? "0 20px" : "0",
      }}
    >
      {children}
    </div>
  );

  return isAgencyPortal ? (
    <DivContainer />
  ) : (
    <Container maxWidth={"lg"}>
      <DivContainer />
    </Container>
  );
};

export default NavContainer;
