import { Container } from "@mui/material";
import { useRecoilValue } from "recoil";
import AppRoutes from "./AppRoutes";
import Footer from "./Footer";
import { globalOptions } from "./GlobalAtoms";
import "./Layout.css";
import NavBar from "./components/NavBar/NavBar";
import TopLinks from "./components/TopLinks/TopLinks";
import { PortalThemeBackgroundTypeEnum } from "./dtos/portal-theme-background-type-enum";
import { FC } from "react";
import AgencyAppRoutes from "./AgencyAppRoutes";

type LayoutProps = {
  isAgencyPortal?: boolean;
};

const Layout: FC<LayoutProps> = ({ isAgencyPortal = false }) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;

  const backgroundStyles = () => {
    if (theme?.backgroundType === PortalThemeBackgroundTypeEnum.IMAGE) {
      return {
        backgroundImage: `url(${theme?.backgroundPath})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      };
    }
    return { background: theme?.primaryBackground ?? "#FFF" };
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
        ...backgroundStyles(),
      }}
    >
      <TopLinks isAgencyPortal={isAgencyPortal} />
      <NavBar isAgencyPortal={isAgencyPortal} />
      <div
        style={{
          height: "100%",
          display: "flex",
          overflow: "hidden auto",
        }}
      >
        <div style={{ display: "flex", width: "100%" }}>
          <Container
            sx={{ minWidth: "300px", display: "flex", flexDirection: "column" }}
            maxWidth="lg"
          >
            {isAgencyPortal ? AgencyAppRoutes : AppRoutes}
          </Container>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
