import { FC, useEffect, useState } from "react";
import { BaseTableDisplayOptions } from "../../../../dtos/base-table-display-options";
import { BaseTableCellSelectProperties } from "../BaseTable2/TableProperties";
import { SelectOptions } from "../../../../dtos/select-options";
import { idArrayToFlatString } from "../../../../utilities/arrayFunctions";
import MultiSelectOLD from "../../Select/MultiselectOLD";

interface BaseTableTagCellProps2 extends BaseTableCellSelectProperties {
  dataOptions: BaseTableDisplayOptions[];
}

const isJSON = (jsonAsString) => {
  try {
    JSON.parse(jsonAsString);
  } catch (e) {
    return false;
  }

  return true;
};

const JSONStructure = '^[{"Id":[0-9]+}(,{"Id":[0-9]+})*]$';

const regex = new RegExp(JSONStructure);

const TagsCell2: FC<BaseTableTagCellProps2> = ({
  rowKey,
  dataOptions,
  cellKey,
  column,
  value,
  isEdit,
  onChange,
  variant,
}) => {
  const displayValues: Partial<SelectOptions>[] = dataOptions.map((options) => {
    return { displayName: options.text, stringValue: options.value };
  });

  const [selectedValues, setSelectedValues] = useState<
    Partial<SelectOptions>[]
  >([]);

  const setInitialValues = () => {
    if (value && isJSON(value) && regex.test(value)) {
      const convertedValues = JSON.parse(value).map((value) => {
        const valueArray: Partial<SelectOptions> = {
          stringValue: `${value.Id}`,
        };
        return valueArray;
      });
      setSelectedValues(convertedValues);
    }
  };

  const onSelectedChange = (values) => {
    const selected = values.map((value) => ({
      stringValue: value.stringValue,
    }));
    setSelectedValues(selected);
  };

  useEffect(() => {
    setInitialValues();
  }, [value]);

  useEffect(() => {
    onChange?.({
      cellKey: cellKey,
      rowKey: rowKey,
      column: column,
      value: idArrayToFlatString(selectedValues),
    });
  }, [selectedValues]);

  return (
    <MultiSelectOLD
      name={`name-${column.fieldName}-${cellKey}`}
      key={`table_tag_${cellKey}`}
      readOnly={!isEdit}
      options={displayValues}
      values={selectedValues}
      onChange={(values) => {
        onSelectedChange(values);
      }}
      variant={variant}
      type={"greenChips"}
      openOnFocus
    />
  );
};

export default TagsCell2;
