import { FC, useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
// import TextField from "@mui/material/TextField";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { usePermissions } from "../../../hooks";
import { DesktopTimePicker } from "@mui/x-date-pickers";
import { FormHelperText } from "@mui/material";

type inputTimeProps = {
  id?: string;
  name?: string;
  defaultValue?: string | Date;
  errorMessage?: string[];
  label?: string;
  readOnly?: boolean;
  onChange?: (e) => void;
  variant?: "filled" | "standard" | "outlined";
  className?: string;
  permissions?: number[];
  helperText?: string;
  inputWidth?: string;
};

const InputTime: FC<inputTimeProps> = ({
  // id,
  // name = "",
  defaultValue,
  errorMessage = null,
  label,
  readOnly = false,
  onChange,
  // variant = "standard",
  // className = "",
  permissions = [1, 2, 3], // TODO: @elara Update until Antonio's PR will be ready
  helperText = null,
  // inputWidth = "100%",
}) => {
  const hasPermission = usePermissions(permissions);
  const handleDateChange = (newValue) => {
    setValue(newValue);
    onChange?.(newValue);
  };
  const getValidDateValue = (val: string | Date | undefined) => {
    if (typeof val === "string") {
      return new Date(val);
    }
    return val;
  };

  const [_isThereError, setIsThereError] = useState(false);
  const [_helperErrorText, setHelperErrorText] = useState("");
  const [value, setValue] = useState(getValidDateValue(defaultValue));
  const [showHelpText, _setShowHelpText] = useState<boolean>(false);

  useEffect(() => {
    getErrorHelper();
  }, [errorMessage]);

  function getErrorHelper() {
    if (errorMessage != null && errorMessage != undefined) {
      setIsThereError(true);
      setHelperErrorText(errorMessage?.join(" "));
    } else {
      setIsThereError(false);
      setHelperErrorText("");
    }
  }

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment as any}
      // onMouseEnter={() => {
      //   setShowHelpText(true);
      // }}
      // onMouseLeave={() => {
      //   setShowHelpText(false);
      // }}
    >
      <Stack spacing={3}>
        <DesktopTimePicker
          label={label}
          disabled={hasPermission ? readOnly : true}
          value={value}
          onChange={handleDateChange}
          // renderInput={(params) => (
          //   <TextField
          //     {...params}
          //     error={isThereError}
          //     helperText={helperErrorText}
          //     id={id}
          //     variant={variant as any}
          //     className={className}
          //     InputLabelProps={{
          //       shrink: true,
          //     }}
          //     inputProps={{
          //       ...params.inputProps,
          //       "true-element": `true-input-time-${name}`,
          //     }}
          //     sx={{ width: inputWidth }}
          //   />
          // )}
        />
      </Stack>
      {helperText && (
        <FormHelperText>{showHelpText ? helperText : " "}</FormHelperText>
      )}
    </LocalizationProvider>
  );
};

export default InputTime;
