import { getColorWithOpacityHexaDecimal } from "../../../media/themeConstants";
import { Theme } from "../../../media/themeTypes";

export const collapseStyles: any = (
  theme: Theme,
  hasContentOnReadOnly: boolean,
  hasBottomMargin: boolean,
  collapsedHeight?: string,
  shadowContainer?: boolean,
  titleHeightAuto?: boolean
) => {
  const accordionStyles = hasContentOnReadOnly
    ? {
        "& .MuiAccordionDetails-root": {
          padding: "8px 11px 5px",
        },
        "& .MuiCollapse-root": {
          visibility: "visible",
          minHeight: `fit-content !important`,
          height: `fit-content !important`,
        },
      }
    : { "& .MuiCollapse-root": { visibility: "inherit" } };

  const containerStyles = shadowContainer
    ? {
        boxShadow:
          "0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)",
      }
    : {};

  return {
    ...containerStyles,
    ".MuiButtonBase-root.Mui-expanded": {
      minHeight: titleHeightAuto ? "auto" : "64px",
      padding: titleHeightAuto ? "10px 16px" : "0 16px",
    },
    ".MuiAccordionSummary-content.Mui-expanded": {
      margin: titleHeightAuto ? "0px" : "20px 0px",
    },
    ".MuiAccordionSummary-expandIconWrapper": {
      transformOrigin: "center center",
    },
    ".MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
    },
    mb: hasBottomMargin ? 1 : 0,
    textAlign: "left",

    width: "100%",
    mt: "1px!important",
    ...accordionStyles,
    "& .MuiAccordion-root": {
      border: `1px solid ${getColorWithOpacityHexaDecimal(
        theme?.borderColor,
        30
      )}`,
      height: collapsedHeight === "" ? "100%" : collapsedHeight,
    },
    "& .MuiAccordion-root.Mui-expanded": {
      height: "auto",
    },
    svg: {
      color: theme?.textColor,
    },
  };
};
