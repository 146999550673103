import { Modal } from "../../../TrueUI";
import { useApiGet, useApiPost } from "../../../../hooks";
import { ScheduledTransactionConfigurationDto } from "../../../../dtos/scheduled-transaction-configuration-dto";
import { FC, useEffect, useState } from "react";
import { BillingScheduledTransactionDto } from "../../../../dtos/billing-scheduled-transaction-dto";
import {
  isAPITotallyComplete,
  isAPITotallyCompleteNoContentResponse,
} from "../../../../utilities/apiFunctions";
import { BillingScheduledTransactionsStatusEnum } from "../../../../dtos/billing-scheduled-transactions-status-enum";
import ScheduledTransactionModalContent from "./ScheduledTransactionModalContent";

export type ScheduledTransactionModalConfig = {
  isOpen: boolean;
  transactionId?: number | null;
  insuredId?: number | null;
  rowKey?: string | null;
  rowData?: any;
};

type ScheduledTransactionModalProps = {
  configuration: ScheduledTransactionModalConfig;
  closeModal: (close: boolean) => void;
  onSaveSuccessful?: (data: BillingScheduledTransactionDto) => void;
  onDeleteSuccessful?: () => void;
  openAddAccountModal?: () => void;
  refreshConfig?: boolean;
  setRefreshConfig?: (refresh: boolean) => void;
};

export const defaultData: BillingScheduledTransactionDto = {
  policyId: 0,
  scheduleStatus: 1,
  insuredAccountId: 0,
  accountNickname: "",
  createBy: "",
  invoiceId: 0,
  insuredName: "",
  scheduledDate: new Date(),
  scheduleId: 0,
  tranAccountId: 0,
  transactionAmount: 0,
};

const ScheduledTransactionModal: FC<ScheduledTransactionModalProps> = ({
  configuration: { isOpen, transactionId, insuredId },
  closeModal,
  onSaveSuccessful,
  onDeleteSuccessful,
  openAddAccountModal,
  refreshConfig,
  setRefreshConfig
}) => {
  const [configurationState, setConfigurationState] =
    useState<ScheduledTransactionConfigurationDto | null>(null);
  const [data, setData] = useState<BillingScheduledTransactionDto>(defaultData);
  const [errorDetails, setErrorDetails] = useState<any>();

  const { responseGet, dispatchGet } =
    useApiGet<ScheduledTransactionConfigurationDto>(
      `api/BillingTransaction/GetScheduledTransactionsConfiguration?insuredId=${
        insuredId ?? 0
      }`
    );

  const { responsePost, validatorErrorResponse, dispatchPost } = useApiPost(
    `api/BillingTransaction/SaveScheduledTransaction`,
    data
  );

  const getInsuredAccountName = () => {
    const insuredAccount = configurationState?.accountOptions?.find(
      (x) => x.intValue === data.insuredAccountId
    );

    return insuredAccount?.displayName ?? "";
  };
  
  const getAccountType = () => {
    const account = configurationState?.accountTypes?.find(
      (x) => x.intValue == data.insuredAccountId
    );
    
    return account?.displayName ?? "";
  };

  useEffect(() => {
    setConfigurationState(null);
    if (insuredId) {
      dispatchGet();
    }
  }, [insuredId]);
  
  useEffect(() => {
    if (refreshConfig) {
      dispatchGet();
      setRefreshConfig?.(false);
    }
  }, [refreshConfig]);
  

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setConfigurationState(responseGet.responseData ?? null);
    }
  }, [responseGet]);

  useEffect(() => {
    if (isAPITotallyCompleteNoContentResponse(responsePost)) {
      if (
        data.scheduleStatus === BillingScheduledTransactionsStatusEnum.DELETED
      ) {
        onDeleteSuccessful?.();
      } else {
        onSaveSuccessful?.({
          ...data,
          insuredAccountName: getInsuredAccountName(),
          accountType: getAccountType(),
        });
      }
      setData(defaultData);
      onCloseEvent(false);
    }
  }, [responsePost]);

  useEffect(() => {
    if (validatorErrorResponse) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    }
  }, [validatorErrorResponse]);

  const onCloseEvent = (close: boolean) => {
    closeModal(close);
    setErrorDetails(null);
  };

  const onSaveEvent = () => {
    setErrorDetails(null);
    dispatchPost();
  };

  const onDeleteEvent = () => {
    setData({
      ...data,
      scheduleStatus: BillingScheduledTransactionsStatusEnum.DELETED,
    });
    setErrorDetails(null);

    dispatchPost();
  };

  return (
    <>
      <Modal
        id={"edit_scheduled_transaction"}
        title={"Scheduled Transaction"}
        open={isOpen}
        showCloseButton={true}
        deleteEvent={onDeleteEvent}
        showCancelTextButton={true}
        saveEvent={onSaveEvent}
        cancelEvent={() => onCloseEvent(false)}
        closeEvent={onCloseEvent}
        cancelButtonWithConfirmation
        deleteButtonWithConfirmation
      >
        {configurationState !== null && (
          <ScheduledTransactionModalContent
            configuration={configurationState}
            transactionId={transactionId ?? 0}
            formData={data}
            setFormData={setData}
            errorDetails={errorDetails}
            openAddAccountModal={openAddAccountModal}
          />
        )}
      </Modal>
    </>
  );
};

export default ScheduledTransactionModal;
