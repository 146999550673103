import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";

const Circle = ({ children }) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;

  return (
    <div
      style={{
        padding: "5px 10px",
        display: "inline",
        margin: "5px",
        borderRadius: "100px",
        background: theme?.primary,
        color: "#FFF",
      }}
    >
      {children}
    </div>
  );
};

export default Circle;
