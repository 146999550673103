import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import { styled, alpha } from "@mui/material/styles";
import InputBase from "@mui/material/InputBase";
import { Theme } from "../../../media/themeTypes";
import { getColorWithOpacityHexaDecimal } from "../../../media/themeConstants";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
  onTopNavClose?: () => void;
}

export const NavBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme }) => ({
  width: `100%`,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
}));

export const topNavStyles = (theme: Theme) => ({
  flexGrow: 1,
  "& .tool-bar": {
    minHeight: 58,
  },
  "& .tool-bar .MuiButtonBase-root": {
    color: theme?.textColor,
    padding: "0px 12px",
  },
  "& .tool-bar .settings-container": {
    display: { xs: "none", md: "flex" },
  },
  "& .tool-bar .settings-container .top-nav-avatar": {
    width: 30,
    height: 30,
    fontSize: "small",
  },
  "& .tool-bar .more-icon": {
    display: { xs: "flex", md: "none" },
  },
  "& .tool-bar .empty-container": {
    flexGrow: 1,
  },
});

export const SearchStyle = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.grey.A400, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.grey.A400, 0.3),
  },
  marginRight: theme.spacing(2),
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(3),
    width: "auto",
  },
}));

export const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  top: 0,
  right: 0,
}));

export const searchStyle = (theme: Theme) => ({
  width: "400px !important",
  background: getColorWithOpacityHexaDecimal(theme?.inputBackgroundColor, 60),
  borderBottom: `1px solid ${theme.borderColor}`,
  borderRadius: `4px 4px 0px 0px`,
  marginRight: 0,
  ".input-base": {
    width: "100%",
  },
  ".input-base>input": {
    width: "100%",
    color: `${theme?.textColor} !important`,
    "::placeholder": {
      color: `${theme?.textColor} !important`,
      opacity: 1,
    },
  },
});

export const searchStyleInputForm = (theme: Theme, inputWidth?: any) => ({
  background: getColorWithOpacityHexaDecimal(theme?.inputBackgroundColor, 60),
  borderBottom: `1px solid ${theme.borderColor}`,
  borderRadius: `4px 4px 0px 0px`,
  "&.true_search_text_input >div.MuiInputBase-root ": {
    width: conditionHasValue(inputWidth) ? `${inputWidth}` : "auto",
  },
  "&.true_search_text_input >div.MuiInputBase-root input": {
    width: conditionHasValue(inputWidth) ? `100%` : "auto",
  },
  "& .MuiInputBase-input": {
    paddingRight: "20px",
  },
  "&.true_search_text_input >div:last-child": {
    paddingLeft: "3px",
    paddingRight: "5px",
  },
});
const getStyledInputBaseCommonProps = (theme) => ({
  color: "inherit",

  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingRight: `calc(1em + ${theme.spacing(4)})`,
    paddingLeft: 10,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
});

export const StyledInputBase = styled(InputBase)(({ theme }) =>
  getStyledInputBaseCommonProps(theme)
);

export const StyledInputBaseLarge = styled(InputBase)(({ theme }) => ({
  ...getStyledInputBaseCommonProps(theme),
  "& .MuiInputBase-input": {
    ...getStyledInputBaseCommonProps(theme)["& .MuiInputBase-input"],
    paddingLeft: 20,
    [theme.breakpoints.up("md")]: {
      width: "37ch",
    },
  },
}));

export const StyledInputBaseFullWidth = styled(InputBase)(({ theme }) => ({
  ...getStyledInputBaseCommonProps(theme),
  "& .MuiInputBase-input": {
    ...getStyledInputBaseCommonProps(theme)["& .MuiInputBase-input"],
    paddingLeft: 20,
  },
}));

export const menuStyles = (theme: Theme, menuPadding: string) => ({
  maxHeight: 400,
  minWidth: "200px !important",
  mt: 6,
  padding: 0,
  "& .MuiMenu-list": {
    background: theme?.primaryBackground,
    color: theme?.textColor,
    padding: menuPadding,
  },
  "& .true_container_list": {
    p: 0,
    width: 665,
  },
  "& .true_container_list > .MuiTypography-root": {
    m: 0,
    flexGrow: 1,
    textAlign: "center",
    color: theme?.textColor,
  },
  "& .true_container_list .MuiList-root": {
    p: 0,
  },
  "& .true_container_list .MuiList-root .MuiListItemButton-root": {
    borderBottom: `1px solid ${theme?.borderColor}`,
  },
  "& .true_container_list .MuiList-root .MuiListItemButton-root .true_first_row_typography":
    {
      maxWidth: "480px",
    },
  "& .true_container_list .MuiList-root .MuiListItemButton-root .true_first_row_typography span":
    {
      color: theme?.textColor,
      maxWidth: "480px",
    },
  "& .true_container_list .MuiList-root .MuiListItemButton-root .true_first_row_typography p":
    {
      color: getColorWithOpacityHexaDecimal(theme?.textColor, 60),
      maxWidth: "fit-content",
      ml: "15px",
    },
  "& .true_container_list .MuiList-root .MuiListItemButton-root .second-row-typography":
    {
      width: "140px",
      display: "flex",
      justifyContent: "space-between",
      alignSelf: "flex-start",
      pr: 0,
      pl: 0,
    },
  "& .true_container_list .MuiList-root .MuiListItemButton-root .second-row-typography span":
    {
      textAlign: "right",
      whiteSpace: "break-spaces",
    },
  "& .MuiPaper-root": {
    "&::-webkit-scrollbar": {
      width: "5px",
    },
    "&::-webkit-scrollbar-track": {
      background: "transparent",
    },
    "&::-webkit-scrollbar-thumb": {
      background: theme?.secondaryBackground,
      borderRadius: "5px",
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: theme?.secondaryBackground,
    },
  },
});

export const menuPaperStyles = (theme: Theme) => ({
  color: theme?.textColor,
  background: theme?.primaryBackground,
  svg: { color: theme?.textColor },
});

export const dividerStyles = (theme: Theme) => ({
  my: 1,
  borderColor: theme?.borderColor,
});
