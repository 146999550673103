import { Box, TypographyProps } from "@mui/material";
import { FC, useEffect, useState } from "react";
import { SelectOptions } from "../../../dtos/select-options";
import { FontBold, Switch } from "../../TrueUI";

type MultiSwitchProps = {
  name?: string;
  selectOptions: Partial<SelectOptions>[];
  value?: number[];
  title?: string;
  control?: "checkbox" | "switch" | "radio";
  keyPrefix?: string;
  helperText?: string;
  labelTypography?: TypographyProps;
  readOnly?: boolean;
  onChangeRawValue?: (e?: any) => void;
  inputWidth: string;
  spaceBetweenLabelAndControls?: boolean;
};

export const MultiSwitch: FC<MultiSwitchProps> = ({
  name,
  selectOptions,
  value,
  title,
  control = "switch",
  keyPrefix,
  helperText,
  labelTypography,
  readOnly,
  onChangeRawValue,
  inputWidth = "100%",
  spaceBetweenLabelAndControls,
}) => {
  const getSelectedValue = () => {
    const options = [...selectOptions].map((option) => {
      const mappedOption: Partial<SelectOptions> = {
        ...option,
        booleanValue:
          value && value !== undefined && value.length > 0
            ? value?.find((v) => option.intValue === v) !== undefined
              ? true
              : false
            : false,
      };
      return mappedOption;
    });
    return options;
  };

  const [selectedOptions, setSelectedOptions] = useState<
    Partial<SelectOptions>[]
  >(getSelectedValue());

  const updateSelected = (option: Partial<SelectOptions>, value) => {
    const options = [...selectedOptions].map((item) =>
      item.intValue !== option.intValue
        ? item
        : {
            ...option,
            booleanValue: value,
          }
    );
    setSelectedOptions(options);
  };

  useEffect(() => {
    onChangeRawValue?.(selectedOptions);
  }, [selectedOptions]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", width: inputWidth }}>
      <FontBold textAlign="start">{title}</FontBold>
      <>
        {selectedOptions.map((option, i) => (
          <Switch
            name={`${name}-id-${i}`}
            readOnly={readOnly}
            key={`${keyPrefix}-${option.intValue}`}
            spaceBetweenLabelAndControl={spaceBetweenLabelAndControls}
            label={option.displayName}
            labelPlacement={"start"}
            control={control}
            isChecked={option.booleanValue ?? false}
            onChangeRawValue={(value) => {
              updateSelected(option, value);
            }}
            labelStyle={{ marginLeft: 0 }}
            labelTypographyProps={{ typography: labelTypography }}
            helperText={helperText}
          />
        ))}
      </>
    </Box>
  );
};
