import { FC, useEffect, useState } from "react";
import { Button, Input } from "../../TrueUI";
import { PayrollReportDetailInfoDto } from "../../../dtos/payroll-report-detail-info-dto";
import { useApiGet } from "../../../hooks";
import style from "./PayrollReport.module.css";
import { AllPayrollReportJSON } from "../../../dtos/all-payroll-report-json";
import { isAPITotallyComplete } from "../../../utilities/apiFunctions";
import { isEmptyValue } from "../../../utilities/conditionalSupportFunctions";
import { FormTypeEnum } from "../../../dtos/form-type-enum";
import { useFormRequest } from "../../../hooks/useFileStorage";
import DialogConfirmation, {
  DialogConfirmationProps,
} from "../../TrueUI/Dialogs/DialogConfirmation";
import { useRecoilState } from "recoil";
import {
  dispatchPrintJSONAtom,
  refreshPayrollReportDetailsHeaderAtom,
} from "./PayrollReportAtoms";

type PayrollReportHeaderProps = {
  payrollReportId?: string;
};

const PayrollReportHeader: FC<PayrollReportHeaderProps> = ({
  payrollReportId,
}) => {
  const [dialogConfiguration, setDialogConfiguration] =
    useState<DialogConfirmationProps | null>(null);
  const [dispatchJSON, setDispatchJSON] = useRecoilState(dispatchPrintJSONAtom);
  const [refreshHeader, setRefreshHeader] = useRecoilState(
    refreshPayrollReportDetailsHeaderAtom
  );

  const { sendMergeFormRequest } = useFormRequest();
  const { responseGet, dispatchGet } = useApiGet<PayrollReportDetailInfoDto>(
    `api/PayrollReport/GetPayrollReportHeaderInfo?payrollReportId=${payrollReportId}`
  );

  const {
    responseGet: responseGetPayrollToPrint,
    dispatchGet: dispatchGetPayrollToPrint,
  } = useApiGet<AllPayrollReportJSON>(
    `api/PayrollReport/GetPayrollReportToPrint?payrollReportId=${payrollReportId}`
  );

  const payrollReportInfo = responseGet?.responseData;

  const getCorrectFileName = () => {
    if (
      !isEmptyValue(payrollReportInfo?.insuredName) &&
      !isEmptyValue(payrollReportInfo?.policyNumber)
    ) {
      return `Payroll Report ${payrollReportInfo?.insuredName} ${payrollReportInfo?.policyNumber}`;
    }
    if (!isEmptyValue(payrollReportInfo?.insuredName)) {
      return `Payroll Report ${payrollReportInfo?.insuredName}`;
    }

    if (!isEmptyValue(payrollReportInfo?.policyNumber)) {
      return `Payroll Report ${payrollReportInfo?.policyNumber}`;
    }

    return `Payroll Report`;
  };

  const printPayrollReport = (allPayrollReportJSON?: AllPayrollReportJSON) => {
    if (
      allPayrollReportJSON !== undefined &&
      allPayrollReportJSON !== null &&
      !isEmptyValue(allPayrollReportJSON.templateName)
    ) {
      sendMergeFormRequest({
        formType: FormTypeEnum.PAYROLL_REPORT_FORM,
        jsonObjectWithMergeFields: allPayrollReportJSON,
        templateNames: [allPayrollReportJSON.templateName ?? ""],
        customFileName: getCorrectFileName(),
        printOrDownload: "download",
      });
    }
    if (isEmptyValue(allPayrollReportJSON?.templateName)) {
      setDialogConfiguration({
        dialogDescriptionText: "No print template found.",
        open: true,
        optionYesOverrideLabel: "OK",
        onOptionYesEvent: () => setDialogConfiguration(null),
      });
    }
  };

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGetPayrollToPrint))
      printPayrollReport(responseGetPayrollToPrint?.axiosResponse?.data);
  }, [responseGetPayrollToPrint]);

  useEffect(() => {
    if (dispatchJSON) {
      dispatchGetPayrollToPrint();
      setDispatchJSON(false);
    }
  }, [dispatchJSON]);

  useEffect(() => {
    if (refreshHeader) {
      dispatchGet();
      setRefreshHeader(false);
    }
  }, [refreshHeader]);

  return (
    <div className={style.payroll_report_header}>
      <Input
        id="policy-number"
        name="policy-number"
        readOnly
        label="Policy Number"
        labelFontType="BOLD_CAPTION"
        labelPosition="top"
        value={payrollReportInfo?.policyNumber}
        inputWidth="auto"
      />
      <Input
        id="reported-from"
        name="reported-from"
        readOnly
        label="For PayrollReported From"
        labelFontType="BOLD_CAPTION"
        labelPosition="top"
        value={payrollReportInfo?.reportFrom}
        inputWidth="auto"
      />

      <Input
        id="reported-to"
        name="reported-to"
        readOnly
        label="To"
        labelFontType="BOLD_CAPTION"
        labelPosition="top"
        value={payrollReportInfo?.reportTo}
        inputWidth="auto"
      />

      <Input
        id="status"
        name="status"
        readOnly
        label="Status"
        labelFontType="BOLD_CAPTION"
        labelPosition="top"
        value={payrollReportInfo?.status}
        inputWidth="300px"
      />
      <Button
        variantStyle="outlined"
        onClick={() => dispatchGetPayrollToPrint()}
      >
        PRINT FORM
      </Button>
      <DialogConfirmation
        id="payrollDetailsJSON"
        {...dialogConfiguration}
        onCloseEvent={() => setDialogConfiguration(null)}
      />
    </div>
  );
};
export default PayrollReportHeader;
