import { SelectOptions } from "../dtos/select-options";

export function FromEnumToArray(enumType) {
  const enumArray = getEnumKeys(enumType).map((enumValue) => {
    const key = getEnumValue(enumType[enumValue], enumType);
    const finalKey = formattingKey(key);
    return {
      key: finalKey,
      value: enumType[enumValue],
    };
  });
  return enumArray;
}

export function formattingKey(key: string): string {
  const words = key.toLowerCase().split("_");
  for (let i = 0; i < words.length; i++) {
    words[i] = words[i][0].toUpperCase() + words[i].substring(1);
  }
  return words.join(" ");
}

export function FromEnumToArrayWithExceptions(enumType, excludedKeys: any[]) {
  const enumArrayWithExceptions = getEnumKeys(enumType).map((enumValue) => {
    const isExcluded = excludedKeys.find(
      (keyValue) => enumType[keyValue] === enumValue
    );
    if (isExcluded === undefined) {
      const key = getEnumValue(enumType[enumValue], enumType);
      const finalKey = formattingKey(key);
      return {
        key: finalKey,
        value: enumType[enumValue],
      };
    }
    return null;
  });
  const removeNulls = enumArrayWithExceptions.filter(
    (valueMaybeNull) => valueMaybeNull !== null
  );
  return removeNulls;
}

export function getEnumKeys(enumType): string[] {
  return Object.keys(enumType).filter((key) => !isNaN(Number(enumType[key])));
}

export function getEnumValues(enumType): number[] {
  return Object.values(enumType).filter(Number) as number[];
}

export function getEnumKey(enumType, enumName): string {
  return enumType[enumName];
}

export const getEnumValue = (statusValue: string, enumType) => {
  if (statusValue !== null && enumType !== null) {
    return enumType[statusValue];
  }
  return "";
};

export function toTitleCase(str: string) {
  return str
    .replace(/^[-_]*(.)/, (_, char) => char.toUpperCase())
    .replace(/[-_]+(.)/g, (_, char) => " " + char.toUpperCase());
}

export const filterSelectOptionsByEnumValues = (
  options: Partial<SelectOptions>[],
  enumValues: number[]
) => {
  return options?.filter((option) =>
    enumValues.includes(
      option?.intValue !== null && option?.intValue !== undefined
        ? option?.intValue
        : -1
    )
  );
};

export const FromEnumToArrayOfValues = (enumType): number[] => {
  const enumArray = getEnumKeys(enumType).map(
    (enumValue) => enumType[enumValue]
  );
  return enumArray;
};
