import { Button as MaButton } from "@mui/material";
import { FC } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import { usePermissions } from "../../../hooks";
import { buttonStyles } from "./ButtonStyles";

interface ButtonProperties
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  ignoreGlobalDisableOverride?: boolean | undefined;
  name?: string;
  isDisabled?: boolean;
  fullWidth?: boolean;
  sx?: any;
  variantStyle?: "contained" | "outlined" | "text";
  permissions?: number[];
  size?: "small" | "medium" | "large";
  onClick?: (event) => void;
  id?: string;
  stopClickPropagation?: boolean;
  tabIndex?: number;
  children?: any | string;
  className?: string;
  autoFocus?: boolean;
}

const Button: FC<ButtonProperties> = (props) => {
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;
  const { permissions = [1, 2, 3] } = props; // TODO: @elara Update until Antonio's PR will be ready
  const hasPermission = usePermissions(permissions);

  const toShow = hasPermission ? (props.isDisabled ? false : true) : false;

  const customClickEvent = (event) => {
    props.stopClickPropagation && event.stopPropagation();
    props.onClick && props.onClick(event);
  };

  return (
    <MaButton
      tabIndex={props.tabIndex ?? 0}
      id={props.id}
      autoFocus={props.autoFocus ?? false}
      true-element={`true-element-button-${props.name ?? ""}`}
      fullWidth={props.fullWidth ?? false}
      variant={props.variantStyle ?? "contained"}
      disabled={!toShow}
      onClick={customClickEvent}
      size={props.size ?? "small"}
      disableElevation
      className={`${props.variantStyle ?? "contained"} ${
        props.className ?? ""
      }`}
      sx={{ ...buttonStyles(theme), ...props.sx }}
    >
      {props.children}
    </MaButton>
  );
};

export default Button;
