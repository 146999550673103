import {
  CheckBox,
  CheckBoxOutlineBlank,
  IndeterminateCheckBox,
} from "@mui/icons-material";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Switch as MaSwitch,
  Radio,
  SxProps,
  Theme,
  TypographyProps,
} from "@mui/material";
import { FC, useState } from "react";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import Font from "../Typography/Font";
import { switchStyles } from "./SwitchStyles";

type SwitchProperties = {
  id?: string;
  label?: string;
  isChecked?: boolean;
  isInvalid?: boolean;
  isCheckboxIndeterminate?: boolean;
  control?: "checkbox" | "switch" | "radio";
  labelPlacement?: "end" | "start" | "top" | "bottom";
  labelStyle?: SxProps<Theme>;
  labelFontType?: KeysAsType<FontsType>;
  labelTypographyProps?: { typography?: TypographyProps };
  name?: string;
  trueElement?: string;
  helperText?: string;
  readOnly?: boolean;
  inputWidth?: string;
  spaceBetweenLabelAndControl?: boolean;
  labelAlignment?: "left" | "center" | "right";
  primaryLabelColor?: boolean;
  className?: string;
  isTableCell?: boolean;
  onChangeRawValue?: (e?: any) => void;
  onChange?: (e?: any) => void;
  tabIndex?: number;
};

const labelDefaultStyle = {
  marginLeft: 0,
};

const labelDefaultTypographyProps: any = {
  typography: {
    width: "100%",
    textAlign: "left",
    marginLeft: 0,
  },
};

const Switch: FC<SwitchProperties> = ({
  id,
  label,
  trueElement,
  isChecked,
  isInvalid = false,
  isCheckboxIndeterminate = false,
  control,
  labelPlacement,
  labelFontType = "BOLD_BODY",
  onChange,
  onChangeRawValue,
  labelStyle = labelDefaultStyle,
  labelTypographyProps = labelDefaultTypographyProps,
  name,
  helperText,
  readOnly,
  inputWidth = "100%",
  spaceBetweenLabelAndControl,
  labelAlignment = "left",
  primaryLabelColor = false,
  className,
  isTableCell = false,
  tabIndex = 0,
}) => {
  const [showHelpText, setShowHelpText] = useState<boolean>(false);
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;

  const controlInputProps = {
    id: id ?? name,
  };

  const getControl = (controlSwitch, inputProps) => {
    switch (controlSwitch) {
      case "checkbox":
        return (
          <Checkbox
            inputProps={inputProps}
            icon={<CheckBoxOutlineBlank />}
            checkedIcon={
              isCheckboxIndeterminate ? <IndeterminateCheckBox /> : <CheckBox />
            }
            true-element={trueElement ? trueElement : `true-${control}-${name}`}
          />
        );
      case "radio":
        return (
          <Radio
            color="primary"
            inputProps={inputProps}
            true-element={trueElement ? trueElement : `true-${control}-${name}`}
          />
        );
      default:
        return (
          <MaSwitch
            color="primary"
            inputProps={inputProps}
            true-element={trueElement ? trueElement : `true-${control}-${name}`}
          />
        );
    }
  };

  const getAlignmentClass = () => {
    switch (labelAlignment) {
      case "center":
        return "align-label-center";
      case "right":
        return "align-label-right";
      default:
        return "align-label-left";
    }
  };

  const getStateOfControl = () => {
    if (control === "switch") {
      return isChecked ? "switch-selected" : "switch-unselected";
    } else {
      if (isInvalid) {
        return "invalid";
      } else if ((!readOnly && isChecked) || (isTableCell && isChecked)) {
        return "selected";
      } else if ((!readOnly && !isChecked) || (isTableCell && !isChecked)) {
        return "unselected";
      }
      return "disabled";
    }
  };

  return (
    <FormGroup tabIndex={tabIndex}>
      <FormControlLabel
        className={`${className} ${getStateOfControl()}`}
        disabled={readOnly}
        onMouseEnter={() => {
          setShowHelpText(true);
        }}
        onMouseLeave={() => {
          setShowHelpText(false);
        }}
        control={getControl(control, controlInputProps)}
        label={
          label != null && (
            <Font
              name={name}
              className={`true-switch-label ${
                labelFontType === undefined ? "withoutSize" : ""
              } ${getAlignmentClass()}`}
              primaryColor={primaryLabelColor}
              fontType={labelFontType}
            >
              {label}
            </Font>
          )
        }
        labelPlacement={labelPlacement}
        checked={isChecked ?? false}
        onChange={(e: any) => {
          onChangeRawValue?.(e.target.checked);
          onChange?.(e);
        }}
        sx={{
          ...labelStyle,
          ...switchStyles(theme, inputWidth, spaceBetweenLabelAndControl),
        }}
        componentsProps={labelTypographyProps}
        {...controlInputProps}
      />
      {helperText && (
        <FormHelperText>{showHelpText ? helperText : " "}</FormHelperText>
      )}
    </FormGroup>
  );
};

export default Switch;
