import { atom } from "recoil";
import { Location } from "react-router";
import { PolicyBlob } from "./dtos/policy-blob";
import { NavBarEnum } from "./components/NavBar/NavBar";
import { Theme } from "./media/themeTypes";
import themes from "./media/TrueTheme";
import { PortalInitOptionsDto } from "./dtos/portal-init-options-dto";
import { ThemeDto } from "./dtos/theme-dto";

type UnsavedChangesProperties = {
  url: string;
  unsavedChanges: UnsavedChangesDataProperties[];
};

type UnsavedChangesDataProperties = {
  originalData?: any;
  originalHash?: number;
  newData?: any;
  newHash?: number;
};

export type GlobalOptions = {
  theme: Theme | ThemeDto;
  initOptions?: PortalInitOptionsDto;
};

const hasAppAccess = atom<boolean>({
  key: "hasAppAccess",
  default: false,
});

const isTenantSelected = atom<boolean>({
  key: "isTenantSelected",
  default: false,
});
const hasLocationChanged = atom<Location | null>({
  key: "hasLocationChanged",
  default: null,
});

const globalIsLoading = atom<boolean>({
  key: "globalIsLoading",
  default: false,
});

const globalIsDisabledElementAtom = atom<boolean>({
  key: "globalDisableElementAtom",
  default: false,
});

const globalIsSaving = atom<boolean>({
  key: "globalIsSaving",
  default: false,
});

const globalUnsavedChanges = atom<UnsavedChangesProperties | null>({
  key: "globalUnsavedChanges",
  default: null,
});

const globalOptions = atom<GlobalOptions>({
  key: "globalOptions",
  default: {
    theme: themes["defaultTheme"],
  },
});

const tmpPermissionsAtom = atom<number[]>({
  key: "tmpPermissionsAtom",
  default: [1, 2, 3], // TODO: @elara Update until Antonio's PR will be ready
});

const acord130FormProperties = atom<PolicyBlob | null>({
  key: "acord130FormAtom",
  default: null,
});

const navBarActiveSection = atom<NavBarEnum>({
  key: "navBarActiveSection",
  default: NavBarEnum.HOME,
});

export {
  hasAppAccess,
  isTenantSelected,
  hasLocationChanged,
  globalIsLoading,
  globalIsDisabledElementAtom,
  globalIsSaving,
  globalUnsavedChanges,
  globalOptions,
  tmpPermissionsAtom,
  acord130FormProperties,
  navBarActiveSection,
};
