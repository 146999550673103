import { Route, Routes } from "react-router-dom";

import RouteError from "./RouteError";
import Documents from "./components/Documents/Documents";
import { PortalAreaEnum } from "./dtos/portal-area-enum";
import AgencyDashboard from "./components/AgencyDashboard/AgencyDashboard";

const AgencyAppRoutes = (
  <Routes>
    <Route path="/home" element={<AgencyDashboard />} />
    <Route path="/" element={<AgencyDashboard />} />
    <Route path="*" element={<RouteError />} />
    <Route
      path="/documents-and-forms"
      element={<Documents portalArea={PortalAreaEnum?.GENERAL_DOCUMENTS} />}
    />
    <Route
      path="/safety-and-loss-control"
      element={
        <Documents portalArea={PortalAreaEnum?.LOSS_CONTROL_DOCUMENTS} />
      }
    />
  </Routes>

  // <Redirect from="*" to="/" />
);

export default AgencyAppRoutes;
