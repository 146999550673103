const INCIDENT_ID = "incid";
const INCIDENT_BODY_SECTION = "incbsec";
const INCIDENT_STEPPER_SECTION = "incstsec";
const POLICY_ID = "polid";

export {
  INCIDENT_ID,
  INCIDENT_BODY_SECTION,
  INCIDENT_STEPPER_SECTION,
  POLICY_ID,
};
