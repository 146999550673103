import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { BaseTableDisplayOptions } from "../../../../dtos/base-table-display-options";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import { BillingTransactionDataDto } from "../../../../dtos/billing-transaction-data-dto";
import { ProcessorChargeFeeTypeEnum } from "../../../../dtos/processor-charge-fee-type-enum";
import { currencyFormat } from "../../../../utilities/currencyFunctions";
import { unFormatLocalString } from "../../../../utilities/stringFunctions";
import { Col, Font, FontBold, Row } from "../../../TrueUI";
import { rowWithNoMarginNorGutter } from "../../../TrueUI/Grids/Row";
import {
  ComputeForCellParameters,
  ConditionForCellResponse,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { getDataIndexByColumnName } from "../../../TrueUI/Tables/tableFunctions";
import style from "./AccountAndBalance.module.css";

export const policyCellInitCondition = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  if (options.row?.BillingTransactionId === "") {
    return {
      value: options.row?.PolicyId,
      typeId: BaseTableInputType.SELECT,
      isEditable: (options?.currentValue ?? "") === "" ? false : true,
    };
  }
};

export const policyNumberCellOnChangeCondition = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  if (options.row?.BillingTransactionId === "") {
    return {
      value: options.currentValue,
      typeId: BaseTableInputType.SELECT,
      triggerComputeFieldNames: ["PolicyId"],
    };
  }
};

export const policyIdCellOnChangeCondition = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  if (options.row?.BillingTransactionId === "") {
    return {
      value: options.row?.PolicyNumber,
    };
  }
};

export const tranAccountIdCellOnChangeCondition = (
  options: ComputeForCellParameters<any>
): ConditionForCellResponse | void => {
  if (options.row?.BillingTransactionId === "") {
    return {
      value: options.row?.Account,
    };
  }
};

export const descriptionCellOnChangeCondition = (
  options: ComputeForCellParameters<any>,
  tranAccountOptions: BaseTableDisplayOptions[]
): ConditionForCellResponse | void => {
  if (options.row?.BillingTransactionId === "") {
    const newValue = tranAccountOptions.find(
      (x) => x.value === options.row?.Account
    );
    return {
      value: newValue?.text ?? "",
      typeId: BaseTableInputType.READONLY,
      isEditable: false,
    };
  }
};

export const getRowValueByIndex = (row: any, rowIndex: number) => {
  return row[rowIndex] === ""
    ? null
    : Number(unFormatLocalString(row[rowIndex]));
};

export const getTextOfSelectedItem = (
  idValue: number | null,
  displayOptions: BaseTableDisplayOptions[] | null
) => {
  return idValue === null
    ? null
    : getTextFromDisplayOptionsItemByValue(idValue, displayOptions ?? []);
};

const getTextFromDisplayOptionsItemByValue = (
  value: number | null,
  displayOptions: BaseTableDisplayOptions[]
): string => {
  if (value === null) return "";
  return displayOptions.find((x) => Number(x.value) === value)?.text ?? "";
};

export const getTotalPayment = (billingData: BillingTransactionDataDto[]) => {
  const payments = billingData?.map((data) => data.payment);
  if (payments.length > 0) return payments.reduce((x, y) => x + y);
  return 0;
};

export const getTotalFee = (
  totalPayment: number,
  feeType: number,
  fee: number
) => {
  switch (feeType) {
    case ProcessorChargeFeeTypeEnum.FLAT:
      return fee;
    case ProcessorChargeFeeTypeEnum.PERCENT:
      return Math.ceil(totalPayment * fee) / 100;
    default:
      return 0;
  }
};

export const FeeRowCustomFooter = (
  account: string,
  description: string,
  initialFee: number,
  updatedFee: number
) => {
  return (
    <Row
      className={style.account_and_balance_fee_row}
      {...rowWithNoMarginNorGutter}
      numberOfColumns={50}
      rowHeight="32px"
    >
      <Col horizontalMargin="0px" />
      <Col
        horizontalMargin="0px"
        horizontalAlign="flex-end"
        verticalAlign="center"
      >
        <Font>{account}</Font>
      </Col>
      <Col
        horizontalMargin="0px"
        horizontalAlign="flex-start"
        verticalAlign="center"
      >
        <Font>{description}</Font>
      </Col>
      <Col
        horizontalMargin="0px"
        horizontalAlign="flex-end"
        verticalAlign="center"
      >
        <Font>{currencyFormat(initialFee, false, 2, true)}</Font>
      </Col>
      <Col
        horizontalMargin="0px"
        horizontalAlign="flex-end"
        verticalAlign="center"
      >
        <Font>{currencyFormat(updatedFee, false, 2, true)}</Font>
        <div style={{ width: "7px" }} />
      </Col>
    </Row>
  );
};

export const TotalPaymentCustomFooter = (totalPayment: number) => {
  return (
    <Row {...rowWithNoMarginNorGutter} numberOfColumns={50} rowHeight="45px">
      <Col breakpoints={{ md: 44, lg: 44, xl: 44 }} />
      <Col
        horizontalMargin="0px"
        breakpoints={{ md: 5.2, lg: 5.2, xl: 5.2 }}
        horizontalAlign="flex-end"
        verticalAlign="center"
      >
        <FontBold>{currencyFormat(totalPayment, false, 2, true)}</FontBold>
      </Col>
      <Col breakpoints={{ md: 0.8, lg: 0.8, xl: 0.8 }} />
    </Row>
  );
};

export const BillingTransactionFromTableData = (
  data: string[][],
  columns: BaseTableColumn[]
) => {
  const billTranIdIndex = getDataIndexByColumnName(
    columns,
    "BillingTransactionId"
  );
  const policyIdIndex = getDataIndexByColumnName(columns, "PolicyId");
  const policyNumberIndex = getDataIndexByColumnName(columns, "PolicyNumber");
  const accountIndex = getDataIndexByColumnName(columns, "Account");
  const descriptionIndex = getDataIndexByColumnName(columns, "Description");
  const paymentIndex = getDataIndexByColumnName(columns, "Payment");
  const tranAccountIdIndex = getDataIndexByColumnName(columns, "TranAccountId");
  const baseTableRowKeyIndex = getDataIndexByColumnName(columns, "_row_key");

  const originalItemsWithPaymentData: BillingTransactionDataDto[] = data
    .filter(
      (row) => row[paymentIndex] !== "" && Number(row[paymentIndex]) !== 0
    )
    .map((row: string[]) => {
      const policyId = getRowValueByIndex(row, policyIdIndex);
      const tranAccountId = getRowValueByIndex(row, tranAccountIdIndex);
      const policyNumber = getTextOfSelectedItem(
        policyId,
        columns[policyNumberIndex]?.displayOptions
      );
      const account = getTextOfSelectedItem(
        tranAccountId,
        columns[accountIndex]?.displayOptions
      );

      return {
        billingTransactionId: Number(row[billTranIdIndex]),
        policyId: policyId === 0 ? null : policyId,
        policyNumber: policyNumber,
        description: row[descriptionIndex],
        payment: getRowValueByIndex(row, paymentIndex) ?? 0,
        tranAccountId: tranAccountId === 0 ? null : tranAccountId,
        account: account,
        isBaseTableRowDeleted: false,
        baseTableRowKey: row[baseTableRowKeyIndex],
      };
    });

  return originalItemsWithPaymentData;
};
