import { Box, Container } from "@mui/material";
import { FC } from "react";
import { Heading3 } from "../TrueUI";
import IncidentsTable from "./IncidentsTable";
import ClaimsTable from "./ClaimsTable";

const MainClaims: FC = () => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "0 24px",
          width: "100%",
          maxWidth: "1200px",
        }}
      >
        <Heading3 textAlign="start" display="block" whiteSpace="normal">
          Claims
        </Heading3>
      </div>
      <Container sx={{ minWidth: "300px", marginBottom: "10px" }} maxWidth="lg">
        <Box sx={{ height: "fit-content" }}>
          <IncidentsTable />
          <ClaimsTable />
        </Box>
      </Container>
    </div>
  );
};

export default MainClaims;
