import { FC } from "react";
import { useFileRequestInstance } from "../../../hooks/useFileStorage";
import Button from "../Buttons/Button";

type DownloadButtonProps = {
  fileName: string;
  variantStyle?: "contained" | "outlined" | "text";
  children?: any;
  onClick?: () => void;
};

const DownloadButton: FC<DownloadButtonProps> = ({
  fileName,
  children,
  variantStyle,
  onClick,
}) => {
  const { downloadFile } = useFileRequestInstance();
  return (
    <Button
      variantStyle={variantStyle}
      name={fileName}
      onClick={() => {
        downloadFile(fileName);
        onClick?.();
      }}
    >
      {children}
    </Button>
  );
};

export default DownloadButton;
