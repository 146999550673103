import { Theme } from "../../../media/themeTypes";

export const dialogStyle = () => ({
  width: "400px",
  margin: "0 auto",
  "#dialogConfirmationDescription .dialog-content": {
    width: "100%",
    justifyContent: "center",
  },
  ".ribbon-node-container": {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    width: "100%",
    ".ribbon-node": {
      flexGrow: 1,
    },
  },
});

export const paperStyles = (theme: Theme) => ({
  background: theme?.primaryBackground,
  "& p": {
    color: theme?.textColor,
  },
});
