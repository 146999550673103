import { FC, useEffect } from "react";
import { BaseTable2, Collapse } from "../TrueUI";
import { PayrollReportsPage } from "../../dtos/payroll-reports-page";
import { useApiGet } from "../../hooks";
import { conditionHasValue } from "../../utilities/conditionalSupportFunctions";
import { useBaseTable } from "../../hooks/useBaseTable";
import { BaseTable2Properties } from "../TrueUI/Tables/BaseTable2/TableProperties";
import BaseTablePolicyDropdownAsyncFilter from "../TrueUI/Tables/BaseTableCommonFilters/BaseTablePolicyDropdownAsyncFilter";
import { DROPDOWN_FILTER } from "../TrueUI/Tables/TableConstants";
import { Box, IconButton } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PayrollReports: FC = () => {
  const navigate = useNavigate();

  const { responseGet, dispatchGet } = useApiGet<PayrollReportsPage>(
    "api/Billing/GetPayrollReportsInformation"
  );

  const payrollReportData = responseGet?.axiosResponse?.data;

  useEffect(() => {
    dispatchGet();
  }, []);

  const tableName = "billing-payroll-reports-table";

  const newFilterColumns = (e) => {
    if (e != "Active") {
      return [
        {
          columnNames: ["PolicyId"],
          filterValue: e.toString(),
          filterName: DROPDOWN_FILTER,
        },
      ];
    } else {
      return [
        {
          columnNames: ["FilterStatus"],
          filterValue: e,
          filterName: DROPDOWN_FILTER,
        },
      ];
    }
  };

  const PayRollReportComponent = (actionOptions) => {
    return (
      <Box>
        <IconButton
          aria-label="save"
          size="small"
          onClick={() => {
            navigate(`/payroll-report/${actionOptions?.row?.PayrollId}`);
          }}
        >
          <span
            className="k-icon k-font-icon k-i-hyperlink-open-sm"
            style={{ fontSize: "20px", color: "#000" }}
          ></span>
        </IconButton>
      </Box>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    columnsAndData: {
      columns: payrollReportData?.tableData?.columns ?? [],
      data: payrollReportData?.tableData?.data ?? [],
    },
    columnOptions: [
      { fieldName: "Policy", width: 22 },
      { fieldName: "Balance", align: "right" },
      { fieldName: "Amount", align: "right" },
      { fieldName: "PayrollReportingPeriod", width: 22 },
      { fieldName: "Status", width: 15 },
    ],
    filterOptions: [
      (actionOptions) =>
        BaseTablePolicyDropdownAsyncFilter({
          selectWidth: "230px",
          columnsNames: ["FilterStatus"],
          filterOptions: actionOptions,
          labelText: "Policy:",
          onChangeAfterAPI: false,
          defaultValue: "Active",
          overrideAllOptionLabel: "All Active",
          overrideAllOptionValue: "Active",
          onChange: (value) => {
            actionOptions?.setFilterInclude?.(newFilterColumns(value));
          },
        }),
    ],
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
    },
    advancedOptions: {
      paginate: false,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          PayRollReportComponent(actionOptions),
      },
    },
  };

  useBaseTable(tableConfiguration);

  return (
    <>
      {conditionHasValue(payrollReportData?.showPayrollInformation) &&
        payrollReportData?.showPayrollInformation && (
          <Collapse
            id={"billing-payroll-reports-collapse"}
            title="Payroll Reports"
            titleHeightAuto
            content={
              <div style={{ height: "215px", marginTop: "-15px" }}>
                <BaseTable2 name={tableName} />
              </div>
            }
          />
        )}
    </>
  );
};
export default PayrollReports;
