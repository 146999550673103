import { CardProviderEnums } from "../dtos/card-provider-enums";
import { SubSideNavChildrenIndexEnum } from "../dtos/sub-side-nav-children-index-enum";
import { SubSideNavItemDestinationsEnum } from "../dtos/sub-side-nav-item-destinations-enum";
import { SubSideNavParentsIndexEnum } from "../dtos/sub-side-nav-parents-index-enum";

export function getStates() {
  return [
    { name: "ALABAMA", abbreviation: "AL" },
    { name: "ALASKA", abbreviation: "AK" },
    { name: "AMERICAN SAMOA", abbreviation: "AS" },
    { name: "ARIZONA", abbreviation: "AZ" },
    { name: "ARKANSAS", abbreviation: "AR" },
    { name: "CALIFORNIA", abbreviation: "CA" },
    { name: "COLORADO", abbreviation: "CO" },
    { name: "CONNECTICUT", abbreviation: "CT" },
    { name: "DELAWARE", abbreviation: "DE" },
    { name: "DISTRICT OF COLUMBIA", abbreviation: "DC" },
    { name: "FEDERATED STATES OF MICRONESIA", abbreviation: "FM" },
    { name: "FLORIDA", abbreviation: "FL" },
    { name: "GEORGIA", abbreviation: "GA" },
    { name: "GUAM", abbreviation: "GU" },
    { name: "HAWAII", abbreviation: "HI" },
    { name: "IDAHO", abbreviation: "ID" },
    { name: "ILLINOIS", abbreviation: "IL" },
    { name: "INDIANA", abbreviation: "IN" },
    { name: "IOWA", abbreviation: "IA" },
    { name: "KANSAS", abbreviation: "KS" },
    { name: "KENTUCKY", abbreviation: "KY" },
    { name: "LOUISIANA", abbreviation: "LA" },
    { name: "MAINE", abbreviation: "ME" },
    { name: "MARSHALL ISLANDS", abbreviation: "MH" },
    { name: "MARYLAND", abbreviation: "MD" },
    { name: "MASSACHUSETTS", abbreviation: "MA" },
    { name: "MICHIGAN", abbreviation: "MI" },
    { name: "MINNESOTA", abbreviation: "MN" },
    { name: "MISSISSIPPI", abbreviation: "MS" },
    { name: "MISSOURI", abbreviation: "MO" },
    { name: "MONTANA", abbreviation: "MT" },
    { name: "NEBRASKA", abbreviation: "NE" },
    { name: "NEVADA", abbreviation: "NV" },
    { name: "NEW HAMPSHIRE", abbreviation: "NH" },
    { name: "NEW JERSEY", abbreviation: "NJ" },
    { name: "NEW MEXICO", abbreviation: "NM" },
    { name: "NEW YORK", abbreviation: "NY" },
    { name: "NORTH CAROLINA", abbreviation: "NC" },
    { name: "NORTH DAKOTA", abbreviation: "ND" },
    { name: "NORTHERN MARIANA ISLANDS", abbreviation: "MP" },
    { name: "OHIO", abbreviation: "OH" },
    { name: "OKLAHOMA", abbreviation: "OK" },
    { name: "OREGON", abbreviation: "OR" },
    { name: "PALAU", abbreviation: "PW" },
    { name: "PENNSYLVANIA", abbreviation: "PA" },
    { name: "PUERTO RICO", abbreviation: "PR" },
    { name: "RHODE ISLAND", abbreviation: "RI" },
    { name: "SOUTH CAROLINA", abbreviation: "SC" },
    { name: "SOUTH DAKOTA", abbreviation: "SD" },
    { name: "TENNESSEE", abbreviation: "TN" },
    { name: "TEXAS", abbreviation: "TX" },
    { name: "UTAH", abbreviation: "UT" },
    { name: "VERMONT", abbreviation: "VT" },
    { name: "VIRGIN ISLANDS", abbreviation: "VI" },
    { name: "VIRGINIA", abbreviation: "VA" },
    { name: "WASHINGTON", abbreviation: "WA" },
    { name: "WEST VIRGINIA", abbreviation: "WV" },
    { name: "WISCONSIN", abbreviation: "WI" },
    { name: "WYOMING", abbreviation: "WY" },
  ];
}
export const getPhoneTypeSelectOptions = () => ["Home", "Mobile", "Work"];

export function getYesNo() {
  return [
    { name: "Yes", value: "1" },
    { name: "No", value: "0" },
  ];
}

export function getSelectBusinessTypes() {
  return [
    "Corporation",
    "Sole Proprietor",
    "Individual",
    "Joint Venture",
    "Partnership",
    "Limited Corp",
    "Subchapter S Corp",
  ];
}

export function getSelectRelationshipTypes() {
  return ["Named Insured"];
}
export function getUserPreferencesColorPalette() {
  return [
    "#a9d4fe",
    "#fbc2b7",
    "#ffdb9d",
    "#77fb95",
    "#fbb9f4",
    "#fbaabd",
    "#ced4dA",
    "#94c4f4",
    "#f39c92",
    "#fdd37f",
    "#62db7e",
    "#e282d9",
    "#ee90a2",
    "#adb5bd",
    "#7ab4e6",
    "#e6766b",
    "#fac865",
    "#4fba6a",
    "#a04f92",
    "#cb707f",
    "#6c757d",
    "#569cd8",
    "#d35348",
    "#f3b93c",
    "#3d9855",
    "#803270",
    "#af5161",
    "#495057",
    "#2e7bc9",
    "#ce342c",
    "#f3af30",
    "#287642",
    "#6b1b60",
    "#852735",
    "#343A40",
    "#065eb7",
    "#bb201b",
    "#ffa920",
    "#13562b",
    "#421149",
    "#5a0d17",
    "#212529",
  ];
}

export function getMonths() {
  return [
    { name: "January", value: "1" },
    { name: "February", value: "2" },
    { name: "March", value: "3" },
    { name: "April", value: "4" },
    { name: "May", value: "5" },
    { name: "June", value: "6" },
    { name: "July", value: "7" },
    { name: "August", value: "8" },
    { name: "September", value: "9" },
    { name: "October", value: "10" },
    { name: "November", value: "11" },
    { name: "December", value: "12" },
  ];
}

export const getParentItemByInsuredSection = (insuredSection: number) => {
  //Add new Sections here
  switch (insuredSection) {
    case SubSideNavItemDestinationsEnum?.MAIN:
    case SubSideNavItemDestinationsEnum?.INFORMATION:
    case SubSideNavItemDestinationsEnum?.USERS:
      return SubSideNavParentsIndexEnum?.INSURED;
    case SubSideNavItemDestinationsEnum?.HISTORY:
      return SubSideNavParentsIndexEnum?.POLICY;
    case SubSideNavItemDestinationsEnum?.CLAIMS_LANDING:
    case SubSideNavItemDestinationsEnum?.CLAIMS:
    case SubSideNavItemDestinationsEnum?.INCIDENTS:
    case SubSideNavItemDestinationsEnum?.INCIDENTS_INCIDENT_SECTIONS:
      return SubSideNavParentsIndexEnum?.CLAIMS;
    default:
      return SubSideNavParentsIndexEnum?.INSURED;
  }
};

export const getChildItemByInsuredSection = (insuredSection: number) => {
  //Add new Sections here
  switch (insuredSection) {
    case SubSideNavItemDestinationsEnum?.MAIN:
      return SubSideNavChildrenIndexEnum?.INSURED_MAIN;
    case SubSideNavItemDestinationsEnum?.INFORMATION:
      return SubSideNavChildrenIndexEnum?.INSURED_INFORMATION;
    case SubSideNavItemDestinationsEnum?.USERS:
      return SubSideNavChildrenIndexEnum?.INSURED_USERS;
    case SubSideNavItemDestinationsEnum?.HISTORY:
      return SubSideNavChildrenIndexEnum?.POLICY_HISTORY;
    case SubSideNavItemDestinationsEnum?.CLAIMS:
    case SubSideNavItemDestinationsEnum?.CLAIMS_LANDING:
      return SubSideNavChildrenIndexEnum?.CLAIMS_CLAIMS;
    case SubSideNavItemDestinationsEnum?.INCIDENTS:
    case SubSideNavItemDestinationsEnum?.INCIDENTS_INCIDENT_SECTIONS:
      return SubSideNavChildrenIndexEnum?.CLAIMS_INCIDENTS;
    default:
      return 0;
  }
};

export const getCardProviderMaxLength = (cardNumber: number) => {
  // Remove any non-numeric characters from the card number
  const cardNumberString = cardNumber?.toString()?.replace(/\D/g, "");

  // Define the IIN/BIN ranges for different card providers
  const cardProviders = [
    {
      id: CardProviderEnums.VISA,
      prefix: ["4"],
      cardNumberAllowedLengths: [13, 16],
    },
    {
      id: CardProviderEnums.MASTER_CARD,
      prefix: ["51", "52", "53", "54", "55"],
      length: [16],
    },
    {
      id: CardProviderEnums.AMERICAN_EXPRESS,
      prefix: ["34", "37"],
      cardNumberAllowedLengths: [15],
    },
    // Add more card providers as needed
  ];

  const selectedProvider = cardProviders.find((provider) =>
    provider.prefix.some((prefix) => cardNumberString?.startsWith(prefix))
  );

  return selectedProvider?.cardNumberAllowedLengths ?? [16]; // If no provider is matched defaults to 16
};

export type AbbrMonthsProps = {
  name: string;
  abbreviation: string;
  value: number;
};

export const abbreviatedMonths: AbbrMonthsProps[] = [
  { name: "January", abbreviation: "Jan", value: 1 },
  { name: "February", abbreviation: "Feb", value: 2 },
  { name: "March", abbreviation: "Mar", value: 3 },
  { name: "April", abbreviation: "Apr", value: 4 },
  { name: "May", abbreviation: "May", value: 5 },
  { name: "June", abbreviation: "Jun", value: 6 },
  { name: "July", abbreviation: "Jul", value: 7 },
  { name: "August", abbreviation: "Aug", value: 8 },
  { name: "September", abbreviation: "Sep", value: 9 },
  { name: "October", abbreviation: "Oct", value: 10 },
  { name: "November", abbreviation: "Nov", value: 11 },
  { name: "December", abbreviation: "Dec", value: 12 },
];