import { Theme } from "../../../media/themeTypes";

export const switchStyles = (
  theme: Theme,
  inputWidth,
  spaceBetweenLabelAndControl
) => ({
  marginRight: 0,
  width: inputWidth,
  justifyContent: spaceBetweenLabelAndControl ? "space-between" : "inherit",
  "&.invalid": { ...getCorrectStyles(theme?.danger) },
  "&.selected": { ...getCorrectStyles(theme?.primary) },
  "&.unselected": { ...getCorrectStyles(theme?.borderModalColor) },
  "&.disabled": { ...getCorrectStyles(theme?.borderColor) },
  "&.switch-selected": { ...getCorrectStyles(theme?.primary) },
  "&.switch-unselected": { ...getCorrectStyles("#FFF") },
  ".Mui-checked+.MuiSwitch-track": {
    backgroundColor: `${theme?.primary} !important`,
    opacity: 1,
  },
  "& .MuiFormControlLabel-label": {
    whiteSpace: "nowrap",
    overflow: "hidden !important",
    textOverflow: "ellipsis",
  },
  ".PrivateSwitchBase-input": {
    display: "none",
  },
});

const getCorrectStyles = (color) => ({
  ".MuiButtonBase-root": {
    color: color,
    svg: {
      color: color,
    },
  },
});
