import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { FC, useState } from "react";
import style from "./CategoryButtonsCollapse.module.css";
import FontBold from "../Typography/FontBold";

type ButtonCollapseProps = {
  id?: string;
  name?: string;
  expanded?: boolean;
  children?: any;
  title?: string;
  setExpanded?: (expanded: boolean) => void;
  onChange?: (e: any) => void;
};

const CategoryButtonCollapse: FC<ButtonCollapseProps> = ({
  id,
  name,
  expanded = false,
  setExpanded,
  onChange,
  children,
  title = "",
}) => {
  const [expandedCollapse, setExpandedCollapse] = useState<boolean>(expanded);

  const handleChange = () => {
    setExpanded?.(!expandedCollapse);
    onChange?.(!expandedCollapse);
    setExpandedCollapse(!expandedCollapse);
  };

  return (
    <Accordion
      true-element={`true-element-button-collapse-${name}`}
      className={style.button_collapse_primary}
      onChange={handleChange}
      expanded={expandedCollapse}
      elevation={0}
    >
      <AccordionSummary
        aria-controls={`panel${id}-content`}
        id={id}
        className={"title_button_collapse_primary"}
      >
        <FontBold primaryColor>{title}</FontBold>
      </AccordionSummary>
      {expandedCollapse && (
        <AccordionDetails className={style.button_collapse_content_primary}>
          {children}
        </AccordionDetails>
      )}
    </Accordion>
  );
};
export default CategoryButtonCollapse;
