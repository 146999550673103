import { FC, useEffect, useState } from "react";
import Box from "@mui/material/Box";

import {
  descriptionCellOnChangeCondition,
  policyIdCellOnChangeCondition,
  tranAccountIdCellOnChangeCondition,
  getTotalPayment,
  policyCellInitCondition,
  policyNumberCellOnChangeCondition,
  getTotalFee,
  TotalPaymentCustomFooter,
  FeeRowCustomFooter,
  BillingTransactionFromTableData,
} from "./AccountAndBalanceSectionUtil";
import { BillingTransactionDataDto } from "../../../../dtos/billing-transaction-data-dto";
import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { BaseTable2Properties } from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { BaseTable2 } from "../../../TrueUI";
import { useBaseTable } from "../../../../hooks/useBaseTable";
import style from "./AccountAndBalance.module.css";
import { BaseTableDisplayOptions } from "../../../../dtos/base-table-display-options";
import { ProcessorTypeEnum } from "../../../../dtos/processor-type-enum";
import { useApiGet } from "../../../../hooks";
import { AccountAndBalancePage } from "../../../../dtos/account-and-balance-page";
import { isAPITotallyComplete } from "../../../../utilities/apiFunctions";

export type AccountAndBalanceSectionProps = {
  insuredId?: number;
  errorDetails?: any;
  tranAccountOptions?: BaseTableDisplayOptions[];
  setBillingTransactionData?: (value: BillingTransactionDataDto[]) => void;
  processorType: ProcessorTypeEnum;
  hideToolbar?: boolean;
};

type TableData = { columns: BaseTableColumn[]; data: string[][] };

const AccountAndBalanceSection: FC<AccountAndBalanceSectionProps> = ({
  insuredId,
  errorDetails,
  tranAccountOptions,
  setBillingTransactionData,
  processorType,
  hideToolbar = true,
}) => {
  const [accountAndBalanceData, setAccountAndBalanceData] =
    useState<Partial<AccountAndBalancePage>>();
  const { responseGet, dispatchGet } = useApiGet<AccountAndBalancePage>(
    `api/BillingPayments/GetAccountAndBalancePage?insuredId=${insuredId}&processorType=${processorType}`
  );
  const [tableData, setTableData] = useState<TableData>({
    columns: [],
    data: [],
  });

  useEffect(() => {
    dispatchGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setAccountAndBalanceData(responseGet?.responseData ?? undefined);
    }
  }, [responseGet]);

  useEffect(() => {
    const billingData = BillingTransactionFromTableData(
      tableData?.data,
      tableData?.columns
    );

    const totalPayment = getTotalPayment(billingData);
    if (accountAndBalanceData?.chargeFee === true) {
      const fee = getTotalFee(
        totalPayment,
        accountAndBalanceData?.feeType ?? 0,
        accountAndBalanceData?.fee ?? 0
      );
      setAccountAndBalanceData((prev) => ({
        ...prev,
        totalPayment: totalPayment + fee,
        calcFee: fee,
      }));
      const feeBillingData: BillingTransactionDataDto = {
        billingTransactionId: 0,
        policyId: accountAndBalanceData.policyId ?? 0,
        policyNumber: accountAndBalanceData.policyNumber ?? "",
        tranAccountId: accountAndBalanceData?.feeTranAccountId ?? null,
        account: accountAndBalanceData?.account ?? null,
        payment: fee,
        description: accountAndBalanceData?.chargeFeeDescription ?? "",
      };
      setBillingTransactionData?.([...billingData, feeBillingData]);
    }
    if (accountAndBalanceData?.chargeFee !== true) {
      if (accountAndBalanceData !== undefined) {
        setAccountAndBalanceData((prev) => ({
          ...prev,
          totalPayment: totalPayment,
        }));
      }
      setBillingTransactionData?.(billingData);
    }
  }, [tableData]);

  const onDataChangeEvent = (data: string[][], columns: BaseTableColumn[]) => {
    setTableData({ columns, data });
  };

  const getFeeRow = () =>
    processorType === ProcessorTypeEnum.CREDIT_CARD ? (
      FeeRowCustomFooter(
        accountAndBalanceData?.account ?? "",
        accountAndBalanceData?.chargeFeeDescription ?? "",
        accountAndBalanceData?.initialFee ?? 0,
        accountAndBalanceData?.calcFee ?? 0
      )
    ) : (
      <></>
    );

  const tableName = "AccountAndBalanceTable";
  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    columnsAndData: accountAndBalanceData?.accountAndBalanceTable,
    columnOptions: [
      {
        fieldName: "PolicyNumber",
        width: 20,
        computeOnInit: {
          conditionForCell: [(options) => policyCellInitCondition(options)],
        },
        computeOnChange: {
          conditionForCell: [
            (options) => policyNumberCellOnChangeCondition(options),
          ],
        },
      },
      {
        fieldName: "PolicyId",
        computeOnChange: {
          conditionForCell: [
            (options) => policyIdCellOnChangeCondition(options),
          ],
        },
      },
      {
        fieldName: "Account",
        width: 20,
        selectMaxHeight: "200px",
        align: "right",
      },
      {
        fieldName: "TranAccountId",
        computeOnChange: {
          conditionForCell: [
            (options) => tranAccountIdCellOnChangeCondition(options),
          ],
        },
      },
      {
        fieldName: "Description",
        width: 20,
        computeOnChange: {
          conditionForCell: [
            (options) =>
              descriptionCellOnChangeCondition(
                options,
                tranAccountOptions ?? []
              ),
          ],
        },
      },
      { fieldName: "Balance", width: 20, align: "right" },
      {
        fieldName: "Payment",
        width: 20,
        maxNumericValue: 99999999.99,
        minNumericValue: 0.0,
        allowNegatives: false,
        align: "right",
      },
    ],
    toolbarOptions: {
      hideToolbar: hideToolbar,
      toolBarType: "compact",
      showEditButton: false,
      showSaveButton: false,
      showAddButton: false,
      showExcelButton: false,
      showImportButton: false,
      showPDFButton: false,
      showSortFilter: false,
    },
    advancedOptions: {
      paginate: false,
      isEditMode: true,
      disableOrderBy: true,
      tableStyle: {
        height: "auto",
        headerStyle: "transparent",
      },
      tableErrors: errorDetails,
    },
    events: {
      onDataChange: (data, columns) => onDataChangeEvent(data, columns),
    },
    footerOptions: {
      CustomFooterComponent: () => getFeeRow(),
    },
  };

  useBaseTable([tableConfiguration]);

  return accountAndBalanceData !== undefined ? (
    <>
      <Box
        id={"account-and-balance-table-wrapper"}
        className={style.account_and_balance_table_wrapper}
      >
        <BaseTable2 name={tableName} />
      </Box>
      {TotalPaymentCustomFooter(accountAndBalanceData?.totalPayment ?? 0)}
    </>
  ) : (
    <div>{"Loading..."}</div>
  );
};

export default AccountAndBalanceSection;
