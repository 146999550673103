import {
  Box,
  Divider,
  ListItemText,
  Menu as MaMenu,
  MenuItem,
  MenuList,
} from "@mui/material";
import { FC, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useSecurity } from "../../hooks";

type MenuProperties = {
  open: boolean;
  onTopNavClose?: () => void;
};

const Menu: FC<MenuProperties> = ({ open, onTopNavClose }) => {
  const menuAnchorRef = useRef(null);
  const [menuPositionAnchor, setMenuPositionAnchor] =
    useState<null | HTMLElement>(menuAnchorRef.current);
  const [isOpen, setIsOpen] = useState<boolean>(open);
  const { logout } = useSecurity();

  const LogoutHandler = () => {
    try {
      logout();
    } catch (err) {
      //TODO Catch handling
    }
  };

  useEffect(() => {
    setMenuPositionAnchor(menuAnchorRef.current);
  }, []);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Box ref={menuAnchorRef}>
      <MaMenu
        anchorEl={menuPositionAnchor}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        id={"primary-search-account-menu"}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isOpen}
        onClose={() => {
          setIsOpen(false);
          if (onTopNavClose !== undefined) onTopNavClose();
        }}
        sx={{ mt: 3, ".MuiList-root": { p: 0 } }}
      >
        <MenuList dense>
          <MenuItem component={Link} to={"#"}>
            <ListItemText true-element={`true-element-button-userPreferences`}>
              Preferences
            </ListItemText>
          </MenuItem>
          <MenuItem component={Link} to={"#"}>
            <ListItemText true-element={`true-element-button-notifications`}>
              Notifications
            </ListItemText>
          </MenuItem>
          <Divider />
          <MenuItem component={Link} to={"#"}>
            <ListItemText true-element={`true-element-button-viewPermissions`}>
              Security
            </ListItemText>
          </MenuItem>
          <MenuItem
            onClick={() => LogoutHandler()}
            true-element={`true-element-button-logOut`}
          >
            Logout
          </MenuItem>
        </MenuList>
      </MaMenu>
    </Box>
  );
};

export default Menu;
