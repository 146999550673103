import { Grid, GridSpacing } from "@mui/material";
import { FC } from "react";
import "./Grids.css";

type ResponsiveStyleValue<T> =
  | T
  | Array<T | null>
  | { [key: string]: T | null };

export type RowProps = {
  id?: string;
  className?: string;
  selfAlign?: "flex-start" | "center" | "flex-end" | "unset";
  verticalAlign?: "flex-start" | "center" | "flex-end" | "unset";
  horizontalAlign?: "flex-start" | "center" | "flex-end" | "space-between";
  verticalGutter?: "0px" | "5px" | "10px" | "15px" | "20px" | "25px";
  horizontalGutter?: "0px" | "5px" | "10px" | "15px" | "20px" | "25px";
  verticalMargin?: "0px" | "5px" | "10px" | "20px" | "30px" | "40px" | "50px";
  horizontalMargin?: "0px" | "10px" | "20px" | "30px" | "40px" | "50px";
  rowDirection?: "row" | "column";
  allowWrap?: boolean;
  numberOfColumns?: number;
  spacing?: ResponsiveStyleValue<GridSpacing> | undefined;
  rowAsContainer?: boolean;
  rowWidth?: string;
  rowHeight?: string;
  children?: any | any[];
};

export const rowWithNoMarginNorGutter: RowProps = {
  horizontalMargin: "0px",
  verticalMargin: "0px",
  horizontalGutter: "0px",
  verticalGutter: "0px",
};

const Row: FC<RowProps> = ({
  id,
  className,
  selfAlign = "unset",
  verticalAlign = "center",
  horizontalAlign = "space-between",
  verticalGutter = "5px",
  horizontalGutter = "5px",
  verticalMargin = "10px",
  horizontalMargin = "10px",
  rowDirection = "row",
  allowWrap,
  numberOfColumns = 12,
  spacing,
  rowAsContainer = true,
  rowWidth = "100%",
  rowHeight = "auto",
  children,
}) => {
  return (
    <Grid
      id={id}
      className={className ? className : "row-grid-wrapper"}
      container={rowAsContainer}
      columns={numberOfColumns}
      spacing={spacing}
      sx={{
        width: rowWidth,
        height: rowHeight,
        margin: `${verticalMargin} ${horizontalMargin}`,
        padding: `${verticalGutter} ${horizontalGutter}`,
        alignSelf: selfAlign,
        alignItems: verticalAlign,
        justifyContent: horizontalAlign,
        flexDirection: rowDirection,
        flexWrap: allowWrap ? "wrap !important" : "nowrap !important",
      }}
    >
      {children}
    </Grid>
  );
};

export default Row;
