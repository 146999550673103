import { FC, useState } from "react";
import { BaseTable2Properties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { IconButton } from "@mui/material";
import OpenIcon from "@mui/icons-material/Launch";
import { BaseTable2 } from "../../TrueUI";
import { useBaseTable } from "../../../hooks/useBaseTable";
import BaseTablePolicyDropdownFilter from "../BaseTablePolicyDropdownFilter";
import { getEnumKey } from "../../../utilities/enumFunctions";
import { CertificateStatusEnums } from "../../../dtos/certificate-status-enums";
import IssueCertificateModal from "./IssueCertificateModal/IssueCertificateModal";
import { globalOptions } from "../../../GlobalAtoms";
import { useRecoilValue } from "recoil";
import { IssueCertificateModalConfig } from "./IssueCertificateModal/CertificateTypes";

const CertificatesGrid: FC = () => {
  const onOpenCertificate = (certificateNum?: string) => {
    const editCertificateId = parseInt(certificateNum ?? "0");
    setModalConfig({
      ...modalConfig,
      editCertificateId,
      mode: "READONLY",
      isOpen: true,
    });
  };

  const openCertificateModalLink = (rowData?: any) => {
    const status = rowData?.CertificateStatus ?? "";
    const issuedValue = getEnumKey(
      CertificateStatusEnums,
      CertificateStatusEnums.ISSUED
    );
    const showOpen = status.toUpperCase() === issuedValue;
    return showOpen ? (
      <IconButton
        aria-label="save"
        size="small"
        onClick={() => onOpenCertificate(rowData?.CertificateId)}
      >
        <OpenIcon fontSize="small" />
      </IconButton>
    ) : null;
  };

  const localOptions = useRecoilValue(globalOptions);
  const portalAutoApproveCert =
    localOptions?.initOptions?.programInfo?.portalAutoApproveCert;
  const [modalConfig, setModalConfig] = useState<IssueCertificateModalConfig>({
    isOpen: false,
  });
  const name = "policy_certificates_grid";
  const getURL = `api/Certificate/GetCertificateGrid`;

  const certificatesGridConfig: BaseTable2Properties = {
    name,
    getURL,
    toolbarOptions: {
      showEditButton: false,
      showSaveButton: false,
      showImportButton: false,
      showSortFilter: false,
      addButtonText: "NEW CERTIFICATE",
    },
    columnOptions: [
      { fieldName: "IssueDate", width: 8 },
      { fieldName: "CertificateStatus", width: 10 },
      { fieldName: "CertificateHolder", width: 30 },
      { fieldName: "CertificateId", width: 8 },
      { fieldName: "AuditSource", width: 8 },
      { fieldName: "IssueAtRenewal", width: 16 },
      { fieldName: "CreateBy", width: 20 },
    ],
    filterOptions: [
      (actionOptions) =>
        BaseTablePolicyDropdownFilter({
          columnsNames: ["PolicyId"],
          filterOptions: actionOptions,
          overrideAllOptionValue: "all",
          selectWidth: "250px",
        }),
    ],
    events: {
      addEventOverrideCallback: () =>
        setModalConfig({ ...modalConfig, mode: "ADD", isOpen: true }),
    },
    advancedOptions: {
      paginate: false,
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) =>
          openCertificateModalLink(actionOptions.row),
      },
    },
  };

  const { tableMethods } = useBaseTable(certificatesGridConfig);
  const tableInstanceMethods = tableMethods[name];

  const onSaveSuccessful = () => {
    setModalConfig({ ...modalConfig, mode: "REISSUE", isOpen: false });
    tableInstanceMethods?.methods?.refreshTable();
  };

  const onDeleteSuccessful = () => {
    setModalConfig({ isOpen: false });
  };

  return (
    <div>
      <BaseTable2 name={name} />
      <IssueCertificateModal
        configuration={{
          ...modalConfig,
          portalAutoApproveCert: portalAutoApproveCert,
        }}
        closeModal={() => setModalConfig({ isOpen: false })}
        onSaveSuccessful={onSaveSuccessful}
        onDeleteSuccessful={onDeleteSuccessful}
        onReissue={() =>
          setModalConfig({
            ...modalConfig,
            editCertificateId: null,
            mode: "ADD",
          })
        }
      />
    </div>
  );
};

export default CertificatesGrid;
