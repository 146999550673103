import { BaseTableColumn } from "../../../../dtos/base-table-column";
import { IncidentBodyPartDto } from "../../../../dtos/incident-body-part-dto";
import { SelectOptions } from "../../../../dtos/select-options";
import { removeValue } from "../../../../utilities/arrayFunctions";
import {
  ConditionForCellResponse,
  ComputeForCellParameters,
} from "../../../TrueUI/Tables/BaseTable2/TableProperties";
import { getDataIndexByColumnName } from "../../../TrueUI/Tables/tableFunctions";

export const onBodyPartChange = (): ConditionForCellResponse | void => {
  const conditionResponse = {
    triggerComputeFieldNames: ["BodyPartName"],
  } as ConditionForCellResponse;
  return conditionResponse;
};

export const getBodyPartName = (
  bodyPartId?: string,
  allBodyParts?: SelectOptions[]
) => allBodyParts?.find((x) => x.stringValue === bodyPartId)?.displayName ?? "";

export const onDescriptionChange = (
  options: ComputeForCellParameters<IncidentBodyPartDto>,
  allBodyParts?: SelectOptions[]
): ConditionForCellResponse | void => {
  const description = getBodyPartName(
    options?.row?.BodyPartId?.toString(),
    allBodyParts
  );
  const conditionResponse = {
    value: description,
  } as ConditionForCellResponse;
  return conditionResponse;
};

const getIncidentBodyPartsByTableData = (
  data: string[][],
  columns: BaseTableColumn[]
) => {
  const incidentBodyPartIdIndex = getDataIndexByColumnName(
    columns,
    "IncidentBodyPartId"
  );
  const incidentIdIndex = getDataIndexByColumnName(columns, "IncidentId");
  const bodyPartIdIndex = getDataIndexByColumnName(columns, "BodyPartId");
  const primaryInjuryIndex = getDataIndexByColumnName(columns, "PrimaryInjury");
  const sideIndex = getDataIndexByColumnName(columns, "Side");
  const bodyPartNameIndex = getDataIndexByColumnName(columns, "BodyPartName");
  const rowIndex = getDataIndexByColumnName(columns, "_row_key");
  return data.map((row) => ({
    baseTableRowKey: row[rowIndex],
    incidentBodyPartId:
      row[incidentBodyPartIdIndex] !== ""
        ? parseInt(row[incidentBodyPartIdIndex])
        : null,
    incidentId: parseInt(row[incidentIdIndex]),
    bodyPartId: parseInt(row[bodyPartIdIndex]),
    bodyPartName: row[bodyPartNameIndex],
    primaryInjury: parseInt(row[primaryInjuryIndex]),
    side: parseInt(row[sideIndex]),
    isBaseTableRowDeleted: false,
  }));
};

export const getTableErrorsByRowKey = (
  claimBodyParts?: any[] | null,
  errorDetails?: any
) =>
  removeValue(
    claimBodyParts?.map((bodyPart, index) =>
      Object.keys(errorDetails).includes(bodyPart.baseTableRowKey)
        ? {
            index,
            errorsByRow: errorDetails?.[bodyPart.baseTableRowKey].errorsByRow,
            errorsByCell: errorDetails?.[bodyPart.baseTableRowKey].errorsByCell,
          }
        : null
    ) ?? [],
    null
  );

const getErrorDetails = (
  errorsByRowKey?: any[] | null,
  tableData?: string[][]
) => {
  const errorList = {};
  errorsByRowKey?.forEach((error) =>
    Object.assign(errorList, {
      [tableData?.[error.index][0] ?? ""]: {
        errorsByRow: error.errorsByRow,
        errorsByCell: error.errorsByCell,
      },
    })
  );
  return errorList;
};

export const onChangeTableData = (
  data: string[][],
  columns: BaseTableColumn[],
  setIncidentBodyParts: (data: any[]) => void,
  errorDetails?: any[] | null,
  setTableErrors?: (errors: {}) => void
) => {
  setTableErrors?.(getErrorDetails(errorDetails, data));
  const newTableData = getIncidentBodyPartsByTableData(data, columns);
  setIncidentBodyParts(newTableData);
};

export const getDeletedBodyParts = (deletedIncidentBodyParts?: any[]) =>
  deletedIncidentBodyParts?.filter((bodyPart) => bodyPart.incidentBodyPartId) ??
  [];

export const deleteByHydratedRow = (
  hydratedRow?: any,
  deletedIncidentBodyParts?: any[],
  setDeletedIncidentBodyParts?: (data: any) => void
) => {
  setDeletedIncidentBodyParts?.([
    ...(deletedIncidentBodyParts ?? []),
    {
      baseTableRowKey: hydratedRow._row_key,
      isBaseTableRowDeleted: true,
      incidentBodyPartId: parseInt(hydratedRow.IncidentBodyPartId),
      incidentId: parseInt(hydratedRow.IncidentId),
      bodyPartId: parseInt(hydratedRow.BodyPartId),
      bodyPartName: hydratedRow.BodyPartName,
      primaryInjury: parseInt(hydratedRow.PrimaryInjury),
      side: parseInt(hydratedRow.Side),
    },
  ]);
};

export const getTableDataByIncidentBodyParts = (
  claimBodyParts?: any[] | null
): any[] =>
  claimBodyParts?.map((bodyPart) => [
    bodyPart.incidentBodyPartId?.toString() ?? "",
    bodyPart.incidentId?.toString() ?? "",
    bodyPart.bodyPartId?.toString() ?? "",
    bodyPart.primaryInjury?.toString() ?? "",
    bodyPart.side?.toString() ?? "",
    bodyPart.bodyPartName?.toString() ?? "",
  ]) ?? [];
