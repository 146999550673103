import { FC, useEffect, useState } from "react";
import { SplitButton } from "../../TrueUI";
import CancelButton from "../../TrueUI/Buttons/CancelButton";
import payrollStyles from "./PayrollReport.module.css";
import { PayrollDetailsUpdatedProps } from "./PayrollReportDetailsTable";
import { useApiPost } from "../../../hooks";
import FontError from "../../TrueUI/Typography/FontError";
import { isAPITotallyCompleteNoContentResponse } from "../../../utilities/apiFunctions";
import { PayrollReportStatusEnum } from "../../../dtos/payroll-report-status-enum";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import { PaymentProcessorUseEnum } from "../../../dtos/payment-processor-use-enum";
import ModalNewPaymentContainer from "./PaymentSelector/ModalNewPaymentContainer";
import {
  dispatchPrintJSONAtom,
  refreshPayrollReportDetailsHeaderAtom,
} from "./PayrollReportAtoms";
import { useNavigate } from "react-router";

type PayrollReportButtonsProps = {
  payrollDetailsData: PayrollDetailsUpdatedProps | null;
  defaultPayrollDetails: PayrollDetailsUpdatedProps;
  setPayrollDetailsData: (data) => void;
  setUUID: (uuid: any) => void;
};

type actionProps = "save-only" | "mail-check" | "pay-online";

const PayrollReportButtons: FC<PayrollReportButtonsProps> = ({
  payrollDetailsData,
  setPayrollDetailsData,
  defaultPayrollDetails,
  setUUID,
}) => {
  const localOptions = useRecoilValue(globalOptions);

  const showPayOnline =
    localOptions?.initOptions?.programProcessorUse ===
      PaymentProcessorUseEnum.PORTAL ||
    localOptions?.initOptions?.programProcessorUse ===
      PaymentProcessorUseEnum.BOTH;

  const [_payrollDetailsUpdated, setPayrollDetailsUpdated] =
    useState<PayrollDetailsUpdatedProps | null>(payrollDetailsData);
  const [errorDetails, setErrorDetails] = useState<any>(null);
  const [actionButton, setActionButton] = useState<actionProps>("save-only");
  const [isModalPaymentsOpen, setIsModalPaymentsOpen] =
    useState<boolean>(false);
  const setDispatchJSON = useSetRecoilState(dispatchPrintJSONAtom);
  const setRefreshHeader = useSetRecoilState(
    refreshPayrollReportDetailsHeaderAtom
  );
  const navigate = useNavigate();

  const {
    responsePost: savePayrollReportDetails,
    dispatchPost: dispatchSave,
    validatorErrorResponse,
  } = useApiPost(
    "api/PayrollReport/SavePayrollReportDetailRows",
    _payrollDetailsUpdated
  );

  const onSubmitFromAsPayOnlineClick = () => {
    setActionButton("pay-online");
    updatePayrollStatus(PayrollReportStatusEnum?.COMPLETE);
  };

  const onSubmitFromAndMailCheckClick = () => {
    setActionButton("mail-check");
    updatePayrollStatus(PayrollReportStatusEnum?.COMPLETE);
  };

  const submitOptions = [
    {
      numberOfOption: 0,
      option: "Save Only",
      dropdownText: "Save Only",
      action: () => updatePayrollStatus(PayrollReportStatusEnum?.ACTIVE),
    },
    {
      numberOfOption: 1,
      option: "Submit From as Zero Payroll",
      dropdownText: "Submit From as Zero Payroll",
      action: () => updatePayrollStatus(PayrollReportStatusEnum?.COMPLETE_ZERO),
    },
    {
      numberOfOption: 2,
      option: "Submit From as Pay Online",
      dropdownText: "Submit From as Pay Online",
      action: () => onSubmitFromAsPayOnlineClick(),
    },
    {
      numberOfOption: 3,
      option: "Submit From and Mail Check",
      dropdownText: "Submit From and Mail Check",
      action: () => onSubmitFromAndMailCheckClick(),
    },
  ];

  const newOptions = showPayOnline
    ? submitOptions
    : submitOptions.filter((o) => o.numberOfOption !== 2);

  const defaultOption = newOptions.findIndex((o) => o.numberOfOption === 3);

  const updatePayrollStatus = (status: number) => {
    setPayrollDetailsUpdated({
      ..._payrollDetailsUpdated,
      payrollReportStatus: status,
    });
  };

  useEffect(() => {
    if (conditionHasValue(_payrollDetailsUpdated?.payrollReportStatus)) {
      dispatchSave();
    }
  }, [_payrollDetailsUpdated?.payrollReportStatus]);

  useEffect(() => {
    setPayrollDetailsUpdated(payrollDetailsData);
  }, [payrollDetailsData]);

  useEffect(() => {
    if (
      !savePayrollReportDetails.requestInstanceSuccessful &&
      validatorErrorResponse !== null
    ) {
      setErrorDetails(validatorErrorResponse?.errorDetails);
    } else if (
      isAPITotallyCompleteNoContentResponse(savePayrollReportDetails)
    ) {
      if (actionButton === "pay-online") {
        setIsModalPaymentsOpen(true);
      }
      if (actionButton === "mail-check") {
        setDispatchJSON(true);
      }
      setUUID(crypto.randomUUID());
      setPayrollDetailsData(defaultPayrollDetails);
      setErrorDetails(null);
      setRefreshHeader(true);
    }
  }, [savePayrollReportDetails]);

  return (
    <div className={payrollStyles.payroll_report_details_buttons}>
      {errorDetails != null && (
        <FontError># of Employees must not be empty.</FontError>
      )}
      <ModalNewPaymentContainer
        isOpen={isModalPaymentsOpen}
        setRefreshPaymentsTableData={setUUID}
      />
      <SplitButton items={newOptions} currentSelectionIndex={defaultOption} />
      <CancelButton
        withConfirmation
        onClick={() => {
          setUUID(crypto.randomUUID());
          setPayrollDetailsData(defaultPayrollDetails);
          navigate(-1);
        }}
      />
    </div>
  );
};
export default PayrollReportButtons;
