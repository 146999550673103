import { conditionHasValue, isEmptyValue } from "./conditionalSupportFunctions";

export function truncateString(
  value?: string,
  maxLength?: number,
  addEllipsis: boolean = false
) {
  var truncatedValue =
    value !== null && value !== undefined ? value.substring(0, maxLength) : "";
  return truncatedValue !== "" && addEllipsis && value!!.length > maxLength!!
    ? truncatedValue + "..."
    : truncatedValue;
}

export function getSafeStringToBool(value: string): boolean {
  return value === "true";
}

export function getSafeBoolToString(value: boolean): string {
  return String(value);
}

export function formatNumber(number: number, length) {
  var len = length - ("" + number).length;
  return (len > 0 ? new Array(++len).join("0") : "") + number;
}

export function toCamelCase(val: string) {
  return (val.slice(0, 1).toLowerCase() + val.slice(1))
    .replace(/([-_ ]){1,}/g, " ")
    .split(/[-_ ]/)
    .reduce((cur, acc) => {
      return cur + acc[0].toUpperCase() + acc.substring(1);
    });
}

export function getCorrectURL(url) {
  let formattedURL = url;
  if (url != null && url.indexOf("http") === -1) {
    formattedURL = "https://".concat(url);
  }
  return formattedURL;
}

export function ssnFormat(ssn: string | undefined) {
  return ssn ? ssn.replace(/(\d{3})(\d{2})(\d{4})/, "$1-$2-$3") ?? "" : "";
}

export function ssnMask(ssn: string) {
  const valueHidden = ssn ? ssn.replace(/[\d]/g, "X") : "";
  return valueHidden.length <= 3
    ? valueHidden
    : valueHidden.length <= 5
    ? valueHidden.slice(0, 3) + "-" + valueHidden.slice(3, 5)
    : valueHidden.slice(0, 3) +
      "-" +
      valueHidden.slice(3, 5) +
      "-" +
      ssn.substr(5);
}

export function einFormat(ein: string | undefined) {
  return ein ? ein.replace(/(\d{2})(\d{7})/, "$1-$2") ?? "" : "";
}

export function einMask(ein: string) {
  return ein ? ein.replace(/(\d{5})(\d{4})/, "XX-XXX$2") : "";
}

export function phoneMask(phone: string) {
  return phone ? phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3") : "";
}

export function customRound(value: string, decimalPlaces: number) {
  const result = Number(parseFloat(value).toFixed(decimalPlaces));
  return result === 0 ? Math.abs(result) : result;
}

export function unFormatLocalString(formatted: string) {
  return parseFloat(formatted.replaceAll(",", ""));
}

export function isJsonString(str) {
  try {
    if (!isNaN(str)) {
      return false;
    } else {
      JSON.parse(str);
    }
  } catch (e) {
    return false;
  }
  return true;
}

export function removeHTMLTagsByRegex(str: string) {
  const strWithLineBreaks = str.replace(/<\/?p>/gi, "\n");
  const removeTagsRegex = /(<([^>]+)>)/gi;
  return strWithLineBreaks.replace(removeTagsRegex, "");
}

export const getNumberAsStringWithComas = (value: number) =>
  value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const formatToCurrency = (amount) => {
  return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
};

export const splitPhoneNumberAndExtension = (value: string) => {
  if (isEmptyValue(value)) {
    return null;
  }
  const pattern = /^(\d{10})(\d+)?/;
  return value.match(pattern);
};

export const formatNegativeNumbers = (amount: number) =>
  amount < 0 ? `($${(amount * -1).toFixed(2)})` : `$${amount.toFixed(2)}`;

export const getFileNameWithoutExtension = (filenameWithExtension: string) => {
  var index = filenameWithExtension.lastIndexOf(".");

  if (index !== -1) {
    return filenameWithExtension.substring(0, index);
  }

  return filenameWithExtension;
};
export const capitalizeText = (text: string) =>
  text.length >= 0
    ? text.charAt(0).toUpperCase() + text.slice(1).toLocaleLowerCase()
    : "";

export const parseFloatValueIfExist = (value) =>
  conditionHasValue(value) && value !== "" ? parseFloat(value) : null;

export const castStringToTags = (string) => {
  return string?.trim()?.replaceAll(" ", "-")?.toLowerCase();
};

export const escapeTextForRegex = (text: string) =>
  text.replace(/[/\-\\^$*+?.()|[\]{}]/g, "\\$&");

export const fromUppercaseSnakeCaseToCamelCase = (text: string) => {
  const outputString = text
    .toLowerCase()
    .replaceAll(/_([a-z1-9])/g, function (_match, group) {
      return group.toUpperCase();
    });
  return outputString.charAt(0).toLocaleLowerCase() + outputString.slice(1);
};

export function toCamelCaseWhenAllCaps(val: string) {
  const camelCaseValue = toCamelCase(val);
  let isFirstCamelCase = true;
  const trueCamelCaseValue = camelCaseValue.split("")?.map((character) => {
    if (isFirstCamelCase === true && /[A-Z]/.test(character) === true) {
      isFirstCamelCase = false;
      return character;
    }
    return character.toLowerCase();
  });
  return trueCamelCaseValue.join("");
}
