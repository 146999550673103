import {
  RecoilState,
  useRecoilCallback,
  useRecoilState,
  useRecoilValue,
} from "recoil";
import { BaseTableInputType } from "../../../../dtos/base-table-input-type";
import {
  tableInstanceAtomFamily2,
  CellManagerCell,
  cellManagerCells,
  // tableCellManagerKeys,
} from "../TableAtoms";
import TextCell2 from "../SharedTableCells/TextCell2";
import SelectCell2 from "../SharedTableCells/SelectCell2";
import TagsCell2 from "../SharedTableCells/TagsCell2";
import DatePickerCell2 from "../SharedTableCells/DatePickerCell2";
import CheckBoxCell2 from "../SharedTableCells/CheckBoxCell2";
import LinkCell2 from "../SharedTableCells/LinkCell2";
import ScoreChipCell2 from "../SharedTableCells/ScoreChipCell2";
import {
  BaseTableCellSelectOnChangeProperties,
  ComputeColumnDataObject,
  ComputeForCellParameters,
  ConditionForCellResponse,
  OptionsStateConditionProperties,
  TableInstanceType2,
} from "./TableProperties";
import { FC, useEffect, useState } from "react";
import PhoneCell2 from "../SharedTableCells/PhoneCell2";
import IndicationCell from "../SharedTableCells/IndicationCell";
import OptionsCell2, { OptionCellType } from "../SharedTableCells/OptionsCell2";
import {
  createConditionForCellResponse,
  findRowByKey,
  findRowIndexByKey,
  findRowsByKey,
  getCellVariant,
  getColumnIndexByColumnName,
  getRowByRowKey,
  getSafeRowKey,
  getWidthOfCells,
  hydrateAndCastDataSingle,
  hydrateDataSingle,
  hydrateDataSingleExternalColumnsOnly,
  updateDataRows,
  upsertDataRows,
} from "../tableFunctions";
import AutocompleteCell2 from "../SharedTableCells/AutocompleteCell2";
import InputTaxCell2 from "../SharedTableCells/InputTaxCell2";
import ConditionalRadioButtonCell from "../SharedTableCells/ConditionalRadioButtonCell";
import {
  NO_CELL_KEY_ERROR,
  NO_COLUMN_FIELD_NAME_FOUND_ERROR,
  NO_ROW_KEY_ERROR,
  // WARN_MISSING_CELLKEYS,
  WARN_TRIGGER_COMPUTE_OVERLOAD,
} from "../TableConstants";
import { toCamelCase } from "../../../../utilities/stringFunctions";
import { BaseTableColumn } from "../../../../dtos/base-table-column";

import DownloadLinkCell2 from "../SharedTableCells/DownloadLinkCell2";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { BaseTableDisplayOptions } from "../../../../dtos/base-table-display-options";
import { CellVariantEnums } from "../../../../dtos/cell-variant-enums";
import { TableData } from "../BaseTableStructure";
import TextWithIconCell2 from "../SharedTableCells/TextWithIconCell2";
import { InternalTableColumns } from "../InternalTableColumns";
import MultiSelectCell from "../SharedTableCells/MultiSelectCell";

export type BaseTableCellSelectProperties_RESTRUCTURE = {
  uiid: string;
  rowKey: string;
  row: string[];
  rowIndex: number;
};

// export type CellIds = {
//   cellKey: string;
//   value: string;
//   column: BaseTableColumn;
// };

export type CellManagerManagedCells = {
  uiid: string;
  row: string[];
  rowIndex: number;
};

type InterceptedEventProperties = {
  cellKey: string;
  rowKey: string;
  incomingValue: any;
  apiResponseData: any;
  column: BaseTableColumn | null;
  hasValueChanges: boolean;
  typeId?: BaseTableInputType | null;
  computeTargetName: string | null;
  computeTargetIndex: number | null;
};

const BaseTable2CellManager: FC<BaseTableCellSelectProperties_RESTRUCTURE> = (
  cellProps
) => {
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(cellProps.uiid) as RecoilState<TableInstanceType2>
  );
  const [cells, setCells] = useRecoilState(cellManagerCells(cellProps.rowKey));
  const [sortedCells, setSortedCells] = useState<CellManagerCell[]>([]);
  const [renderedCells, setRenderedCells] = useState<JSX.Element[]>([]);
  const [interceptedChanges, setInterceptedChanges] =
    useState<InterceptedEventProperties | null>(null);

  const isRequestedCellManager = (): boolean =>
    tableInstance._requestingCellManagerRowKeys?.includes(cellProps.rowKey)
      ? true
      : false;

  const getCellsInstance = useRecoilCallback(
    ({ snapshot }) =>
      (key: string) => {
        const _cells = snapshot.getLoadable(cellManagerCells(key))
          .contents as CellManagerCell[];

        return { cells: _cells, hasCells: _cells.length > 0 ? true : false };
      }
  );

  const getAllColumnDataByFieldName = useRecoilCallback(
    ({ snapshot }) =>
      (fieldName: string) => {
        return tableInstance?.allRowKeys.map((key) => {
          const managerInstance = cellManagerCells(key ?? NO_ROW_KEY_ERROR);
          const cellManagerInstance = snapshot.getLoadable(managerInstance)
            .contents as any;

          const result =
            cellManagerInstance.find((c) => c.column.fieldName === fieldName) ??
            null;
          return {
            cellKey: result?.cellKey ?? null,
            rowKey: key ?? null,
            value: result?.currentValue ?? null,
          };
        });
      }
  );

  const isCellEditable = () =>
    // isColumnsDisabled: string[],
    // fieldName: string
    {
      if (tableInstance.isEdit) {
        return true;
      }
      if (tableInstance?.selectedEditRows?.includes(cellProps.rowKey)) {
        return true;
      }

      return true;

      // if (isColumnsDisabled.length === 0) {
      //   return false;
      // }

      // return isColumnsDisabled.includes(fieldName);
    };

  const updateCellManagerState = useRecoilCallback(
    ({ snapshot, set }) =>
      (
        cellsToUpdate: {
          rowKey: string;
          cellKey: string;
          value: any;
          columnIndex: number;
          isColumnsDisabled: string[];
          listDisplayOptions: BaseTableDisplayOptions[] | null;
          typeId: BaseTableInputType | null;
          refreshCell: boolean;
        }[]
      ) => {
        cellsToUpdate.map((cellToUpdate) => {
          const managerInstance = snapshot.getLoadable(
            cellManagerCells(cellToUpdate.rowKey)
          ).contents as CellManagerCell[];

          const result = managerInstance.map((c) => {
            const isEditable = isCellEditable();

            if (c.cellKey === cellToUpdate.cellKey) {
              const x = {
                ...c,
                previousValue: c.currentValue,
                currentValue: cellToUpdate.value,
                isEditable: isEditable,
                listDisplayOptions: cellToUpdate.listDisplayOptions ?? null,
                typeId: cellToUpdate.typeId ?? null,
                refreshCellManager: cellToUpdate.refreshCell,
              } as CellManagerCell;

              return x;
            } else {
              const a = {
                ...c,
                isEditable: isEditable,
              };

              return a;
            }
          });

          set(cellManagerCells(cellToUpdate.rowKey), result);
        });
      },
    []
  );

  const updateCellManagerStateByCellManager = useRecoilCallback(
    ({ set }) =>
      (cellsToUpdate: CellManagerCell[]) => {
        set(cellManagerCells(cellProps.rowKey), cellsToUpdate);
      },
    []
  );

  const filterComputesByName = (computeName: string, fieldName: string) =>
    tableInstance?.columnOptions
      ?.map((column) => {
        if (
          column.fieldName === fieldName &&
          column.computeOnChange?.[computeName] !== undefined &&
          column.computeOnChange?.[computeName]?.length > 0
        ) {
          return {
            fn: column.computeOnChange?.[computeName][0] ?? null,
            fieldName: column.fieldName ?? null,
          };
        }
        return null;
      })
      .flat()
      .filter((c) => c !== null);

  const filterComputesOnInitByName = (computeName: string, fieldName: string) =>
    tableInstance?.columnOptions
      ?.map((column) => {
        if (
          column.fieldName === fieldName &&
          column.computeOnInit?.[computeName] !== undefined &&
          column.computeOnInit?.[computeName]?.length > 0
        ) {
          return {
            fn: column.computeOnInit?.[computeName][0] ?? null,
            fieldName: column.fieldName ?? null,
          };
        }
        return null;
      })
      .flat()
      .filter((c) => c !== null);

  const getCellComputesByHiddenColumnStatus = (
    changedCell: BaseTableCellSelectOnChangeProperties,
    isComputeForHiddenColumn: boolean
  ) => {
    return filterComputesByName(
      "conditionForCell",
      isComputeForHiddenColumn
        ? changedCell.computeTargetName ?? NO_COLUMN_FIELD_NAME_FOUND_ERROR
        : changedCell?.column?.fieldName ?? NO_COLUMN_FIELD_NAME_FOUND_ERROR
    );
  };

  const getApiDataByHiddenColumnStatus = (
    changedCell: BaseTableCellSelectOnChangeProperties,
    isComputeForHiddenColumn: boolean
  ) => {
    const resolvedAPIData = tableInstance?._computeAPIData.find(
      (c) =>
        c.rowKey === changedCell.rowKey &&
        c.targetFieldNames.includes(
          isComputeForHiddenColumn
            ? changedCell.computeTargetName ?? NO_COLUMN_FIELD_NAME_FOUND_ERROR
            : changedCell?.column?.fieldName ?? NO_COLUMN_FIELD_NAME_FOUND_ERROR
        )
    );

    return {
      data: resolvedAPIData?.data ?? null,
      errorMessage: resolvedAPIData?.errorResponse?.["title"] ?? null,
      errorResponse: resolvedAPIData?.errorResponse,
    };
  };

  const processCellComputes = (
    changedCell: BaseTableCellSelectOnChangeProperties,
    hydratedRow: any,
    castedRow: any,
    isComputeForHiddenColumn: boolean
  ): ConditionForCellResponse[] => {
    const lastCellComputed = getCellByCellKey(
      tableInstance?._lastOriginComputeChainCellKey ?? ""
    );

    const cellComputes = getCellComputesByHiddenColumnStatus(
      changedCell,
      isComputeForHiddenColumn
    );

    const getApiDataByDataNameAndHiddenColumnStatus = (dataName: string) => {
      const resolvedAPIData = tableInstance?._computeAPIData.find(
        (c) =>
          c.rowKey === changedCell.rowKey &&
          c.targetFieldNames.includes(
            isComputeForHiddenColumn
              ? changedCell.computeTargetName ??
                  NO_COLUMN_FIELD_NAME_FOUND_ERROR
              : changedCell?.column?.fieldName ??
                  NO_COLUMN_FIELD_NAME_FOUND_ERROR
          ) &&
          c.getDataName === dataName
      );

      return {
        data: resolvedAPIData?.data ?? null,
        errorMessage: resolvedAPIData?.errorResponse?.["title"] ?? null,
        errorResponse: resolvedAPIData?.errorResponse,
      };
    };

    const tryCurrentValue = (index: number) => {
      if (index === -1)
        console.warn(
          "There is no computeTargetIndex specified for the hidden column in the current process."
        );
      const row = getRowByRowKey(cellProps.rowKey, tableInstance.data);
      const currentValue = row[index] ?? null;
      return currentValue;
    };

    const processedComputes =
      cellComputes
        ?.map((cellCompute) => {
          const computedValue =
            (cellCompute?.fn?.({
              rowKey: changedCell.rowKey,
              cellKey: changedCell.cellKey,
              currentValue:
                changedCell.value ??
                tryCurrentValue(changedCell.computeTargetIndex ?? -1),
              row: hydratedRow,
              castedRow: castedRow,
              originalRow: hydrateDataSingle(
                tableInstance?.columns ?? [],
                cellProps.row
              ),
              currentApiData: getApiDataByHiddenColumnStatus(
                changedCell,
                isComputeForHiddenColumn
              ),
              getApiDataByDataName: getApiDataByDataNameAndHiddenColumnStatus,
              computeTriggerSource: lastCellComputed?.column.fieldName,
              responseType: null,
            } as ComputeForCellParameters<any>) as ConditionForCellResponse) ??
            null;

          if (computedValue === undefined || computedValue === null) {
            // this only excutes when compute functions are void
            return null;
          }

          const computeValueCellKey = changedCell.cellKey ?? NO_CELL_KEY_ERROR;

          if (
            (computedValue?.triggerComputeFieldNames?.length ?? 0) > 0 &&
            (computedValue?.apiOptions?.triggerComputes?.length ?? 0) > 0
          ) {
            console.warn(WARN_TRIGGER_COMPUTE_OVERLOAD(cellCompute?.fieldName));
          }

          const safeAPIOptions = () => {
            if (computedValue?.apiOptions?.getURL !== undefined) {
              return computedValue?.apiOptions;
            }
            return null;
          };

          const getAllTriggeredComputes = (): string[] => [
            ...new Set(
              (computedValue?.triggerComputeFieldNames ?? []).concat(
                computedValue.apiOptions?.triggerComputes ?? []
              ) ?? []
            ),
          ];

          return createConditionForCellResponse(
            computeValueCellKey,
            cellProps?.rowKey ?? NO_ROW_KEY_ERROR,
            computedValue?.value?.toString() ?? changedCell.value,
            tableInstance?.isEdit ?? false,
            computedValue.typeId ?? null,
            "standard",
            computedValue?.targetFieldName ??
              cellCompute?.fieldName ??
              changedCell.column?.fieldName ??
              "",
            getAllTriggeredComputes(),
            null,
            "cell_compute",
            safeAPIOptions(),
            computedValue.listDisplayOptions ?? null,
            computedValue.isColumnsDisabled ?? null,
            computedValue.refreshCell ?? false
          );
        })
        .filter((c) => c !== null) ?? [];

    return (processedComputes ?? []) as ConditionForCellResponse[];
  };

  const processColumnComputes = (
    value: any,
    fieldName: string,
    castedRow: any
  ) => {
    const columnComputes = filterComputesByName(
      "conditionForColumn",
      fieldName
    );

    return (columnComputes
      ?.map((cellCompute) => {
        const allColumnDataWithCellKeys =
          getAllColumnDataByFieldName(fieldName);

        const computedValue = cellCompute?.fn({
          rowKey: cellProps.rowKey,
          rowValue: value,
          rowEventIndex: findRowIndexByKey(
            cellProps.rowKey,
            tableInstance?.data ?? []
          ),
          columnData: allColumnDataWithCellKeys,
          castedRow: castedRow,
        });

        if (computedValue === undefined || computedValue === null) {
          // this only excutes when compute functions are void

          const defaultCellObject =
            allColumnDataWithCellKeys?.find(
              (c) => c?.rowKey === cellProps.rowKey
            ) ?? null;

          return createConditionForCellResponse(
            defaultCellObject?.cellKey ?? NO_CELL_KEY_ERROR,
            cellProps.rowKey ?? NO_ROW_KEY_ERROR,
            defaultCellObject?.value ?? "",
            tableInstance?.isEdit ?? false,
            computedValue?.typeId ?? null,
            `standard`, // TODO - give this the ability to be overridden
            fieldName, // TODO - to be fixed correctly, old way: computedValue?.targetFieldName ?? cellProps.column.fieldName
            [],
            null,
            "column_compute",
            null,
            [] ?? null,
            [] ?? null,
            computedValue.refreshCell ?? false
          );
        }

        const computedResults = allColumnDataWithCellKeys?.map((columnData) => {
          const targetField = (computedValue?.targetFields?.find(
            (f) => f.cellKey === columnData?.cellKey
          ) ?? null) as ComputeColumnDataObject | null;

          return createConditionForCellResponse(
            columnData?.cellKey ?? NO_CELL_KEY_ERROR,
            columnData?.rowKey ?? NO_ROW_KEY_ERROR,
            targetField?.value?.toString() ?? "",
            tableInstance?.isEdit ?? false,
            targetField?.typeId ?? null,
            `standard`, // TODO - give this the ability to be overridden
            computedValue?.targetFieldName ?? fieldName, // TODO - to be fixed correctly, old way: computedValue?.targetFieldName ?? cellProps.column.fieldName
            [],
            null,
            "column_compute",
            null,
            computedValue.listDisplayOptions ?? null,
            targetField?.isColumnDisabled ?? null,
            computedValue.refreshCell ?? false
          );
        });
        return computedResults;
      })
      .flat()
      .filter((c) => c !== null) ?? []) as ConditionForCellResponse[];
  };

  const getVisibleColumns = tableInstance?.columns
    .filter((c) => !c.isHidden)
    .map((c, i) => ({
      ...c,
      _visibleColumnIndex: i,
    }));

  const hydratedRow = hydrateDataSingle(
    tableInstance?.columns ?? [],
    getRowByRowKey(cellProps.rowKey, tableInstance.data) ?? []
  );
  const hydratedAndCastedRow = hydrateAndCastDataSingle(
    tableInstance.columns ?? [],
    getRowByRowKey(cellProps.rowKey, tableInstance.data) ?? []
  );

  // // const isColumnDisabled = (fieldName: string) => {
  // //   const column = tableInstance?.columnOptions?.find(
  // //     (c) => c.fieldName === fieldName
  // //   );
  // //   return !(column?.isDisabled ? true : false);
  // // };

  // // const updateAllToEdit = () => {
  // //   const updatedCells = cells.map((c) => {
  // //     const isColumnEditable = isColumnDisabled(c.column.fieldName);
  // //     return {
  // //       ...c,
  // //       isEditable: isColumnEditable ? tableInstance?.isEdit : false,
  // //     };
  // //   }) as CellManagerCell[];
  // //   setCells(updatedCells);
  // // };

  const updateRowToEdit = (isEdit) => {
    const updatedCells = cells.map((c) => {
      // const isColumnEditable = isColumnDisabled(c.column.fieldName);
      return {
        ...c,
        isEditable: isEdit,
        // tableInstance?.selectedEditRows === cellProps.rowKey
        //   ? isColumnEditable
        //   : false,
      };
    });

    setCells(updatedCells);
  };

  const mergeCellResponses = (
    cellsA: ConditionForCellResponse[],
    cellsB: ConditionForCellResponse[],
    cellsC: ConditionForCellResponse[]
  ): ConditionForCellResponse[] => {
    const uniqueKeys: string[] = [];
    const filteredResps = [...cellsA, ...cellsB, ...cellsC]
      .map((c) => ({ ...c, value: c.value?.toString() ?? "" })) // [BASETABLE TODO] - added this recently because some values we're coming back as not strings. ensure this is safe.
      .filter((c) => {
        if (c.cellKey === "") {
          /*
          sometimes cells won't have a cellKey because they are not being rendered therfore they don't need a key.
          however we still want to update the tableInstance?.data object in reference to keyless cell data objects.
          these keyless data objects should always have a _rowKey however.
        */
          return true;
        }
        const dup = uniqueKeys.includes(c.cellKey ?? NO_CELL_KEY_ERROR);
        // const unchanged = c._originalValue === c.value ? true : false;
        if (!dup) {
          //if (!dup && !unchanged) {
          uniqueKeys.push(c.cellKey ?? NO_CELL_KEY_ERROR);
          return true;
        }

        return false;
      });
    return filteredResps;
  };

  const setTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        set(tableInstanceAtomFamily2(cellProps.uiid), {
          ...tableInstance,
          ...newValueTableInstance,
          uiid: cellProps.uiid,
        });
      },
    []
  );

  const getCellByCellKey = (cellKey: string) =>
    (cells ?? []).find((c) => c.cellKey === cellKey);

  const getCellKeyByColumnName = (columnName: string) => {
    const cellKey =
      (cells ?? []).find((c) => c.column.fieldName === columnName)?.cellKey ??
      NO_CELL_KEY_ERROR;
    return cellKey;
  };

  const getCellByColumnName = (columnName: string) => {
    const cell = (cells ?? []).find((c) => c.column.fieldName === columnName);
    return cell ?? null;
  };

  const getFirstComputedAPI = (computes: ConditionForCellResponse[]) => {
    return (
      computes
        .map((c) => {
          return c?.apiRequestOptions ?? null;
        })
        .find((c) => c !== null) ?? null
    );
  };

  const createDefaultConditionForCellResponse = (
    changedCell: BaseTableCellSelectOnChangeProperties
  ) => {
    return [
      createConditionForCellResponse(
        changedCell.cellKey,
        cellProps.rowKey,
        changedCell?.value ?? changedCell?.currentValue ?? null,
        tableInstance?.isEdit ?? false,
        changedCell.typeId ?? changedCell.column?.type ?? null,
        "standard",
        changedCell.column?.fieldName ?? NO_COLUMN_FIELD_NAME_FOUND_ERROR,
        [],
        changedCell.column?.fieldName ?? NO_COLUMN_FIELD_NAME_FOUND_ERROR,
        "init",
        null,
        [],
        null,
        false
      ),
    ];
  };

  const updateOptionsRowValueState = (
    row: string[],
    optionsStateCondition?: (e: OptionsStateConditionProperties) => any
  ) => {
    const optionRowValue = row[
      InternalTableColumns._optionsColumn._columnIndex
    ] as OptionCellType;
    const hydratedRow = hydrateDataSingleExternalColumnsOnly(
      tableInstance.columns,
      row
    );
    const conditionValue = optionsStateCondition?.({
      hydratedRow,
      currentOptionValue: optionRowValue,
      triggerState: "on_change",
    });

    if (conditionValue === undefined) {
      return row;
    }

    const updatedRow = row.map((value, index) => {
      if (index === InternalTableColumns._optionsColumn._columnIndex) {
        return conditionValue;
      }
      return value;
    });

    return updatedRow;
  };

  const updateCellOptionsState = (row: string[]) => {
    const rowOptionValue =
      row[InternalTableColumns._optionsColumn._columnIndex] ?? "none";
    const optionCell = getCellByColumnName(
      InternalTableColumns._optionsColumn.fieldName
    );

    if (
      optionCell !== undefined &&
      optionCell?.cellKey !== undefined &&
      rowOptionValue !== optionCell?.currentValue
    ) {
      return [
        {
          rowKey: cellProps?.rowKey ?? NO_ROW_KEY_ERROR,
          cellKey: optionCell?.cellKey ?? NO_CELL_KEY_ERROR,
          value: rowOptionValue,
          columnIndex: optionCell?.column._columnIndex,
          isColumnsDisabled: false, // TODO - fix this
          listDisplayOptions: optionCell?.listDisplayOptions ?? null,
          typeId: optionCell?.typeId ?? null,
          refreshCell: optionCell?.refreshCellManager,
        },
      ];
    }
    return []; // empty basically means we'll ignore updating the options cell
  };

  const interceptOnChangeEvent2 = (
    changedCell: BaseTableCellSelectOnChangeProperties
  ) => {
    const isComputeForHiddenColumn = conditionHasValue(
      changedCell.computeTargetIndex
    );

    const cellComputes = processCellComputes(
      changedCell,
      hydratedRow,
      hydratedAndCastedRow,
      isComputeForHiddenColumn
    );

    const columnComputes = processColumnComputes(
      changedCell.value,
      changedCell?.column?.fieldName ?? NO_COLUMN_FIELD_NAME_FOUND_ERROR,
      hydratedAndCastedRow
    );

    const defaultCompute = createDefaultConditionForCellResponse(changedCell);

    const mergedComputes = mergeCellResponses(
      cellComputes,
      columnComputes,
      cellComputes.length === 0 || columnComputes.length === 0
        ? defaultCompute
        : []
    );

    const computeAPI = getFirstComputedAPI(mergedComputes);

    const triggerComputes =
      mergedComputes
        .map((c) => {
          const zz = c.triggerComputeFieldNames?.map((tc) => {
            const cellKey = getCellKeyByColumnName(tc);
            return {
              rowKey: cellProps.rowKey,
              cellKey: cellKey,
              computeTargetName: cellKey === NO_CELL_KEY_ERROR ? tc : null,
              computeTargetIndex:
                cellKey === NO_CELL_KEY_ERROR
                  ? getColumnIndexByColumnName(tc, tableInstance.columns)
                  : null,
            };
          });
          return zz ?? [];
        })
        .flat() ?? [];

    const newUpdatedRows = mergedComputes.map((mc) => ({
      cellKey: mc.cellKey ?? NO_CELL_KEY_ERROR,
      rowKey: mc._rowKey ?? NO_ROW_KEY_ERROR,
      value: mc.value,
      // value: changedCell
      columnIndex: getColumnIndexByColumnName(
        mc.targetFieldName ?? "",
        tableInstance?.columns ?? []
      ),
      isColumnsDisabled: mc.isColumnsDisabled ?? [],
      listDisplayOptions: mc.listDisplayOptions ?? null,
      typeId: mc.typeId ?? null,
      refreshCell: mc.refreshCell ?? false,
    }));

    // create a newly massaged just to extract the newly updated row keys
    const newlyUpdatedKeys = mergedComputes.map((mc) => mc._rowKey ?? "");

    // update the tableInstance data object
    const updatedData = updateDataRows(
      newUpdatedRows,
      tableInstance?.data ?? []
    );

    // get this instance current row
    const currentManagerRow = findRowByKey(cellProps.rowKey, updatedData);

    // update this instance row with the new option value, if the value has changed
    const recentlyUpdatedRowWithUpdateOptionValue = updateOptionsRowValueState(
      currentManagerRow,
      tableInstance.advancedOptions?.optionsColumnConfiguration
        ?.optionsStateCondition
    );

    // update the cell state value
    const updatedOptionsCell = updateCellOptionsState(
      recentlyUpdatedRowWithUpdateOptionValue
    );

    // update the data value with updated options value
    const updateRowWithNewOptionValue = upsertDataRows(
      [recentlyUpdatedRowWithUpdateOptionValue],
      updatedData
    );

    // extract the updated data row from the tableInstance data object
    const recentlyUpdatedRow = findRowsByKey(
      newlyUpdatedKeys,
      updateRowWithNewOptionValue
    );

    // update the tableInstance changedData object (similiar to what we do data)
    const updatedChangedData = upsertDataRows(
      recentlyUpdatedRow,
      tableInstance?.changedData ?? []
    );

    const mergedTriggeredComputes = (
      tableInstance?._inProcessComputesQueue ?? []
    ).concat(triggerComputes);

    setInterceptedChanges(null);

    setTableInstance({
      ...(tableInstance as TableInstanceType2),
      data: updateRowWithNewOptionValue,
      changedData: updatedChangedData,
      _lastOnChangeInitiatorCellKey: {
        rowKey: cellProps.rowKey,
        cellKey: changedCell.cellKey,
      },
      _inProcessAPIResponse: computeAPI,
      _inProcessComputesQueue:
        mergedTriggeredComputes.filter(
          (c) => c.cellKey !== changedCell.cellKey
        ) ?? [],
      _lastOriginComputeChainCellKey:
        tableInstance._lastOriginComputeChainCellKey === null
          ? changedCell.cellKey
          : tableInstance._lastOriginComputeChainCellKey,
      _initalInProcessComputesQueueStarted: true, // on init this is false, we only need to set this to true one time as it is mainly used for the `onComputeFinish` event.
    });

    updateCellManagerState(newUpdatedRows.concat(updatedOptionsCell as any));

    tableInstance.events?.onUpdateRow?.(cellProps.rowKey);
  };

  const isProcessIncluded = () => {
    return tableInstance?._inProcessComputesQueue
      .map((c) => c.rowKey)
      .includes(cellProps.rowKey);
  };

  const processNextComputeInQueue = () => {
    if (
      tableInstance?._inProcessAPIResponse === null &&
      tableInstance?._inProcessComputesQueue.length > 0 &&
      interceptedChanges === null &&
      isProcessIncluded()
    ) {
      const [nextCompute] = tableInstance?._inProcessComputesQueue ?? null;

      if (nextCompute !== null && nextCompute.rowKey === cellProps.rowKey) {
        const cell = getCellByCellKey(nextCompute.cellKey);

        setInterceptedChanges({
          cellKey: cell?.cellKey ?? NO_CELL_KEY_ERROR,
          rowKey: cellProps.rowKey,
          incomingValue: cell?.currentValue ?? null,
          apiResponseData: null,
          column: cell?.column ?? null,
          typeId: cell?.typeId ?? cell?.column.type ?? BaseTableInputType.TEXT,
          // responseType: "init",
          hasValueChanges: true,
          computeTargetName: nextCompute.computeTargetName ?? null,
          computeTargetIndex: nextCompute.computeTargetIndex ?? null,
        });
      }
    }
  };

  // // const hasValueChanged = (newValue: string, previousValue: string): boolean =>
  // //   newValue !== previousValue;

  const executeInterceptedOnChangeEvent = () => {
    if (
      interceptedChanges !== null &&
      interceptedChanges.rowKey === cellProps.rowKey
    ) {
      const cell = getCellByCellKey(interceptedChanges.cellKey);
      if (interceptedChanges.hasValueChanges) {
        interceptOnChangeEvent2({
          cellKey: interceptedChanges.cellKey,
          rowKey: interceptedChanges.rowKey,
          originalValue: cell?.originalValue ?? null,
          currentValue: cell?.currentValue ?? "",
          value: interceptedChanges.incomingValue,
          typeId: cell?.typeId ?? cell?.column.type ?? null,
          apiResponseData: interceptedChanges.apiResponseData,
          column: interceptedChanges.column,
          responseType: "none",
          computeTargetName: interceptedChanges.computeTargetName,
          computeTargetIndex: interceptedChanges.computeTargetIndex,
        });
      }
      // TODO - review why we had this logic
      else {
        setInterceptedChanges(null);
      }
    }
  };

  const getErrorsByCell = (rowKey: string, columnField: string) => {
    if (tableInstance?.validationErrors !== null) {
      const errors = tableInstance?.validationErrors[rowKey];
      const allCellsErrors = errors !== undefined && errors.errorsByCell;
      const errorsByCell =
        allCellsErrors !== undefined &&
        allCellsErrors[toCamelCase(columnField)];
      return errorsByCell !== undefined ? errorsByCell : null;
    }
    return null;
  };

  const getApplicableList = (managedCell: any) => {
    if (
      managedCell.listDisplayOptions !== null &&
      (managedCell?.listDisplayOptions?.length ?? 0) > 0
    ) {
      return managedCell.listDisplayOptions;
    }

    return managedCell.column?.displayOptions;
  };

  const getCellType = (managedCell: {
    // TODO - create defintion
    refreshCellManager;
    typeId;
    cellKey;
    rowKey;
    value;
    onChange;
    column;
    isEdit;
    isComputeDisabled;
    // type;
    variant;
    errorMessage;
    extraProps;
    listDisplayOptions;
    columnProps;
    isBold;
    // isOptionsHide;
  }) => {
    switch (managedCell.typeId) {
      case BaseTableInputType.TEXT:
        return (
          <TextCell2
            {...(managedCell as any)}
            value={managedCell.value}
            type={"text"}
            maxLength={
              managedCell.column.maxLength ?? managedCell.columnProps.maxLength
            }
            align={managedCell.columnProps.align}
            refreshCell={managedCell.refreshCellManager}
            inputFontType={managedCell.isBold ? "BOLD_BODY" : "BODY"}
          />
        );
      case BaseTableInputType.NUMBER:
        return (
          <TextCell2
            {...(managedCell as any)}
            value={managedCell.value}
            type={"number"}
            maxLength={
              managedCell.column.maxLength ?? managedCell.columnProps.maxLength
            }
            align={managedCell.columnProps.align}
            extraProps={{
              maxNumericValue: managedCell.columnProps.maxNumericValue,
              minNumericValue: managedCell.columnProps.minNumericValue,
              decimalScale: 0,
              prefix: "",
              thousandSeparator: managedCell.columnProps.thousandSeparator,
            }}
            refreshCell={managedCell.refreshCellManager}
          />
        );
      case BaseTableInputType.SSN:
        return (
          <InputTaxCell2 {...(managedCell as any)} value={managedCell.value} />
        );
      case BaseTableInputType.PHONE:
        return (
          <PhoneCell2 {...(managedCell as any)} value={managedCell.value} />
        );
      case BaseTableInputType.PHONE_AND_EXTENSION:
        return (
          <PhoneCell2
            {...(managedCell as any)}
            value={managedCell.value}
            phoneExt
          />
        );
      case BaseTableInputType.EMAIL:
        return (
          <TextCell2
            {...(managedCell as any)}
            value={managedCell.value}
            type={"email"}
            maxLength={
              managedCell.column.maxLength ?? managedCell.columnProps.maxLength
            }
            align={managedCell.columnProps.align}
          />
        );
      case BaseTableInputType.CURRENCY:
        return (
          <TextCell2
            {...(managedCell as any)}
            value={managedCell.value}
            type={"currency"}
            maxLength={
              managedCell.column.maxLength ?? managedCell.columnProps.maxLength
            }
            align={managedCell.columnProps.align}
            extraProps={{
              maxNumericValue: managedCell.columnProps.maxNumericValue,
              minNumericValue: managedCell.columnProps.minNumericValue,
              decimalScale: managedCell.columnProps.decimalScale,
              prefix: managedCell.columnProps.prefix ?? "",
              thousandSeparator: managedCell.columnProps.thousandSeparator,
              allowNegatives: managedCell.columnProps.allowNegatives ?? false,
            }}
            refreshCell={managedCell.refreshCellManager}
            inputFontType={managedCell.isBold ? "BOLD_BODY" : "BODY"}
          />
        );
      case BaseTableInputType.INPUT_WITH_ICON:
        return (
          <TextWithIconCell2
            {...(managedCell as any)}
            value={managedCell.value}
            type={"currency"}
            maxLength={
              managedCell.column.maxLength ?? managedCell.columnProps.maxLength
            }
            align={managedCell.columnProps.align}
            extraProps={{
              maxNumericValue: managedCell.columnProps.maxNumericValue,
              minNumericValue: managedCell.columnProps.minNumericValue,
              decimalScale: managedCell.columnProps.decimalScale,
              prefix: managedCell.columnProps.prefix ?? "",
              thousandSeparator: managedCell.columnProps.thousandSeparator,
              allowNegatives: managedCell.columnProps.allowNegatives ?? false,
            }}
            refreshCell={managedCell.refreshCellManager}
            inputFontType={managedCell.isBold ? "BOLD_BODY" : "BODY"}
          />
        );
      case BaseTableInputType.DECIMAL:
        return (
          <TextCell2
            {...(managedCell as any)}
            value={managedCell.value}
            type={"currency"}
            maxLength={
              managedCell.column.maxLength ?? managedCell.columnProps.maxLength
            }
            align={managedCell.columnProps.align}
            extraProps={{
              maxNumericValue: managedCell.columnProps.maxNumericValue,
              minNumericValue: managedCell.columnProps.minNumericValue,
              decimalScale: managedCell.columnProps.decimalScale,
              prefix: managedCell.columnProps.prefix ?? "",
              thousandSeparator: managedCell.columnProps.thousandSeparator,
            }}
            refreshCell={managedCell.refreshCellManager}
          />
        );
      case BaseTableInputType.CHECKBOX:
        return (
          <CheckBoxCell2 {...(managedCell as any)} value={managedCell.value} />
        );
      case BaseTableInputType.READONLY_CHECKBOX:
        return (
          <CheckBoxCell2
            {...(managedCell as any)}
            value={managedCell.value}
            isDisabled
          />
        );
      case BaseTableInputType.DATE_PICKER:
        return (
          <DatePickerCell2
            {...(managedCell as any)}
            value={managedCell.value}
          />
        );
      case BaseTableInputType.SELECT:
        if (managedCell.isEdit) {
        }
        return (
          <SelectCell2
            {...(managedCell as any)}
            value={managedCell.value}
            dataOptions={getApplicableList(managedCell)}
            selectMaxHeight={managedCell.columnProps.selectMaxHeight}
          />
        );
      case BaseTableInputType.READONLY_SELECT:
        return (
          <SelectCell2
            {...(managedCell as any)}
            value={managedCell.value}
            dataOptions={getApplicableList(managedCell)}
            selectMaxHeight={managedCell.columnProps.selectMaxHeight}
            isDisabled
          />
        );
      case BaseTableInputType.TAGS:
        return (
          <TagsCell2
            {...(managedCell as any)}
            dataOptions={managedCell.column?.displayOptions ?? []}
          />
        );
      case BaseTableInputType.LINK:
        return (
          <LinkCell2
            {...(managedCell as any)}
            alternateDisplayValues={
              managedCell.column?.alternateDisplayValues ?? []
            }
          />
        );
      case BaseTableInputType.DOWNLOAD_LINK:
        return (
          <DownloadLinkCell2
            {...(managedCell as any)}
            alternateDisplayValues={
              managedCell.column?.alternateDisplayValues ?? []
            }
          />
        );
      case BaseTableInputType.CHIPS:
        return (
          <ScoreChipCell2 {...(managedCell as any)} value={managedCell.value} />
        );
      case BaseTableInputType.MULTI_SELECTION:
        return (
          <MultiSelectCell rowKey={cellProps.rowKey} uiid={cellProps.uiid} />
        );
      case BaseTableInputType.INDICATION:
        return (
          <IndicationCell
            {...(managedCell as any)}
            uiid={cellProps.uiid}
            row={cellProps.row}
            value={managedCell.value ?? "none"}
          />
        );
      case BaseTableInputType.OPTIONS:
        return (
          <OptionsCell2
            uiid={cellProps.uiid}
            {...(managedCell as any)}
            optionType={managedCell?.value ?? "none"}
            contextMenuOptionRenderingCondition={
              tableInstance?.advancedOptions?.optionsContextActions
                ?.contextMenuOptionRenderingCondition ?? null
            }
            optionsContextActions={
              tableInstance?.advancedOptions?.optionsContextActions
                ?.contextComponent ??
              tableInstance?.advancedOptions?.optionsContextActions
                ?.contextMenu ??
              null
            }
            hydratedRow={hydrateAndCastDataSingle(
              tableInstance?.columns ?? [],
              // castRowToType(
              //   tableInstance?.columns ?? [],
              getRowByRowKey(cellProps.rowKey, tableInstance.data)
              // )
            )}
            rowIndex={cellProps.rowIndex}
          />
        );
      case BaseTableInputType.AUTOCOMPLETE:
        return (
          <AutocompleteCell2
            {...(managedCell as any)}
            dataOptions={getApplicableList(managedCell)}
          />
        );
      case BaseTableInputType.CONDITIONAL_RADIO_BUTTON:
        return (
          <ConditionalRadioButtonCell
            {...(managedCell as any)}
            value={managedCell.value}
          />
        );
      default:
        return (
          <TextCell2
            {...(managedCell as any)}
            type={"text"}
            readOnly
            value={managedCell.value}
            maxLength={
              managedCell.column.maxLength ?? managedCell.columnProps.maxLength
            }
            align={managedCell.columnProps.align}
          />
        );
    }
  };

  const isEditableOnCreation = (): boolean => {
    if (tableInstance?.selectedEditRows?.includes(cellProps.rowKey)) {
      return true;
    }
    return tableInstance.isEdit ?? false;
    // ? true
    // : tableInstance?.selectedEditRows === cellProps.rowKey
    // ? true
    // : false,
  };

  const createCellManagerObjects = (): CellManagerCell[] => {
    return (
      cellProps.row
        .map((value, i) => {
          const column = getVisibleColumns?.find((c) => c._columnIndex === i);
          const cellKey = `${i}_${cellProps.rowKey}` ?? NO_CELL_KEY_ERROR;

          //// onInitCompute logic start ////

          const initCompute = filterComputesOnInitByName(
            "conditionForCell",
            column?.fieldName ?? "NO_FIELD_NAME_FOUND"
          );

          const initComputeResults =
            initCompute
              ?.map((compute) => {
                return (
                  (compute?.fn?.({
                    cellKey: cellKey,
                    currentValue: value,
                    row: hydratedRow,
                    castedRow: hydratedAndCastedRow,
                    originalRow: hydrateDataSingle(
                      tableInstance?.columns ?? [],
                      cellProps.row
                    ),
                    //currentApiData: getApiData(),
                    responseType: null,
                  } as ComputeForCellParameters<any>) as ConditionForCellResponse) ??
                  null
                );
              })
              .filter((x) => x !== null) ?? [];

          // if (initComputeResults === undefined || initComputeResults === null) {
          //   // this only excutes when compute functions are void
          //   return null;
          // }

          const [firstInitComputeResult] = initComputeResults ?? null;
          const cellValue =
            firstInitComputeResult !== undefined
              ? firstInitComputeResult.value
              : value ?? "";

          //// onInitCompute logic end ////

          return {
            cellKey: cellKey ?? null,
            originalValue: value,
            previousValue: null,
            currentValue: cellValue ?? null,
            column: column ?? null,
            typeId:
              firstInitComputeResult?.typeId ??
              column?.type ??
              BaseTableInputType.TEXT,
            isEditable: isEditableOnCreation(),
            isRowHidden: false,
            refreshCellManager: false,
          } as CellManagerCell;
        })
        .filter((x) => x?.column !== null) ?? []
    );
  };

  const sortCellsByHeaderSortOrder = () => {
    const updatedSortedCells = [...cells].sort((a, b) => {
      if (
        (a?.column?._headerSortOrder ?? 0) < (b?.column?._headerSortOrder ?? 0)
      ) {
        return -1;
      }
      if (
        (a?.column?._headerSortOrder ?? 0) > (b?.column?._headerSortOrder ?? 0)
      ) {
        return 1;
      }
      return 0;
    });

    setSortedCells(updatedSortedCells);
  };

  const getColumnProps = (fieldName: string) => {
    if (
      tableInstance?.columnOptions !== null &&
      (tableInstance?.columnOptions?.length ?? 0) > 0
    ) {
      const columnInfo = tableInstance?.columnOptions?.find(
        (column) => column.fieldName === fieldName
      );
      return {
        align: columnInfo?.align,
        maxLength: columnInfo?.maxLength,
        maxNumericValue: columnInfo?.maxNumericValue,
        minNumericValue: columnInfo?.minNumericValue,
        decimalScale: columnInfo?.decimalScale,
        prefix: columnInfo?.prefix,
        selectMaxHeight: columnInfo?.selectMaxHeight,
        thousandSeparator: columnInfo?.thousandSeparator,
        allowNegatives: columnInfo?.allowNegatives,
      };
    }
    return {};
  };

  const updateCellsFromExternalUpdateSource = () => {
    if (tableInstance._recentlyUpdatedData.length > 0) {
      const rowKey = getSafeRowKey(
        tableInstance._recentlyUpdatedData[0] ?? null
      );
      if (rowKey === cellProps.rowKey) {
        const row = tableInstance._recentlyUpdatedData[0] ?? null;
        const updatedCells = cells.map((cell) => {
          return {
            ...cell,
            currentValue: row[cell.column._columnIndex],
          };
        });

        setCells(updatedCells);
      }

      setTableInstance({
        ...tableInstance,
        _recentlyUpdatedData: [],
        selectedEditRows: null,
      });
    }
  };

  useEffect(() => {
    if (cells.length > 0) {
      updateRowToEdit(tableInstance?.isEdit);
    }
  }, [tableInstance?.isEdit]);

  useEffect(() => {
    if (cells.length > 0 && isRequestedCellManager()) {
      const isEditable = tableInstance?.selectedEditRows?.includes(
        cellProps.rowKey
      )
        ? true
        : false;
      updateRowToEdit(isEditable);
    }
  }, [tableInstance?.selectedEditRows]);

  useEffect(() => {
    if (
      cells.length > 0 &&
      isRequestedCellManager() &&
      !tableInstance.toggleEditModeState
    ) {
      updateRowToEdit(tableInstance?.isEdit);
    }
  }, [tableInstance.toggleEditModeState]);

  useEffect(() => {
    executeInterceptedOnChangeEvent();
  }, [interceptedChanges]);

  useEffect(() => {
    processNextComputeInQueue();
  }, [
    tableInstance?._inProcessComputesQueue,
    tableInstance?._inProcessAPIResponse,
    interceptedChanges,
  ]);

  useEffect(() => {
    const cellInstance = getCellsInstance(cellProps.rowKey);

    if (cellInstance.hasCells === false) {
      const createdInstance = createCellManagerObjects();

      setCells(createdInstance);
    } else {
      setCells(cellInstance.cells);
    }
  }, []);

  useEffect(() => {
    if (cells.length > 0) {
      sortCellsByHeaderSortOrder();
    }
  }, [cells]);

  const isOptionsColumnType = (cell: any) =>
    cell.typeId === BaseTableInputType.OPTIONS;

  const isIndicationColumnType = (cell: any) =>
    cell.typeId === BaseTableInputType.INDICATION;

  const isMultiSelectionColumnType = (cell: any) =>
    cell.typeId === BaseTableInputType.MULTI_SELECTION;

  const isBoldCheck = (row: string[]) => {
    const boldColumn =
      tableInstance?.columns?.find(
        (c) => c.type === BaseTableInputType.TEXT_BOLD
      ) ?? null;
    if (boldColumn) {
      const hasBoldValue =
        row[boldColumn._columnIndex] === "true" ? true : false;
      return hasBoldValue;
    }
    return false;
  };

  useEffect(() => {
    if (sortedCells.length > 0) {
      const _renderedCells =
        sortedCells?.map((cell) => {
          const isOptionsColumn = isOptionsColumnType(cell);
          const isBold = isBoldCheck(cellProps.row);
          const isMultiSelectionColumn = isMultiSelectionColumnType(cell);
          const isIndicationColumn = isIndicationColumnType(cell);

          return (
            <TableData
              key={`td_${cell?.cellKey}`}
              cellType={cell?.column.type}
              width={getWidthOfCells(
                tableInstance?.columnOptions ?? [],
                tableInstance?.columns,
                cell.column.fieldName,
                isOptionsColumn,
                isMultiSelectionColumn,
                isIndicationColumn
              )}
            >
              {getCellType({
                refreshCellManager: cell.refreshCellManager,
                typeId:
                  cell.typeId ?? cell?.column.type ?? BaseTableInputType.TEXT,
                cellKey: cell?.cellKey,
                rowKey: cellProps.rowKey ?? NO_ROW_KEY_ERROR,
                // value: cell?.currentValue ?? "",
                value: cell.currentValue,
                onChange: (e) => {
                  setInterceptedChanges({
                    cellKey: cell?.cellKey,
                    rowKey: cellProps.rowKey,
                    incomingValue: e.value ?? "",
                    apiResponseData: null,
                    column: cell?.column,
                    typeId:
                      cell.typeId ??
                      cell?.column.type ??
                      BaseTableInputType.TEXT,
                    // responseType: "init",
                    hasValueChanges:
                      cell.currentValue !== e.value ? true : false,
                    computeTargetName: null,
                    computeTargetIndex: null,
                  });
                },
                column: cell?.column ?? null,
                isEdit: cell.isEditable ?? false,
                // cell?.isEditable === false
                //   ? false
                //   : tableInstance?.isEdit ?? false, // TODO - temp hack
                // isEdit: tableInstance.isEdit ?? false, // TODO - original
                isComputeDisabled: cell?.isEditable ?? true,
                variant: getCellVariant(
                  cell?.column?.cellVariant ?? CellVariantEnums.STANDARD
                ),
                errorMessage: getErrorsByCell(
                  cellProps.rowKey,
                  cell?.column.fieldName
                ),
                extraProps: {},
                listDisplayOptions: cell.listDisplayOptions ?? null,
                columnProps: getColumnProps(cell?.column.fieldName),
                isBold: isBold,
                // isOptionsHide: isOptionsColumnHide,
              })}
            </TableData>
          );
        }) ?? [];

      setRenderedCells(_renderedCells);

      // Refresh Cell Logic
      if (!cells.every((c) => c.refreshCellManager === false)) {
        const updatedCellRefreshValue = cells.map((c) => {
          if (c.refreshCellManager) {
            return { ...c, refreshCellManager: false };
          } else return c;
        });

        updateCellManagerStateByCellManager(updatedCellRefreshValue);
      }
    }
  }, [sortedCells]);

  useEffect(() => {
    if (conditionHasValue(tableInstance?.validationErrors)) {
      sortCellsByHeaderSortOrder();
    }
  }, [tableInstance.validationErrors]);

  useEffect(() => {
    updateCellsFromExternalUpdateSource();
  }, [tableInstance._recentlyUpdatedData]);

  return cells !== undefined &&
    cells !== null &&
    cells?.length > 0 &&
    renderedCells !== null &&
    renderedCells?.length > 0 ? (
    <>{renderedCells}</>
  ) : null;
};

export default BaseTable2CellManager;
