import { FC } from "react";
import "./TableStyles.css";
import { FormattingDate } from "../../../utilities/dateFunctions";
import { INTERNAL_TABLE_COLUMNS } from "../Tables/TableConstants";
import {
  isJsonString,
  splitPhoneNumberAndExtension,
} from "../../../utilities/stringFunctions";
import { getTagsAsCommaSeparatedString } from "../Tables/tableFunctions";
import { DataTypeEnums } from "../../../dtos/data-type-enums";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
type DefaultTableTemplateProperties = {
  columns: any[];
  data: string[][];
};

const DefaultTableTemplate: FC<DefaultTableTemplateProperties> = ({
  columns,
  data,
}) => {
  const generateColumnHeaders = () => {
    return columns
      .filter(
        (column) =>
          !column.isHidden && !INTERNAL_TABLE_COLUMNS.includes(column.fieldName)
      )
      .map((column: any, index: number) => {
        return <th key={index}>{column.displayName}</th>;
      });
  };

  const formatURL = (alternateDisplayValue) => {
    const url =
      alternateDisplayValue?.displayValuesOrFieldNames[1] ??
      alternateDisplayValue?.displayValuesOrFieldNames[0];

    if (conditionHasValue(url)) {
      if (url?.charAt(0) === "/") return url;
      const cleanURL = url.replace("http://", "").replace("https://", "");
      return "https://" + cleanURL;
    }
  };

  const castColumn = (columnContent, column) => {
    if (column?.defaultValueType === DataTypeEnums.DATE) {
      return FormattingDate(columnContent);
    }

    if (column?.displayOptions != null) {
      if (isJsonString(columnContent)) {
        return getTagsAsCommaSeparatedString(
          columnContent,
          column?.displayOptions
        );
      } else {
        const option = column?.displayOptions.find(
          (option) => option.value === columnContent
        );
        return option?.text ?? "";
      }
    }

    if (column?.alternateDisplayValues !== null) {
      const alternatives = column?.alternateDisplayValues ?? null;
      if (alternatives === null) {
        return columnContent;
      }

      const alternative = alternatives.find((a) => a.guid === columnContent);
      if (alternative === null || alternative?.length === 0) {
        return columnContent;
      }

      return (
        <a
          title={alternative?.displayValuesOrFieldNames?.[0]}
          href={formatURL(alternative)}
          target={alternative?.displayValuesOrFieldNames?.[2] ?? "_blank"}
        >
          {alternative?.displayValuesOrFieldNames?.[0]}
        </a>
      );
    }

    const match = splitPhoneNumberAndExtension(columnContent?.toString());
    if (match) {
      const phoneNumber = match[1];
      const extension = match[2] ? `-${match[2]}` : "";
      return `${phoneNumber}${extension}`;
    }

    return columnContent;
  };

  const generateRows = () => {
    return (data ?? []).map((row: string[], rowIndex: number) => {
      return (
        <tr key={`PDFrow ${rowIndex}`}>
          {row?.map((columnContent: any, columnIndex: number) => {
            return (
              !columns[columnIndex]?.isHidden &&
              !INTERNAL_TABLE_COLUMNS.includes(
                columns[columnIndex]?.fieldName
              ) && (
                <td
                  key={`PDFColumn ${columnIndex}`}
                  style={{ maxWidth: "200px", wordWrap: "break-word" }}
                >
                  {castColumn(columnContent, columns[columnIndex])}
                </td>
              )
            );
          })}
        </tr>
      );
    });
  };

  return (
    <div className="true_table_pdf_container">
      <table className="true_pdf_table">
        <thead>
          <tr>{generateColumnHeaders()}</tr>
        </thead>
        <tbody>{generateRows()}</tbody>
      </table>
    </div>
  );
};

export default DefaultTableTemplate;
