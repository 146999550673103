import { useEffect } from "react";
import { RecoilState, useRecoilCallback, useRecoilValue } from "recoil";
import { conditionHasValue } from "../../../../utilities/conditionalSupportFunctions";
import { tableInstanceAtomFamily2 } from "../TableAtoms";
import {
  getDataOfRowKeys,
  getExternalDataOnlyOfMultipleRows,
} from "../tableFunctions";
import {
  MultiSelectOptions,
  RowKey,
  TableInstanceType2,
} from "./TableProperties";

const BaseTable2MultiSelectManager = (props) => {
  const tableInstance = useRecoilValue(
    tableInstanceAtomFamily2(props.uiid) as RecoilState<TableInstanceType2>
  );

  const setTableInstance = useRecoilCallback(
    ({ set }) =>
      (newValueTableInstance: TableInstanceType2) => {
        set(tableInstanceAtomFamily2(props.uiid), {
          ...tableInstance,
          ...newValueTableInstance,
          uiid: props.uiid,
        });
      },
    []
  );

  const copyAction = (rows: RowKey[]) => {
    const fullData = getDataOfRowKeys(
      tableInstance?.sortedAndFilteredData,
      rows ?? []
    );

    const selectedRows = getExternalDataOnlyOfMultipleRows(
      tableInstance?.columns,
      fullData
    );

    setTableInstance({
      ...(tableInstance as TableInstanceType2),
      _accessors: {
        ...tableInstance._accessors,
        _addMultipleRows: { rows: selectedRows, isEditableAfterAdd: false },
      },
    });
  };

  const deleteAction = (rowsKeys: RowKey[]) => {
    setTableInstance({
      ...(tableInstance as TableInstanceType2),
      _accessors: {
        ...tableInstance._accessors,
        _deleteMultipleRows: { rowsKeys },
      },
    });
  };

  const updateAction = (rowsKeys: RowKey[]) => {
    setTableInstance({
      ...(tableInstance as TableInstanceType2),
      _accessors: {
        ...tableInstance._accessors,
        _updateMultipleRows: { rowsKeys },
      },
    });
  };

  const downloadAction = (rowsKeys: any) => {
    setTableInstance({
      ...(tableInstance as TableInstanceType2),
      _accessors: {
        ...tableInstance._accessors,
        _downloadMultipleRows: { rowsKeys },
      },
    });
  };

  const executeAction = (actionToExecute: MultiSelectOptions) => {
    const selectedRows = tableInstance?.selectedRows ?? [];

    switch (actionToExecute?.name) {
      case "copy":
        return copyAction(selectedRows);
      case "delete":
        return deleteAction(selectedRows);
      case "update":
        return updateAction(selectedRows);
      case "download":
        return downloadAction(selectedRows);
      default:
        console.warn("Nothing to do");
    }
  };

  const validateAction = (actionToExecute: MultiSelectOptions) => {
    if ((tableInstance?.selectedRows?.length ?? 0) > 0) {
      executeAction(actionToExecute);
      // In the case that you need something extra or do a custom action
      actionToExecute?.customActionMethod?.(tableInstance?.selectedRows);
    } else {
      console.warn("There are no rows selected");
    }
    actionToExecute?.onActionComplete?.(true);
  };

  useEffect(() => {
    const actionToExecute = tableInstance?.multiSelectOptions?.find(
      (action) => action?.dispatchAction
    );
    conditionHasValue(actionToExecute) &&
      validateAction(actionToExecute as MultiSelectOptions);
  }, [tableInstance?.multiSelectOptions]);

  return null;
};

export default BaseTable2MultiSelectManager;
