import { useMsal } from "@azure/msal-react";
import { Container, ListItemText, MenuItem, MenuList } from "@mui/material";
import { globalOptions, hasAppAccess } from "../../GlobalAtoms";
import { useRecoilState, useSetRecoilState } from "recoil";
import { useEffect, useState } from "react";
import { useApiAuthenticate, useApiGet } from "../../hooks";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";
import { useNavigate } from "react-router-dom";
import { PortalInformationDto } from "../../dtos/portal-information-dto";
import style from "./InsuredSelector.module.css";
import { Font, Heading4, Heading6, Loading, TitleBold } from "../TrueUI";
import { PortalListInsuredDto } from "../../dtos/portal-list-insured-dto";
import { conditionHasValue } from "../../utilities/conditionalSupportFunctions";
import { getPathBase } from "../../hooks/useURL";
import { UserTypeEnum } from "../../dtos/user-type-enum";

const Landing = () => {
  const [localOptions, setLocalOptions] = useRecoilState(globalOptions);
  const setHasAppAccess = useSetRecoilState(hasAppAccess);

  const [availableInsureds, setAvailableInsureds] =
    useState<PortalListInsuredDto[]>();
  const [selectedInsuredId, setSelectedInsuredId] = useState<any>(null);

  const { accounts } = useMsal();
  const navigate = useNavigate();

  const {
    responseGet: responseInitPortal,
    dispatchGet: dispatchInitPortalGet,
  } = useApiGet<PortalInformationDto>(
    `api/TenantInformation/getUserPortalInformation?key=${
      accounts[0].localAccountId
    }&portalUrl=${getPathBase()}`
  );

  const getPortalType = () => {
    const userType = responseInitPortal?.axiosResponse?.data?.userType;
    if (userType === UserTypeEnum.AGENCY_PORTAL) {
      return "Agency";
    }
    return "Insured";
  };

  const [authenticationStatus, dispatchInsuredAuthentication] =
    useApiAuthenticate(`api/PortalAuthentication/authenticateUser`, {
      tenantId: responseInitPortal?.axiosResponse?.data?.tenantId,
      programId: responseInitPortal?.axiosResponse?.data?.programId,
      userId: responseInitPortal?.axiosResponse?.data?.userId,
      programUserId: responseInitPortal?.axiosResponse?.data?.programUserId,
      insuredId: selectedInsuredId ?? 0,
      agencyId: responseInitPortal?.axiosResponse?.data?.agencyId ?? 0,
      timeZoneId: responseInitPortal?.axiosResponse?.data?.timeZoneId,
    });

  useEffect(() => {
    dispatchInitPortalGet();
  }, []);

  useEffect(() => {
    if (isAPITotallyComplete(responseInitPortal)) {
      if (responseInitPortal?.axiosResponse?.data != null) {
        const responseData = responseInitPortal?.axiosResponse?.data;
        setAvailableInsureds(responseData?.availableInsureds);
        setLocalOptions({
          ...localOptions,
          theme: {
            ...localOptions.theme,
            primary: responseData?.primaryColor ?? localOptions.theme.primary,
            logoPath:
              responseData?.loginLogoPath ?? localOptions.theme.loginLogoPath,
            backgroundPath:
              responseData?.backgroundPath ?? localOptions.theme.backgroundPath,
          } as any,
        });
      }
    }
  }, [responseInitPortal]);

  useEffect(() => {
    const responseData = responseInitPortal?.axiosResponse?.data;
    if (availableInsureds?.length === 1) {
      setSelectedInsuredId(responseData?.availableInsureds?.[0].insuredId);
    }
    // To give access to the Agency User
    if (responseData?.agencyId !== 0 && responseData?.userType === 3) {
      setSelectedInsuredId(0);
    }
  }, [availableInsureds]);

  useEffect(() => {
    if (selectedInsuredId !== null) {
      dispatchInsuredAuthentication();
    }
  }, [selectedInsuredId]);

  useEffect(() => {
    if (authenticationStatus === 200) {
      setHasAppAccess(true);
    } else {
      setHasAppAccess(false);
    }
  }, [authenticationStatus]);

  return (
    <>
      {conditionHasValue(responseInitPortal?.axiosResponse?.data) ? (
        <div
          style={{
            backgroundImage: `url(${localOptions?.theme?.backgroundPath})`,
            backgroundOrigin: "center",
            backgroundSize: "cover",
            width: "100%",
            height: "100%",
          }}
        >
          <Container maxWidth={"lg"}>
            <div className={style.logo_container}>
              <img
                className={style.company_logo}
                data-tenant-branding-logo="true"
                src={localOptions?.theme?.logoPath}
                alt="Company Logo"
                style={{ width: "450px", marginTop: "90px", height: "100px" }}
              />
            </div>
            <div
              className={style.insured_selector_container_list}
              style={{ border: `2px solid ${localOptions?.theme?.primary}` }}
            >
              {(availableInsureds?.length ?? 0) > 1 && (
                <div style={{ marginTop: "100px", textAlign: "center" }}>
                  <Heading4>Select an Insured</Heading4>
                  <MenuList
                    sx={{ maxHeight: "300px", overflow: "hidden auto" }}
                  >
                    {(availableInsureds ?? []).map(
                      (insured: any, i: number) => {
                        return (
                          <MenuItem
                            key={"u" + i}
                            className="insured-item"
                            onClick={() => {
                              setSelectedInsuredId(insured?.insuredId);
                              navigate("/");
                            }}
                          >
                            <ListItemText
                              sx={{
                                textAlign: "center",
                                fontSize: "24px",
                                display: "block",
                              }}
                            >
                              {insured?.insuredName}
                            </ListItemText>
                          </MenuItem>
                        );
                      }
                    )}
                  </MenuList>
                </div>
              )}
              {availableInsureds?.length === 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: "80%",
                    height: "100%",
                  }}
                >
                  <Font whiteSpace="normal" truncate>
                    {`This user does not have access to the ${getPortalType()} Portal. If you
                    think you have received this message in error, please
                    contact your carrier for additional support.`}
                  </Font>
                </div>
              )}
            </div>

            <div className={style.footer_container}>
              <Heading6>Power By</Heading6>
              <img
                src="https://truestatic.blob.core.windows.net/root/TrueLogo-RGB.svg"
                width="10%;"
              />
              <TitleBold>
                Copyright © 2023 True Insurtech Solutions LLC. All rights
                reserved.
              </TitleBold>
            </div>
          </Container>
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
};

export default Landing;
