import { CertificateDto } from "../../../../dtos/certificate-dto";
import { CertificateStatusEnums } from "../../../../dtos/certificate-status-enums";
import { SelectOptions } from "../../../../dtos/select-options";
import { FromEnumToArray } from "../../../../utilities/enumFunctions";
import { getStates } from "../../../../utilities/staticDataFunctions";
import { ButtonWithConfirmationProps } from "../../../TrueUI/Buttons/ButtonWithConfirmation";

export const defaultCertificateData = (
  modalMode: string,
  insuredId: number,
  portalAutoApproveCert: boolean
): CertificateDto => ({
  certificateId: 0,
  insuredId,
  insuredCopyOnly: false,
  certHolder: "",
  certDba: "",
  certAddress1: "",
  certAddress2: "",
  certCity: "",
  certState: "",
  certPostalCode: "",
  certPhone: "",
  certFax: "",
  certAttention: "",
  certEmail: "",
  jobName: "",
  comments: "",
  issueAtRenewal: false,
  auditSource: "Portal",
  issuedBy: "",
  issueDate: new Date(),
  certificateStatus: getUpdateCertificateStatus(
    CertificateStatusEnums.PENDING,
    modalMode,
    false,
    portalAutoApproveCert
  ),
});
export const certStatusOptions = FromEnumToArray(CertificateStatusEnums).map(
  (enumType) => ({
    displayName: enumType.key,
    stringValue: enumType.value,
  })
);
export const certStateOptions: Partial<SelectOptions>[] = getStates().map(
  (state): Partial<SelectOptions> => ({
    displayName: state.abbreviation,
    stringValue: state.abbreviation,
  })
);

export const getUpdateCertificateStatus = (
  statusFromFormData,
  modalMode,
  insuredCopyCheckValue,
  portalAutoApproveCert
) => {
  const updatedCertStatus = { value: statusFromFormData };
  if (modalMode === "ADD") {
    updatedCertStatus.value = CertificateStatusEnums.PENDING;
    if (insuredCopyCheckValue || portalAutoApproveCert === true) {
      updatedCertStatus.value = CertificateStatusEnums.ISSUED;
    }
  }
  return updatedCertStatus.value;
};

export const getInsuredCopyOnlyData = (
  formData,
  modalMode,
  insuredCopyCheckValue,
  portalAutoApproveCert,
  certificateHolder
) => ({
  insuredCopyOnly: insuredCopyCheckValue,
  certHolder: certificateHolder,
  certDba: "",
  certAddress1: "",
  certAddress2: "",
  certCity: "",
  certState: "",
  certPostalCode: "",
  certAttention: "",
  certPhone: "",
  certFax: "",
  certEmail: "",
  jobName: "",
  certificateStatus: getUpdateCertificateStatus(
    formData?.certificateStatus,
    modalMode,
    insuredCopyCheckValue,
    portalAutoApproveCert
  ),
});

export const getExtraButtons = (dispatchPrint, onReissueCertificate) => {
  const extraButtons: ButtonWithConfirmationProps[] = [
    {
      textOnButton: "PRINT",
      onClick: () => {
        dispatchPrint();
      },
    },
    {
      textOnButton: "RE-ISSUE",
      onClick: () => {
        onReissueCertificate();
      },
    },
  ];

  return extraButtons;
};
