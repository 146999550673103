import { FC } from "react";
import Heading3 from "../Typography/Heading3";
import Paper from "./Paper";

type PortalPageContainerProps = {
  title: string;
  children?: any;
  paperPadding?: string;
  paperOverflowX?: "visible" | "hidden" | "clip" | "scroll" | "auto";
  childrenContainerHeight?: string;
};
const PortalPageContainer: FC<PortalPageContainerProps> = ({
  title,
  children,
  paperPadding = "0",
  paperOverflowX,
  childrenContainerHeight,
}) => {
  return (
    <>
      <Heading3 display="block" textAlign="start">
        {title}
      </Heading3>
      <Paper
        fullHeight
        padding={paperPadding}
        overflowX={paperOverflowX}
        childrenContainerHeight={childrenContainerHeight}
      >
        {children}
      </Paper>
    </>
  );
};
export default PortalPageContainer;
