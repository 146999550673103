import { FC } from "react";
import { sumFloat, sumInt } from "../../../utilities/arrayFunctions";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import Input from "../../TrueUI/Inputs/Input";
import { ColumnOptionsProperties } from "../../TrueUI/Tables/BaseTable2/TableProperties";
import { TableData, TableRow } from "../../TrueUI/Tables/BaseTableStructure";

type PayrollReportDetailsFooterProps = {
  rows: any;
  columnOptionsProps: ColumnOptionsProperties<any>[];
};

const PayrollReportDetailsFooter: FC<PayrollReportDetailsFooterProps> = ({
  rows,
  columnOptionsProps,
}) => {
  const totalEmployees = sumInt(rows?.map((row) => row?.NumEE ?? 0));
  const totalPayroll = sumFloat(rows?.map((row) => row?.NetPayroll ?? 0));
  const totalAmount = sumFloat(rows?.map((row) => row?.Amount ?? 0));

  const printCellValue = (
    column,
    totalEmployees,
    totalPayroll,
    totalAmount
  ) => {
    switch (column) {
      case "Description":
        return (
          <Input
            type={"text"}
            id={"total"}
            name={"total"}
            value={"Totals"}
            inputFontType={"BOLD_BODY"}
            align="right"
            readOnly
          />
        );
      case "NumEE":
        return (
          <div style={{ width: "100%", paddingRight: "12px" }}>
            <Input
              type={"number"}
              id={"total-employees"}
              name={"total-employees"}
              value={totalEmployees}
              inputFontType={"BOLD_BODY"}
              align="right"
              readOnly
              key={totalEmployees}
            />
          </div>
        );
      case "NetPayroll":
        return (
          <div style={{ width: "100%", paddingRight: "12px" }}>
            <Input
              type={"fixedCurrency"}
              id={"total-payroll"}
              name={"total-payroll"}
              value={totalPayroll}
              inputFontType={"BOLD_BODY"}
              align="right"
              prefix=""
              readOnly
              key={totalPayroll}
            />
          </div>
        );
      case "Amount":
        return (
          <div style={{ width: "100%", paddingRight: "12px" }}>
            <Input
              type={"fixedCurrency"}
              id={"total-amount"}
              name={"total-amount"}
              value={totalAmount}
              inputFontType={"BOLD_BODY"}
              align="right"
              prefix=""
              readOnly
              key={totalAmount}
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <TableRow
        style={{
          width: "100%",
          display: "flex",
          fontWeight: "700",
          height: "48px",
        }}
      >
        {columnOptionsProps.map(
          (column, key) =>
            column.width != 0 && (
              <TableData
                key={key}
                width={
                  conditionHasValue(column.width) ? `${column.width}%` : "auto"
                }
              >
                {printCellValue(
                  column.fieldName,
                  totalEmployees,
                  totalPayroll,
                  totalAmount
                )}
              </TableData>
            )
        )}
      </TableRow>
    </>
  );
};
export default PayrollReportDetailsFooter;
