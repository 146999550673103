import { Box } from "@mui/material";
import { FC, useEffect, useState, useCallback, useRef } from "react";
import { SelectOptions } from "../../../dtos/select-options";
import { usePermissions } from "../../../hooks";
import Font from "../Typography/Font";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FontsType } from "../../../media/themeTypes";
import { KeysAsType } from "../../../types/KeysAsAType";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../GlobalAtoms";
import { conditionHasValue } from "../../../utilities/conditionalSupportFunctions";
import {
  getCoordsForOptionContainer,
  setCurrentElementOption,
  getPositionTopByOptionsPosition,
  updateOptionWidthToLargestOptionWidth,
  isAcceptKey,
  isCancelKey,
  getSelectedOptionIndexByKeyPress,
} from "./SelectUtils";
import { getVariant } from "../Inputs/InputUtil";
import {
  TABBABLE_CLASS_NAME,
  focusNextElement,
} from "../../../utilities/tabFunctions";
import style from "./select.module.css";
import { PermissionsEnums } from "../../../dtos/permissions-enums";

export type FocusedSelectOptionProps = {
  index: number;
  keySelected: string;
};

export type SelectProperties = {
  id: string;
  name: string;
  trueElement?: string;
  label?: string;
  className?: string;
  errorMessage?: string[] | null;
  labelPosition?: "start" | "end" | "top" | "bottom";
  value?: any;
  options: SelectOptions[] | Partial<SelectOptions>[];
  readOnly?: boolean;
  disabled?: boolean;
  variant?: "filled" | "standard";
  permissions?: PermissionsEnums[];
  helperText?: string;
  inputWidth?: string;
  optionsMaxHeight?: string;
  inputFontType?: KeysAsType<FontsType>;
  labelFontType?: KeysAsType<FontsType>;
  labelTextAlign?: "start" | "center" | "end";
  firstOptionAsDefault?: boolean;
  isCustomArrow?: boolean;
  optionsContainerPosition?: "bottom" | "top";
  type?: "standard" | "tableFilter" | "tableCell";
  onChange?: (e: any) => void;
  tabIndex?: number;
  errorValues?: any[];
  title?: string;
};

const Select: FC<SelectProperties> = ({
  id = "",
  name = "",
  trueElement,
  label,
  className,
  errorMessage,
  labelPosition = "top",
  value,
  options,
  readOnly,
  disabled = false,
  variant,
  permissions, // TODO: implement permissions @ELara
  helperText,
  inputWidth = "100%",
  optionsMaxHeight = "auto",
  inputFontType = "BODY",
  labelFontType = "BOLD_BODY",
  labelTextAlign = "start",
  firstOptionAsDefault = true,
  isCustomArrow = false,
  optionsContainerPosition = "bottom",
  type = "standard",
  onChange,
  tabIndex = 0,
  errorValues = [],
  title = "",
}) => {
  const selectRef = useRef<HTMLDivElement | null>(null);
  const labelRef = useRef<HTMLDivElement | null>(null);
  const [focusedSelectOption, setFocusedSelectOption] =
    useState<FocusedSelectOptionProps>({ index: -1, keySelected: "" });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isThereError, setIsThereError] = useState(false);
  const hasPermission = usePermissions(permissions ?? []);
  const localOptions = useRecoilValue(globalOptions);
  const theme = localOptions?.theme;
  const [optionContainerWidth, setOptionContainerWidth] = useState(0);

  const isDisabled = disabled || !hasPermission;

  const SELECT_ITEM_CLASS = `select_item_option_${id}`;

  useEffect(() => {
    function setOptionsWidthByWindowsResize() {
      setOptionContainerWidth(selectRef?.current?.clientWidth ?? 0);
    }

    window.addEventListener("resize", setOptionsWidthByWindowsResize);

    return () => {
      window.removeEventListener("resize", setOptionsWidthByWindowsResize);
    };
  }, []);

  const getValueFromOption = (option) => {
    return (
      option?.intValue ??
      option?.stringValue ??
      option?.decimalValue ??
      option?.dateValue ??
      option?.booleanValue ??
      null
    );
  };

  const getSelectedValue = useCallback(
    (_value, _options: Partial<SelectOptions>[]) => {
      if (_value !== undefined && _value !== null) {
        const selectedOption = _options?.find(
          (x: Partial<SelectOptions>) =>
            x.intValue === _value ||
            x.stringValue === _value ||
            x.decimalValue === _value ||
            x.dateValue === _value ||
            x.booleanValue === _value
        );

        return selectedOption === undefined ? null : _value;
      }
      return firstOptionAsDefault
        ? getValueFromOption(_options?.[0] ?? [])
        : null;
    },
    [firstOptionAsDefault]
  );
  const [selectedValue, setSelectedValue] = useState<any>(
    getSelectedValue(value, options)
  );

  const getErrorHelper = useCallback(() => {
    if (errorMessage != null) {
      setIsThereError(true);
    } else {
      setIsThereError(false);
    }
  }, [errorMessage]);

  useEffect(() => {
    getErrorHelper();
  }, [errorMessage, getErrorHelper]);

  useEffect(() => {
    if (value !== selectedValue) {
      const _selectedValue = getSelectedValue(value, options);
      setSelectedValue(_selectedValue);
      if (_selectedValue != null) {
        if (firstOptionAsDefault) {
          onChange?.(_selectedValue);
        }
      }
    }
  }, [value]);

  useEffect(() => {
    updateOptionWidthToLargestOptionWidth(
      isOpen,
      selectRef,
      options,
      setOptionContainerWidth,
      setFocusedSelectOption
    );
  }, [isOpen]);

  useEffect(() => {
    setCurrentElementOption(SELECT_ITEM_CLASS, focusedSelectOption.index);
  }, [setFocusedSelectOption]);

  useEffect(() => {
    if (isOpen === true) {
      setFocusedSelectedOptionByValue();
    }
  }, [isOpen]);

  const setFocusedSelectedOptionByValue = () => {
    const indexOfValue = options.findIndex(
      (x) =>
        x.stringValue === value ||
        x.intValue === value ||
        x.booleanValue === value ||
        x.decimalValue === value ||
        x.dateValue === value
    );
    setFocusedSelectOption({
      index: indexOfValue,
      keySelected: "",
    });
  };

  const getSelectedOption = (_value, _options: Partial<SelectOptions>[]) => {
    if (_value !== undefined && _value !== null) {
      const selectedOption = _options?.find(
        (x: Partial<SelectOptions>) =>
          x.intValue === _value ||
          x.stringValue === _value ||
          x.decimalValue === _value ||
          x.dateValue === _value ||
          x.booleanValue === _value
      );

      return selectedOption;
    }
    return firstOptionAsDefault ? _options?.[0] ?? [] : null;
  };

  const onSelectedOption = (option) => {
    onChange?.(getValueFromOption(option));
    setSelectedValue(getValueFromOption(option));
    setIsOpen(!isOpen);
  };

  const onOptionKeyPressEvent = (event, option) => {
    if (isAcceptKey(event) && isOpen === true) onSelectedOption(option);
    if (isCancelKey(event)) setIsOpen(false);
    if (event.code === "Tab") {
      event.preventDefault();
      setIsOpen(false);
      focusNextElement(id);
    }
    if (
      event.code === "ArrowDown" &&
      isOpen === true &&
      focusedSelectOption.index < options.length - 1
    ) {
      setFocusedSelectOption({
        ...focusedSelectOption,
        index: focusedSelectOption.index + 1,
      });
    }
    if (
      event.code === "ArrowUp" &&
      isOpen === true &&
      focusedSelectOption.index > 0
    ) {
      setFocusedSelectOption({
        ...focusedSelectOption,
        index: focusedSelectOption.index - 1,
      });
    }

    if (event.key.length === 1 && event.key.match(/[a-zA-Z0-9]/)) {
      setFocusedSelectOption(
        getSelectedOptionIndexByKeyPress(event, options, focusedSelectOption)
      );
    }
  };

  const onContainerKeyPressEvent = (event) => {
    if (isAcceptKey(event)) setIsOpen(!isOpen);
    if (isCancelKey(event)) setIsOpen(false);
    if (event.code === "Tab") {
      event.preventDefault();
    }
    if (event.code === "ArrowDown" && isOpen === false)
      if (isOpen === false) setIsOpen(true);
  };
  const trueElementTag = {
    "true-element": trueElement
      ? `true-select-options-${trueElement}`
      : `true-select-options-${name}`,
  };
  const inputProps = {
    id: id,
    name: name,
    ...trueElementTag,
  };
  const inputSize = theme?.[inputFontType]?.SIZE;
  const labelSize = label ? theme?.[labelFontType]?.SIZE : "0px";

  const getStyleType = () => {
    switch (type) {
      case "tableFilter":
        return "true_select_options_container_table_filter";
      case "tableCell":
        return "true_select_options_container_table_cell";
      default:
        return "";
    }
  };

  const GetStyleLabelPosition = () => {
    switch (labelPosition) {
      case "start":
        return style.true_input_container_start;
      case "top":
        return style.true_input_container_top;
      case "end":
        return style.true_input_container_end;
      case "bottom":
        return style.true_input_container_bottom;
      default:
        return "";
    }
  };

  const getOptionContainer = () => {
    const { top, left } = getCoordsForOptionContainer(selectRef.current);

    return isOpen && !readOnly && !isDisabled ? (
      <div
        className={style.true_select_option_container_paper}
        onClick={isOpen ? () => setIsOpen(!isOpen) : () => {}}
      >
        <div
          className={`${style.true_select_options_container} ${getStyleType()}`}
          style={{
            transform: `translateY(calc(${inputSize} + ${labelSize} + ${
              label ? "23px" : "17px"
            }))`,
            maxHeight: optionsMaxHeight,
            width: `${optionContainerWidth}px`,
            top: getPositionTopByOptionsPosition(
              top,
              options?.length ?? 0,
              optionsContainerPosition,
              labelPosition,
              conditionHasValue(label),
              labelRef?.current?.offsetHeight ?? 0,
              optionsMaxHeight.includes("px")
                ? parseInt(optionsMaxHeight.split("px")[0])
                : null
            ),
            left: left,
            height: "auto",
          }}
          {...trueElementTag}
        >
          {options?.map((option) => (
            <button
              className={`${
                option?.displayName === "" ? style.empty_option : ""
              } ${style.true_select_option} ${SELECT_ITEM_CLASS} ${
                getValueFromOption(option) === selectedValue
                  ? style.true_select_option_is_selected
                  : ""
              } ${
                option?.displayName === ""
                  ? style.true_select_option_empty_option
                  : ""
              }`}
              key={`true-select-option${getValueFromOption(option)}`}
              onClick={() => onSelectedOption(option)}
              onKeyUp={(e) => onOptionKeyPressEvent(e, option)}
              title={option?.displayName}
            >
              <Font fontType={inputFontType}>{option?.displayName}</Font>
            </button>
          ))}
        </div>
      </div>
    ) : (
      <></>
    );
  };

  return (
    <>
      <Box
        className={`${style.true_input_general_container} select_true_input_general_container`}
        width={`${
          labelPosition === "start" || labelPosition === "end"
            ? "fit-content"
            : inputWidth
        } !important`}
      >
        <Box
          className={`${GetStyleLabelPosition()} select_true_input_container`}
          width={`${
            labelPosition === "start" || labelPosition === "end"
              ? "fit-content"
              : inputWidth.includes("%")
              ? "100%"
              : inputWidth
          } !important`}
        >
          {label != null && (
            <div
              className={style.true_input_label_container_for_select}
              ref={labelRef}
            >
              <Font
                name={name}
                trueElement={
                  trueElement ? trueElement : `true-element-select-${name}`
                }
                className={`${style.label_for_select} true-input-label ${
                  labelFontType === undefined ? "withoutSize" : ""
                }`}
                fontType={labelFontType}
                textAlign={labelTextAlign}
              >
                {label}
              </Font>
            </div>
          )}
          <div
            {...inputProps}
            tabIndex={tabIndex}
            className={`${
              style.true_input_select_container
            } ${TABBABLE_CLASS_NAME} ${
              getVariant(readOnly, variant) === "filled" ? style.filled : ""
            } ${errorMessage != null ? style.true_input_error_txt : ""} ${
              readOnly ? style.true_input_select_container_read_only : ""
            } ${
              isDisabled ? style.true_input_select_container_is_disabled : ""
            } ${
              isThereError ? style.true_input_select_container_has_error : ""
            } ${className} ${theme} ${
              isOpen && !readOnly && !isDisabled
                ? style.true_input_select_container_open
                : "closed"
            } ${getStyleType()}`}
            style={{
              width: inputWidth.includes("%") ? "100%" : inputWidth,
            }}
            title={title}
            onClick={() => !readOnly && setIsOpen(!isOpen)}
            onKeyUp={(e) => onContainerKeyPressEvent(e)}
            ref={selectRef}
          >
            <Font
              name={name}
              trueElement={
                trueElement ? trueElement : `true-element-select-${name}`
              }
              fontType={inputFontType}
              className={`${style.select_displayed_option} ${
                isDisabled ? style.select_displayed_option_is_disabled : ""
              }`}
              isErrorFont={errorValues.includes(value)}
            >
              {getSelectedOption(selectedValue, options)?.displayName}
            </Font>
            <div className={style.select_arrow_container}>
              {!readOnly &&
                !isDisabled &&
                (isCustomArrow ? (
                  <KeyboardArrowDownIcon
                    className={`${style.select_arrow_icon} ${
                      isOpen && !readOnly && !isDisabled
                        ? style.select_arrow_icon_open
                        : "closed"
                    }`}
                  />
                ) : (
                  <ArrowDropDownIcon
                    className={`${style.select_arrow_icon} ${
                      isOpen && !readOnly && !isDisabled
                        ? style.select_arrow_icon_open
                        : "closed"
                    }`}
                  />
                ))}
            </div>
          </div>
          {errorMessage != null && !isOpen && (
            <span className={style.true_input_error_txt}>
              {errorMessage?.join(" ")}
            </span>
          )}
        </Box>
        {helperText && (
          <span className="true_input_helper_text">{helperText}</span>
        )}
        {getOptionContainer()}
      </Box>
    </>
  );
};

export default Select;
