import { FC, useEffect, useState } from "react";
import { useSetRecoilState } from "recoil";
import { PortalDocCategoryDto } from "../../dtos/portal-doc-category-dto";
import { PortalDocDoubleCategoryDto } from "../../dtos/portal-doc-double-category-dto";
import { PortalDocumentDto } from "../../dtos/portal-document-dto";
import { PortalDocumentsPage } from "../../dtos/portal-documents-page";
import { useApiGet } from "../../hooks";
import { isAPITotallyComplete } from "../../utilities/apiFunctions";
import { Title } from "../TrueUI";
import PortalPageContainer from "../TrueUI/Containers/PortalPageContainer";
import ButtonCollapse from "../TrueUI/Documents/CategoryButtonCollapse";
import DoubleButtonCollapse from "../TrueUI/Documents/DoubleCategoryButtonCollapse";
import ListDocument from "../TrueUI/Documents/ListDocument";
import SearchTextNoPredictive from "../TrueUI/Search/SearchTextNoPredictive";
import { refreshDocumentsAtom } from "./DocumentAtoms";
import { DocumentsProps, portalAreaInformation } from "./DocumentUtils";
import ListDocumentContainer from "./ListDocumentContainer";

const Documents: FC<DocumentsProps> = ({ portalArea }) => {
  const [readyToRender, setReadyToRender] = useState<boolean>(false);
  const portalInfo = portalAreaInformation.find(
    (portal) => portal.areaId === portalArea
  );

  const setRefreshDocuments = useSetRecoilState(refreshDocumentsAtom);

  const { responseGet, dispatchGet } = useApiGet<PortalDocumentsPage>(
    `api/Documents/GetPortalDocCategories?portalDocArea=${portalArea}`
  );

  const primaryCategories: PortalDocCategoryDto[] =
    responseGet?.axiosResponse?.data?.primaryCategories ?? [];
  const secondaryCategories: PortalDocDoubleCategoryDto[] =
    responseGet?.axiosResponse?.data?.secondaryCategories ?? [];

  const ListItems = (documents: PortalDocumentDto[]) => (
    <ListDocument
      documents={documents}
      onSaveFavorite={() => setRefreshDocuments(true)}
      isFromSearch
    />
  );

  useEffect(() => {
    if (isAPITotallyComplete(responseGet)) {
      setReadyToRender(true);
    }
  }, [responseGet]);

  useEffect(() => {
    dispatchGet();
  }, []);

  return (
    <>
      {readyToRender && (
        <PortalPageContainer
          title={portalInfo?.areaTitle ?? ""}
          paperPadding={"10px"}
          paperOverflowX={"hidden"}
        >
          <Title display="block" whiteSpace="normal" textAlign="start">
            {portalInfo?.areaDescription}
          </Title>
          <div style={{ width: "70%", margin: "20px auto 0 auto" }}>
            <SearchTextNoPredictive
              tabIndex={1}
              searchTextKey="documentName"
              placeholderText={"Search"}
              name={"searchDocument"}
              saveSelectedResult={(result) => console.log({ result })}
              additionalParams={`&portalArea=${portalArea}`}
              CustomComponent={ListItems}
              menuPadding={"0 10px 10px 10px"}
              floatList={false}
              url={"api/Documents/SearchPortalDocuments?searchText="}
            />
            {primaryCategories?.map((primaryCategory) => (
              <ButtonCollapse
                title={primaryCategory?.categoryName ?? ""}
                key={`primary-category-${primaryCategory?.section}`}
              >
                <ListDocumentContainer category={primaryCategory} />
              </ButtonCollapse>
            ))}
            {secondaryCategories?.map((secondaryCategory) => (
              <DoubleButtonCollapse
                informationCategory1={secondaryCategory?.categoryOne}
                informationCategory2={secondaryCategory?.categoryTwo}
                key={`categories-${secondaryCategory?.categoryOne?.portalDocCategoryID}-${secondaryCategory?.categoryOne?.portalDocCategoryID}`}
              />
            ))}
          </div>
        </PortalPageContainer>
      )}
    </>
  );
};
export default Documents;
