import { FC, useEffect, useState } from "react";
import { PaymentModalConfigurationProps } from "../PaymentSelector/PaymentSelectorTypes";
import { Modal } from "../../../TrueUI";
import CreditCardPaymentForm from "./CreditCardPaymentForm";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../../../GlobalAtoms";
import AccountAndBalanceSection from "../AccountAndBalance/AccountAndBalanceSection";
import { BillingTransactionDataDto } from "../../../../dtos/billing-transaction-data-dto";
import { useApiGet, useApiPost } from "../../../../hooks";
import { TransactionRequestDto } from "../../../../dtos/transaction-request-dto";
import {
  CreditCardInfo,
  evaluateErrorResponse,
} from "./CreditCardPaymentUtils";
import {
  conditionHasValue,
  isEmptyValue,
} from "../../../../utilities/conditionalSupportFunctions";
import { TransactionResponseDto } from "../../../../dtos/transaction-response-dto";
import { ConfigurationNewPaymentDto } from "../../../../dtos/configuration-new-payment-dto";
import { ProcessorTypeEnum } from "../../../../dtos/processor-type-enum";
import FontError from "../../../TrueUI/Typography/FontError";

type AchModalProps = {
  paymentModalConfiguration: PaymentModalConfigurationProps;
  setPaymentModalConfiguration?: (
    value: PaymentModalConfigurationProps
  ) => void;
  setUiid?: (value: string) => void;
};
const ModalCreditCardPayment: FC<AchModalProps> = ({
  paymentModalConfiguration,
  setPaymentModalConfiguration,
  setUiid,
}) => {
  const localOptions = useRecoilValue(globalOptions);
  const insuredId = localOptions?.initOptions?.insuredId;

  const [creditCardInfo, setCreditCardInfo] =
    useState<Partial<CreditCardInfo> | null>({
      cardMonth: 1,
      emailFormatError: null,
    });

  const [transactionRequest, setTransactionRequest] =
    useState<TransactionRequestDto>();

  const [billingTransactionData, setBillingTransactionData] = useState<
    BillingTransactionDataDto[]
  >([]);

  const { responsePost, dispatchPost, validatorErrorResponse } =
    useApiPost<TransactionResponseDto>(
      "api/AuthorizeNet/AuthorizeCreditCard",
      transactionRequest
    );

  const { responseGet: responseGetConfig, dispatchGet: dispatchGetConfig } =
    useApiGet<ConfigurationNewPaymentDto>(
      `api/BillingPayments/GetConfigurationNewPayment`
    );
  const [errorDetails, setErrorDetails] = useState<any>();

  const onSaveEvent = () => {
    setErrorDetails(null);
    setTransactionRequest({
      postcode: creditCardInfo?.cardBillingZipCode?.toString() ?? "",
      cardNumber: creditCardInfo?.cardNumber?.toString() ?? "",
      cardCode: creditCardInfo?.cardCvv?.toString() ?? "",
      month: creditCardInfo?.cardMonth?.toString() ?? "",
      year: creditCardInfo?.cardYear?.toString() ?? "",
      emailReceiptTo: creditCardInfo?.emailReceipt?.toString() ?? "",
      insuredID: insuredId ?? 0,
      paymentData: billingTransactionData,
    });
  };

  const onCancelEvent = () => {
    setPaymentModalConfiguration?.({
      ...paymentModalConfiguration,
      isOpenModalCreditCardPayment: false,
    });
  };

  const onCloseEvent = () => {
    setErrorDetails(null);
    setCreditCardInfo({
      cardMonth: 1,
      emailFormatError: null,
    });
  };

  useEffect(() => {
    if (paymentModalConfiguration.isOpenModalCreditCardPayment) {
      dispatchGetConfig();
    }
  }, [paymentModalConfiguration.isOpenModalCreditCardPayment]);

  useEffect(() => {
    if (!isEmptyValue(transactionRequest)) {
      dispatchPost();
    }
  }, [transactionRequest]);

  useEffect(() => {
    if (
      responsePost.requestInstanceSuccessful &&
      !conditionHasValue(validatorErrorResponse) &&
      isEmptyValue(responsePost.axiosResponse?.data.errorCode)
    ) {
      const response = responsePost.axiosResponse?.data;
      if (!isEmptyValue(response?.transactionID)) {
        onCancelEvent();
        setUiid?.(crypto.randomUUID());
      }
    }
    evaluateErrorResponse(
      setErrorDetails,
      responsePost,
      validatorErrorResponse
    );
  }, [responsePost]);
  
  return (
    <Modal
      id={"credit-card-payment"}
      title={"Credit Card Payment"}
      open={paymentModalConfiguration.isOpenModalCreditCardPayment}
      showCloseButton={true}
      deleteEvent={undefined}
      showCancelTextButton={true}
      saveEvent={onSaveEvent}
      cancelEvent={onCancelEvent}
      closeEvent={onCloseEvent}
      saveOverrideLabel={"OK"}
      cancelButtonWithConfirmation
    >
      <CreditCardPaymentForm
        insuredId={insuredId}
        creditCardInfo={creditCardInfo}
        setCreditCardInfo={setCreditCardInfo}
        errorDetails={errorDetails}
      />
      <AccountAndBalanceSection
        insuredId={insuredId}
        setBillingTransactionData={setBillingTransactionData}
        processorType={ProcessorTypeEnum.CREDIT_CARD}
        tranAccountOptions={
          responseGetConfig?.responseData?.tranAccountOptions ?? []
        }
      />
      {errorDetails && (
        <FontError display="inline-block" maxWidth="840px" truncate>
          {errorDetails.code}: {errorDetails.msg}
        </FontError>
      )}
    </Modal>
  );
};

export default ModalCreditCardPayment;
