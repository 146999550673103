import { FC } from "react";
import Paper from "../TrueUI/Containers/Paper";
import { useBaseTable } from "../../hooks/useBaseTable";
import { BaseTable2Properties } from "../TrueUI/Tables/BaseTable2/TableProperties";
import { BaseTable2, Divider } from "../TrueUI";
import Link from "../TrueUI/Typography/Link";
import { usePermissions } from "../../hooks";

const ActivePolicies: FC = () => {
  const hasPermission = usePermissions([29]);

  const tableName = "policies-table";

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: "api/Dashboard/GetActivePolicies",
    advancedOptions: {
      paginate: false,
      tableStyle: {
        headerStyle: "normal",
      },
    },
    toolbarOptions: {
      hideToolbar: true,
    },
    columnOptions: [
      { fieldName: "Coverage", width: "20" },
      { fieldName: "PolicyNumber", width: "20" },
      { fieldName: "InsuredName", width: "30" },
      { fieldName: "Dates", width: "20" },
      { fieldName: "Status", width: "10" },
    ],
  };

  useBaseTable(tableConfiguration);

  return (
    <Paper title="Active Policies" collapse>
      <BaseTable2 name={tableName} />
      <Divider height={"20px"} />
      {hasPermission && (
        <Link href={"/policy"} displayValue="View all policies  >" />
      )}
    </Paper>
  );
};

export default ActivePolicies;
