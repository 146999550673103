import { FC, useEffect, useState } from "react";
import { BaseTable2 } from "../TrueUI";
import { useBaseTable } from "../../hooks/useBaseTable";
import Paper from "../TrueUI/Containers/Paper";
import BaseTableDropdownFilter from "../TrueUI/Tables/BaseTableCommonFilters/BaseTableDropdownFilter";
import { SelectOptions } from "../../dtos/select-options";
import {
  BaseTable2Properties,
  OptionsContextActionParameterProperties,
} from "../TrueUI/Tables/BaseTable2/TableProperties";
import { IncidentDateRange } from "../../dtos/incident-date-range";
import { Box, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import { IncidentStatusEnums } from "../../dtos/incident-status-enums";
import { useNavigate } from "react-router";
import { INCIDENT_ID } from "../../utilities/queryStringsHash";
import { IncidentMergeFieldsAndTemplateNameDto } from "../../dtos/incident-merge-fields-and-template-name-dto";
import { useApiGet } from "../../hooks";
import { FormTypeEnum } from "../../dtos/form-type-enum";
import { isEmptyValue } from "../../utilities/conditionalSupportFunctions";
import DialogConfirmation from "../TrueUI/Dialogs/DialogConfirmation";
import { useFormRequest } from "../../hooks/useFileStorage";

type SelectedFilters = {
  statusSelected: number;
  dateRangeSelected: number;
};
type IncidentInfo = {
  incidentId: number | null;
  incidentJurisdiction: string;
  incidentClaimantName: string;
};

const tableName = "incidents-table";

const indicentStatusFilterOptions: Partial<SelectOptions>[] = [
  { displayName: "All", intValue: -1 },
  { displayName: "In Progress Only", intValue: 1 },
  { displayName: "Complete", intValue: 2 },
];

const indicentDateRangeFilterOptions: Partial<SelectOptions>[] = [
  { displayName: "All", intValue: IncidentDateRange.ALL },
  { displayName: "Last 60 Days", intValue: IncidentDateRange.SIXTY_DAYS },
  { displayName: "Last 6 Months", intValue: IncidentDateRange.SIX_MONTHS },
  { displayName: "Last 12 Months", intValue: IncidentDateRange.TWELVE_MONTHS },
];

const IncidentsTable: FC = () => {
  const navigate = useNavigate();

  const [selectedFilers, setSelectedFilers] = useState<SelectedFilters>({
    statusSelected: 1,
    dateRangeSelected: 1,
  });

  const [incidentInfo, setIncidentInfo] = useState<IncidentInfo>({
    incidentId: null,
    incidentJurisdiction: "",
    incidentClaimantName: "",
  });

  const [dialogOpen, setDialogOpen] = useState<boolean>(false);

  const { sendMergeFormRequest } = useFormRequest();

  const { responseGet, dispatchGet } =
    useApiGet<IncidentMergeFieldsAndTemplateNameDto>(
      `api/PortalIncident/GetIncidentJSON?incidentId=${incidentInfo?.incidentId}&stateCode=${incidentInfo.incidentJurisdiction}`
    );

  const printFROI = (
    templateNameAndIncidentJSON?: IncidentMergeFieldsAndTemplateNameDto
  ) => {
    if (
      templateNameAndIncidentJSON !== undefined &&
      templateNameAndIncidentJSON !== null &&
      !isEmptyValue(templateNameAndIncidentJSON.templateName)
    ) {
      const claimantName = incidentInfo.incidentClaimantName
        ? `${incidentInfo.incidentClaimantName} `
        : "";

      sendMergeFormRequest({
        formType: FormTypeEnum.INCIDENT_FORM,
        jsonObjectWithMergeFields:
          templateNameAndIncidentJSON.incidentMergeFields,
        templateNames: [templateNameAndIncidentJSON.templateName],
        customFileName: `${
          templateNameAndIncidentJSON.incidentMergeFields.incidentID ?? ""
        } ${claimantName}FROI`,
        printOrDownload: "download",
      });
    }
    if (isEmptyValue(templateNameAndIncidentJSON?.templateName)) {
      setDialogOpen(true);
    }
  };

  useEffect(() => {
    if (incidentInfo.incidentId !== null) dispatchGet();
  }, [incidentInfo]);

  useEffect(() => {
    if (responseGet.requestInstanceSuccessful) {
      printFROI(responseGet.axiosResponse?.data);
    }
  }, [responseGet]);

  const IncidentOptions = (
    actionOptions: OptionsContextActionParameterProperties<any>
  ) => {
    return (
      <Box>
        {actionOptions.row?.Status === IncidentStatusEnums.IN_PROGRESS ? (
          <IconButton
            aria-label="save"
            size="small"
            onClick={() =>
              navigate(
                `/incident#${INCIDENT_ID}=${actionOptions.row?.IncidentId}`
              )
            }
          >
            <EditIcon fontSize="small" />
          </IconButton>
        ) : (
          <IconButton
            aria-label="save"
            size="small"
            onClick={() =>
              setIncidentInfo((prev) => ({
                ...prev,
                incidentId: actionOptions.row?.IncidentId,
                incidentJurisdiction: actionOptions.row?.Jurisdiction,
                incidentClaimantName: actionOptions.row?.Claimant,
              }))
            }
          >
            <PrintIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    );
  };

  const tableConfiguration: BaseTable2Properties = {
    name: tableName,
    getURL: `api/Claims/GetIncidentsTable?status=${selectedFilers.statusSelected}&dateRange=${selectedFilers.dateRangeSelected}`,
    advancedOptions: {
      paginate: false,
      tableStyle: {
        headerStyle: "normal",
        height: "165px",
      },
      optionsColumnConfiguration: {
        isHidden: false,
        optionType: "component",
      },
      optionsContextActions: {
        contextComponent: (actionOptions) => IncidentOptions(actionOptions),
      },
    },
    toolbarOptions: {
      showAddButton: false,
      showEditButton: false,
      showSortFilter: false,
      showImportButton: false,
    },
    filterOptions: [
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "incident-status-filter",
          filterOptions: actionOptions,
          optionsOverride: indicentStatusFilterOptions,
          defaultValue: 1,
          usingExternalFiltering: true,
          isStaticFilter: true,
          onChange: (value) =>
            setSelectedFilers((prev) => ({ ...prev, statusSelected: value })),
        }),
      (actionOptions) =>
        BaseTableDropdownFilter({
          id: "incident-date-range-filter",
          filterOptions: actionOptions,
          optionsOverride: indicentDateRangeFilterOptions,
          defaultValue: 1,
          usingExternalFiltering: true,
          isStaticFilter: true,
          onChange: (value) =>
            setSelectedFilers((prev) => ({
              ...prev,
              dateRangeSelected: value,
            })),
        }),
    ],
  };

  useBaseTable(tableConfiguration);
  return (
    <Paper title={"Incident Reports"} margin="12px 0">
      <BaseTable2 name={tableName} />
      <DialogConfirmation
        id="id-no-template-message"
        dialogDescriptionText="No print template found."
        optionYesOverrideLabel="OK"
        open={dialogOpen}
        onOptionYesEvent={setDialogOpen}
        onCloseEvent={setDialogOpen}
      />
    </Paper>
  );
};

export default IncidentsTable;
