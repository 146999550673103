import { FC, useState } from "react";
import PortalPageContainer from "../TrueUI/Containers/PortalPageContainer";
import PayrollReports from "./PayrollReports";
import Activity from "./Activity";
import Payments from "./Payments";
import CurrentBillingHeader from "./CurrentBillingHeader";
import { Heading6 } from "../TrueUI";
import style from "./Billing.module.css";
import ModalNewPaymentContainer from "./PayrollReport/PaymentSelector/ModalNewPaymentContainer";
import ScheduledPayments from "./ScheduledPayments";
import { useRecoilValue } from "recoil";
import { globalOptions } from "../../GlobalAtoms";

const Billing: FC = () => {
  const [refreshPaymentsTableData, setRefreshPaymentsTableData] =
    useState<string>(crypto.randomUUID());
  const [isModalPaymentsOpen, setIsModalPaymentsOpen] =
    useState<boolean>(false);

  const localOptions = useRecoilValue(globalOptions);
  const insuredId = localOptions?.initOptions?.insuredId;

  return (
    <PortalPageContainer title={"Billing"}>
      <Heading6>Current Billing</Heading6>
      <div className={style.billing_components_container}>
        <CurrentBillingHeader setIsModalPaymentsOpen={setIsModalPaymentsOpen} />
        <div style={{ marginTop: "5px" }}>
          <Activity />
          <PayrollReports />
          <Payments />
          <ScheduledPayments
            insuredId={insuredId ?? 0}
            refreshTable={refreshPaymentsTableData}
          />
        </div>
      </div>
      <ModalNewPaymentContainer
        isOpen={isModalPaymentsOpen}
        setIsModalPaymentsOpen={setIsModalPaymentsOpen}
        setRefreshPaymentsTableData={setRefreshPaymentsTableData}
      />
    </PortalPageContainer>
  );
};
export default Billing;
